import React from 'react'
import { IMobileLink } from '@local/src/App.types'
import { useEpiContent } from '@trr/app-shell-data'
import { Box, Link, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'

import DistinctionIllustration from '../DistinctionIllustration/DistinctionIllustration'
import { parseExternalLinks } from '../Home.helpers'
import { HomePageEpiContent } from '../Home.types'

const ExternalLinks = (): JSX.Element => {
  const content = useEpiContent<HomePageEpiContent>()
  const externalLinks = parseExternalLinks(content)

  return (
    <Box
      display='flex'
      flexDirection={{xs: 'column', lg: 'row' }}
      justifyContent={{xs: 'center', lg: 'space-between' }}>
      {Object.keys(externalLinks).map((key) => (
        <Box key={key}>
          <Typography variant='h5' mb={2}>{key}</Typography>
          <Box display='flex' flexDirection='column' gap={2} alignItems={{xs: 'center', lg: 'unset'}}>
            {externalLinks[key]?.map((link: IMobileLink) => {
              return (
                <Link
                  key={link.url}
                  href={link.url}
                  target={link.isExternal ? '_blank' : '_self'}
                  display={{xs: link.showOnMobile ? 'flex' : 'none', md: 'flex'}}
                  gap={1}
                  alignItems='center'>
                  {link.isExternal && <LaunchIcon fontSize='small' /> }
                  {link.name}
                </Link>
              )
            })}
          </Box>
        </Box>
      ))}
      <DistinctionIllustration />
    </Box>
  )
}

export default ExternalLinks
