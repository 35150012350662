import React, { useState } from 'react'
import Filter from '@local/Blocks/Filter'
import FilterTags from '@local/Components/FilterTags'
import CasesFilter from '@local/src/Components/CasesFilter/CasesFilter'
import { Facets } from '@local/src/Common.types'
import { IArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'
import TuneIcon from '@mui/icons-material/Tune'
import { Box, Button, Collapse, IconButton, Paper } from '@mui/material'

import { IFilterTag } from '../Arenden.types'
import { arendenEmptyFilterState } from '../Arenden.reducers'

export interface ArendenFilterPanelProps {
  resetArendenFilter(): void
  removeFilterParameterValuePair(tag: IFilterTag): void
  filterTags?: IFilterTag[]
  facets: Facets
  subStatusFacets: Facets
  filter: IArendenFilterState
  setFilterParameters(filterParams: Partial<IArendenFilterState>): void
  isDinaArenden?: boolean
}

export const ArendenFilterPanel = ({
  filterTags,
  resetArendenFilter,
  removeFilterParameterValuePair,
  facets,
  subStatusFacets,
  filter,
  setFilterParameters,
  isDinaArenden,
}: ArendenFilterPanelProps): JSX.Element => {

  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <Box alignSelf='flex-start' position='sticky' top='0' height={isExpanded ? '100vh' : 'inherit'} pb={2}>
      <Box pb={1} sx={{ direction: 'ltr' }} borderBottom={isExpanded && '1px solid'} borderColor='neutral.divider'>
        {isExpanded && <Button startIcon={<TuneIcon />} variant='text' size='medium' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded && 'Filter'}</Button>}
        {!isExpanded && <IconButton onClick={() => setIsExpanded(!isExpanded)} sx={{color:'primary.main'}}><TuneIcon /></IconButton>}
      </Box>
      <Collapse in={isExpanded} orientation='horizontal'>
        <Paper elevation={0} sx={{ width: '280px', direction: 'rtl', mt: 2, overflowY: 'auto', height: '100vh', pb: '75px' }} data-testid="arende-filter-panel">
          <Filter>
            <>
              {filterTags.length !== 0 && (
                <FilterTags
                  filterTags={filterTags}
                  removeFilterParameterValuePair={removeFilterParameterValuePair}
                  resetFilter={resetArendenFilter}
                />
              )}
              <CasesFilter
                isDinaArenden={isDinaArenden}
                emptyFilterState={arendenEmptyFilterState}
                facets={facets}
                subStatusFacets={subStatusFacets}
                filter={filter}
                setFilterParameters={setFilterParameters}
              />
            </>
          </Filter>
        </Paper>
      </Collapse>
    </Box>
  )
}

export default ArendenFilterPanel
