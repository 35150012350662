import { ICaseDetails } from '@local/src/Views/Case/Case.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v2.0/arenden'

export const arendenApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getArende: builder.query<ICaseDetails, string>({
      query: (arendeId) => `${baseUrl}/${arendeId}`
    })
  })
})

export const {
  useGetArendeQuery,
} = arendenApi