import React from 'react'
import { ListItem, ListItemText, Typography } from '@mui/material'

import { Avslutshandelse, educationLengthOptions, sunCodeOptions, typeOfEducationOptions } from '../../../Avslut.types'
import { getLabelByValue } from '../Common'
import { AvslutshandelseCardBase } from '../Common/AvslutshandelseCardBase'

export interface StlEmploymentDetails {
  typeOfEducation: string
  sunCode: string
  educationLength: string
  educationOccupancy: string
  distanceStudies: string
  retraining: string
}

export type StlEmploymentDetailsKey = keyof StlEmploymentDetails

interface IStlAvslutshandelseProps {
  avslutshandelse: Avslutshandelse
}

const StlAvslutshandelse = ({ avslutshandelse }: IStlAvslutshandelseProps): JSX.Element => {
  const answers: StlEmploymentDetails = Object.fromEntries(
    avslutshandelse.avslutsFragorSvar?.map((item) => [item.key, item.value])
  ) as Record<StlEmploymentDetailsKey, string>

  const typeOfEducation = getLabelByValue(typeOfEducationOptions, answers.typeOfEducation)
  const sunCode = getLabelByValue(sunCodeOptions, answers.sunCode)
  const educationLength = getLabelByValue(educationLengthOptions, answers.educationLength)

  return (
    <AvslutshandelseCardBase avslutshandelse={avslutshandelse}>
      <Typography variant={'h6'}>Studieuppgifter</Typography>
      <ListItem>
        <ListItemText primary={'Typ av utbildning'} secondary={typeOfEducation} />
      </ListItem>
      <ListItem>
        <ListItemText primary={'SUN-kod'} secondary={sunCode} />
      </ListItem>
      <ListItem>
        <ListItemText primary={'Utbildningens längd'} secondary={educationLength} />
      </ListItem>
      <ListItem>
        <ListItemText primary={'Utbildningens omfattning'} secondary={answers.educationOccupancy === 'true' ? 'Heltid' : 'Deltid'} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Studieform'}
          secondary={answers.distanceStudies === 'true' ? 'Distansundervisning' : 'Undervisning på plats'}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={'Påbyggnad/omskolning'}
          secondary={answers.retraining === 'true' ? 'Omskolning i ny yrkesinriktning' : 'Påbyggnad i tidigare yrkesinriktning'}
        />
      </ListItem>
    </AvslutshandelseCardBase>
  )
}

export default StlAvslutshandelse
