/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAction, createActions } from 'redux-actions'
import { get, put, deleteRequest, post } from '@local/Utils/network'

import { IAgeArende } from './Case.types'
import { EventBody } from './EventLog/EventLog.types'

export const {
  changeAdvisorInitiate,
  changeAdvisorFailure,
  changeAdvisorSuccess,
  clearChangingAdvisorState,

  changeTeamInitiated,
  changeTeamSuccess,
  changeTeamFailure,
  clearChangingTeamState,

  clearCase,

  fetchEventsInitiate,
  fetchEventsFailure,
  fetchEventsSuccess,

  updateEventInitiate,
  updateEventFailure,
  updateEventSuccess,

  deleteEventInitiate,
  deleteEventSuccess,
  deleteEventFailure,

  createEventInitiate,
  createEventSuccess,
  createEventFailure,

  fetchDocumentsInitiate,
  fetchDocumentsSuccess,
  fetchDocumentsFailure,

  postDocumentInitiate,
  postDocumentSuccess,
  postDocumentFailure,

  fetchDocumentInitiate,
  fetchDocumentSuccess,
  fetchDocumentFailure,

  deleteDocumentInitiate,
  deleteDocumentSuccess,
  deleteDocumentFailure,

  resetPostDocumentState,

  fetchUtbetalningsbeslutInitiate,
  fetchUtbetalningsbeslutSuccess,
  fetchUtbetalningsbeslutFailure,

  updateMarkningInitiate,
  updateMarkningSuccess,
  updateMarkningFailure,

  fetchAgeArendeMedRattTillAgeInitiate,
  fetchAgeArendeMedRattTillAgeSuccess,
  fetchAgeArendeMedRattTillAgeFailure,

  resetState,

  fetchCaseInitiate,
  fetchCaseSuccess,
  fetchCaseFailure,

  updatePreferredLanguageInitiated,
  updatePreferredLanguageSuccess,
  updatePreferredLanguageFailure,

  fetchUserStatusInitiated,
  fetchUserStatusSuccess,
  fetchUserStatusFailure,

  fetchUserJmtStatusInitiated,
  fetchUserJmtStatusSuccess,
  fetchUserJmtStatusFailure,
} = createActions({
  fetchCaseInitiate: (reload: boolean) => reload,
  fetchCaseSuccess: (caseDetails) => ({ caseDetails }),
  fetchCaseFailure: (errorMessage) => {
    return `Det gick inte att hämta ärendet. ${String(errorMessage)}`
  },

  resetPostDocumentState: () => null,

  postDocumentInitiate: (body) => body,
  postDocumentSuccess: (body) => ({
    body,
    successMessage: 'Dokumentet har laddats upp',
  }),
  postDocumentFailure: (errorMessage) => {
    return `Det gick inte att ladda upp dokumentet. ${String(errorMessage)}`
  },

  fetchEventsInitiate: () => null,
  fetchEventsSuccess: (events) => ({ events }),
  fetchEventsFailure: (errorMessage) => {
    return `Det gick inte att hämta händelser i tidslinjen. ${String(errorMessage)}`
  },

  deleteDocumentInitiate: () => null,
  deleteDocumentSuccess: (responseBody) => ({
    responseBody,
    successMessage: 'Dokumentet har tagits bort',
  }),
  deleteDocumentFailure: (errorMessage) => {
    return `Det gick inte att ta bort dokumentet. ${String(errorMessage)}`
  },

  updateEventInitiate: (requestBody) => requestBody,
  updateEventSuccess: (responseBody) => ({
    responseBody,
    successMessage: 'Händelsen uppdaterades',
  }),
  updateEventFailure: (errorMessage) => {
    return `Det gick inte att redigera händelsen. ${String(errorMessage)}`
  },

  deleteEventInitiate: () => null,

  deleteEventSuccess: (deletedEventId) => ({
    deletedEventId,
    successMessage: 'Händelsen togs bort',
  }),
  deleteEventFailure: (errorMessage) => {
    return `Det gick inte att ta bort händelsen. ${String(errorMessage)}`
  },

  createEventInitiate: (requestBody) => requestBody,
  createEventSuccess: (responseBody) => ({
    responseBody,
    successMessage: 'Händelsen har skapats',
  }),
  createEventFailure: (errorMessage) => {
    return `Det gick inte att skapa händelsen ${String(errorMessage)}`
  },

  changeAdvisorInitiate: () => null,
  changeAdvisorFailure: (errorMessage) => {
    return `Det gick inte att byta rådgivare på ärendet ${String(errorMessage)}`
  },
  changeAdvisorSuccess: (requestBody: IChangeAdvisorBody) => ({
    ...requestBody,
    successMessage: 'Rådgivare bytt',
  }),
  updateMarkningInitiate: () => null,
  updateMarkningSuccess: () => ({ successMessage: 'Märkningen uppdaterades' }),
  updateMarkningFailure: (errorMessage) => {
    return `Det gick inte att uppdatera märkningen på ärendet. ${String(errorMessage)}`
  },
  clearChangingAdvisorState: () => null,
  changeTeamInitiated: () => null,
  changeTeamSuccess: (requestBody: IChangeTeamBody) => {
    return { ...requestBody, successMessage: 'Team bytt' }
  },
  changeTeamFailure: (errorMessage) => {
    return `Det gick inte att byta team på ärendet. ${String(errorMessage)}`
  },
  clearChangingTeamState: () => null,

  clearCase: () => null,

  fetchDocumentsInitiate: () => null,
  fetchDocumentsSuccess: (documents) => ({
    documents,
  }),
  fetchDocumentsFailure: (errorMessage) => {
    return `Det gick inte att hämta några dokument. ${String(errorMessage)}`
  },

  fetchDocumentInitiate: () => null,
  fetchDocumentSuccess: (file: Blob, fileName: string) => {
    //https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
    const anchor = document.createElement('a')
    document.body.appendChild(anchor)
    const objectUrl = window.URL.createObjectURL(file)
    anchor.href = objectUrl
    anchor.download = fileName
    anchor.target = '_blank'
    anchor.click()
    return { file }
  },
  fetchDocumentFailure: (errorMessage) => {
    return `Det gick inte att ladda ner dokumentet. ${String(errorMessage)}`
  },

  fetchUtbetalningsbeslutInitiate: () => null,
  fetchUtbetalningsbeslutSuccess: (utbetalningsbeslut) => utbetalningsbeslut,
  fetchUtbetalningsbeslutFailure: (errorMessage) => {
    return `Det gick inte att hämta några utbetalningsbeslut. ${String(errorMessage)}`
  },

  fetchAgeArendeMedRattTillAgeInitiate: () => null,
  fetchAgeArendeMedRattTillAgeSuccess: (ageArendeMedRattTillAge: IAgeArende) => ageArendeMedRattTillAge,
  fetchAgeArendeMedRattTillAgeFailure: (errorMessage) => {
    return `Det gick inte att hämta det öppnade AGE-ärendet. ${String(errorMessage)}`
  },

  updatePreferredLanguageInitiated: () => null,
  updatePreferredLanguageSuccess: () => ({ successMessage: 'Föredraget språk uppdaterades' }),
  updatePreferredLanguageFailure: () => {
    return 'Det gick inte att uppdatera föredraget språk'
  },

  fetchUserStatusInitiated: () => null,
  fetchUserStatusSuccess: (karriarVagledningStatuses) => {
    // Remove userId because we don't use it
    const { userId, ...karriarVagledningStatusesWithoutUserId } = karriarVagledningStatuses
    return karriarVagledningStatusesWithoutUserId
  },
  fetchUserStatusFailure: (errorMessage) => {
    return `Det gick inte att hämta status för Karriärvägledning. ${String(errorMessage)}`
  },

  fetchUserJmtStatusInitiated: () => null,
  fetchUserJmtStatusSuccess: (jmtStatus) => jmtStatus,
  fetchUserJmtStatusFailure: (errorMessage) => {
    return `Det gick inte att hämta statusen för Karriärvägledning. ${String(errorMessage)}`
  },

  resetState: (resetStateKey: string) => resetStateKey,
})

export interface IChangeAdvisorBody {
  arendeId: string
  radgivareId: string
}

export const deleteDocument = (arendeId: string, dokumentId: string) => {
  return deleteRequest({
    url: `/arenden/${arendeId}/dokument/${dokumentId}`,
    initiated: deleteDocumentInitiate,
    fulfilled: deleteDocumentSuccess,
    rejected: deleteDocumentFailure,
  })
}

export const postDocument = (arendeId: string, body: FormData) => {
  return post({
    url: `/arenden/${arendeId}/dokument`,
    initiated: postDocumentInitiate,
    fulfilled: postDocumentSuccess,
    rejected: postDocumentFailure,
    multipart: true,
    body,
  })
}
export const fetchDocument = (arendeId: string, documentId: string, fileName: string) =>
  get({
    url: `/arenden/${arendeId}/dokument/${documentId}`,
    initiated: fetchDocumentInitiate,
    fulfilled: fetchDocumentSuccess,
    rejected: fetchDocumentFailure,
    isBlob: true,
    fileName: fileName,
  })

export const fetchDocuments = (arendeId: string) =>
  get({
    url: `/arenden/${arendeId}/dokument`,
    initiated: fetchDocumentsInitiate,
    fulfilled: fetchDocumentsSuccess,
    rejected: fetchDocumentsFailure,
  })

export const changeAdvisor = (body: IChangeAdvisorBody) =>
  put({
    url: '/radgivningsarende/bytradgivare',
    initiated: changeAdvisorInitiate,
    fulfilled: changeAdvisorSuccess,
    rejected: changeAdvisorFailure,
    body,
  })

export interface IChangeTeamBody {
  arendeId: string
  teamId: string
}
export const changeTeam = (body: IChangeTeamBody) =>
  put({
    url: `/fordelning/bytfordelningsteam`,
    initiated: changeTeamInitiated,
    fulfilled: changeTeamSuccess,
    rejected: changeTeamFailure,
    body,
  })

export const createEvent = (body: EventBody, arendeId: string) =>
  post({
    url: `/radgivningsarende/${arendeId}/notering`,
    initiated: createEventInitiate,
    fulfilled: createEventSuccess,
    rejected: createEventFailure,
    body,
  })

export const updateEvent = (body: EventBody, eventId: string) =>
  put({
    url: `/noteringar/${eventId}`,
    initiated: updateEventInitiate,
    fulfilled: updateEventSuccess,
    rejected: updateEventFailure,
    body,
  })

export const fetchEvents = (arendeId: string) =>
  get({
    url: `/radgivningsarende/${arendeId}/handelser`,
    initiated: fetchEventsInitiate,
    fulfilled: fetchEventsSuccess,
    rejected: fetchEventsFailure,
  })

export const deleteEvent = (handelseId: string) =>
  deleteRequest({
    url: `/noteringar/${handelseId}`,
    initiated: deleteEventInitiate,
    fulfilled: deleteEventSuccess,
    rejected: deleteEventFailure,
  })

export const fetchUtbetalningsbeslut = (ansokanId: string) =>
  get({
    url: `/ekonomi/arende/${ansokanId}/utbetalningsbeslut`,
    initiated: fetchUtbetalningsbeslutInitiate,
    fulfilled: fetchUtbetalningsbeslutSuccess,
    rejected: fetchUtbetalningsbeslutFailure,
  })

export const updateMarkning = (arendeId: string, markning: string, value: boolean) =>
  put({
    url: `/arenden/${arendeId}/markningar/${markning}`,
    apiVersion: '2.0',
    initiated: createAction(updateMarkningInitiate.toString(), () => ({
      markningId: markning,
      value: value,
    })),
    fulfilled: updateMarkningSuccess,
    rejected: updateMarkningFailure,
    body: value,
  })

export const fetchAgeArendenMedRattTillAge = (klientId: string, ansokanId: string) =>
  get({
    url: `/agearende/klient/${klientId}/arende/${ansokanId}/rattTillAge`,
    initiated: fetchAgeArendeMedRattTillAgeInitiate,
    fulfilled: fetchAgeArendeMedRattTillAgeSuccess,
    rejected: fetchAgeArendeMedRattTillAgeFailure,
  })

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchCase = ({ caseId, reload = true }: { caseId: string; reload?: boolean }) =>
  get({
    url: `/arenden/${caseId}`,
    apiVersion: '2.0',
    initiated: () => fetchCaseInitiate({ reload }),
    fulfilled: fetchCaseSuccess,
    rejected: fetchCaseFailure,
  })

export const updatePreferredLanguage = ({ klientId, languageCode }: { klientId: string; languageCode: string }) =>
  put({
    url: `/userProfile/${klientId}/preferred-language`,
    initiated: updatePreferredLanguageInitiated,
    fulfilled: updatePreferredLanguageSuccess,
    rejected: updatePreferredLanguageFailure,
    body: languageCode,
  })

export const fetchUserStatus = ({ klientId }: { klientId: string; reload?: boolean }) =>
  get({
    url: `/kompetens/user/status?userId=${klientId}`,
    initiated: fetchUserStatusInitiated,
    fulfilled: fetchUserStatusSuccess,
    rejected: fetchUserStatusFailure,
  })

export const fetchUserJmtStatus = (klientId: string) =>
  get({
    url: `/jmt/medarbetare/status/${klientId}`,
    initiated: fetchUserJmtStatusInitiated,
    fulfilled: fetchUserJmtStatusSuccess,
    rejected: fetchUserJmtStatusFailure,
  })
