import { Box, Stack } from '@mui/material'
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ArrowDropDownRounded } from '@mui/icons-material'

import EmptyResult from '../EmptyResult/EmptyResult'

interface DataGridWrapperProps {
  columns: GridColDef<unknown>[]
  rows: unknown[]
  getRowId: (row: unknown) => string
  columnVisibilityModel?: GridColumnVisibilityModel
  paginationModel?: GridPaginationModel
  sortModel?: GridSortModel
  getDetailPanelContent?: (params: GridRowParams<unknown>) => React.ReactNode
  onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => void
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void
  isMobile?: boolean
  rowCount?: number
  loading?: boolean
  labelRowsPerPage?: string
  paginationMode?: 'client' | 'server'
  sortingMode?: 'client' | 'server'
}

const DataGridWrapper = ({
  rows,
  columns,
  columnVisibilityModel,
  paginationModel,
  sortModel,
  getRowId,
  onSortModelChange,
  onPaginationModelChange,
  getDetailPanelContent,
  isMobile = false,
  rowCount = rows?.length,
  loading = false,
  labelRowsPerPage = 'Rader per sida',
  paginationMode = 'client',
  sortingMode = 'client',
}: DataGridWrapperProps) => {
  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <EmptyResult description="Din filtrering fick inga träffar." />
      </Stack>
    )
  }

  return (
    <Box height={rows?.length > 0 ? 'auto' : 600} marginTop={2}>
      <DataGridPro
        pagination
        loading={loading}
        rows={rows || []}
        columns={columns}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        getRowHeight={() => 'auto'}
        rowCount={rowCount}
        sortModel={sortModel}
        disableRowSelectionOnClick
        disableColumnMenu
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        paginationModel={paginationModel}
        columnVisibilityModel={columnVisibilityModel}
        getDetailPanelHeight={() => 'auto'}
        getRowId={getRowId}
        getDetailPanelContent={getDetailPanelContent}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={onPaginationModelChange}
        columnBuffer={columns.length + 1} // +1 for testing purposes (can mess with rendering in headless.)
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
          detailPanelExpandIcon: KeyboardArrowDownIcon,
          detailPanelCollapseIcon: KeyboardArrowUpIcon,
        }}
        slotProps={{
          pagination: {
            SelectProps: { IconComponent: ArrowDropDownRounded },
            labelRowsPerPage,
          },
        }}
        autoHeight={rows?.length > 0 ? true : false}
        sx={(theme) => ({
          border: 0,
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            padding: isMobile ? 0 : undefined,
          },
          '& .MuiDataGrid-columnHeader': {
            padding: isMobile ? 0 : undefined,
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            flexDirection: 'initial !important',
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-columnHeaderTitleContainerContent':
            {
              marginLeft: 'auto',
            },
          '& .MuiDataGrid-row--detailPanelExpanded': {
            background: theme.palette?.surface?.grey,
          },
          '& .MuiDataGrid-row--detailPanelExpanded > .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-detailPanel': {
            background: theme.palette?.surface?.grey,
          },
        })}
      />
    </Box>
  )
}

export default DataGridWrapper
