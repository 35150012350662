import { formatDateFromDateTime } from '@local/src/Utils/DateTime'
import { Box, Typography } from '@mui/material'
import React from 'react'

import { IListStudier } from '../ActionBasedCaseItem.types'

interface BegaranYttrandeProps {
  studier: IListStudier
}

const BegaranYttrandeDeadline = ({ studier }: BegaranYttrandeProps) => {
  if (!studier?.mostRelevantBegaranYttrandeDeadline || studier.mostRelevantBegaranYttrandeDeadline.trim() === '') return <></>

  return (
    <Box display="grid" alignContent="center">
      <Typography variant="body2">Begäran om yttrande</Typography>
      <Typography variant="caption" color="text.secondary">
        Svara senast: {formatDateFromDateTime(studier.mostRelevantBegaranYttrandeDeadline, true)}
      </Typography>
    </Box>
  )
}

export default BegaranYttrandeDeadline
