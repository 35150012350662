/* eslint-disable @typescript-eslint/no-explicit-any */
/** Disable eslint any for the entire file. But they should preferably be fixed. */
import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'
import { filterId } from '@local/Utils/Filters'
import { sortAscendingArrayOfObjectsByKey as sortAsc } from '@local/src/Utils/helpers'

import { IAdvisor } from '../AllocateCases/AllocateCases.types'

import {
  fetchCaseListStartklarInitiate,
  fetchCaseListStartklarSuccess,
  fetchCaseListStartklarFailure,
  fetchCaseListStartklarFacetsInitiate,
  fetchCaseListStartklarFacetsSuccess,
  fetchCaseListStartklarFacetsFailure,
  setStartklarPage,
  setStartklarPageSize,
  resetFilterProp,
  submitStartklarFormInitiate,
  submitStartklarFormSuccess,
  submitStartklarFormFailure,
  resetStartklarFormSubmissionState,
  setStartklarFilterParameters,
  fetchHandlaggareListInitiate,
  fetchHandlaggareListSuccess,
  fetchHandlaggareListFailure,
  updateNoOfUppringningsforsokInitiate,
  updateNoOfUppringningsforsokSuccess,
  updateNoOfUppringningsforsokFailure,
  assignHandlaggareInitiate,
  assignHandlaggareSuccess,
  assignHandlaggareFailure,
  unassignHandlaggareInitiate,
  unassignHandlaggareSuccess,
  unassignHandlaggareFailure,
  fetchStartklarRattTillAgeInitiate,
  fetchStartklarRattTillAgeSuccess,
  fetchStartklarRattTillAgeFailure,
  fetchStartklarCaseInitiate,
  fetchStartklarCaseSuccess,
  fetchStartklarCaseFailure,
} from './Startklar.actions'
import { IStartklarFilterState, IStartklarState } from './Startklar.types'

export const initialState: IStartklarState = {
  caseListStartklar: { totalHits: 0, arenden: [] },
  facets: null,
  fetchingCaseListStartklar: FetchingState.IDLE,
  fetchingCaseListStartklarFacets: FetchingState.IDLE,
  ignoredArendeId: null,
  facetedTotalHits: 0,
  startklarFilterState: {
    handlaggareObjects: [],
    arbetsmarknadsomraden: [],
    foretagNamn: [],
    ejTilldeladHandlaggare: false,
    subStatusar: 'Nytt',
    orderBy: 'SubStatusDatum asc',
    page: 1,
    pageSize: 20,
  },
  fetchingHandlaggareList: FetchingState.IDLE,
  handlaggareList: [] as IAdvisor[],
  updateNoOfUppringningsforsokState: FetchingState.IDLE,
  assignHandlaggareState: FetchingState.IDLE,
  unassignHandlaggareState: FetchingState.IDLE,
  startklarFormSubmissionState: FetchingState.IDLE,
  reload: false,
  startklarCaseFetchingState: FetchingState.IDLE,
  caseDetails: undefined,
  ageArendeMedRattTillAge: null,
}

const resetFilterPropertyFromPayload = (filterParameter: string | number, filterValue: string, state: IStartklarState) => {
  switch (filterParameter) {
    case 'arbetsmarknadsomraden':
      return {
        arbetsmarknadsomraden: state.startklarFilterState.arbetsmarknadsomraden.filter((omrade) => omrade !== filterValue),
        page: 1,
      }
    case 'foretagNamn':
      return {
        foretagNamn: state.startklarFilterState.foretagNamn.filter((foretag) => foretag !== filterValue),
        page: 1,
      }
    case 'handlaggareObjects':
      return {
        handlaggareObjects: state.startklarFilterState.handlaggareObjects.filter((item) => item.value !== filterValue),
        page: 1,
      }

    case 'ejTilldeladHandlaggare':
      return { ejTilldeladHandlaggare: false, page: 1 }
    case 'resetAll':
      return { handlaggareObjects: [] as string[], arbetsmarknadsomraden: [] as string[], foretagNamn: [] as string[], ejTilldeladHandlaggare: false, page: 1 }
    case 'default':
    default:
      return new Error()
  }
}

const startklarReducer = handleActions(
  {
    [fetchCaseListStartklarInitiate.toString()]: (state) => ({
      ...state,
      fetchingCaseListStartklar: FetchingState.LOADING,
    }),
    [fetchCaseListStartklarSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      fetchingCaseListStartklar: FetchingState.SUCCESS,
      caseListStartklar: {
        ...payload.startklarListWrapper,
        arenden: filterId(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          payload.startklarListWrapper.arenden,
          state.ignoredArendeId
        ),
      },
    }),
    [fetchCaseListStartklarFailure.toString()]: (state) => ({
      ...state,
      fetchingCaseListStartklar: FetchingState.ERROR,
    }),

    [fetchCaseListStartklarFacetsInitiate.toString()]: (state) => ({
      ...state,
      fetchingCaseListStartklarFacets: FetchingState.LOADING,
    }),
    [fetchCaseListStartklarFacetsSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      fetchingCaseListStartklarFacets: FetchingState.SUCCESS,
      facets: payload.facetsWrapper.facets,
      facetedTotalHits: payload.facetsWrapper.totalHits,
    }),
    [fetchCaseListStartklarFacetsFailure.toString()]: (state) => ({
      ...state,
      fetchingCaseListStartklarFacets: FetchingState.ERROR,
    }),

    [resetStartklarFormSubmissionState.toString()]: (state) => ({
      ...state,
      startklarFormSubmissionState: FetchingState.IDLE,
    }),
    [submitStartklarFormInitiate.toString()]: (state) => ({
      ...state,
      startklarFormSubmissionState: FetchingState.LOADING,
    }),
    [submitStartklarFormSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      startklarFormSubmissionState: FetchingState.SUCCESS,
      ignoredArendeId: payload.id,
      caseListStartklar: {
        ...state.caseListStartklar,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        arenden: filterId(state.caseListStartklar.arenden, payload.id),
      },
    }),
    [submitStartklarFormFailure.toString()]: (state) => ({
      ...state,
      startklarFormSubmissionState: FetchingState.ERROR,
    }),

    [setStartklarPage.toString()]: (state, { payload }: any) => ({
      ...state,
      startklarFilterState: { ...state.startklarFilterState, page: payload.page },
    }),
    [setStartklarPageSize.toString()]: (state, { payload }: any) => ({
      ...state,
      startklarFilterState: { ...state.startklarFilterState, pageSize: payload.pageSize },
    }),
    [resetFilterProp.toString()]: (
      state,
      {
        payload,
      }: {
        payload: { parameterValuePair: { filterParameter: keyof IStartklarFilterState; filterValue: string } }
      }
    ) => ({
      ...state,
      startklarFilterState: {
        ...state.startklarFilterState,
        ...resetFilterPropertyFromPayload(payload.parameterValuePair.filterParameter, payload.parameterValuePair.filterValue, state),
      },
    }),
    [setStartklarFilterParameters.toString()]: (state, { payload }: any) => ({
      ...state,
      startklarFilterState: {
        ...state.startklarFilterState,
        page: 1,
        ...payload.filterParams,
      },
    }),
    [fetchHandlaggareListInitiate.toString()]: (state) => ({
      ...state,
      fetchingHandlaggareList: FetchingState.LOADING,
    }),
    [fetchHandlaggareListSuccess.toString()]: (state, action) => ({
      ...state,
      handlaggareList: sortAsc('fornamn', action.payload.handlaggareList) as IAdvisor[],
      fetchingHandlaggareList: FetchingState.SUCCESS,
    }),
    [fetchHandlaggareListFailure.toString()]: (state) => ({
      ...state,
      fetchingHandlaggareList: FetchingState.ERROR,
    }),
    [assignHandlaggareInitiate.toString()]: (state) => ({
      ...state,
      assignHandlaggareState: FetchingState.LOADING,
    }),
    [assignHandlaggareSuccess.toString()]: (state) => ({
      ...state,
      assignHandlaggareState: FetchingState.SUCCESS,
    }),
    [assignHandlaggareFailure.toString()]: (state) => ({
      ...state,
      assignHandlaggareState: FetchingState.ERROR,
    }),
    [unassignHandlaggareInitiate.toString()]: (state) => ({
      ...state,
      unassignHandlaggareState: FetchingState.LOADING,
    }),
    [unassignHandlaggareSuccess.toString()]: (state) => ({
      ...state,
      unassignHandlaggareState: FetchingState.SUCCESS,
    }),
    [unassignHandlaggareFailure.toString()]: (state) => ({
      ...state,
      unassignHandlaggareState: FetchingState.ERROR,
    }),
    [updateNoOfUppringningsforsokInitiate.toString()]: (state) => ({
      ...state,
      updateNoOfUppringningsforsokState: FetchingState.LOADING,
    }),
    [updateNoOfUppringningsforsokSuccess.toString()]: (state) => ({
      ...state,
      updateNoOfUppringningsforsokState: FetchingState.SUCCESS,
    }),
    [updateNoOfUppringningsforsokFailure.toString()]: (state) => ({
      ...state,
      updateNoOfUppringningsforsokState: FetchingState.ERROR,
    }),
    [fetchStartklarCaseInitiate.toString()]: (state, action) => ({
      ...state,
      startklarCaseFetchingState: action.payload.reload ? FetchingState.LOADING : state.startklarCaseFetchingState,
    }),
    [fetchStartklarCaseFailure.toString()]: (state) => ({
      ...state,
      startklarCaseFetchingState: FetchingState.ERROR,
    }),
    [fetchStartklarCaseSuccess.toString()]: (state, action) => ({
      ...state,
      startklarCaseFetchingState: FetchingState.SUCCESS,
      caseDetails: action.payload.caseDetails,
    }),
    [fetchStartklarRattTillAgeInitiate.toString()]: (state) => ({
      ...state,
    }),
    [fetchStartklarRattTillAgeSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      ageArendeMedRattTillAge: payload.ageArendeMedRattTillAge,
    }),
    [fetchStartklarRattTillAgeFailure.toString()]: (state) => ({
      ...state,
    }),
  },
  initialState
)

export default startklarReducer
