import { useEffect, useState } from 'react'

import { isNotNullOrEmpty } from '../Utils/smallfuncs'

const useArendenLocalStorage = <T>(arendenEnv: string, key: string, initialValue: T): readonly [T, (val: T) => void] => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (arendenEnv) {
      getValue()
      setLoaded(true)
    }
  }, [arendenEnv])

  const getValue = () => {
    try {
      const lsValue = window.localStorage.getItem(`${arendenEnv}.${key}`)
      const parsedLsValue: T = JSON.parse(lsValue)

      if (isNotNullOrEmpty(lsValue)) {
        setStoredValue(parsedLsValue)
        return parsedLsValue
      } else return initialValue
    } catch {
      return initialValue
    }
  }

  const [storedValue, setStoredValue] = useState<T>(getValue())

  const setValue = (value: T) => {
    try {
      if (loaded) {
        setStoredValue(value)
        window.localStorage.setItem(`${arendenEnv}.${key}`, JSON.stringify(value))
      }
    } catch {
      console.log('Cant set { value: ' + JSON.stringify(value) + ', key: ' + key + ' }')
    }
  }

  return [storedValue, setValue]
}

export { useArendenLocalStorage }
