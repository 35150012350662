import React, { useState } from 'react'
import { Box, Button, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { generateRandomId } from '@local/src/Utils/helpers'

import { ITidigareRadgivare } from '../Case.types'

import ChangeAdvisorModal from './ChangeAdvisorModal/ChangeAdvisorModal'
import ChangeTeamModal from './ChangeTeamModal/ChangeTeamModal'

export interface IChangeCaseDetails {
  teamId: string
  tidigareRadgivare: ITidigareRadgivare[]
}

const ChangeCaseDetails = ({
  teamId,
  tidigareRadgivare,
}: IChangeCaseDetails): JSX.Element => {

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [changeAdvisorModalActive, setChangeAdvisorModalActive] = useState<boolean>(false)
  const [changeTeamModalActive, setChangeTeamModalActive] = useState<boolean>(false)

  const closeChangeAdvisorModal = () => setChangeAdvisorModalActive(false)
  const openChangeAdvisorModal = () => setChangeAdvisorModalActive(true)
  const openChangeTeamModal = () => setChangeTeamModalActive(true)
  const closeChangeTeamModal = () => setChangeTeamModalActive(false)

  const hasTidigareRadgivare = tidigareRadgivare.length > 0

  return (
    <Stack
      marginTop={5}
      marginX={{ xs: 2, md: 0}}
      maxWidth={{ lg: '80%' }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems={isSmallScreen ? 'stretch' : 'center'}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        gap={2}
      >
        <div>
          <Typography variant="h5">Rådgivare</Typography>
          <Typography variant="body1">Byt till en annan rådgivare.</Typography>
        </div>
        <Button variant='outlined' size='medium' onClick={openChangeAdvisorModal}>
          Byt rådgivare
        </Button>
      </Box>

      {hasTidigareRadgivare && (
        <Box paddingTop={2}>
          <Typography variant="h5">Tidigare rådgivare på ärendet</Typography>
          {tidigareRadgivare.map(({ id, fornamn, efternamn }) => (
            <Typography variant="body1" key={`${id}%${generateRandomId()}`}>{`${fornamn} ${efternamn}`}</Typography>
          ))}
        </Box>
      )}

      <Divider sx={{ marginY: 2 }}/>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems={isSmallScreen ? 'stretch' : 'center'}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        gap={2}
      >
        <div>
          <Typography variant="h5">Team</Typography>
          <Typography variant="body1">Skicka tillbaka ärendet till fördelning.</Typography>
        </div>
        <Button variant='outlined' size='medium' sx={{ minWidth: '150px' }} onClick={openChangeTeamModal}>
          Byt team
        </Button>
      </Box>

      <ChangeAdvisorModal active={changeAdvisorModalActive} close={closeChangeAdvisorModal} teamId={teamId} />
      <ChangeTeamModal active={changeTeamModalActive} close={closeChangeTeamModal} teamId={teamId} />
    </Stack>
  )
}

export default ChangeCaseDetails
