import { useLocation } from 'react-router-dom'

import { allaArendenPath, kompetensstodsArendenPath, omstallningsArendenPath, dinaArendenPath } from '../basename'
import { EArendenEnv } from '../Views/Arenden/Arenden.types'

const useGetArendenEnv = (): Partial<EArendenEnv> => {
  const { pathname } = useLocation()
  if (pathname.includes(allaArendenPath)) return EArendenEnv.AllaArenden
  if (pathname.includes(kompetensstodsArendenPath)) return EArendenEnv.KompetensstodsArenden
  if (pathname.includes(omstallningsArendenPath)) return EArendenEnv.OmstallningsArenden
  if (pathname.includes(dinaArendenPath)) return EArendenEnv.DinaArenden
  return EArendenEnv.AllaArenden
}

export { useGetArendenEnv }
