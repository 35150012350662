import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import { Box, Button } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid-pro'
import React from 'react'
import DataGridWrapper from '@local/src/Components/DataGridWrapper/DataGridWrapper'

import { FordelningsArende, Team } from '../Fordelning.types'

import { getColumns } from './FordelningTableColumns'
import FordelningTableDetailPanel from './FordelningTableDetailPanel'

interface FordelningTableProps {
  arenden: FordelningsArende[]
  teams: Team[]
  selectedTeam: Team
  isFetching: boolean
  selectedTeamOnChange: (selectedTeamId: string) => void
}

const FordelningTable = ({
  arenden,
  teams,
  selectedTeam,
  isFetching,
  selectedTeamOnChange,
}: FordelningTableProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {teams && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box width="131px">
            <Dropdown
              options={teams}
              getOptionLabel={(option: Team) => option.namn}
              getOptionValue={(option: Team) => option.id}
              label="Team"
              value={selectedTeam?.id}
              size="small"
              onChange={(event) => selectedTeamOnChange(event.target.value)}
            />
          </Box>
          <Button variant="contained" size="small">
            Fördela ärende
          </Button>
        </Box>
      )}
      <DataGridWrapper
        loading={isFetching}
        columns={getColumns()}
        rows={arenden}
        getRowId={(row: FordelningsArende) => row.id}
        getDetailPanelContent={(params: GridRowParams<FordelningsArende>) => (
          <FordelningTableDetailPanel params={params} teams={teams} />
        )}
      />
    </Box>
  )
}

export default FordelningTable
