/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createActions } from 'redux-actions'
import { get, post, put, deleteRequest } from '@local/Utils/network'
import { abortController } from '@local/src/Utils/helpers'

import { IAgeArende } from '../Case/Case.types'
import { IAdvisor } from '../AllocateCases/AllocateCases.types'

export const {
  fetchCaseListStartklarInitiate,
  fetchCaseListStartklarSuccess,
  fetchCaseListStartklarFailure,

  fetchCaseListStartklarFacetsInitiate,
  fetchCaseListStartklarFacetsSuccess,
  fetchCaseListStartklarFacetsFailure,

  resetStartklarFormSubmissionState,
  submitStartklarFormInitiate,
  submitStartklarFormSuccess,
  submitStartklarFormFailure,
  setStartklarPage,
  setStartklarPageSize,

  resetFilterProp,
  setStartklarFilterParameters,
  fetchHandlaggareListInitiate,
  fetchHandlaggareListSuccess,
  fetchHandlaggareListFailure,
  updateNoOfUppringningsforsokInitiate,
  updateNoOfUppringningsforsokSuccess,
  updateNoOfUppringningsforsokFailure,
  assignHandlaggareInitiate,
  assignHandlaggareSuccess,
  assignHandlaggareFailure,
  unassignHandlaggareInitiate,
  unassignHandlaggareSuccess,
  unassignHandlaggareFailure,

  fetchStartklarCaseInitiate,
  fetchStartklarCaseSuccess,
  fetchStartklarCaseFailure,

  fetchStartklarRattTillAgeInitiate,
  fetchStartklarRattTillAgeSuccess,
  fetchStartklarRattTillAgeFailure,
} = createActions({
  setStartklarPage: (page) => ({ page }),
  setStartklarPageSize: (pageSize) => ({ pageSize }),
  resetFilterProp: (parameterValuePair: { filterParameter: string; filterValue: string }) => ({ parameterValuePair }),

  fetchCaseListStartklarInitiate: () => null,
  fetchCaseListStartklarSuccess: (startklarListWrapper) => ({
    startklarListWrapper,
  }),
  fetchCaseListStartklarFailure: (errorMessage) => {
    return `Det gick inte att hämta ärendelistan. ${String(errorMessage)}`
  },

  fetchCaseListStartklarFacetsInitiate: () => null,
  fetchCaseListStartklarFacetsSuccess: (facetsWrapper) => ({
    facetsWrapper,
  }),
  fetchCaseListStartklarFacetsFailure: (errorMessage) => {
    return `Det gick inte att hämta totalt antal ärenden i listan. ${String(errorMessage)}`
  },

  resetStartklarFormSubmissionState: () => null,
  submitStartklarFormInitiate: () => null,
  submitStartklarFormSuccess: ({ id }: IStartklarFormApiValues) => {
    return { id, successMessage: `Ärendet skickas till fördelning` }
  },
  submitStartklarFormFailure: (errorMessage) => {
    return `Det gick inte att skicka ärendet till fördelning. ${String(errorMessage)}`
  },
  setStartklarFilterParameters: (filterParams) => ({ filterParams }),
  fetchHandlaggareListInitiate: () => ({}),
  fetchHandlaggareListSuccess: (handlaggareList) => ({ handlaggareList }),
  fetchHandlaggareListFailure: (errorMessage) => {
    return `Det gick inte att hämta några handläggare. ${String(errorMessage)}`
  },
  updateNoOfUppringningsforsokInitiate: () => ({}),
  updateNoOfUppringningsforsokSuccess: () => ({}),
  updateNoOfUppringningsforsokFailure: (errorMessage) => {
    return `Det gick inte att lägga till ett samtal. ${String(errorMessage)}`
  },
  assignHandlaggareInitiate: () => ({}),
  assignHandlaggareSuccess: () => ({}),
  assignHandlaggareFailure: (errorMessage) => {
    return `Det gick inte att tilldela ärendet en handläggare. ${String(errorMessage)}`
  },
  unassignHandlaggareInitiate: () => ({}),
  unassignHandlaggareSuccess: () => ({}),
  unassignHandlaggareFailure: (errorMessage) => {
    return `Det gick inte att ta bort handläggaren från ärendet. ${String(errorMessage)}`
  },
  fetchStartklarCaseInitiate: (reload: boolean) => reload,
  fetchStartklarCaseSuccess: (caseDetails) => ({ caseDetails }),
  fetchStartklarCaseFailure: (errorMessage) => {
    return `Det gick inte att hämta ärendet. ${String(errorMessage)}`
  },
  fetchStartklarRattTillAgeInitiate: () => null,
  fetchStartklarRattTillAgeSuccess: (ageArendeMedRattTillAge: IAgeArende) => ({ ageArendeMedRattTillAge }),
  fetchStartklarRattTillAgeFailure: (errorMessage) => {
    return `Det gick inte att hämta det öppnade AGE-ärendet. ${String(errorMessage)}`
  },
})

export const fetchCaseListStartklar = abortController(
  (abortableFetch: unknown) => (query?: string) =>
    get({
      url: `/radgivning/arenden/startklar/${query ? query : ''}`,
      initiated: fetchCaseListStartklarInitiate,
      fulfilled: fetchCaseListStartklarSuccess,
      rejected: fetchCaseListStartklarFailure,
      _fetch: abortableFetch,
    })
)

export const fetchCaseListFacets = () =>
  get({
    url: '/radgivning/arenden/startklar?subStatusar=Nytt',
    initiated: fetchCaseListStartklarFacetsInitiate,
    fulfilled: fetchCaseListStartklarFacetsSuccess,
    rejected: fetchCaseListStartklarFacetsFailure,
  })

export const fetchHandlaggareList = () =>
  get({
    url: '/radgivning/arenden/handlaggare',
    initiated: fetchHandlaggareListInitiate,
    fulfilled: fetchHandlaggareListSuccess,
    rejected: fetchHandlaggareListFailure,
  })

export interface IStartklarFormApiValues {
  id: string
  beskrivning: string
  ejRedo: boolean
  engelsktalande: boolean
  klientId: string
  pratatOmTrrSe: boolean
  klientenSvarade: boolean
  sokerJobb: boolean
}

export const submitStartklarForm = (caseId: string, body: IStartklarFormApiValues) => {
  return post({
    url: `/radgivning/arenden/${caseId}/startklar`,
    initiated: submitStartklarFormInitiate,
    fulfilled: submitStartklarFormSuccess,
    rejected: submitStartklarFormFailure,
    body,
  })
}

export const assignHandlaggare = (arendeId: string, body: IAdvisor) =>
  put({
    url: `/radgivning/arenden/${arendeId}/handlaggare`,
    initiated: assignHandlaggareInitiate,
    fulfilled: assignHandlaggareSuccess,
    rejected: assignHandlaggareFailure,
    body,
  })

export const unassignHandlaggare = (arendeId: string) =>
  deleteRequest({
    url: `/radgivning/arenden/${arendeId}/handlaggare`,
    initiated: unassignHandlaggareInitiate,
    fulfilled: unassignHandlaggareSuccess,
    rejected: unassignHandlaggareFailure,
  })

export interface IUpdateNoOfUppringningsforsokBody {
  antalUppringningsforsok: number
}

export const updateNoOfUppringningsforsok = (arendeId: string, body: IUpdateNoOfUppringningsforsokBody) =>
  put({
    url: `/radgivning/arenden/${arendeId}/antaluppringningsforsok`,
    initiated: updateNoOfUppringningsforsokInitiate,
    fulfilled: updateNoOfUppringningsforsokSuccess,
    rejected: updateNoOfUppringningsforsokFailure,
    body,
  })

export const fetchStartklarRattTillAge = (klientId: string, ansokanId: string) =>
  get({
    url: `/agearende/klient/${klientId}/arende/${ansokanId}/rattTillAge`,
    initiated: fetchStartklarRattTillAgeInitiate,
    fulfilled: fetchStartklarRattTillAgeSuccess,
    rejected: fetchStartklarRattTillAgeFailure,
  })

export const fetchStartklarCase = ({ caseId, reload = true }: { caseId: string; reload?: boolean }) =>
  get({
    url: `/arenden/${caseId}`,
    apiVersion: '2.0',
    initiated: () => fetchStartklarCaseInitiate({ reload }),
    fulfilled: fetchStartklarCaseSuccess,
    rejected: fetchStartklarCaseFailure,
  })
