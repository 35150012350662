import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'

import {
  fetchAttendedActivitiesFailure,
  fetchAttendedActivitiesInitiate,
  fetchAttendedActivitiesSuccess,
  fetchCurrentActivitiesFailure,
  fetchCurrentActivitiesInitiate,
  fetchCurrentActivitiesSuccess,
  unregisterActivitiesFailure,
  unregisterActivitiesInitiate,
  unregisterActivitiesSuccess,
  clearActivities,
} from './Activities.actions'
import { ActivityRound } from './Activities.types'

export interface ActivitiesState {
  attendedActivities: ActivityRound[]
  currentActivities: ActivityRound[]
  fetchingActivitiesState: FetchingState
  unregisteringActivitiesState: FetchingState
}

export const initialState: ActivitiesState = {
  attendedActivities: [],
  currentActivities: [],
  fetchingActivitiesState: FetchingState.IDLE,
  unregisteringActivitiesState: FetchingState.IDLE,
}

const activitiesReducer = handleActions(
  {
    [fetchAttendedActivitiesInitiate.toString()]: (state) => ({
      ...state,
      fetchingActivitiesState: FetchingState.LOADING,
    }),
    [fetchAttendedActivitiesFailure.toString()]: (state) => ({
      ...state,
      fetchingActivitiesState: FetchingState.ERROR,
    }),
    [fetchAttendedActivitiesSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingActivitiesState: FetchingState.SUCCESS,
      // we want to sort by newest first. Backend sends in reversed order.
      attendedActivities: action.payload.attendedActivities.sort((a, b) => new Date(b.schedule?.start).getTime() - new Date(a.schedule?.start).getTime()),
    }),

    [fetchCurrentActivitiesInitiate.toString()]: (state) => ({
      ...state,
      fetchingActivitiesState: FetchingState.LOADING,
    }),
    [fetchCurrentActivitiesFailure.toString()]: (state) => ({
      ...state,
      fetchingActivitiesState: FetchingState.ERROR,
    }),
    [fetchCurrentActivitiesSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingActivitiesState: FetchingState.SUCCESS,
      currentActivities: action.payload.currentActivities,
    }),

    [unregisterActivitiesInitiate.toString()]: (state) => ({
      ...state,
      unregisteringActivitiesState: FetchingState.LOADING,
    }),
    [unregisterActivitiesFailure.toString()]: (state) => ({
      ...state,
      unregisteringActivitiesState: FetchingState.ERROR,
    }),
    [unregisterActivitiesSuccess.toString()]: (state, action: any) => ({
      ...state,
      unregisteringActivitiesState: FetchingState.SUCCESS,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      currentActivities: state.currentActivities.filter(activity => !action.payload.activityList.includes(activity.id)),
    }),

    [clearActivities.toString()]: () => ({
      ...initialState,
    }),
  },
  initialState
)

export default activitiesReducer
