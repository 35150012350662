import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'


import { ageArendeMedRattTillAgeSelector } from '../Views/Case/Case.selectors'
import { startklarAgeArendeMedRattTillAgeSelector } from '../Views/Startklar/Startklar.selectors'

export enum AgeFlag {
  Ja = 'Ja',
  JaTidigare = 'Ja, från tidigare',
  Nej = 'Nej',
}

export const useHasRightToAge = (): AgeFlag => {
  const location = useLocation()
  const isStartklar = location.pathname.includes('/startklar')

  const arendeAge = useSelector(ageArendeMedRattTillAgeSelector)
  const startklarAge = useSelector(startklarAgeArendeMedRattTillAgeSelector)
  const age = isStartklar ? startklarAge : arendeAge
  const hasAge = age?.hasRattTillAgeForArendekontext

  return !age ? AgeFlag.Nej : hasAge ? AgeFlag.Ja : AgeFlag.JaTidigare
}
