import React, { useEffect, useState } from 'react'
import { useLazyGetFacetsForPropertyQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import FilterAccordion from '@local/src/Components/FilterAccordion/FilterAccordion'

import AssigneeFilter from '../FilterTypes/AssigneeFilter'
import { useArbetsuppgiftFilterContext } from '../ArbetsuppgiftFilterContext'
import { useAssigneeFilter } from '../Hooks/useAssigneeFilter'

interface AssigneeAccordionProps {
  open: boolean
  onOpen: () => void
}

const AssigneeAccordion = ({ open, onOpen }: AssigneeAccordionProps) => {

  const [isOpen, setIsOpen] = useState(open)
  const [fetchFacets, data] = useLazyGetFacetsForPropertyQuery()

  const { filters, advisors, setFilter: handleFilter } = useArbetsuppgiftFilterContext()
  const {
    searchValue,
    updateSearchValue,
    pickAdvisor,
    isRadgivareSelected,
    currentUserFacet,
    noOwnerFacet,
    filteredAdvisorList,
  } = useAssigneeFilter(data?.data?.Owner, advisors, filters.ownerIds, handleFilter)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (isOpen) {
      onOpen()
      fetchFacets({key: 'Owner', filter: filters })
    }
  }, [isOpen])

  return (
    <FilterAccordion
      label='Ansvarig'
      open={isOpen}
      indicatorCount={filters.ownerIds?.length}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <AssigneeFilter
        facets={data?.data?.Owner}
        noOwnerFacet={noOwnerFacet}
        searchValue={searchValue}
        updateSearchValue={updateSearchValue}
        pickAdvisor={pickAdvisor}
        currentUserFacet={currentUserFacet}
        isRadgivareSelected={isRadgivareSelected}
        filteredAdvisorList={filteredAdvisorList}
      />
    </FilterAccordion>
  )
}

export default AssigneeAccordion
