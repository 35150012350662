import { IMobileLink } from '@local/src/App.types'
import { groupBy, prop } from 'ramda'

import { HomePageEpiContent } from './Home.types'

const isMobileLink = (epiContent: HomePageEpiContent, key: string): boolean =>
  // eslint-disable-next-line no-prototype-builtins
  epiContent[key as keyof HomePageEpiContent].hasOwnProperty('url')

const getEpiFunctionBlockNames = (epiContent: HomePageEpiContent): string[] =>
  Object.keys(epiContent).filter((key) => isMobileLink(epiContent, key))

export const parseExternalLinks = (epiContent: HomePageEpiContent): { [index: string]: IMobileLink[] } => {
  const links = getEpiFunctionBlockNames(epiContent).map((key) => createLink(epiContent, key))
  const urls = links.map((link) => link.url)
  const uniqueLinks = links.filter(({ url }, index) => !urls.includes(url, index + 1))
  const groupedLinks = groupBy(prop('header'), uniqueLinks)

  return groupedLinks
}

export const createLink = (epiContent: HomePageEpiContent, key: string): IMobileLink => {
  const mobileLink = epiContent[key as keyof HomePageEpiContent] as Record<string, string>

  return {
    header: mobileLink.header,
    name: mobileLink.name,
    url: mobileLink.url,
    showOnMobile: JSON.parse(mobileLink.showOnMobile),
    isExternal: mobileLink.url.startsWith('http'),
  }
}
