/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createActions } from 'redux-actions'
import { post } from '@local/Utils/network'

import { ICreateEmailForm } from './CreateEmail.types'

export const { createEmailInitiate, createEmailSuccess, createEmailFailure } = createActions({
  createEmailInitiate: () => null,
  createEmailSuccess: (body) => {
    return {
      successMessage: `Utkastet har skapats i Outlook. ${
        body?.note?.createNote ? 'När du skickat mejlet skapas en händelse i tidslinjen.' : ''
      }`,
    }
  },
  createEmailFailure: (errorMessage) => {
    return `Något gick fel när vi försökte skapa ditt utkast. Försök gärna igen. ${String(errorMessage)}`
  },
})

export const createEmail = (isRadgivare: boolean, query: string, body: ICreateEmailForm) =>
  post({
    url: `${isRadgivare ? '/radgivare' : ''}/arenden/draftemail/${query ? query : ''}`,
    initiated: createEmailInitiate,
    fulfilled: createEmailSuccess,
    rejected: createEmailFailure,
    body,
  })
