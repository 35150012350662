import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { FetchingState } from '@local/src/Common.types'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import { ArendeTyp } from '../../Arenden/Arenden.types'

interface IFileForm {
  file: File
  abortUpload(): void
  uploadedBy: string
  postDocument(arendeId: string, body: FormData): void
  resetPostDocumentState(): void
  postDocumentState: FetchingState
  arendeId: string
  samtyckeHalsa: boolean
  arendeTyp: string
}

const FileForm = ({
  file,
  abortUpload,
  uploadedBy,
  postDocument,
  arendeId,
  postDocumentState,
  samtyckeHalsa,
  arendeTyp,
  resetPostDocumentState,
}: IFileForm): JSX.Element => {
  const [isHealthRelated, setIsHealthRelated] = useState<boolean>(false)
  const isOmstallningsArende = arendeTyp === ArendeTyp.Omstallning
  const [isDisabled, setIsDisabled] = useState(isOmstallningsArende && samtyckeHalsa)
  const [showModal, setShowModal] = useState(true)
  const formatRequestBody = () => {
    const healthRelated = isOmstallningsArende && isHealthRelated
    const formData = new FormData()
    formData.append('File', file, encodeURIComponent(file.name))
    formData.append('IsHealthRelated', JSON.stringify(healthRelated))
    formData.append('UploadedBy', uploadedBy)
    return formData
  }
  const submit = (e: SyntheticEvent) => {
    e.preventDefault()
    postDocument(arendeId, formatRequestBody())
    setShowModal(false)
  }
  const radioButtonOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsHealthRelated(e.target.value === 'true')
    setIsDisabled(false)
  }

  useEffect(() => {
    if (postDocumentState === FetchingState.SUCCESS) {
      abortUpload()
      resetPostDocumentState()
    }
  }, [postDocumentState, abortUpload, resetPostDocumentState])

  return (
    <Dialog open={showModal}>
      <DialogTitle>{isOmstallningsArende && samtyckeHalsa ? 'Ladda upp fil' : 'Vill du verkligen ladda upp filen?'}</DialogTitle>
      <form name="FileForm" onSubmit={submit}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <Typography marginBottom={3} paddingTop={0}>
            {file.name}
          </Typography>
          {postDocumentState === FetchingState.LOADING ? (
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {isOmstallningsArende ? (
                samtyckeHalsa ? (
                  <FormControl>
                    <FormLabel focused={false}>Innehåller filen någon hälsorelaterad information?</FormLabel>
                    <RadioGroup onChange={radioButtonOnChange}>
                      <FormControlLabel value={false.toString()} control={<Radio />} label="Nej" name="isHealthRelated" />
                      <FormControlLabel value={true.toString()} control={<Radio />} label="Ja" name="isHealthRelated" />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <Alert severity="warning">
                    Omställningskunden har inte lämnat samtycke för hälsa. Innehåller filen hälsorelaterad information får du inte ladda upp
                    den.
                  </Alert>
                )
              ) : (
                <Alert severity="warning">Innehåller filen hälsorelaterad information får du inte ladda upp den.</Alert>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" disabled={isDisabled} type="submit">
            Ladda upp
          </Button>
          <Button variant="text" onClick={abortUpload}>
            Avbryt
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default FileForm
