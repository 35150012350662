import { MenuItem, MenuList, SxProps, Theme, useTheme } from '@mui/material'
import ChipWithPopper from '@local/src/Components/Chips/ChipWithPopover/ChipWithPopper'
import React, { useEffect } from 'react'
import { useUpdateStatusMutation } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { addErrorMessage, addSuccessMessage } from '@local/src/Blocks/UserFeedback/UserFeedback.actions'
import { useUser } from '@trr/app-shell-data'

import { ArbetsuppgiftStatus } from '../types'

interface TogglestatusChipProps {
  id: string
  status: ArbetsuppgiftStatus
}

const TogglestatusChip = ({ id, status }: TogglestatusChipProps) => {
  const user = useUser()
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const [updateStatus, { isError, isSuccess }] = useUpdateStatusMutation()

  useEffect(() => {
    if (isError) {
      dispatch(addErrorMessage('Något gick fel vid uppdatering av status'))
    }

    if (isSuccess) {
      dispatch(addSuccessMessage('Arbetsuppgiften uppdaterades'))
    }
  }, [isError, isSuccess])

  const handleUpdateStatus = (newStatus: ArbetsuppgiftStatus) => {
    if (newStatus === status) return
    updateStatus({ id, status: newStatus, userId: user.mdid })
  }

  const getChipLabel = (status: ArbetsuppgiftStatus) => {
    switch (status) {
      case ArbetsuppgiftStatus.Ny:
        return 'Ny'
      case ArbetsuppgiftStatus.Pagaende:
        return 'Pågående'
      case ArbetsuppgiftStatus.Klar:
        return 'Klar'
    }
  }

  const getChipStyling = (status: ArbetsuppgiftStatus, theme: Theme): SxProps<Theme> => {
    switch (status) {
      case ArbetsuppgiftStatus.Ny:
        return { backgroundColor: '', color: '' }
      case ArbetsuppgiftStatus.Pagaende:
        return {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.dark,
          '&&&:hover': { backgroundColor: 'rgba(12, 59, 233, 0.3)' },
          '&&&:focus': { backgroundColor: 'rgba(12, 59, 233, 0.3)' },
          '& .MuiSvgIcon-root': { color: theme.palette.info.dark },
        }
      case ArbetsuppgiftStatus.Klar:
        return { backgroundColor: theme.palette.success.light, color: theme.palette.success.dark }
    }
  }

  const getMenuItemStyling = (currentStatus: ArbetsuppgiftStatus, chipStatus: string) => {
    if (currentStatus === chipStatus) {
      return { backgroundColor: theme.palette.surface.grey, py: 2 }
    }
  }

  const getVariant = () => {
    switch (status) {
      case ArbetsuppgiftStatus.Ny:
        return 'outlined'
      case ArbetsuppgiftStatus.Pagaende:
        return 'filled'
      case ArbetsuppgiftStatus.Klar:
        return 'filled'
    }
  }

  return (
    <ChipWithPopper
      label={getChipLabel(status)}
      chipSx={getChipStyling(status, theme)}
      variant={getVariant()}
      hideHeader
      width={150}
      closeOnClick
    >
      <MenuList>
        <MenuItem
          onClick={() => handleUpdateStatus(ArbetsuppgiftStatus.Ny)}
          key={ArbetsuppgiftStatus.Ny}
          sx={getMenuItemStyling(status, ArbetsuppgiftStatus.Ny)}
        >
          Ny
        </MenuItem>
        <MenuItem
          onClick={() => handleUpdateStatus(ArbetsuppgiftStatus.Pagaende)}
          key={ArbetsuppgiftStatus.Pagaende}
          sx={getMenuItemStyling(status, ArbetsuppgiftStatus.Pagaende)}
        >
          Pågående
        </MenuItem>
        <MenuItem
          onClick={() => handleUpdateStatus(ArbetsuppgiftStatus.Klar)}
          key={ArbetsuppgiftStatus.Klar}
          sx={getMenuItemStyling(status, ArbetsuppgiftStatus.Klar)}
        >
          Klar
        </MenuItem>
      </MenuList>
    </ChipWithPopper>
  )
}

export default TogglestatusChip
