import React from 'react'
import MailIcon from '@mui/icons-material/Mail'
import s from '@local/Components/IconWithText/IconWithText.module.scss'
import { Link, Typography } from '@mui/material'

interface IEmail {
  children: string
  title?: string
  displayText?: string
  customClass?: string
  withoutIcon?: boolean
}

const EmailIcon = ({ title = 'Epost' }: { title: string }) => <MailIcon titleAccess={title} sx={{ color: s.linkColor}} fontSize='small'/>

const Email = ({ children, title = 'Epost', displayText, customClass, withoutIcon }: IEmail): JSX.Element => (
  <Link
    component='a'
    title={title}
    rel='noopener noreferrer'
    sx={{ display: 'flex', gap: 1, alignItems: 'center', alignSelf: 'baseline' }}
    className={customClass}
    href={`mailto:${children}`}
    target='_blank'
  >
    {withoutIcon ? null : <EmailIcon title={title} />}
    <Typography variant='body1'>{displayText ? displayText : children}</Typography>
  </Link>
)

export default Email
