/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
import { AppDispatch } from '@local/Store/configureStore'
import { getIdToken } from '@trr/app-shell-data'

import getConfig from '../../Utils/ConfigService'
import { stringify } from '../../Utils/helpers/QueryString'

import { IFetchRequestType, IRequestType } from './network.types'

const { API_URL } = getConfig()
export const baseURL = (url: string, apiVersion: string) =>
  url.endsWith('json') // if request is a json file, retrieve it from public
    ? `${process.env.PUBLIC_URL}/v${apiVersion}${url}`
    : `${API_URL}/v${apiVersion}${url}`

const requestHeaders = ({ id_token, body, method, multipart = false }: Partial<IFetchRequestType>) => {
  // Directly forcing a 'multipart/form-data' will not automatically generate boundaries, if file is provided, dont set content-type and content-type and boundaries will automatically be generated
  const headerContentType = multipart ? {} : { 'Content-type': 'application/json' }
  return {
    headers: {
      Authorization: `Bearer ${id_token}`,
      ...headerContentType,
      'Accept-Language': 'sv',
    },
    method,
    body,
  }
}

const documentTypes = [
  'application/pdf',
  'image/jpg',
  'application/octet-stream',
  'image/bmp',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/gif',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'image/tiff',
  'image/tif',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const handleResponse = async (response: Response): Promise<Response> => {
  const responseContentType = response.headers.get('content-type') ?? ''
  const contentTypeIsDocument = documentTypes.some((type) => responseContentType.includes(type))

  const responseStatusIs2XX = Math.floor(response.status / 100) === 2
  const responseStatusIs4XX = Math.floor(response.status / 100) === 4
  const responseStatusIs5XX = Math.floor(response.status / 100) === 5

  const emptyResponseBody = !responseContentType.includes('application/json') // Cypress responses will use this header by default

  if (responseStatusIs2XX) return contentTypeIsDocument ? response : emptyResponseBody ? null : response

  if (responseStatusIs4XX) {
    await response
      .json()
      .then((responseBody: { userFacingErrorMessage: unknown }) => {
        const error = responseBody.userFacingErrorMessage
        throw new Error(error.toString())
      })
      .catch(() => {
        switch (response.status) {
          case 401:
            throw new Error('Du saknar behörighet.')
          case 403:
            throw new Error('Du saknar behörighet.')
          case 404:
            throw new Error('Resursen hittades inte.')
          default:
            throw new Error('Ett oväntat systemfel inträffade.')
        }
      })
  }

  if (responseStatusIs5XX) throw new Error('Ett oväntat systemfel inträffade.')
}

export const fetchRequest = ({
  body,
  fullUrl,
  method = 'GET',
  id_token,
  url,
  multipart,
  apiVersion,
  _fetch = fetch,
}: IFetchRequestType): Promise<Response> => {
  const headers = requestHeaders({ id_token, body, method, multipart })
  return _fetch(`${fullUrl || baseURL(url, apiVersion)}`, headers as unknown)
}

// base for all requests
const baseRequest =
  ({ method }: { method: string }) =>
  ({
    _fetch = fetch,
    aborted,
    body,
    fulfilled,
    initiated,
    rejected,
    url,
    queryParams,
    apiVersion = '1.0',
    isBlob = false,
    fileName = null,
    multipart = false,
  }: IRequestType) =>
  async (dispatch: AppDispatch) => {
    dispatch(initiated(body))

    const id_token = getIdToken()

    if (queryParams) {
      const queryString = stringify(queryParams as unknown as Record<string, string[] | number>)
      url = `${url}${queryString}`
    }

    try {
      // find better way to catch
      const response = await fetchRequest({
        _fetch,
        method,
        body: multipart ? body : JSON.stringify(body),
        id_token,
        url,
        multipart,
        apiVersion,
      })

      // Filter errors (thrown in handleResponse)
      const handled = await handleResponse(response)

      if (isBlob) {
        const blob = await handled.blob()
        if (fileName) {
          return fulfilled(blob, fileName)
        }
        return fulfilled(blob)
      }

      // Return payload with id when making delete request with empty response
      if (handled === null && method === 'DELETE') {
        return dispatch(fulfilled(url.substring(url.lastIndexOf('/') + 1)))
      }
      // Check for empty success
      if (handled === null) {
        // Then return the payload that was sent in case that is of interest
        return dispatch(fulfilled(body))
      }
      // Unwrap promise
      const json = await handled.json()

      // Dispatch success payload
      return dispatch(fulfilled(json))
    } catch (ex) {
      // https://github.com/microsoft/TypeScript/issues/8677
      if (ex instanceof Error) {
        if (ex.name === 'AbortError') {
          if (aborted) return dispatch(aborted())
          return // Request has been canceled, so do nothing
        }
        // Dispatch error message
        return dispatch(rejected(ex.message))
      }
      // Unknown throw
      return dispatch(rejected())
    }
  }

// Delete is a reserved word
export const deleteRequest = baseRequest({ method: 'DELETE' })
export const get = baseRequest({ method: 'GET' })
export const post = baseRequest({ method: 'POST' })
export const put = baseRequest({ method: 'PUT' })
export const patch = baseRequest({ method: 'PATCH' })
