/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'

import { useEpiString } from '../../../Context/EpiStringContext'
import { approveStudieAnmalan } from '../StudieAnmalanApi'
import { addErrorMessage } from '../../../Blocks/UserFeedback/UserFeedback.actions'

interface IDialogProps {
  instituteName: string
  educationName: string
  open: boolean
  id: string
  setOpen: (arg0: boolean) => void
  refetch: () => Promise<UseQueryResult>
}

const RejectDialog: React.FC<IDialogProps> = ({ setOpen, open, educationName, instituteName, id, refetch }) => {
  const [loading, setLoading] = useState(false)
  const epi = useEpiString()
  const dispatch = useDispatch()
  const onRejectClick = (e: React.MouseEvent) => {
    setLoading(true)
    e.preventDefault()
    void approveStudieAnmalan(id, false)
      .then(async () => {
        await refetch()
        setOpen(false)
        setLoading(false)
      })
      .catch(async () => {
        await refetch()
        dispatch(addErrorMessage('Det gick inte att avböja anmälan'))
        setOpen(false)
        setLoading(false)
      })
  }
  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <DialogTitle>{epi('common.rejectBooking')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <Typography sx={{ fontWeight: 'bold' }} variant="body1">
            {educationName}
          </Typography>
          <Typography variant="caption">{instituteName}</Typography>
          <Alert severity="warning" sx={{ my: 2 }}>
            <Typography variant="body2">
              Meddela kunden anledningen till att utbildningen avböjs. Tänk på att beslutet inte kan ändras efter det är fattat.
            </Typography>
          </Alert>
          <Typography variant="body2">Notera att det kan dröja en stund innan status uppdateras i ärendet.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          size="small"
          variant="text"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            setOpen(false)
          }}
        >
          {epi('common.cancel')}
        </Button>
        <Button disabled={loading} size="small" variant="text" onClick={onRejectClick}>
          {epi('common.rejectBooking')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RejectDialog
