import { Checkbox, Typography, ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material'
import React from 'react'

interface CheckboxFacetableProps {
  label: string
  facetCount: number
  checked: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const CheckboxFacetable = ({ label, facetCount, checked, onClick }: CheckboxFacetableProps) => {
  return (
    <ListItem key={label} secondaryAction={<Typography>{facetCount}</Typography>}>
      <ListItemButton role={'checkbox'} onClick={onClick} dense>
        <ListItemIcon sx={{minWidth: 0}}>
          <Checkbox
            edge="start"
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': label }}
          />
        </ListItemIcon>
        <ListItemText primary={label} primaryTypographyProps={{ variant: 'body1' }} />
      </ListItemButton>
    </ListItem>
  )
}

export default CheckboxFacetable
