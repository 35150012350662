import React, { useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { useGetHandlaggareQuery, useGetStartklarListQuery, useGetStartklarInitialFacetsQuery } from '@local/src/Api/Startklar/startklarApi'
import { useUser } from '@trr/app-shell-data'
import Loading from '@local/src/Components/Loading/Loading'
import { useStartklarFilter } from '@local/src/Views/Startklar/Hooks/useStartklarFilter'

import StartklarList from './StartklarList/StartklarList'
import StartklarFilterContext from './StartklarFilters/StartklarFilterContext'
import StartklarFilters from './StartklarFilters/StartklarFilters'
import { getQueryParameters } from './helpers/helpers'
import useIsStartklarFilterActive from './Hooks/useIsStartklarFilterActive'
import { StartklarFilter } from './Startklar.types'

const Startklar = (): JSX.Element => {

  const prevFilters = useRef<Partial<StartklarFilter>>(null)
  const [filters, setFilter, clearFilter, clearHandlaggareFilter] = useStartklarFilter()

  const currentUser = useUser().mdid
  const { data: handlaggareList, isLoading: isLoadingHandlaggare } = useGetHandlaggareQuery(currentUser)
  const {
    data: startklarList,
    isLoading: isLoadingList,
    isFetching: isFetchingList,
    refetch,
  } = useGetStartklarListQuery(getQueryParameters(filters))
  const { data: initialFacets } = useGetStartklarInitialFacetsQuery()

  const isFilterActive = useIsStartklarFilterActive(filters)

  useEffect(() => {
    if (prevFilters.current !== null) {
      refetch()
    }
    prevFilters.current = filters
  }, [filters])

  return (
    <Box display='flex' flexDirection='column' maxWidth='1512px' margin='0 auto'>
      <Typography variant='h5' mb={1}>Startklar</Typography>
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <StartklarFilterContext.Provider
          value={{
            filters,
            isFilterActive,
            currentFacets: startklarList,
            handlaggareList,
            initialFacets: initialFacets,
            clearFilter,
            setFilter,
            clearHandlaggareFilter,
          }}
        >
          <StartklarFilters />
        </StartklarFilterContext.Provider>
        { isLoadingList || isFetchingList || isLoadingHandlaggare ? (
          <Loading />
        ) : (
          <StartklarList
            startklarCases={startklarList?.arenden}
            handlaggareList={handlaggareList}
            totalHits={startklarList?.totalHits}
            page={filters.pagination.page}
            pageSize={filters.pagination.pageSize}
            sortOption={filters.orderBy}
            setPage={(page) => setFilter('pagination', {...filters.pagination, page})}
            setPageSize={(pageSize) => setFilter('pagination', {page: 1, pageSize})}
            setSort={(sort) => setFilter('orderBy', sort.value)}
          />
        )}
      </Box>
    </Box>
  )
}

export default Startklar
