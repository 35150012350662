import React from 'react'
import { FetchingState } from '@local/src/Common.types'
import StartklarListItem from '@local/src/Views/Startklar/StartklarListItem/StartklarListItem'
import { List } from '@mui/material'
import Loading from '@local/src/Components/Loading/Loading'

import { ICaseStartklar, IHandlaggare } from '../Startklar.types'
import { IUpdateNoOfUppringningsforsokBody } from '../Startklar.actions'


interface StartklarCaseListProps {
  startklarCases: ICaseStartklar[]
  fetchingCaseListStartklar: FetchingState
  updateNoOfUppringningsforsok: (arendeId: string, body: IUpdateNoOfUppringningsforsokBody) => void
  fetchingHandlaggareList: FetchingState
  assignHandlaggare(arendeId: string, body: IHandlaggare): void
  unassignHandlaggare(arendeId: string): void
}

const StartklarCaseList = ({
  startklarCases,
  fetchingCaseListStartklar,
  updateNoOfUppringningsforsok,
  fetchingHandlaggareList,
  assignHandlaggare,
  unassignHandlaggare,
}: StartklarCaseListProps): JSX.Element => (
  <List disablePadding>
    {(fetchingCaseListStartklar || fetchingHandlaggareList) === FetchingState.LOADING && <Loading />}
    {(fetchingCaseListStartklar || fetchingHandlaggareList) === FetchingState.SUCCESS &&
      startklarCases.map((startklarCase: ICaseStartklar) => {
        return (
          <StartklarListItem
            key={startklarCase.id}
            startklarCase={startklarCase}
            updateNoOfUppringningsforsok={updateNoOfUppringningsforsok}
            assignHandlaggare={assignHandlaggare}
            unassignHandlaggare={unassignHandlaggare}
          />
        )
      })}
  </List>
)

export default StartklarCaseList
