import React, { ChangeEvent, useCallback } from 'react'
import { Checkbox, Dropdown, ISelectOption, useAccordion } from '@trr/common-component-library'
import Panel from '@local/Components/Panel'
import { isEmpty, partial } from 'ramda'
import { addMonths } from 'date-fns'
import { formatDateFromDateTime } from '@local/Utils/DateTime'
import { convertToSelectOptionArray } from '@local/Utils/helpers'
import { getFacetCount } from '@local/Utils/Facets'
import { suggestionsLoader } from '@local/Utils/Suggestions'
import { markningChipDisplayNameDic } from '@local/src/Utils/Categories'
import { formatAmount } from '@local/src/Utils/smallfuncs'
import { useLocation } from 'react-router-dom'
import { isKompetensstodsTab } from '@local/src/Views/Arenden/Arenden.helpers'
import { ArendeMarkning, ArendeTyp } from '@local/src/Views/Arenden/Arenden.types'
import { Box } from '@mui/material'
import { ForedragetSprak } from '@local/src/Views/Case/Case.types'
import { useAuthentication, useUser } from '@trr/app-shell-data'
import { ExtendedUser } from '@local/src/App.types'

import { IArendenFilterState, ICasesFilter, IDinaArendenFilterState } from './CasesFilter.types'
import s from './CasesFilter.module.scss'

export const CasesFilter = ({ isDinaArenden, facets, subStatusFacets, filter, setFilterParameters }: ICasesFilter): JSX.Element => {
  const { pathname } = useLocation()
  const isKompetensstod = isKompetensstodsTab(pathname)
  let loadSuggestionsFilter = { ...filter }
  const radgivarId = (useUser() as ExtendedUser).mdid
  const id_token = useAuthentication().idToken

  if (isDinaArenden) {
    loadSuggestionsFilter = {
      ...loadSuggestionsFilter,
      radgivarId,
    }
  }

  const loadSuggestions = useCallback(suggestionsLoader(loadSuggestionsFilter, id_token), [loadSuggestionsFilter, id_token])

  // Nice to have setters, maybe can be external file?
  const toggleSubstatus = (event: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event
    const currentListOfSubStatusar = filter.subStatusar

    let updatedListOfSubStatusar = []
    if (currentListOfSubStatusar.includes(event.currentTarget.id)) {
      updatedListOfSubStatusar = currentListOfSubStatusar.filter((value: string) => value !== currentTarget.id)
    } else {
      updatedListOfSubStatusar = [...currentListOfSubStatusar, currentTarget.id]
    }

    if (updatedListOfSubStatusar.includes('Avslutat')) {
      setFilterParameters({
        subStatusar: updatedListOfSubStatusar,
      })
    } else {
      setFilterParameters({
        radgivningAvslutsKoder: [],
        subStatusar: updatedListOfSubStatusar,
      })
    }
  }

  const toggleMultipleChoiceValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event
    const targetInputElementName = currentTarget.name as keyof (IArendenFilterState | IDinaArendenFilterState)
    const currentListOfFilteredValues = filter[targetInputElementName] as string[]
    let updatedListOfFilteredValues = []
    if (currentListOfFilteredValues.includes(currentTarget.value)) {
      updatedListOfFilteredValues = currentListOfFilteredValues.filter((value: string) => value !== currentTarget.value)
    } else {
      updatedListOfFilteredValues = [...currentListOfFilteredValues, currentTarget.value]
    }

    setFilterParameters({
      [targetInputElementName]: updatedListOfFilteredValues,
    })
  }

  const toggleRadgivningSlutDatumMax = () => {
    let newMaxDate = formatDateFromDateTime(addMonths(new Date(), 6).toString())
    if (!isEmpty(filter.radgivningSlutDatumMax)) {
      newMaxDate = ''
    }
    setFilterParameters({
      radgivningSlutDatumMax: newMaxDate,
    })
  }

  const toggleBoolean = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterParameters({
      [event.currentTarget.name]: !filter.saknarInbokatMote,
    })
  }

  const toggleUndantagsgrupp = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterParameters({
      [event.currentTarget.name]: !filter.undantagsgrupp,
    })
  }

  const setSaknarRadgivare = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const saknarRadgivare = !filter.saknarRadgivare
      setFilterParameters({
        [event.currentTarget.name]: saknarRadgivare,
        radgivareNamn: saknarRadgivare ? [] : filter.radgivareNamn,
      })
    },
    [filter.saknarRadgivare, filter.radgivareNamn, setFilterParameters]
  )

  const setMultipleChoiceValues = (filterParameter: string, selectedOptions: ISelectOption[]) => {
    const selectedValues = selectedOptions ? selectedOptions.map((selectedOption: ISelectOption) => selectedOption.value) : []
    setFilterParameters({
      [filterParameter]: selectedValues,
    })
  }

  const isVisibleInKompetensstodsTab = (title: string): boolean => {
    const visibleFilters = [
      'Rådgivare',
      'Ärendestatus',
      'Ärendemärkning',
      'Avslutskoder',
      'Plats',
      'Företag',
      'Befattning',
      'Begäran om yttrande',
      'Studiestödsansökan',
      'Språk',
    ]
    return visibleFilters.includes(title)
  }

  const isFilterOptionInUse = (title: string): boolean => {
    switch (title) {
      case 'Ärendestatus':
        return !isEmpty(filter.subStatusar)
      case 'Avslutskoder':
        return !isEmpty(filter.radgivningAvslutsKoder)
      case 'Ärendemärkning':
        return !isEmpty(filter.arendeMarkningar) || filter.undantagsgrupp
      case 'Kontakt':
        return Boolean(filter.saknarInbokatMote)
      case 'Rådgivare':
        return Boolean(filter.saknarRadgivare) || !isEmpty(filter.radgivareNamn)
      case 'Tid':
        return filter.orsakerAnsokan.includes('RiskForUppsagningPgaArbetsbrist') || Boolean(filter.radgivningSlutDatumMax)
      case 'Plats':
        return ['klientOrter', 'klientKommuner', 'klientLan'].map((plats) => !isEmpty(filter[plats])).includes(true)
      case 'Företag':
        return !isEmpty(filter.foretagNamn)
      case 'Befattning':
        return !isEmpty(filter.befattningar)
      case 'Projekt':
        return !isEmpty(filter.projektNamn)
      case 'TRR-organisation':
        return ['radgivareNamn', 'teamNamn', 'arbetsmarknadsomraden'].map((trrOrg) => !isEmpty(filter[trrOrg])).includes(true)
      case 'Begäran om yttrande':
        return !isEmpty(filter.begaranYttrandeStatusar)
      case 'Studiestödsansökan':
        return !(isEmpty(filter.utbildningsAnmalanStatusar) && isEmpty(filter.studiestodAnsokanStatusar))
      case 'Språk':
        return !isEmpty(filter.klientForedragnaSprak)
    }
  }

  const data: { title: string; isInUse: boolean; content: JSX.Element; renderOrder?: number }[] = [
    ...(isKompetensstod
      ? [
          {
            title: 'Rådgivare',
            isInUse: isFilterOptionInUse('Rådgivare'),
            content: (
              <div className={s.MultipleDropdownWrapper}>
                <Dropdown
                  cacheOptions={false}
                  label="Rådgivare"
                  loadOptions={partial(loadSuggestions, ['radgivareNamn', 2])}
                  multi
                  noResultMinLength={1}
                  onChange={partial(setMultipleChoiceValues, ['radgivareNamn'])}
                  remoteDataSource
                  disabled={filter.saknarRadgivare}
                  value={convertToSelectOptionArray((filter as IArendenFilterState).radgivareNamn)}
                />
                <Checkbox
                  label="Ingen rådgivare"
                  customClass={s.FilterCheckbox}
                  name="saknarRadgivare"
                  checked={filter.saknarRadgivare}
                  onChange={setSaknarRadgivare}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      title: 'Ärendestatus',
      isInUse: isFilterOptionInUse('Ärendestatus'),
      content: (
        <>
          {!isKompetensstod && (
            <>
              <span className={s.TotalHitsGroup}>
                <Checkbox
                  onChange={toggleSubstatus}
                  label="Nya för mig"
                  name="subStatusar"
                  id="NyttForMig"
                  checked={filter.subStatusar.includes('NyttForMig')}
                  customClass={s.FilterCheckbox}
                />
                <span className={s.NoLineBreak}>{getFacetCount(subStatusFacets, 'SubStatusar', 'NyttForMig')}</span>
              </span>
              <span className={s.TotalHitsGroup}>
                <Checkbox
                  onChange={toggleSubstatus}
                  label="Ej kontaktade"
                  id="KlientEjKontaktad"
                  name="subStatusar"
                  checked={filter.subStatusar.includes('KlientEjKontaktad')}
                  customClass={s.FilterCheckbox}
                />
                <span className={s.NoLineBreak}>{getFacetCount(subStatusFacets, 'SubStatusar', 'KlientEjKontaktad')}</span>
              </span>
            </>
          )}

          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleSubstatus}
              label="Aktuella"
              id="Aktuellt"
              name="subStatusar"
              checked={filter.subStatusar.includes('Aktuellt')}
              customClass={s.FilterCheckbox}
            />
            <span className={s.NoLineBreak}>{getFacetCount(subStatusFacets, 'SubStatusar', 'Aktuellt')}</span>
          </span>

          {!isKompetensstod && !isDinaArenden && (
            <>
              <span className={s.TotalHitsGroup}>
                <Checkbox
                  onChange={toggleSubstatus}
                  label="Nya"
                  name="subStatusar"
                  id="Nytt"
                  checked={filter.subStatusar.includes('Nytt')}
                  customClass={s.FilterCheckbox}
                />
                <span className={s.NoLineBreak}>{getFacetCount(subStatusFacets, 'SubStatusar', 'Nytt')}</span>
              </span>
              <span className={s.TotalHitsGroup}>
                <Checkbox
                  onChange={toggleSubstatus}
                  label="Fördelning"
                  name="subStatusar"
                  id="Fordelning"
                  checked={filter.subStatusar.includes('Fordelning')}
                  customClass={s.FilterCheckbox}
                />
                <span className={s.NoLineBreak}>{getFacetCount(subStatusFacets, 'SubStatusar', 'Fordelning')}</span>
              </span>
              <span className={s.TotalHitsGroup}>
                <Checkbox
                  onChange={toggleSubstatus}
                  label="Omfördelning"
                  name="subStatusar"
                  id="ForOmfordelning"
                  checked={filter.subStatusar.includes('ForOmfordelning')}
                  customClass={s.FilterCheckbox}
                />
                <span className={s.NoLineBreak}>{getFacetCount(subStatusFacets, 'SubStatusar', 'ForOmfordelning')}</span>
              </span>
            </>
          )}

          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleSubstatus}
              id="Avslutat"
              label="Avslutade"
              name="subStatusar"
              customClass={s.FilterCheckbox}
              checked={filter.subStatusar.includes('Avslutat')}
            />
            <span className={s.NoLineBreak}>{getFacetCount(subStatusFacets, 'SubStatusar', 'Avslutat') || ''}</span>
          </span>
        </>
      ),
    },
    {
      title: 'Avslutskoder',
      isInUse: isFilterOptionInUse('Avslutskoder'),
      content: (
        <>
          {facets?.AvslutsKoder?.map((avslutsKod: { value: string; count: number }) =>
            avslutsKod.value ? (
              <span className={s.TotalHitsGroup} key={avslutsKod.value}>
                <Checkbox
                  onChange={toggleMultipleChoiceValue}
                  label={avslutsKod.value}
                  name="radgivningAvslutsKoder"
                  id={avslutsKod.value}
                  value={avslutsKod.value}
                  customClass={s.FilterCheckbox}
                  checked={filter.radgivningAvslutsKoder.includes(avslutsKod.value)}
                />
                <span className={s.NoLineBreak}>{formatAmount(avslutsKod.count)}</span>
              </span>
            ) : null
          )}
        </>
      ),
    },
    {
      title: 'Ärendemärkning',
      isInUse: isFilterOptionInUse('Ärendemärkning'),
      content: (
        <>
          {!isKompetensstod && (
            <>
              <Checkbox
                onChange={toggleMultipleChoiceValue}
                label={markningChipDisplayNameDic[ArendeMarkning.Chefsomstallning]}
                id={ArendeMarkning.Chefsomstallning}
                name="arendeMarkningar"
                value={ArendeMarkning.Chefsomstallning}
                checked={filter.arendeMarkningar.includes(ArendeMarkning.Chefsomstallning)}
                customClass={s.FilterCheckbox}
              />

              <Checkbox
                onChange={toggleMultipleChoiceValue}
                label={markningChipDisplayNameDic[ArendeMarkning.ForstarktStod]}
                id={ArendeMarkning.ForstarktStod}
                name="arendeMarkningar"
                value={ArendeMarkning.ForstarktStod}
                checked={filter.arendeMarkningar.includes(ArendeMarkning.ForstarktStod)}
                customClass={s.FilterCheckbox}
              />

              <Checkbox
                onChange={toggleMultipleChoiceValue}
                label={markningChipDisplayNameDic[ArendeMarkning.StartaForetag]}
                id={ArendeMarkning.StartaForetag}
                name="arendeMarkningar"
                value={ArendeMarkning.StartaForetag}
                checked={filter.arendeMarkningar.includes(ArendeMarkning.StartaForetag)}
                customClass={s.FilterCheckbox}
              />
            </>
          )}

          <Checkbox
            onChange={toggleMultipleChoiceValue}
            label={markningChipDisplayNameDic[ArendeMarkning.Studier]}
            id={ArendeMarkning.Studier}
            name="arendeMarkningar"
            value={ArendeMarkning.Studier}
            checked={filter.arendeMarkningar.includes(ArendeMarkning.Studier)}
            customClass={s.FilterCheckbox}
          />
          <Checkbox
            onChange={toggleUndantagsgrupp}
            label={'Undantagsgrupp'}
            data-testid={'Undantagsgrupp'}
            name="undantagsgrupp"
            checked={filter.undantagsgrupp}
            customClass={s.FilterCheckbox}
          />
        </>
      ),
    },
    {
      title: 'Begäran om yttrande',
      isInUse: isFilterOptionInUse('Begäran om yttrande'),
      renderOrder: filter.arendeTyper === ArendeTyp.Kompetensstod ? 3 : undefined,
      content: (
        <>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              label="Ny begäran"
              name="begaranYttrandeStatusar"
              id="Ny"
              value="Ny"
              data-testid="Ny"
              checked={filter.begaranYttrandeStatusar?.includes('Ny')}
              customClass={s.FilterCheckbox}
            />
            <span data-testid={`facetcount-Ny`} className={s.NoLineBreak}>
              {getFacetCount(facets, 'BegaranYttrandeStatusar', 'Ny')}
            </span>
          </span>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              label="Under bedömning"
              name="begaranYttrandeStatusar"
              id="UnderBedomning"
              value="UnderBedomning"
              data-testid="UnderBedomning"
              checked={filter.begaranYttrandeStatusar?.includes('UnderBedomning')}
              customClass={s.FilterCheckbox}
            />
            <span data-testid={`facetcount-UnderBedomning`} className={s.NoLineBreak}>
              {getFacetCount(facets, 'BegaranYttrandeStatusar', 'UnderBedomning')}
            </span>
          </span>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              label="Skickad till CSN"
              name="begaranYttrandeStatusar"
              id="Skickad"
              value="Skickad"
              data-testid="Skickad"
              checked={filter.begaranYttrandeStatusar?.includes('Skickad')}
              customClass={s.FilterCheckbox}
            />
            <span data-testid={`facetcount-Skickad`} className={s.NoLineBreak}>
              {getFacetCount(facets, 'BegaranYttrandeStatusar', 'Skickad')}
            </span>
          </span>
        </>
      ),
    },
    {
      title: 'Studiestödsansökan',
      isInUse: isFilterOptionInUse('Studiestödsansökan'),
      renderOrder: filter.arendeTyper === ArendeTyp.Kompetensstod ? 4 : undefined,
      content: (
        <>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              id="studiestodAnsokanStatusNy"
              label="Ny studiestödsansökan"
              name="studiestodAnsokanStatusar"
              value="Ny"
              checked={filter.studiestodAnsokanStatusar?.includes('Ny')}
              customClass={s.FilterCheckbox}
            />
            <span className={s.NoLineBreak}>{getFacetCount(facets, 'StudiestodAnsokanStatusar', 'Ny')}</span>
          </span>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              id="studiestodAnsokanStatusHandlaggs"
              label="Handläggs"
              name="studiestodAnsokanStatusar"
              value="Handlaggs"
              checked={filter.studiestodAnsokanStatusar?.includes('Handlaggs')}
              customClass={s.FilterCheckbox}
            />
            <span className={s.NoLineBreak}>{getFacetCount(facets, 'StudiestodAnsokanStatusar', 'Handlaggs')}</span>
          </span>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              id="studiestodAnsokanStatusGodkand"
              label="Godkänd"
              name="studiestodAnsokanStatusar"
              value="Godkand"
              checked={filter.studiestodAnsokanStatusar?.includes('Godkand')}
              customClass={s.FilterCheckbox}
            />
            <span className={s.NoLineBreak}>{getFacetCount(facets, 'StudiestodAnsokanStatusar', 'Godkand')}</span>
          </span>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              label="Godkänn köpt utbildning"
              name="utbildningsAnmalanStatusar"
              id="AvvaktarSvar"
              value="AvvaktarSvar"
              checked={filter.utbildningsAnmalanStatusar?.includes('AvvaktarSvar')}
              customClass={s.FilterCheckbox}
            />
            <span className={s.NoLineBreak}>{getFacetCount(facets, 'UtbildningsAnmalanStatusar', 'AvvaktarSvar')}</span>
          </span>
        </>
      ),
    },
    {
      title: 'Språk',
      isInUse: isFilterOptionInUse('Språk'),
      content: (
        <>
          <Checkbox
            onChange={toggleMultipleChoiceValue}
            id="foredragetSprakSvenska"
            label="Svenska"
            name="klientForedragnaSprak"
            value={ForedragetSprak.SWEDISH}
            checked={filter.klientForedragnaSprak?.includes(ForedragetSprak.SWEDISH)}
            customClass={s.FilterCheckbox}
          />
          <Checkbox
            onChange={toggleMultipleChoiceValue}
            id="foredragetSprakEngelska"
            label="Engelska"
            name="klientForedragnaSprak"
            value={ForedragetSprak.ENGLISH}
            checked={filter.klientForedragnaSprak?.includes(ForedragetSprak.ENGLISH)}
            customClass={s.FilterCheckbox}
          />
        </>
      ),
    },
    ...(isDinaArenden
      ? [
          {
            title: 'Kontakt',
            isInUse: isFilterOptionInUse('Kontakt'),
            content: (
              <div className={s.FilterSubSection}>
                <Checkbox
                  onChange={toggleBoolean}
                  checked={filter.saknarInbokatMote}
                  customClass={s.FilterCheckbox}
                  label="Inga inbokade möten"
                  name="saknarInbokatMote"
                />
              </div>
            ),
          },
        ]
      : []),

    {
      title: 'Tid',
      isInUse: isFilterOptionInUse('Tid'),
      content: (
        <>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleMultipleChoiceValue}
              label="Risk"
              name="orsakerAnsokan"
              id="RiskForUppsagningPgaArbetsbrist"
              value="RiskForUppsagningPgaArbetsbrist"
              checked={filter.orsakerAnsokan.includes('RiskForUppsagningPgaArbetsbrist')}
              customClass={s.FilterCheckbox}
            />
            <span className={s.NoLineBreak}>{getFacetCount(facets, 'OrsakerAnsokan', 'RiskForUppsagningPgaArbetsbrist')}</span>
          </span>
          <span className={s.TotalHitsGroup}>
            <Checkbox
              onChange={toggleRadgivningSlutDatumMax}
              label="1,5 åring"
              name={'radgivningSlutDatumMax'}
              checked={filter.radgivningSlutDatumMax ? true : false}
              customClass={s.FilterCheckbox}
            />
            <span className={s.NoLineBreak}>{getFacetCount(facets, 'RadgivningSlutDatum', 'EttOchEttHalvtAring')}</span>
          </span>
        </>
      ),
    },
    {
      title: 'Plats',
      isInUse: isFilterOptionInUse('Plats'),
      content: (
        <div className={s.MultipleDropdownWrapper}>
          <Dropdown
            cacheOptions={false}
            label="Bostadsort"
            loadOptions={partial(loadSuggestions, ['klientOrter', 2])}
            multi
            noResultMinLength={1}
            onChange={partial(setMultipleChoiceValues, ['klientOrter'])}
            remoteDataSource
            value={convertToSelectOptionArray(filter.klientOrter)}
          />
          <Dropdown
            cacheOptions={false}
            label="Kommun"
            loadOptions={partial(loadSuggestions, ['klientKommuner', 2])}
            multi
            noResultMinLength={1}
            onChange={partial(setMultipleChoiceValues, ['klientKommuner'])}
            remoteDataSource
            value={convertToSelectOptionArray(filter.klientKommuner)}
          />
          <Dropdown
            cacheOptions={false}
            label="Län"
            loadOptions={partial(loadSuggestions, ['klientLan', 1])}
            multi
            noResultMinLength={1}
            onChange={partial(setMultipleChoiceValues, ['klientLan'])}
            remoteDataSource
            value={convertToSelectOptionArray(filter.klientLan)}
          />
        </div>
      ),
    },
    {
      title: 'Företag',
      isInUse: isFilterOptionInUse('Företag'),
      content: (
        <Dropdown
          cacheOptions={false}
          label="Företag"
          loadOptions={partial(loadSuggestions, ['foretagNamn', 2])}
          multi
          noResultMinLength={1}
          onChange={partial(setMultipleChoiceValues, ['foretagNamn'])}
          remoteDataSource
          value={convertToSelectOptionArray(filter.foretagNamn)}
        />
      ),
    },
    {
      title: 'Befattning',
      isInUse: isFilterOptionInUse('Befattning'),
      content: (
        <Dropdown
          cacheOptions={false}
          label="Befattning"
          loadOptions={partial(loadSuggestions, ['befattningar', 2])}
          multi
          noResultMinLength={1}
          onChange={partial(setMultipleChoiceValues, ['befattningar'])}
          remoteDataSource
          value={convertToSelectOptionArray(filter.befattningar)}
        />
      ),
    },
    {
      title: 'Projekt',
      isInUse: isFilterOptionInUse('Projekt'),
      content: (
        <Dropdown
          cacheOptions={false}
          label="Projektnamn"
          loadOptions={partial(loadSuggestions, ['projektNamn', 2])}
          multi
          noResultMinLength={1}
          onChange={partial(setMultipleChoiceValues, ['projektNamn'])}
          remoteDataSource
          value={convertToSelectOptionArray(filter.projektNamn)}
        />
      ),
    },
    ...(isDinaArenden
      ? []
      : [
          {
            title: 'TRR-organisation',
            isInUse: isFilterOptionInUse('TRR-organisation'),
            content: (
              <div className={s.MultipleDropdownWrapper}>
                <Dropdown
                  cacheOptions={false}
                  label="Rådgivare"
                  loadOptions={partial(loadSuggestions, ['radgivareNamn', 2])}
                  multi
                  noResultMinLength={1}
                  onChange={partial(setMultipleChoiceValues, ['radgivareNamn'])}
                  remoteDataSource
                  value={convertToSelectOptionArray((filter as IArendenFilterState).radgivareNamn)}
                />
                <Dropdown
                  cacheOptions={false}
                  label="Team"
                  loadOptions={partial(loadSuggestions, ['teamNamn', 1])}
                  multi
                  noResultMinLength={1}
                  onChange={partial(setMultipleChoiceValues, ['teamNamn'])}
                  remoteDataSource
                  value={convertToSelectOptionArray((filter as IArendenFilterState).teamNamn)}
                />
                <Dropdown
                  cacheOptions={false}
                  label="Arbetsmarknadsområde"
                  loadOptions={partial(loadSuggestions, ['arbetsmarknadsomraden', 1])}
                  multi
                  noResultMinLength={1}
                  onChange={partial(setMultipleChoiceValues, ['arbetsmarknadsomraden'])}
                  remoteDataSource
                  value={convertToSelectOptionArray((filter as IArendenFilterState).arbetsmarknadsomraden)}
                />
              </div>
            ),
          },
        ]),
  ]

  const { openIndexes, toggleIndex } = useAccordion()

  return (
    <div className={s.CasesFilter}>
      <Box className={s.FilterSection} sx={{ display: 'grid' }}>
        {data.map((filter, index) => {
          if (isKompetensstod && !isVisibleInKompetensstodsTab(filter.title)) return null

          return (
            <Panel
              key={index}
              index={index}
              isInUse={filter.isInUse}
              isOpen={openIndexes.includes(index)}
              toggleIndex={toggleIndex}
              title={filter.title}
              renderOrder={filter.renderOrder}
            >
              {filter.content}
            </Panel>
          )
        })}
      </Box>
    </div>
  )
}

export default CasesFilter
