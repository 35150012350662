import React, { useEffect } from 'react'
import { TabsContainer, ITabItem } from '@local/src/Components/TabsContainer/TabsContainer'
import { useGetArendenEnv, useGuidFromUrl, useIsSmallScreen } from 'src/Hooks'
import { useLocation, Redirect, useRouteMatch } from 'react-router-dom'
import CompactCaseList from '@local/src/Components/CompactCaseList'
import { FetchingState } from '@local/src/Common.types'
import { useSelector } from 'react-redux'
import { isNil } from 'ramda'
import { Box } from '@mui/material'
import { useSignalR } from '@trr/app-shell-data'
import Loading from '@local/src/Components/Loading/Loading'
import { useAppDispatch } from '@local/src/Store/configureStore'

import { caseList, dinaArendenTotalPages } from '../DinaArenden/DinaArenden.selectors'
import { removeTrailingSlash } from '../../Utils/helpers'
import { isKompetensstodsArende } from '../Arenden/Arenden.helpers'
import { EArendenEnv } from '../Arenden/Arenden.types'
import { arenden, arendenTotalPages } from '../Arenden/Arenden.selectors'

import useConnectToSignalRRoom from './Hooks/connectToSignalRArendeUpdated'
import { IArendeTypTabs, Tabs } from './Case.types'
import { clearCase, fetchCase, fetchUserJmtStatus, fetchUserStatus } from './Case.actions'
import KompetensstodsArendeTypTabs from './Components/KompetensstodsArendeTypTabs'
import OmstallningsArendeTypTabs from './Components/OmstallningsArendeTypTabs'
import { avslutsdatumSelector, caseDetailsSelector, fetchingStateSelector, karriarVagledningProgressionSelector, klientSelector } from './Case.selectors'
import ArendeHeader from './ArendeHeader/ArendeHeader'

const Case = (): JSX.Element => {

  const dispatch = useAppDispatch()
  const fetchingState = useSelector(fetchingStateSelector)
  const arende = useSelector(caseDetailsSelector)
  const klient = useSelector(klientSelector)
  const karriarVagledningProgression = useSelector(karriarVagledningProgressionSelector)
  const isDinaArenden = useGetArendenEnv() === EArendenEnv.DinaArenden
  const isSmallScreen = useIsSmallScreen()
  const { pathname } = useLocation()
  const caseId = useGuidFromUrl()

  const caseSelector = isDinaArenden ? caseList : arenden
  const pageSelector = isDinaArenden ? dinaArendenTotalPages : arendenTotalPages

  const cases = useSelector(caseSelector)
  const totalPages = useSelector(pageSelector)
  const avslutsDatum = useSelector(avslutsdatumSelector)
  const signalRConnection = useSignalR()?.medarbetare as signalR.HubConnection

  const hasSeveralCases = cases.length >= 2 || totalPages >= 2

  const showCompactCaseList = hasSeveralCases && !isSmallScreen

  const isLoading = fetchingState === FetchingState.LOADING
  const doneLoading = fetchingState === FetchingState.SUCCESS
  const inbokadeMoten = arende.inbokadeMoten

  useEffect(() => {
    if (!isNil(caseId)) {
      clearCase()
      dispatch(fetchCase({ caseId, reload: false }))
    }
  }, [caseId, avslutsDatum])

  useConnectToSignalRRoom({ caseId, signalRConnection, roomName: 'ArendeUpdated' })

  useEffect(() => {
    if (fetchingState === FetchingState.SUCCESS && klient) {
      dispatch(fetchUserStatus({ klientId: klient.id }))
      dispatch(fetchUserJmtStatus(klient.id))
    }
  }, [fetchingState, klient])

  const noTabIsSelected = useRouteMatch({ exact: true, path: '*/arende/:arendeId/' })

  if (noTabIsSelected) return <Redirect to={removeTrailingSlash(pathname) + '/tidslinje'} />
  if (isLoading) return <Loading />

  const isKompetensstodsArendeTyp = isKompetensstodsArende(arende.arendeTyp)
  const Tab = isKompetensstodsArendeTyp ? Tabs.KompetensstodsArende : Tabs.OmstallningsArende
  const tabs: string[] = Object.values(Tab)
  const tabBaseProps: IArendeTypTabs = {
    caseId,
    klientId: klient?.id,
    samtyckeHalsa: klient?.samtyckeHalsa,
    arendeTyp: arende.arendeTyp,
    inbokadeMoten,
    caseDetails: arende,
    karriarVagledningProgression: karriarVagledningProgression,
  }
  const tabContents = tabs.map((tab, index) =>
    isKompetensstodsArendeTyp ? (
      <KompetensstodsArendeTypTabs {...tabBaseProps} tab={tab} key={index} />
    ) : (
      <OmstallningsArendeTypTabs {...tabBaseProps} tab={tab} key={index} />
    )
  )
  const tabUrls = tabs.map((tab) => tab.toLowerCase().replace('ä', 'a'))
  const tabItems: ITabItem[] = tabs.map((_, i) => ({ heading: tabs[i], content: tabContents[i], url: tabUrls[i] }))

  return (
    doneLoading && (
      <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} justifyContent="center" data-testid="Main">
        {showCompactCaseList && <CompactCaseList />}
        <Box display='flex' flex='1 1' flexDirection='column' maxWidth='960px'>
          <ArendeHeader />
          <TabsContainer items={tabItems} />
        </Box>
      </Box>
    )
  )
}

export default Case
