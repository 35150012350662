/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createActions } from 'redux-actions'
import { get, put } from '@local/Utils/network'
import { IChangeTeamBody } from '@local/src/Views/Case/Case.actions'

import { IAllocationDetails } from './AllocateCases.types'

export const {
  fetchAllocationDetailsInitiate,
  fetchAllocationDetailsSuccess,
  fetchAllocationDetailsFailure,
  fetchAllocateCaseListInitiate,
  fetchAllocateCaseListSuccess,
  fetchAllocateCaseListFailure,
  fetchAllAllocateTeamListInitiate,
  fetchAllAllocateTeamListSuccess,
  fetchAllAllocateTeamListFailure,
  fetchAllocateTeamListInitiate,
  fetchAllocateTeamListSuccess,
  fetchAllocateTeamListFailure,
  fetchAllocateAdvisorListInitiate,
  fetchAllocateAdvisorListSuccess,
  fetchAllocateAdvisorListFailure,
  allocateCasesInitiated,
  allocateCasesSuccess,
  allocateCasesFailure,
  clearAllocationResponse,
  clearChangeTeamErrors,
  changeClientAllocationTeamInitiated,
  changeClientAllocationTeamSuccess,
  changeClientAllocationTeamFailure,
} = createActions({
  fetchAllocationDetailsInitiate: () => null,
  fetchAllocationDetailsSuccess: (allocationDetails: IAllocationDetails) => ({
    allocationDetails,
  }),
  fetchAllocationDetailsFailure: (errorMessage) => {
    return `Det gick inte att hämta alla detaljer på ärendet. ${String(errorMessage)}`
  },
  fetchAllocateCaseListInitiate: () => null,
  fetchAllocateCaseListSuccess: (caseList) => ({ caseList }),
  fetchAllocateCaseListFailure: (errorMessage) => {
    return `Det gick inte att hämta ärendelistan. ${String(errorMessage)}`
  },
  fetchAllocateTeamListInitiate: () => null,
  fetchAllocateTeamListSuccess: (allocatableTeams) => ({ allocatableTeams }),
  fetchAllocateTeamListFailure: (errorMessage) => {
    return `Det gick inte att hämta några team. ${String(errorMessage)}`
  },
  fetchAllAllocateTeamListInitiate: () => null,
  fetchAllAllocateTeamListSuccess: (allTeams) => ({ allTeams }),
  fetchAllAllocateTeamListFailure: (errorMessage) => {
    return `Det gick inte att hämta några team. ${String(errorMessage)}`
  },
  fetchAllocateAdvisorListInitiate: () => null,
  fetchAllocateAdvisorListSuccess: (advisorList) => ({ advisorList }),
  fetchAllocateAdvisorListFailure: (errorMessage) => {
    return `Det gick inte att hämta rådgivarlistan. ${String(errorMessage)}`
  },
  allocateCasesInitiated: () => null,
  allocateCasesSuccess: (statusListWrapper) => ({
    statusList: statusListWrapper.arendeStatusList,
    successMessage: 'Fördelningen gick bra',
  }),
  allocateCasesFailure: (errorMessage) => {
    return `Det gick inte att fördela ärendet/ärendena. ${String(errorMessage)}`
  },
  changeClientAllocationTeamInitiated: () => null,
  changeClientAllocationTeamSuccess: (requestBody: IChangeTeamBody) => {
    return { ...requestBody, successMessage: 'Team bytt' }
  },
  changeClientAllocationTeamFailure: (errorMessage) => {
    return `Det gick inte att byta team. ${String(errorMessage)}`
  },
  clearAllocationResponse: () => null,
  clearChangeTeamErrors: () => null,
})

export const fetchAllocationDetails = (clientId: string, caseId: string) =>
  get({
    url: `/arenden/${caseId}/klient/${clientId}/detaljer`,
    initiated: fetchAllocationDetailsInitiate,
    fulfilled: fetchAllocationDetailsSuccess,
    rejected: fetchAllocationDetailsFailure,
    apiVersion: '2.0'
  })
export const fetchAllocateCaseList = (teamId: string) =>
  get({
    url: `/fordelning/fordelningsarenden?teamId=${teamId}`,
    initiated: fetchAllocateCaseListInitiate,
    fulfilled: fetchAllocateCaseListSuccess,
    rejected: fetchAllocateCaseListFailure,
  })

export const fetchActiveAdvisors = (teamId?: string) => {
  const advisorsUrl = `/radgivare/aktivaradgivare${teamId ? `?teamId=${teamId}` : ''}`
  return get({
    url: advisorsUrl,
    initiated: fetchAllocateAdvisorListInitiate,
    fulfilled: fetchAllocateAdvisorListSuccess,
    rejected: fetchAllocateAdvisorListFailure,
  })
}

export const fetchAllTeams = () =>
  get({
    url: '/teams',
    initiated: fetchAllAllocateTeamListInitiate,
    fulfilled: fetchAllAllocateTeamListSuccess,
    rejected: fetchAllAllocateTeamListFailure,
  })

export const fetchAllocatableTeams = () =>
  get({
    url: '/fordelningsansvarig/minateam',
    initiated: fetchAllocateTeamListInitiate,
    fulfilled: fetchAllocateTeamListSuccess,
    rejected: fetchAllocateTeamListFailure,
  })

export interface ISingleAdvisorAllocation {
  radgivningsArendeList: string[]
  radgivareId: string
}
export const requestSingleDinaArendeAllocation = (body: ISingleAdvisorAllocation) =>
  put({
    url: '/fordelning/fordela',
    initiated: allocateCasesInitiated,
    fulfilled: allocateCasesSuccess,
    rejected: allocateCasesFailure,
    body,
  })

export interface ICaseAllocationRequestParameters {
  fordelningList: { radgivareId: string; arendeId: string }[]
}
export const requestCaseAllocation = (body: ICaseAllocationRequestParameters) =>
  put({
    url: '/fordelning/fordelaarenden',
    initiated: allocateCasesInitiated,
    fulfilled: allocateCasesSuccess,
    rejected: allocateCasesFailure,
    body,
  })

export const changeTeam = (body: IChangeTeamBody) =>
  put({
    url: `/fordelning/bytfordelningsteam`,
    initiated: changeClientAllocationTeamInitiated,
    fulfilled: changeClientAllocationTeamSuccess,
    rejected: changeClientAllocationTeamFailure,
    body,
  })
