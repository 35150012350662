import React from 'react'
import { IAdvisorStatistics } from '@local/src/Common.types'

import s from './AdvisorListItem.module.scss'

interface IAdvisorListItem {
  advisorName: string
  statistik: IAdvisorStatistics
}

const AdvisorListItem = ({ advisorName, statistik }: IAdvisorListItem): JSX.Element => (
  <div className={s.Wrapper}>
    <div className={s.ContentLeft}>{advisorName}</div>
    <div className={s.ContentRight}>
      <span data-testid={'idag'}>{statistik.idag}</span>
      <span data-testid={'in'}>{statistik.in}</span>
      <span data-testid={'inPlusMinus'}>{statistik.inPlusMinus}</span>
      <span data-testid={'aktuella'}>{statistik.aktuella}</span>
    </div>
  </div>
)

export default AdvisorListItem
