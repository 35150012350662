import React, { useEffect, useState } from 'react'
import { FetchingState } from '@local/src/Common.types'
import { useDispatch, useSelector } from 'react-redux'
import AccordionBox from '@local/src/Components/AccordionBox/AccordionBox'
import { Box, Button, IconButton, List, ListItem, Popover, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useIsSmallScreen } from '@local/src/Hooks'
import Modal from '@local/src/Components/Modal/Modal'
import CloseIcon from '@mui/icons-material/Close'
import Loading from '@local/src/Components/Loading/Loading'

import { ITodo } from './TodoList.types'
import TodoListItem from './TodoListItem/TodoListItem'
import { fetchTodoList } from './TodoList.actions'
import { fetchingTodoListStateSelector, todoListSelector } from './TodoList.selectors'

const TodoList = (): JSX.Element => {

  const isSmallScreen = useIsSmallScreen()
  const dispatch = useDispatch()
  const todoList = useSelector(todoListSelector)
  const fetchingTodoListState = useSelector(fetchingTodoListStateSelector)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  useEffect(() => {
    dispatch(fetchTodoList())
  }, [fetchTodoList])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setModalIsOpen(true)
  }
  const onClose = () => {
    setModalIsOpen(false)
  }

  const getTodosByTaskStatus = (status: string) => {
    const dateParameterBasedOnStatus = status === 'Active' ? 'activeDatum' : 'completedDatum'
    return todoList
      .filter((todo: ITodo) => todo.arbetsuppgiftStatus === status)
      .sort((a, b) => {
        return new Date(b[dateParameterBasedOnStatus]).getTime() - new Date(a[dateParameterBasedOnStatus]).getTime()
      })
      .map((todo: ITodo) => <TodoListItem key={todo.id} todo={todo} />)
  }

  const MAX_AMOUNT_OF_COMPLETED_TODOS = 10
  const todos = getTodosByTaskStatus('Active')
  const todosCount = todos.length
  const doneTodos = getTodosByTaskStatus('Completed')
  const doneTodosCount = doneTodos.length

  const todoContent = (
    <>
      {fetchingTodoListState === FetchingState.LOADING ? (
        <Loading />
      ) : (
        <>
          <List disablePadding>
          {todosCount === 0
            ? <ListItem divider sx={{ justifyContent: 'center' }}><Typography variant='body1' marginBottom={1}>Du har inga uppgifter.</Typography></ListItem>
            : todos
          }
          </List>
          {doneTodosCount !== 0 && (
            <AccordionBox label='Klart' buttonPlacement='center'>
              {doneTodos.slice(0, MAX_AMOUNT_OF_COMPLETED_TODOS)}
            </AccordionBox>
          )}
        </>
      )}
    </>
  )

  return (
    <>
      <Button
        variant='text'
        startIcon={<CheckCircleIcon/>}
        onClick={handleOpen}
      >
        Att göra {todosCount}
      </Button>

      { isSmallScreen ? (
        <Modal
          label={`Att göra ${todosCount}`}
          cancelText='Stäng'
          closeIcon
          open={modalIsOpen}
          onClose={onClose}
        >
          {todoContent}
        </Modal>
      ) : (
        <Popover
          PaperProps={{ sx: { width: 360 }}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={modalIsOpen}
          onClose={onClose}
          anchorEl={anchorEl}
          disableScrollLock
        >
          <Box display='flex' justifyContent='space-between' alignItems='center' pt={1} pr={1} pb={0} pl={3}>
            <Typography variant='body1' fontWeight='bold'>Att göra</Typography>
            <IconButton onClick={onClose}><CloseIcon/></IconButton>
          </Box>
          {todoContent}
        </Popover>
      )}
    </>
  )
}
export default TodoList
