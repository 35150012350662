import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import MicroFrontendLoader from '@local/src/Components/MicroFrontendLoader'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import StudieAnmalanWrapper from '../../../Features/StudieAnmalan/StudieAnmalanWrapper/StudieAnmalanWrapper'

import s from './StudieWrapper.module.scss'

interface IStudieWrapperProps {
  arendeId: string
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
})

const StudieWrapper = ({ arendeId }: IStudieWrapperProps) => {
  const findCoursesPro = useIsFeatureEnabled('medarbetareMicroFrontend.Pluggis.FindcoursesPro')
  const ansokanMatch = useRouteMatch<{ arendeId: string; ansokanId: string }>({ path: '*/arende/:arendeId/studier/ansokan/:ansokanId' })
  const anmalanMatch = useRouteMatch<{ bookingId: string; arendeId: string }>({ path: '*/arende/:arendeId/studier/anmalan/:bookingId' })
  const isAnmalanDetailsPage = Boolean(anmalanMatch?.params?.bookingId)
  const isAnsokanDetailsPage = Boolean(ansokanMatch?.params?.ansokanId)
  const showStudieAnmalan = findCoursesPro && !isAnsokanDetailsPage
  const showStudiestodAnsokan = !isAnmalanDetailsPage

  return (
    <div className={s.Container}>
      {showStudiestodAnsokan && <MicroFrontendLoader frontend="medarbetare-studier" />}
      <QueryClientProvider client={queryClient}>
        {showStudieAnmalan && <StudieAnmalanWrapper arendeId={arendeId} />}
        <ReactQueryDevtools />
      </QueryClientProvider>
    </div>
  )
}

export default StudieWrapper
