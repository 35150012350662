import React, { useState } from 'react'
import { formatDateFromDateTime as formatDate } from '@local/Utils/DateTime'
import { IArendeContent } from '@local/src/Views/Case/Case.types'
import { Box, Button, Chip, ListItem, Typography } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteDocumentModal from '@local/Views/Case/Documents/DeleteDocumentModal'
import { styled } from '@mui/material/styles'
import { useEpiContent } from '@trr/app-shell-data'

const LinkButton = styled(Button)(() => ({
  display: 'flex',
  maxWidth: '80%',
  alignItems: 'center',
  borderRadius: 0,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

export interface IDocumentCard {
  documentName: string
  uploadedBy: string
  uploadedDate: Date
  hasHealthInformation?: boolean
  id: string
  arendeId: string
  fetchDocument(arendeId: string, documentId: string, fileName: string): void
  deleteDocument(arendeId: string, documentId: string): void
  type: string
}

const DocumentCard = ({
  hasHealthInformation,
  documentName,
  uploadedBy,
  uploadedDate,
  id,
  arendeId,
  fetchDocument,
  deleteDocument,
  type,
}: IDocumentCard): JSX.Element => {
  const {
    dokument: { dokumentLista: content },
  }: Partial<IArendeContent> = useEpiContent()
  const [isRemoveDialogActive, setIsRemoveDialogActive] = useState(false)
  const isTypeStudieIntyg = type?.toLowerCase() === 'studieintyg'

  const handleRemoveDialogActive = (active: boolean) => setIsRemoveDialogActive(active)

  return (
    <>
      <ListItem sx={{ padding: { xs: '16px 0', md: 2 }, borderBottom: 0, borderTop: '1px solid #0000001f' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', width: '100%', gap: 1 }}>
            {hasHealthInformation && <Chip label="Hälsa" />}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <LinkButton
                variant="text"
                disableRipple
                disableTouchRipple
                startIcon={<DescriptionIcon fontSize="small" />}
                data-testid="download-file"
                onClick={() => fetchDocument(arendeId, id, documentName)}
              >
                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} noWrap>
                  {documentName}
                </Typography>
              </LinkButton>
              <LinkButton
                variant="text"
                disableRipple
                disableTouchRipple
                data-testid="delete-file"
                onClick={() => handleRemoveDialogActive(true)}
                sx={{ flexShrink: 0 }}
                startIcon={<DeleteIcon fontSize="small" />}
              >
                {content.deleteDocumentButtonText}
              </LinkButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 0.5 }}>
                <Typography variant="subtitle2">{content.uploadedBy}</Typography>
                <Typography variant="body2">{uploadedBy}</Typography>
              </Box>
              <Typography variant="body2">{formatDate(uploadedDate)}</Typography>
            </Box>
          </Box>
        </Box>
      </ListItem>
      <DeleteDocumentModal
        deleteDocument={deleteDocument}
        documentId={id}
        arendeId={arendeId}
        content={content}
        isTypeStudieIntyg={isTypeStudieIntyg}
        isRemoveDialogActive={isRemoveDialogActive}
        setIsRemoveDialogActive={handleRemoveDialogActive}
      />
    </>
  )
}
export default DocumentCard
