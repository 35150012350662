import { Box, Chip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ArendeTyp, EArendenEnv } from '@local/src/Views/Arenden/Arenden.types'
import { AccountCircleOutlined } from '@mui/icons-material'
import { IAdvisor } from '@local/src/Views/AllocateCases/AllocateCases.types'
import { equals } from 'ramda'
import { useGetArendenEnv } from '@local/src/Hooks'
import { useDispatch, useSelector } from 'react-redux'
import { tiaRadgivareList } from '@local/src/Views/Arenden/Arenden.selectors'
import { putTiaRadgivare, deleteTiaRadgivare } from '@local/src/Views/Arenden/Arenden.actions'

import { ICaseAction, IListRadgivare } from '../../ActionBasedCaseItem/ActionBasedCaseItem.types'
import PickAdvisor from '../../AdvisorSelectors/AdvisorSelectorDesktop'

export interface ActionBarProps {
  isSmallScreen: boolean
  actions: ICaseAction[]
  arendeTyp: ArendeTyp
  arendeId: string
  radgivare?: IListRadgivare
  tiaRadgivare?: IAdvisor
}

const ActionBar = ({ isSmallScreen, actions, arendeTyp, arendeId, radgivare, tiaRadgivare }: ActionBarProps) => {
  const dispatch = useDispatch()
  const arendenEnv = useGetArendenEnv()
  const isDinaArenden = arendenEnv === EArendenEnv.DinaArenden
  const isKompetensstodsArenden = arendenEnv === EArendenEnv.KompetensstodsArenden
  const [chosenAdvisor, setChosenAdvisor] = useState(tiaRadgivare)
  const radgivareName = `${radgivare?.fornamn ?? ''} ${radgivare?.efternamn ?? ''}`.trim()
  const tiaRadgivarList = useSelector(tiaRadgivareList)

  useEffect(() => {
    canUpdateTiaRadgivare() && dispatch(putTiaRadgivare(arendeId, chosenAdvisor))
  }, [chosenAdvisor])

  const removeAdvisor = () => {
    dispatch(deleteTiaRadgivare(arendeId))
    setChosenAdvisor(null)
  }

  const canUpdateTiaRadgivare = () => {
    return chosenAdvisor && !equals(chosenAdvisor, tiaRadgivare)
  }

  const isEmptyComponent = () => {
    const hasActions = actions.length > 0
    const hasRadgivare = arendeTyp === ArendeTyp.Omstallning && !isDinaArenden && radgivareName
    const showPickAdvisor = isKompetensstodsArenden

    return !(hasActions || hasRadgivare || showPickAdvisor)
  }

  // To avoid rendering empty divs
  if (isEmptyComponent()) return <></>

  return (
    <Box
      width={1}
      display="flex"
      justifyContent="space-between"
      flexDirection={isSmallScreen ? 'column' : 'row'}
      gap={isSmallScreen ? 1.5 : 3}
      alignItems={isSmallScreen ? 'flex-start' : 'center'}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        gap={isSmallScreen ? 0 : 2}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        paddingLeft={1}
      >
        {actions.map((action: ICaseAction, index: number) => (
          <Chip key={index} variant={`status-${action.severity}`} label={action.displayText} />
        ))}
      </Box>

      <Box>
        {arendeTyp === ArendeTyp.Omstallning
          ? !isDinaArenden &&
            radgivareName && (
              <Box display="flex" gap={1} alignItems="center">
                <AccountCircleOutlined fontSize="medium" />
                <Typography variant="subtitle2">{radgivareName}</Typography>
              </Box>
            )
          : isKompetensstodsArenden && (
              <PickAdvisor advisorList={tiaRadgivarList} choose={setChosenAdvisor} chosenAdvisor={chosenAdvisor} remove={removeAdvisor} />
            )}
      </Box>
    </Box>
  )
}

export default ActionBar
