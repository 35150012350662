import { IStagedCase } from '@local/Views/AllocateCases/AllocateCaseList/AllocateCaseList.types'
import { IAllocateCaseListItem } from '@local/Views/AllocateCases/AllocateCases.types'

export const filterCheckbox = (
  cases: IAllocateCaseListItem[],
  checked: boolean,
  key: keyof IAllocateCaseListItem,
  subkey?: string
): IAllocateCaseListItem[] => {
  return cases.filter(
    (item) =>
      // NOTE: Implementation is tied to business logic. Revisit as necessary.
      // If checked, always show cases.
      // If subkey exists (i.e. there are coupled checkboxes), show cases where the the subkey is not present.
      // If subkey doesn't exist (i.e. standalone checkbox), show cases where the key _IS_ present.
      checked || (subkey ? item[key] !== subkey : item[key])
  )
}
export const filterDropdown = (
  cases: IAllocateCaseListItem[],
  selectedCaseId: string,
  key: keyof IAllocateCaseListItem
): IAllocateCaseListItem[] => cases.filter((item) => selectedCaseId === item[key] || selectedCaseId === 'Visa alla')

export const klientAlderFilterSlider = (
  cases: IAllocateCaseListItem[],
  boundaries: number[],
): IAllocateCaseListItem[] => cases.filter((item) => item.klientAlder >= boundaries[0] && item.klientAlder <= boundaries[1])

interface IObjectWithCaseWithId {
  case: {
    id: string
  }
}

const pluckIds = (objectsWithCasesWithIds: IObjectWithCaseWithId[]) => {
  const ids = objectsWithCasesWithIds.map((objectWithId: IObjectWithCaseWithId) => objectWithId.case.id)
  return ids
}

export const filterStaged = (cases: IAllocateCaseListItem[], stagedCases: IStagedCase[]): IAllocateCaseListItem[] => {
  if (stagedCases.length !== 0) {
    const pluckedArr = pluckIds(stagedCases)
    return cases.filter((item) => !pluckedArr.includes(item.id))
  } else {
    return cases
  }
}

export const filterId = <T extends { id: string }>(objectsWithId: T[], idToFilter: string): T[] => {
  return objectsWithId.filter((objectWithId) => objectWithId.id !== idToFilter)
}
