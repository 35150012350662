import { connect } from 'react-redux'
import { RootState } from '@local/src/Store/configureStore'

import { setStartklarFilterParameters } from '../Startklar.actions'
import { arbetsmarknadsomradeFacetsFromStartklarFacets, filterState, handlaggareListSelector } from '../Startklar.selectors'

import StartklarFilter from './StartklarFilter'

const mapStateToProps = (state: RootState) => ({
  arbetsmarknadsomraden: arbetsmarknadsomradeFacetsFromStartklarFacets(state)?.map((facet) => facet.value),
  filterState: filterState(state),
  handlaggareList: handlaggareListSelector(state),
})

const mapDispatchToProps = {
  setStartklarFilterParameters,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartklarFilter)
