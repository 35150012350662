import { connect } from 'react-redux'
import { RootState } from '@local/src/Store/configureStore'

import {
  startklarCases,
  fetchingCaseListStartklar,
  fetchingHandlaggareList,
  assignHandlaggareState,
  unassignHandlaggareState,
} from '../Startklar.selectors'
import { assignHandlaggare, unassignHandlaggare, updateNoOfUppringningsforsok } from '../Startklar.actions'

import StartklarCaseList from './StartklarCaseList'

const mapStateToProps = (state: RootState) => ({
  startklarCases: startklarCases(state),
  fetchingCaseListStartklar: fetchingCaseListStartklar(state),
  fetchingHandlaggareList: fetchingHandlaggareList(state),
  assignHandlaggareState: assignHandlaggareState(state),
  unassignHandlaggareState: unassignHandlaggareState(state),
})

const mapDispatchToProps = {
  assignHandlaggare,
  unassignHandlaggare,
  updateNoOfUppringningsforsok,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartklarCaseList)
