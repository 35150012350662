import { useAuthentication } from '@trr/app-shell-data'
import { useEffect, useState } from 'react'

import getConfig from '../Utils/ConfigService'

const useCanViewArbetslista = () => {

  const { ARBETSLISTA_ALLOWED_ROLES } = getConfig()
  const appShellRoles = useAuthentication().userRoles
  const [hasAccess, setHasAccess] = useState(false)

  useEffect(() => {
    setHasAccess(appShellRoles.some(role => ARBETSLISTA_ALLOWED_ROLES.includes(role)))
  }, [appShellRoles])

  return hasAccess
}

export { useCanViewArbetslista }