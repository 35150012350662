import React, { useEffect } from 'react'
import { DataGrid, GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useUser } from '@trr/app-shell-data'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'
import { ArrowDropDownRounded } from '@mui/icons-material'

import { Arbetsuppgift, ArbetsuppgiftFilter, ArbetsuppgiftOrderBy, ArbetsuppgiftPagination } from '../types'

import { getColumnVisibility, getColumns } from './ArbetsuppgiftTableColumns'

interface ArbetsuppgiftTableProps {
  rows: Arbetsuppgift[]
  totalHits?: number
  page: string
  pageSize: string
  orderBy: ArbetsuppgiftOrderBy
  onPaginationChange: (key: keyof ArbetsuppgiftFilter, value: ArbetsuppgiftPagination) => void
  onSortChange: (key: keyof ArbetsuppgiftFilter, value: ArbetsuppgiftOrderBy) => void
}

const ArbetsuppgiftTable = ({ rows, totalHits, page, pageSize, orderBy, onPaginationChange, onSortChange }: ArbetsuppgiftTableProps) => {

  const [currentPage, setCurrentPage] = React.useState(page)
  const [currentPageSize, setCurrentPageSize] = React.useState(pageSize)
  const currentUser = useUser().mdid
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const paginationFilterKey = 'pagination'
  const orderByFilterKey = 'orderBy'

  const transformedRows: Arbetsuppgift[] = rows.map((row) => {

    const owner = row.owner
    let ownerName = ''

    if (owner) {
      ownerName = owner.id === currentUser ? 'Jag' : owner.fullName
    }

    return {
      ...row,
      owner: {
        ...row.owner,
        fullName: ownerName,
      }
    }
  })

  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <EmptyResult description="Din filtrering fick inga träffar." />
      </Stack>
    )
  }

  useEffect(() => {
    onPaginationChange(paginationFilterKey, { page: currentPage, pageSize: currentPageSize })
  }, [currentPage])

  useEffect(() => {
    onPaginationChange(paginationFilterKey, { page: '1', pageSize: currentPageSize })
  }, [currentPageSize])

  useEffect(() => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [page, pageSize])

  const handlePaginationChange = (params: GridPaginationModel) => {
    setCurrentPage((params.page + 1).toString())
    setCurrentPageSize(params.pageSize.toString())
  }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      onSortChange(orderByFilterKey, undefined)
    } else {
      onSortChange(orderByFilterKey, { property: model[0].field, direction: model[0].sort })
    }
  }

  return (
    <Box height={transformedRows.length > 0 ? 'auto' : 600} marginTop={2}>
      <DataGrid
        sortModel={orderBy ? [{ field: orderBy.property, sort: orderBy.direction }] : []}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handlePaginationChange}
        rows={transformedRows}
        rowCount={totalHits}
        columns={getColumns(isMobile, currentUser)}
        columnVisibilityModel={getColumnVisibility(isMobile)}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          pagination: { SelectProps: { IconComponent: ArrowDropDownRounded } }
        }}
        paginationModel={{ page: Number(page) - 1, pageSize: Number(pageSize) }}
        disableRowSelectionOnClick
        disableColumnMenu
        paginationMode="server"
        sortingMode="server"
        autoHeight={transformedRows.length > 0 ? true : false}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        getRowHeight={() => 'auto'}
        sx={{
          border: 0,
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            padding: isMobile ? 0 : undefined,
          },
          '& .MuiDataGrid-columnHeader': {
            padding: isMobile ? 0 : undefined,
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            flexDirection: 'initial !important',
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitleContainer > .MuiDataGrid-columnHeaderTitleContainerContent':
            {
              marginLeft: 'auto',
            },
        }}
      />
    </Box>
  )
}

export default ArbetsuppgiftTable
