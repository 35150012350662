import React from 'react'
import { folkbokforingsStatusDisplayNameDic } from '@local/src/Utils/FolkbokföringsStatus'
import { Chip } from '@mui/material'

interface FolkbokforingsStatusChipProps {
  status: string
  variant?: 'status-warning' | 'outlined'
}

const FolkbokforingsStatusChip = ({
  status,
  variant = 'status-warning',
}: FolkbokforingsStatusChipProps): JSX.Element => (
  <Chip variant={variant} title='Folkbokföringsstatus' label={folkbokforingsStatusDisplayNameDic[status]} />
)

export default FolkbokforingsStatusChip
