import React from 'react'
import { useSelector } from 'react-redux'
import { useIsUndantagsgrupp } from '@local/src/Hooks/useIsUndantagsgrupp'
import LaunchIcon from '@mui/icons-material/Launch'
import { Box, Divider, Typography } from '@mui/material'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'

import { caseDetailsSelector } from '../../../Case.selectors'

import AteroppnaModal from './AteroppnaModal/AteroppnaModal'

const ateroppningLink =
  'https://trygghetsradet.sharepoint.com/sites/operativaverksamheten/SitePages/%C3%85ter%C3%B6ppning-av-efterskydd.aspx'

const Ateroppna = () => {
  const arende = useSelector(caseDetailsSelector)
  const isUndantagsgrupp = useIsUndantagsgrupp()

  return (
    <>
      <Box>
        <Typography variant="h4" marginBottom={2}>
          Återöppna
        </Typography>
        {arende.ateroppnaRDGValid ? (
          <>
            <Typography variant="body1" marginBottom={3}>
              Du kan ångra och återöppna ärendet för fortsatt stöd från TRR.
            </Typography>
            <AteroppnaModal arendeId={arende.id} teamId={arende.omstallningsArende.radgivning.team.id} />
          </>
        ) : arende.ateroppnaAGEValid ? (
          <>
            <Typography variant="body1">
              Handläggare på Avtal och ersättning behöver bedöma om ärendet kan återöppnas eller om det eventuellt ska skickas in en ny
              ansökan.
            </Typography>
            <Typography variant="body1">
              <LinkWithIcon icon={<LaunchIcon fontSize="small" />} href={ateroppningLink} external text="Läs mer på Insidan" />
              om vad du som rådgivare kan göra eller hur du ska guida din omställningskund.
            </Typography>
          </>
        ) : (
          <>
            {isUndantagsgrupp ? (
              <Typography variant="body1">
                Detta ärende, som tillhör VD eller en undantagsgrupp, går inte att återöppna. För att få stöd från TRR igen krävs en ny
                ansökan.
              </Typography>
            ) : (
              <Typography variant="body1">Ärendet kan inte återöppnas. Tiden för omställningsstöd/efterskydd slut.</Typography>
            )}
          </>
        )}
      </Box>
      <Divider sx={{ my: 5 }} />
    </>
  )
}

export default Ateroppna
