import React from 'react'
import { Typography, TypographyVariant } from '@mui/material'
import classnames from 'classnames'

import s from './IconWithText.module.scss'

interface IIconWithText {
  children: string | JSX.Element
  icon: JSX.Element
  role?: string
  variant?: TypographyVariant
  noWrap?: boolean
}

const IconWithText = ({ children, icon, role = '', variant, noWrap }: IIconWithText): JSX.Element => {
  return variant ? (
    <Typography className={s.IconWithText} role={role} variant={variant}>
      {icon}
      {children}
    </Typography>
  ) : (
    <span className={classnames(s.IconWithText, noWrap && s.NoWrap)} role={role}>
      {icon}
      {children}
    </span>
  )
}
export default IconWithText
