export interface ArbetsuppgiftFilter {
  searchText: string
  statusar: string[]
  uppgiftsTyper: string[]
  actions: string[]
  ownerIds: string[]
  pagination: ArbetsuppgiftPagination
  orderBy: ArbetsuppgiftOrderBy
}

export interface ArbetsuppgiftOrderBy {
  property: string
  direction: 'asc' | 'desc'
}

export interface ArbetsuppgiftPagination {
  page: string
  pageSize: string
}

export enum ArbetsuppgiftCategory {
  UtbildningAttGodkanna = 'UtbildningAttGodkanna',
  NyStudiestodsAnsokan = 'NyStudiestodsAnsokan',
  UppdateradStudiestodsAnsokan = 'UppdateradStudiestodsAnsokan',
  NyttOmfattningsbeslut = 'NyttOmfattningsbeslut',
  KommentarFranAvtalErsattningar = 'KommentarFranAvtalErsattningar',
}

export enum ArbetsuppgiftAction {
  Info = 'Info',
  Uppgift = 'Uppgift',
}

export enum ArbetsuppgiftStatus {
  Ny = 'Ny',
  Pagaende = 'Pagaende',
  Klar = 'Klar',
}

export enum Actor {
  System = 'System',
  User = 'User',
}

export interface Arbetsuppgift {
  id: string
  arende: ArbetsuppgiftArende
  category: ArbetsuppgiftCategory
  action: ArbetsuppgiftAction
  status: ArbetsuppgiftStatus
  owner: Owner
  latestStatusUpdate: LatestStatusUpdate
  createdDate: string
  header: string
}

export interface LatestStatusUpdate {
  triggeredBy: Actor
  reason: string
  occuredAt: string
  user: Owner
}

export interface ArbetsuppgiftArende {
  id: string
  arendeNummer: string
  arendeStatus: string
  roles: string[]
  radgivare: Radgivare
  kund: Kund
}

export interface Owner {
  id: string
  firstName: string
  lastName: string
  fullName?: string
}

export interface Radgivare {
  id: string
  firstName: string
  lastName: string
  fullName: string
}

export interface Kund {
  id: string
  firstName: string
  lastName: string
  fullName: string
}

export interface Facet {
  value: string
  count: number
}

export interface ArbetsuppgiftFacets {
  Category: ArbetsuppgiftCategoryFacet[]
  Kund: Facet[]
  Radgivare: Facet[]
  Owner: Facet[]
  Action: ArbetsuppgifterActionFacet[]
  Status: ArbetsuppgiftStatusFacet[]
  Arende: Facet[]
}

export interface ArbetsuppgiftOwnerFacet {
  value: string
  count: number
  id?: string
}

export interface ArbetsuppgiftCategoryFacet {
  value: ArbetsuppgiftCategory
  count: number
}

export interface ArbetsuppgifterActionFacet {
  value: ArbetsuppgiftAction
  count: number
}

export interface ArbetsuppgiftStatusFacet {
  value: ArbetsuppgiftStatus
  count: number
}

export interface ArbetsuppgiftResult {
  totalCount: number
  totalPages: number
  pageNumber: number
  results: Arbetsuppgift[]
  facets: ArbetsuppgiftFacets
}

export interface UpdateArbetsuppgiftStatusRequest {
  id: string
  status: ArbetsuppgiftStatus
  userId: string
}
