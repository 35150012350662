import React from 'react'
import { Typography } from '@mui/material'

export interface SearchResultSnippetProps {
  pluralName: string
  searchQuery: string
  singularName: string
  totalHits: number
}

const SearchResultSnippet = ({
  pluralName,
  searchQuery,
  singularName,
  totalHits,
}: SearchResultSnippetProps): JSX.Element => (
  <Typography variant='body1' fontWeight='bold' textAlign={{ xs: 'center', lg: 'left' }} sx={{ wordBreak: 'break-word' }}>
    {`Sökning på "${searchQuery}" ger ${totalHits} ${totalHits === 1 ? singularName : pluralName}`}
  </Typography>
)

export default SearchResultSnippet
