import { lensPath, view } from 'ramda'
import { FetchingState, ITeam } from '@local/src/Common.types'

import { IAdvisor } from './AllocateCases.types'

// lens

export const advisorListLens = lensPath(['allocateCases', 'advisorList'])
export const fetchingAdvisorListStateLens = lensPath(['allocateCases', 'fetchingAdvisorListState'])
export const caseListLens = lensPath(['allocateCases', 'caseList'])
export const fetchingCaseListStateLens = lensPath(['allocateCases', 'fetchingCaseListState'])
export const allocatableTeamsLens = lensPath(['allocateCases', 'allocatableTeams'])
export const allTeamsLens = lensPath(['allocateCases', 'allTeams'])
export const fetchingTeamListStateLens = lensPath(['allocateCases', 'fetchingTeamListStateLens'])
export const responseArrayLens = lensPath(['allocateCases', 'statusList'])
export const changeClientAllocationTeamStateLens = lensPath(['allocateCases', 'changingClientAllocationTeamState'])

export const fetchingAllocationDetailsLens = lensPath(['allocateCases', 'fetchingAllocationDetails'])

export const allocatingCasesStateLens = lensPath(['allocateCases', 'allocatingCasesState'])

// views

export const advisorList = view<unknown, IAdvisor[]>(advisorListLens)
export const fetchingAdvisorListState = view(fetchingAdvisorListStateLens)
export const caseList = view(caseListLens)
export const fetchingCaseListState = view(fetchingCaseListStateLens)
export const allTeams = view<unknown, ITeam[]>(allTeamsLens)
export const allocatableTeams = view<unknown, ITeam[]>(allocatableTeamsLens)
export const fetchingTeamListState = view(fetchingTeamListStateLens)
export const responseArray = view(responseArrayLens)
export const allocatingCasesState = view<unknown, FetchingState>(allocatingCasesStateLens)
export const fetchingAllocationDetails = view(fetchingAllocationDetailsLens)
export const changingClientAllocationTeamState = view(changeClientAllocationTeamStateLens)
