import { useQuery } from '@tanstack/react-query'

import { getStudieAnmalan, getStudieAnmalanListByCaseId } from '../StudieAnmalanApi'

export const useStudieAnmalanList = (arendeId: string) =>
  useQuery(['studieAnmalanList'], () => getStudieAnmalanListByCaseId(arendeId), {
    placeholderData: [],
  })

export const useStudieAnmalan = (bookingId: string) => useQuery(['getStudieAnmalan'], () => getStudieAnmalan(bookingId))
