import { path } from 'ramda'
import { FacetablePropertyValue, FetchingState } from '@local/src/Common.types'

import { IAgeArende } from '../Case/Case.types'
import { IAdvisor } from '../AllocateCases/AllocateCases.types'

import { ICaseStartklar, IStartklarFilterState } from './Startklar.types'

export const facetedTotalHits = path<number | null>(['startklar', 'facetedTotalHits'])
export const totalHits = path<number>(['startklar', 'caseListStartklar', 'totalHits'])
export const filterState = path<IStartklarFilterState>(['startklar', 'startklarFilterState'])

export const fetchingHandlaggareList = path<FetchingState>(['startklar', 'fetchingHandlaggareList'])

export const startklarAgeArendeMedRattTillAgeSelector = path<IAgeArende>(['startklar', 'ageArendeMedRattTillAge'])

export const startklarCases = path<ICaseStartklar[]>(['startklar', 'caseListStartklar', 'arenden'])
export const fetchingCaseListStartklar = path<FetchingState>(['startklar', 'fetchingCaseListStartklar'])
export const currentPage = path<number>(['startklar', 'startklarFilterState', 'page'])
export const handlaggareListSelector = path<IAdvisor[]>(['startklar', 'handlaggareList'])
export const assignHandlaggareState = path<FetchingState>(['startklar', 'assignHandlaggareState'])
export const unassignHandlaggareState = path<FetchingState>(['startklar', 'unassignHandlaggareState'])
export const arbetsmarknadsomradeFacetsFromStartklarFacets = path<FacetablePropertyValue[]>([
  'startklar',
  'facets',
  'ArbetsmarknadsomradeNamn',
])
