import { IAdvisorDecision } from './IAdvisorDecision'
import { IStudieAnmalanStatuses } from './IStudieAnmalanStatuses'
import { IStudieAnmalan } from './StudieAnmalan.types'

export interface IStudieAnmalanGetModel {
  id: string
  advisorDecision?: IAdvisorDecision
  educationName: string
  educationId: string
  educationLink: string
  statuses: IStudieAnmalanStatuses
  instituteName: string
  placeDescription: string
  instituteComment: string
  isDistance: boolean
  lengthInDays: number
  startDate: string
  attendanceReport: {
    attendance: true
    recievedDate: string
    submittedBy: string
    comment: string
  }
  courseFee: {
    fee: number
    vat: number
    totalFee: number
  }
  booking: {
    date: string
    reference: string
  }
  supplier: {
    contactPersonName: string
    email: string
    phone: string
  }
}

export const MapToView = (anmalan: IStudieAnmalanGetModel): IStudieAnmalan => {
  const hasSupplierInformation = () => anmalan.supplier && !Object.values(anmalan.supplier).every((v) => v === null)
  const isCancelled =
    anmalan.statuses.isRejectedByAdvisor ||
    anmalan.statuses.isCancelledByClient ||
    anmalan.statuses.isLateCancelledByClient ||
    anmalan.statuses.isCancelledBySupplier
  return {
    id: anmalan?.id,
    attendanceReport: anmalan?.attendanceReport?.attendance && {
      ...anmalan?.attendanceReport,
      attendance: !!anmalan?.attendanceReport,
      recievedDate: new Date(anmalan?.attendanceReport?.recievedDate),
    },
    advisorDecision: anmalan.advisorDecision && {
      ...anmalan.advisorDecision,
      decidedAt: anmalan?.advisorDecision?.decidedAt ? new Date(anmalan.advisorDecision.decidedAt) : null,
    },
    cancelled: isCancelled,
    placeDescription: anmalan.placeDescription,
    educationName: anmalan.educationName,
    educationId: anmalan.educationId,
    instituteName: anmalan.instituteName,
    courseFee: anmalan.courseFee,
    educationLink: anmalan.educationLink,
    instituteComment: anmalan.instituteComment,
    booking: {
      date: anmalan?.booking?.date && new Date(anmalan.booking.date),
      reference: anmalan?.booking?.reference,
    },
    supplier: hasSupplierInformation() ? anmalan.supplier : null,
    startDate: anmalan.startDate && new Date(anmalan.startDate),
    statuses: anmalan.statuses,
    lengthInDays: anmalan.lengthInDays,
    isDistance: anmalan.isDistance,
  }
}
