import React from 'react'
import { useFormik } from 'formik'
import { Redirect } from 'react-router-dom'
import { getUrlByCaseEnv, startklarPath } from '@local/src/basename'
import { useCaseEnvBasePath, useGuidFromUrl } from '@local/src/Hooks'
import { Box, Button, Checkbox, Divider, FormControlLabel, Link, Stack, TextField, Typography } from '@mui/material'
import { useGetArendeQuery } from '@local/src/Api/Arenden/arendenApi'
import { usePostStartklarFormMutation } from '@local/src/Api/Startklar/startklarApi'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { addErrorMessage, addSuccessMessage } from '@local/src/Blocks/UserFeedback/UserFeedback.actions'
import Loading from '@local/src/Components/Loading/Loading'
import { equals } from 'ramda'

import StartklarKlientPrimaryInformation from './StartklarKlientPrimaryInformation'
import StartklarKlientSecondaryInformation from './StartklarKlientSecondaryInformation'
import { IStartklarFormValues, initialValues } from './StartklarForm.types'

const StartklarForm = () => {

  const dispatch = useAppDispatch()
  const arendeId = useGuidFromUrl()
  const caseEnvBasePath = useCaseEnvBasePath()
  const {
    data: arende,
    isLoading: isLoadingArende,
    isFetching: isFetchingArende,
    isSuccess: hasFetchedArende,
    isError
  } = useGetArendeQuery(arendeId)

  const [submitStartklarForm, { isLoading, isSuccess }] = usePostStartklarFormMutation()

  const handleSubmit = (values: IStartklarFormValues) => {
    submitStartklarForm({ arendeId: arendeId, klientId: arende.klient.id, body: values })
  }

  const validate = (values: IStartklarFormValues) => {
    const errors: { klientenSvarade?: string } = {}
    if (equals(values, initialValues)) {
      errors.klientenSvarade = 'Fyll i formuläret eller markera att omställningskunden ej är nåbar.'
    }
    return errors
  }

  const formik = useFormik({ onSubmit: handleSubmit, validate: validate, initialValues, enableReinitialize: true })

  if (isSuccess) {
    dispatch(addSuccessMessage('Ärendet skickas till fördelning'))
    return <Redirect to={getUrlByCaseEnv(startklarPath)} />
  }

  if (isError) {
    dispatch(addErrorMessage('Det gick inte att skicka ärendet till fördelning'))
    return (
      <div>
        <Typography variant="h1" align='center' gutterBottom>
          Startklar
        </Typography>
      </div>
    )
  }

  return (
    <Stack>
      <Typography variant="h1" align='center' gutterBottom>
        Startklar
      </Typography>

      <Stack component='form' onSubmit={formik.handleSubmit} margin='0 auto' maxWidth={{md: '768px'}} p={2}>
        {isLoadingArende || isFetchingArende || isLoading ? (
          <Box display='flex' justifyContent='center'>
            <Loading />
          </Box>
        ) : (hasFetchedArende && arende) ? (
          <>
            <Stack>
              <Link href={getUrlByCaseEnv(caseEnvBasePath, arende?.id)}>
                <Typography variant='body2'>#{arende.arendeNummer}</Typography>
              </Link>
              <Typography variant='h3' gutterBottom sx={{ wordBreak: {xs: 'break-all', md: 'break-word'}, wordWrap: 'break-word' }}>
                {arende.klient.fornamn} {arende.klient.efternamn}, {arende.klient.alder} år
              </Typography>
              <StartklarKlientPrimaryInformation
                companyName={arende.omstallningsArende.anstallningsuppgifter.foretag.namn}
                email={arende.klient.epost}
                jobTitle={arende.omstallningsArende.anstallningsuppgifter.befattning}
                phoneNumber={arende.klient.telefonnummer}
                kommun={arende.klient.kommun}
              />
              <StartklarKlientSecondaryInformation
                klientId={arende.klient.id}
                arendeId={arende.id}
                orsakUppsagning={arende.omstallningsArende.orsakAnsokanDisplayText}
                dayOfNoticeToQuit={arende.omstallningsArende.anstallningsuppgifter.uppsagningsDatum}
                lastDayOfEmployment={arende.omstallningsArende.inkomstuppgifter.sistaDagMedLon}
                totalLon={arende.omstallningsArende.inkomstuppgifter.totalLon}
                severancePayEndDate={arende.omstallningsArende.anstallningsuppgifter.avgangsvederlagSlutDatum}
              />
            </Stack>
            <Divider sx={{ marginY: 3, marginX: 0 }}/>

            <Stack>
              <FormControlLabel
                label='Engelsktalande'
                sx={{paddingBottom: 2}}
                control={
                  <Checkbox
                    name='engelsktalande'
                    checked={formik.values.engelsktalande}
                    onChange={formik.handleChange}/>
                }
              />

              <Box display='flex' flexDirection='column' paddingBottom={2}>
                <Typography variant='h6' gutterBottom>Nuläge och inriktning framåt</Typography>
                <TextField
                  label='Sammanfattning'
                  value={formik.values.beskrivning}
                  name='beskrivning'
                  multiline
                  fullWidth
                  rows={10}
                  onChange={formik.handleChange} />
                <FormControlLabel
                  label='Kommit igång med jobbsökningen'
                  control={
                    <Checkbox
                      name='sokerJobb'
                      checked={formik.values.sokerJobb}
                      onChange={formik.handleChange}/>
                  }
                />
                <FormControlLabel
                  label='Vill komma igång längre fram'
                  control={
                    <Checkbox
                      checked={formik.values.ejRedo}
                      name='ejRedo'
                      onChange={formik.handleChange}/>
                  }
                />
              </Box>

              <Box>
                <Typography variant='h6'>Informerat om</Typography>
                <FormControlLabel
                  label='Logga in på trr.se och gå till Kom igång'
                  control={
                    <Checkbox
                      checked={formik.values.pratatOmTrrSe}
                      name='pratatOmTrrSe'
                      onChange={formik.handleChange}/>
                  }
                />
              </Box>

              <FormControlLabel
                label='Omställningskunden ej nåbar'
                sx={{paddingRight: 2, marginLeft: 0, my: 5, p: 1, borderRadius: 0.5, maxWidth: 'max-content', bgcolor: '#f2f2f2'}}
                control={
                  <Checkbox
                    checked={formik.values.klientenSvarade === false}
                    name='klientenSvarade'
                    onChange={() => void formik.setFieldValue('klientenSvarade', !formik.values.klientenSvarade)}/>
                }
              />
              <Typography
                variant='body1'
                sx={{ overflowWrap: 'break-word', color: '#ea1845'}}
              >
                {formik.errors?.klientenSvarade}
              </Typography>
              <Button type='submit' size='large' sx={{ alignSelf: 'baseline' }}>
                Skicka till fördelning
              </Button>
            </Stack>
          </>
        ) : (
          <Box display='flex' justifyContent='center'>
            <Loading/>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default StartklarForm