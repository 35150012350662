import { Avatar, SxProps, Theme, styled } from '@mui/material'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'

import CustomPopper from '../../CustomPopper/CustomPopper'

import { StyledChip } from './StyledChip'

const ChipLabelAndIcon = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: -7,
}))

const SelectCountIndicator = (count: number) => (
  <Avatar data-testid="counter" sx={{ bgcolor: '#089188', color: '#fff !important' }}>
    {count}
  </Avatar>
)

interface ChipWithPopperProps {
  variant?: 'filled' | 'outlined'
  label: string
  count?: number
  onClose?: () => void
  onChipClick?: () => void
  onClear?: () => void
  onOpen?: () => void
  children: React.ReactNode
  icon?: ReactElement
  deleteIcon?: ReactElement
  chipSx?: SxProps<Theme>
  width?: number
  hideHeader?: boolean
  closeOnClick?: boolean
  disableClear?: boolean
}

const ChipWithPopper = ({
  variant,
  label,
  count,
  onClose,
  onChipClick,
  onClear,
  onOpen,
  children,
  hideHeader,
  chipSx,
  width,
  disableClear,
  closeOnClick = false,
}: ChipWithPopperProps) => {
  const [open, setOpen] = useState(false)
  const chipRef = useRef(null)

  const handleChipClick = () => {
    setOpen(!open)
    onChipClick?.()
  }

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const handleClickInside = () => {
    closeOnClick && setOpen(false)
  }

  useEffect(() => {
    open && onOpen?.()
  }, [open])

  return (
    <>
      <StyledChip
        visible={count > 0}
        variant={variant ?? (count ? 'filled' : 'outlined')}
        avatar={count ? SelectCountIndicator(count) : undefined}
        label={
          <ChipLabelAndIcon>
            {label} {open ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}
          </ChipLabelAndIcon>
        }
        onClick={handleChipClick}
        ref={chipRef}
        sx={chipSx}
      />
      <CustomPopper
        label={label}
        handleClose={handleClose}
        open={open}
        anchor={chipRef.current}
        hideHeader={hideHeader}
        width={width}
        onClear={onClear}
        onClick={handleClickInside}
        disableClear={disableClear}
      >
        {children}
      </CustomPopper>
    </>
  )
}

export default ChipWithPopper
