export interface ITeam {
  id: string
  namn: string
  arbetsmarknadsomradeId: string
  default: boolean
}

export interface ISelectOption {
  value: string | number
  label: string
}

export interface IAdvisorStatistics {
  idag: number
  in: number
  inPlusMinus: number
  aktuella: number
}

export interface IPastCase {
  arendeTyp: string
  arendeId: string
  avslutsDatum: string
  radgivareEfternamn: string
  radgivareFornamn: string
  radgivareId: string
}

export interface IPastAdvisor {
  id: string
  fornamn: string
  efternamn: string
}

export enum FetchingState {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

export interface FacetablePropertyValue {
  value: string
  count: number
}

export interface Facets {
  [key: string]: FacetablePropertyValue[]
}
