import { Box, MenuItem, Select, SelectChangeEvent, TablePagination, Typography } from '@mui/material'
import React from 'react'
import { ArrowDropDownRounded } from '@mui/icons-material'

interface PaginationProps {
  count: number
  page: number // this is zero based in MUI but not in Azure
  pageSize: number
  showPagination?: boolean
  showRowsPerPage?: boolean
  onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => void
  onRowsPerPageChange?: (event: number) => void
  labelRowsPerPage?: string
  rowsPerPageOptions?: number[]
}

const Pagination = ({
  labelRowsPerPage = 'Rader per sida:',
  rowsPerPageOptions = [5, 10, 20, 50, 100],
  showPagination = true,
  showRowsPerPage = true,
  count,
  pageSize,
  page,
  onPageChange,
  onRowsPerPageChange,
}: PaginationProps): JSX.Element => {

  if (page === 0 || count === 0) {
    return (<></>)
  }

  const onRowsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number>) => {
    const value = typeof event.target.value === 'number'
      ? event.target.value
      : parseInt(event.target.value)
    onRowsPerPageChange(value)
  }

  return (
    showPagination ? (
      <TablePagination
        component={'div'}
        rowsPerPage={pageSize}
        rowsPerPageOptions={showRowsPerPage ? rowsPerPageOptions : []}
        onRowsPerPageChange={onRowsChange}
        count={count}
        onPageChange={onPageChange}
        labelRowsPerPage={labelRowsPerPage}
        page={page - 1}
        slotProps={{
          select: { IconComponent: ArrowDropDownRounded, renderValue:  (value: string) => <Typography variant='body2' pt={0.25}>{value}</Typography>}
        }}
      />
    ) : (
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Typography variant='body2'>{labelRowsPerPage}</Typography>
        <Select
          sx={{pl: 1}}
          variant='standard'
          disableUnderline
          defaultValue={pageSize}
          value={pageSize}
          onChange={onRowsChange}
          data-testid='rowsPerPage'
          IconComponent={ArrowDropDownRounded}
          renderValue={(value) => (
            <Typography variant='body2' pl={1} pt={0.25}>{value}</Typography>
          )}>
          {rowsPerPageOptions.map((item: number, index: number) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </Box>
    )
  )
}

export default Pagination