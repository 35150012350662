import { StartklarFilter } from '../Startklar.types'

export const getQueryParameters = (filter: Partial<StartklarFilter>): string => {
  const queryParameters = Object.entries(filter).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.filter((item) => item).map((item) => `${key}=${item}`)
    } else if (value && typeof value === 'object') {
      return Object.entries(value)
        .filter(([_, subValue]) => subValue)
        .map(([subKey, subValue]) => `${subKey}=${subValue}`)
    } else if (value) {
      return `${key}=${value as string}`
    }
    return []
  })

  const queryString = queryParameters.join('&')
  return queryString ? `?${queryString}` : ''
}