import React, { useEffect, useState, SyntheticEvent } from 'react'
import { pipe } from 'ramda'
import AdvisorList from '@local/src/Blocks/AdvisorList'
import Filter from '@local/Blocks/Filter'
import MobileFilterUtilities from '@local/Blocks/Filter/MobileFilterUtilities'
import { ITeam, ISelectOption, FetchingState } from '@local/src/Common.types'
import { ITag, createTags, pluckCompany, pluckResidence, pluckRole } from '@local/Utils/Tags'
import { buildSelectOptions } from '@local/Utils/smallfuncs'
import { filterCheckbox, filterDropdown, klientAlderFilterSlider, filterStaged } from '@local/Utils/Filters'
import { controlOverflow } from '@local/Utils/smallfuncs/'
import AdvisorSelection from '@local/src/Blocks/AdvisorSelection'
import { IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import { useDisplayFilter, useIsSmallScreen, useToggle } from '@local/src/Hooks'
import { Dialog } from '@trr/common-component-library'
import Modal from '@local/src/Components/Modal/Modal'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { addErrorMessage, addSuccessMessage } from '@local/src/Blocks/UserFeedback/UserFeedback.actions'

import s from './AllocateCases.module.scss'
import { IAllocateCaseListItem, IAllocateCases } from './AllocateCases.types'
import AllocateCasesFilter from './AllocateCasesFilter'
import AllocateCaseList from './AllocateCaseList'
import AllocateCasesBar from './AllocateCasesBar'
import { IStagedCase } from './AllocateCaseList/AllocateCaseList.types'

const AllocateCases = ({
  advisorList,
  cases,
  caseAllocationResponse,
  fetchAllocateCaseList,
  fetchActiveAdvisors,
  fetchAllocatableTeams,
  fetchAllTeams,
  allocatableTeams,
  clearAllocationResponse,
  requestCaseAllocation,
  requestSingleDinaArendeAllocation,
  allocatingCasesState,
  fetchingAdvisorListState,
  changingClientAllocationTeamState,
  allTeams,
  fetchAllocationDetails,
  fetchingAllocationDetails,
}: IAllocateCases): JSX.Element => {
  const dispatch = useAppDispatch()
  const isSmallScreen = useIsSmallScreen()
  const { isFilterVisible, toggleFilter } = useDisplayFilter()
  const [modalDisplay, setModalDisplay] = useState(false)
  const [filteredCases, setFilteredCases] = useState(cases)

  const {
    isEnabled: isShowingAdvisorList,
    enable: enableAdvisorList,
    disable: disableAdvisorList,
    toggle: toggleAdvisorList,
  } = useToggle(true)

  const [filterTags, setFilterTags] = useState<IFilterTag[]>([])

  const maxAge = 70

  const [selectedCaseIds, setSelectedCaseIds] = useState<string[]>([])
  const [stagedCases, setStagedCases] = useState<IStagedCase[]>([])

  const [filterAge, setFilterAge] = useState([0, maxAge])

  const [residenceList, setResidenceList] = useState<string[]>([])
  const [companyList, setCompanyList] = useState<string[]>([])
  const [roleList, setRoleList] = useState<string[]>([])

  const [residence, setResidence] = useState('Visa alla')
  const [company, setCompany] = useState('Visa alla')
  const [role, setRole] = useState('Visa alla')

  const [fordelningStatus, setFordelningStatus] = useState(true)
  const [replacementClients, setReplacementClients] = useState(true)

  const [initialValues, setInitialValues] = useState<ITag[]>([])
  const [filteredValues, setFilteredValues] = useState<ITag[]>([])

  const [modal, setModal] = useState(false)
  const [advisor, setAdvisor] = useState(null)
  const [selectedTeam, setSelectedTeam] = useState<ITeam>(null)

  const residenceDropdownOptions = buildSelectOptions(residenceList)
  const companyDropdownOptions = buildSelectOptions(companyList)
  const roleDropdownOptions = buildSelectOptions(roleList)

  const [disableAllocateBtn, setDisableAllocateBtn] = useState(false)

  const filterStagedClients = (arr: IAllocateCaseListItem[]) => filterStaged(arr, stagedCases)
  const filterReplacementClients = (arr: IAllocateCaseListItem[]) =>
    filterCheckbox(arr, replacementClients, 'radgivningsSubStatus', 'FOROMFORDELNING')
  const filterFordelningStatus = (arr: IAllocateCaseListItem[]) =>
    filterCheckbox(arr, fordelningStatus, 'radgivningsSubStatus', 'FORDELNING')

  const filterCompany = (arr: IAllocateCaseListItem[]) => filterDropdown(arr, company, 'arbetsstalle')
  const filterResidence = (arr: IAllocateCaseListItem[]) => filterDropdown(arr, residence, 'ort')
  const filterRole = (arr: IAllocateCaseListItem[]) => filterDropdown(arr, role, 'befattning')

  const filterClientAge = (arr: IAllocateCaseListItem[]) => klientAlderFilterSlider(arr, filterAge)

  useEffect(() => {
    if (!isSmallScreen) {
      enableAdvisorList()
    } else {
      disableAdvisorList()
    }
  }, [isSmallScreen])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const filters = pipe(
      filterStagedClients,
      filterResidence,
      filterCompany,
      filterRole,
      filterClientAge,
      filterFordelningStatus,
      filterReplacementClients
    )
    setFilteredCases(filters(cases))
    setFilteredValues([
      { name: 'Ort', value: residence },
      { name: 'Företag', value: company },
      { name: 'Befattning', value: role },
      { name: 'Ålder', value: [filterAge[0], filterAge[1]] },
      { name: 'Fördelning', value: fordelningStatus },
      { name: 'Omfördelning', value: replacementClients },
    ])
  }, [cases, filterAge, role, company, residence, fordelningStatus, replacementClients, stagedCases])
  /* eslint-enable react-hooks/exhaustive-deps */
  // Not including filters due to infinite reloading

  const displayModal = () => setModalDisplay(true)

  const hideModal = () => setModalDisplay(false)

  const resetAllFilters = () => {
    setReplacementClients(true)
    setFordelningStatus(true)
    setRole('Visa alla')
    setCompany('Visa alla')
    setResidence('Visa alla')
    setFilterAge([0, maxAge])
  }

  const resetFilter = (tag: IFilterTag) => {
    switch (tag.filterParameter) {
      case 'Ålder':
        setFilterAge([0, maxAge])
        break
      case 'Ort':
        setResidence('Visa alla')
        break
      case 'Företag':
        setCompany('Visa alla')
        break
      case 'Befattning':
        setRole('Visa alla')
        break
      case 'Fördelning':
        setFordelningStatus(false)
        break
      case 'Omfördelning':
        setReplacementClients(false)
        break
    }
  }

  const toggleFordelningStatus = () => setFordelningStatus(!fordelningStatus)
  const toggleReplacementClients = () => setReplacementClients(!replacementClients)

  const onResidenceChange = (selectedResidence: ISelectOption) => setResidence(String(selectedResidence.value))

  const [allocateErrors, setAllocateErrors] = useState(null)

  const onCompanyChange = (selectedCompany: ISelectOption) => setCompany(String(selectedCompany.value))

  const onRoleChange = (selectedRole: ISelectOption) => setRole(String(selectedRole.value))

  useEffect(() => {
    // When mobile modal is closed, reset advisor
    if (!modal) {
      setAdvisor(null)
    }
  }, [modal])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setResidenceList(pluckResidence(filteredCases))
    setCompanyList(pluckCompany(filteredCases))
    setRoleList(pluckRole(filteredCases))
    const remainingIds = filteredCases.map((filteredCase) => filteredCase.id)
    selectedCaseIds.length !== 0 && setSelectedCaseIds(selectedCaseIds.filter((checkedId: string) => remainingIds.includes(checkedId)))
  }, [filteredCases])
  /* eslint-disable react-hooks/exhaustive-deps */
  // Cannot include selectedCaseIds due to maximum call depth exceeded.

  useEffect(() => {
    setFilterTags(createTags(initialValues, filteredValues))
  }, [initialValues, filteredValues])

  useEffect(() => {
    setInitialValues([
      { name: 'Ort', value: 'Visa alla' },
      { name: 'Arbetsställe', value: 'Visa alla' },
      { name: 'Befattning', value: 'Visa alla' },
      { name: 'Ålder', value: [0, 70] },
      { name: 'Lön', value: [0, Math.max(...cases.map((item) => item.lon))] },
      { name: 'Fördelning', value: true },
      { name: 'Omfördelning', value: true },
    ])
  }, [cases])

  useEffect(() => {
    setAdvisor(null)
    resetAllFilters()
  }, [selectedTeam])

  useEffect(() => {
    if (allocatingCasesState !== FetchingState.LOADING && changingClientAllocationTeamState !== FetchingState.LOADING && selectedTeam) {
      fetchAllocateCaseList(selectedTeam.id)
    }
  }, [allocatingCasesState, fetchAllocateCaseList, fetchActiveAdvisors, selectedTeam, changingClientAllocationTeamState])

  const openModal = () => setModal(true)

  const closeModal = React.useCallback(() => {
    setAllocateErrors(null)
    setModal(false)
    clearAllocationResponse()
  }, [clearAllocationResponse])

  const chooseAdvisor = (event: SyntheticEvent) => {
    const eventTarget = event.target as HTMLInputElement
    const chosenAdvisor = eventTarget.value
    setAdvisor(chosenAdvisor)
  }

  const [errorMsg, setErrorMsg] = useState<string>()

  const allocateCases = () => {
    if (advisor ? false : true) {
      setErrorMsg('Välj en rådgivare för att gå vidare')
    } else {
      requestSingleDinaArendeAllocation({
        radgivningsArendeList: [...selectedCaseIds],
        radgivareId: advisor,
      })
      closeModal()
    }
  }

  const allocateStagedCases = () => {
    const requestBody = {
      fordelningList: stagedCases.map((stagedCase: IStagedCase) => ({
        radgivareId: stagedCase.advisor.id,
        arendeId: stagedCase.case.id,
      })),
    }
    setDisableAllocateBtn(true)
    requestCaseAllocation(requestBody)
  }

  useEffect(() => {
    controlOverflow(modal)
    return () => {
      document.getElementsByTagName('html')[0].style.overflow = 'auto'
    }
  }, [modal])

  useEffect(() => {
    selectedCaseIds.length === 0 && closeModal()
  }, [closeModal, selectedCaseIds])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (caseAllocationResponse) {
      setDisableAllocateBtn(false)
      setAllocateErrors(null)

      // TODO hacky code here. Please type it up!
      /* eslint-disable @typescript-eslint/no-unsafe-call */

      if (caseAllocationResponse.find((row: { responseCode: string }) => row.responseCode === 'ERROR')) {
        const errorredCaseIds = caseAllocationResponse
          .filter((row: { responseCode: string }) => row.responseCode === 'ERROR')
          .map((row: { arendeId: string; error: string }) => {
            return {
              id: row.arendeId,
              error: row.error,
            }
          })
        setAllocateErrors(errorredCaseIds)
        dispatch(addErrorMessage(`${(errorredCaseIds as unknown[]).length} av ${(caseAllocationResponse as unknown[]).length} fördelningar gick fel`))
        if (isSmallScreen) {
          setSelectedCaseIds(
            selectedCaseIds.filter(
              (selectedCaseId: string) => !errorredCaseIds.find((erroredCaseId: string) => erroredCaseId === selectedCaseId)
            )
          )
          setStagedCases([])
        } else {
          setStagedCases(
            stagedCases.filter((stagedCase: IStagedCase) => {
              return errorredCaseIds.find((erroredCaseId: { id: string }) => erroredCaseId.id === stagedCase.case.id)
            })
          )
          setSelectedCaseIds([])
        }
        /* eslint-enable @typescript-eslint/no-unsafe-call */
      } else {
        dispatch(addSuccessMessage('Fördelningen gick bra'))
        setSelectedCaseIds([])
        setStagedCases([])
      }
    }
    return () => {
      clearAllocationResponse()
    }
  }, [caseAllocationResponse])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!allocatableTeams.length) return

    const firstDefaultTeam = allocatableTeams.find((team: ITeam) => team.default)
    if (allocatableTeams.length) {
      setSelectedTeam(firstDefaultTeam)
    }
  }, [allocatableTeams])

  useEffect(() => {
    fetchAllocatableTeams()
    fetchAllTeams()
  }, [fetchAllocatableTeams, fetchAllTeams])

  return (
    <div className={s.AllocateCases}>
      <div className={s.LeftSection}>
        {isSmallScreen ? (
          <MobileFilterUtilities displayModal={displayModal} tags={filterTags} removeFilterParameterValuePair={resetFilter} />
        ) : (
          <AllocateCasesBar
            advisorsVisible={isShowingAdvisorList}
            allocateStagedCases={allocateStagedCases}
            allocatingCasesState={allocatingCasesState}
            filterTags={filterTags}
            isFilterVisible={isFilterVisible}
            removeFilterParameterValuePair={resetFilter}
            stagedCases={stagedCases}
            toggleAdvisorList={toggleAdvisorList}
            toggleFilterVisibility={toggleFilter}
            disableAllocateButton={disableAllocateBtn}
          />
        )}

        <div className={s.Body}>
          {!isSmallScreen && (
            <Filter isSticky visible={isFilterVisible}>
              <AllocateCasesFilter
                residenceDropdownOptions={residenceDropdownOptions}
                onResidenceChange={onResidenceChange}
                pickedResidence={residence}
                age={filterAge}
                maxAge={maxAge}
                setAge={setFilterAge}
                companyDropdownOptions={companyDropdownOptions}
                onCompanyChange={onCompanyChange}
                pickedCompany={company}
                roleDropdownOptions={roleDropdownOptions}
                onRoleChange={onRoleChange}
                pickedRole={role}
                fordelningStatus={fordelningStatus}
                replacementClients={replacementClients}
                toggleFordelningStatus={toggleFordelningStatus}
                toggleReplacementClients={toggleReplacementClients}
              />
            </Filter>
          )}

          {isSmallScreen && (
            <Modal
              label='Filter'
              fullScreen
              open={modalDisplay}
              onClose={hideModal}
              closeIcon
              confirmText={`Visa ${filteredCases.length} ärenden`}
              onConfirm={hideModal}
            >
              <AllocateCasesFilter
                residenceDropdownOptions={residenceDropdownOptions}
                onResidenceChange={onResidenceChange}
                pickedResidence={residence}
                age={filterAge}
                maxAge={maxAge}
                setAge={setFilterAge}
                companyDropdownOptions={companyDropdownOptions}
                onCompanyChange={onCompanyChange}
                pickedCompany={company}
                roleDropdownOptions={roleDropdownOptions}
                onRoleChange={onRoleChange}
                pickedRole={role}
                fordelningStatus={fordelningStatus}
                replacementClients={replacementClients}
                toggleFordelningStatus={toggleFordelningStatus}
                toggleReplacementClients={toggleReplacementClients}
              />
            </Modal>
          )}

          {selectedTeam && (
            <AllocateCaseList
              caseAllocationResponse={caseAllocationResponse}
              advisorList={advisorList}
              cases={cases}
              fetchAllocationDetails={fetchAllocationDetails}
              fetchingAllocationDetails={fetchingAllocationDetails}
              filteredCases={filteredCases}
              team={selectedTeam}
              openModal={openModal}
              selectedCaseIds={selectedCaseIds}
              setTeam={setSelectedTeam}
              setSelectedCaseIds={setSelectedCaseIds}
              setStagedCases={setStagedCases}
              stagedCases={stagedCases}
            />
          )}
          {modal && isSmallScreen && (
            <Dialog
              heading={`Du fördelar ${selectedCaseIds.length} st ärenden`}
              onCancel={closeModal}
              cancelText="Avbryt"
              onConfirm={allocateCases}
              confirmText={advisor ? 'Fördela' : 'Välj rådgivare'}
              className={s.Dialog}
            >
              <AdvisorSelection
                allocatingCasesState={allocatingCasesState}
                fetchActiveAdvisors={fetchActiveAdvisors}
                allocatableTeams={allTeams}
                defaultTeam={selectedTeam}
                advisorList={advisorList}
                fetchingAdvisorListState={fetchingAdvisorListState}
                chooseAdvisor={chooseAdvisor}
                setAdvisor={setAdvisor}
                selectedCaseIds={selectedCaseIds}
                setSelectedCaseIds={setSelectedCaseIds}
                caseList={cases}
                allocateErrors={allocateErrors}
              />
              {errorMsg && <Dialog heading={errorMsg} onConfirm={() => setErrorMsg(null)} />}
            </Dialog>
          )}
        </div>
      </div>
      <div className={s.RightSection}>
        {!isSmallScreen && selectedTeam && (
          <AdvisorList
            advisorsVisible={isShowingAdvisorList}
            toggleAdvisorList={toggleAdvisorList}
            advisorList={advisorList}
            fetchActiveAdvisors={fetchActiveAdvisors}
            allocatableTeams={allTeams}
            defaultTeam={selectedTeam}
            fetchingAdvisorListState={fetchingAdvisorListState}
          />
        )}
      </div>
    </div>
  )
}

export default AllocateCases
