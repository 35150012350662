import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { PageWrapper } from '../../../Components/PageWrapper'
import { MapToView } from '../types/StudieAnmalanGetModel.types'
import { useStudieAnmalan } from '../utils/hooks'

import { StudieAnmalanInfo, BackButton } from './components'

interface IStudieAnmalanProps {
  onBackButtonClick: () => void
  bookingId: string
  arendeId: string
}

const StudieAnmalan: React.FC<React.PropsWithChildren<IStudieAnmalanProps>> = ({ onBackButtonClick, bookingId, arendeId }) => {
  const { data, isError, isFetching } = useStudieAnmalan(bookingId)
  const [originArendeId] = useState(arendeId)

  useEffect(() => {
    if (arendeId !== originArendeId) onBackButtonClick()
  }, [arendeId])
  return (
    <Box data-testid="studie-anmalan-details">
      <BackButton onClick={onBackButtonClick} />
      <PageWrapper
        isLoading={isFetching}
        errorLoadingData={isError || (!isFetching && !data)}
        errorText={'Något gick fel vid hämtningen'}
        testId="studieAnmalan-wrapper"
      >
        <StudieAnmalanInfo studieAnmalan={data && MapToView(data)} />
      </PageWrapper>
    </Box>
  )
}

export default StudieAnmalan
