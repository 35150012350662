import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ITeam, FetchingState } from '@local/src/Common.types'
import { medarbetareBasepath } from '@local/src/basename'
import { useCurrentArendeListPathname, useGuidFromUrl } from '@local/src/Hooks'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllocatableTeams } from '@local/src/Views/AllocateCases/AllocateCases.actions'
import { allocatableTeams } from '@local/src/Views/AllocateCases/AllocateCases.selectors'
import Modal from '@local/src/Components/Modal/Modal'
import { Box, SelectChangeEvent, Typography } from '@mui/material'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import Loading from '@local/src/Components/Loading/Loading'

import { IChangeTeamBody, changeTeam, clearChangingTeamState } from '../../Case.actions'
import { changingTeamsStateSelector } from '../../Case.selectors'

interface ChangeTeamModalProps {
  active: boolean
  close(): void
  teamId: string
}

const ChangeTeamModal = ({
  active,
  close,
  teamId,
}: ChangeTeamModalProps): JSX.Element => {

  const dispatch = useDispatch()
  const teams = useSelector(allocatableTeams)
  const changingTeamState = useSelector(changingTeamsStateSelector)
  const [selectedTeam, setSelectedTeam] = useState<ITeam>(teams && teamId ? teams.find(({ id }) => teamId === id) : undefined)
  const [payload, setPayload] = useState<IChangeTeamBody>()
  const [error, setError] = useState<boolean>(false)
  const arendeId = useGuidFromUrl()
  const history = useHistory()
  const currentArendeListPathname = useCurrentArendeListPathname()
  const isLoading = changingTeamState === FetchingState.LOADING

  const onConfirm = () => {
    if (isLoading || !payload || !selectedTeam) {
      setError(true)
    } else {
      setError(false)
      dispatch(changeTeam(payload))
    }
  }

  useEffect(() => {
    if (changingTeamState === FetchingState.SUCCESS) {
      history.push(`${medarbetareBasepath}${currentArendeListPathname}`)
      dispatch(clearChangingTeamState())
    }
  }, [changingTeamState])

  useEffect(() => {
    !active && setSelectedTeam(undefined)
  }, [active])

  useEffect(() => {
    dispatch(fetchAllocatableTeams())
  }, [fetchAllocatableTeams])

  useEffect(() => {
    selectedTeam && setPayload({ arendeId, teamId: selectedTeam.id })
  }, [selectedTeam, arendeId])

  useEffect(() => {
    setSelectedTeam(teams.find(({ id }) => teamId === id))
  }, [active, teams, teamId])

  return (
    <Modal
      open={active}
      label='Byt team'
      onCancel={close}
      onConfirm={onConfirm}
      confirmText='Byt'
      disableSubmit={isLoading}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box paddingTop={1}>
          <Dropdown
            label='Välj team'
            value={selectedTeam?.id || ''}
            error={error}
            errorText='Välj ett alternativ'
            options={teams}
            getOptionLabel={(team: ITeam) => team.namn}
            getOptionValue={(team: ITeam) => team.id}
            onChange={(event: SelectChangeEvent) => setSelectedTeam(teams.find(({ id }) => event.target.value === id))}
          />
          <Typography variant="body1" paddingTop={2}>
            När du byter team kommer ärendet att skickas till fördelning och tilldelas en rådgivare i det teamet.
          </Typography>
        </Box>
      )}
    </Modal>
  )
}

export default ChangeTeamModal
