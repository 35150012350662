import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'
import { filterId } from '@local/Utils/Filters'
import { IArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'

import {
  fetchCaseListFacetsInitiate,
  fetchCaseListFacetsFailure,
  fetchCaseListFacetsSuccess,
  fetchCaseListInitiate,
  fetchCaseListFailure,
  fetchCaseListSuccess,
  deleteTiaRadgivareFailure,
  deleteTiaRadgivareInitiate,
  deleteTiaRadgivareSuccess,
  putTiaRadgivareFailure,
  putTiaRadgivareInitiate,
  putTiaRadgivareSuccess,
  fetchInitialCaseListFacetsFailure,
  fetchInitialCaseListFacetsInitiate,
  fetchInitialCaseListFacetsSuccess,
} from './Arenden.actions'
import { IArendenState } from './Arenden.types'

export const arendenEmptyFilterState: IArendenFilterState = {
  arbetsmarknadsomraden: [],
  befattningar: [],
  foretagNamn: [],
  klientKommuner: [],
  klientLan: [],
  klientOrter: [],
  orsakerAnsokan: [],
  projektNamn: [],
  radgivareNamn: [],
  radgivningAvslutsKoder: [],
  arendeMarkningar: [],
  radgivningSlutDatumMax: '',
  saknarRadgivare: false,
  subStatusar: [],
  teamNamn: [],
  begaranYttrandeStatusar: [],
  utbildningsAnmalanStatusar: [],
  studiestodAnsokanStatusar: [],
  klientForedragnaSprak: [],
  undantagsgrupp: false,
}

export const kompetensstodArendenInitialFilterState: IArendenFilterState = {
  ...arendenEmptyFilterState,
  subStatusar: ['Aktuellt'],
}

export const initialState: IArendenState = {
  caseObject: { totalHits: 0, totalPages: 1, arenden: [] },
  facetedHits: 0,
  facets: null,
  subStatusFacets: null,
  fetchingCaseListFacetsState: FetchingState.IDLE,
  fetchingInitialCaseListFacetsState: FetchingState.IDLE,
  fetchingCaseListState: FetchingState.IDLE,
  filter: {
    arbetsmarknadsomraden: [],
    befattningar: [],
    foretagNamn: [],
    klientKommuner: [],
    klientLan: [],
    klientOrter: [],
    orsakerAnsokan: [],
    radgivareNamn: [],
    radgivningAvslutsKoder: [],
    arendeMarkningar: [],
    radgivningSlutDatumMax: '',
    saknarRadgivare: false,
    projektNamn: [],
    subStatusar: ['NyttForMig', 'KlientEjKontaktad', 'Aktuellt'],
    teamNamn: [],
    begaranYttrandeStatusar: [],
    utbildningsAnmalanStatusar: [],
  },
  orderBy: ['SenasteKontakt asc'],
  page: 1,
  pageSize: 20,
  pendingArendeId: '',
  searchText: '',
  putTiaRadgivareState: FetchingState.IDLE,
  deleteTiaRadgivareState: FetchingState.IDLE,
}

const arendenReducer = handleActions(
  {
    [fetchInitialCaseListFacetsInitiate.toString()]: (state) => ({
      ...state,
      fetchingInitialCaseListFacetsState: FetchingState.LOADING,
    }),
    [fetchInitialCaseListFacetsFailure.toString()]: (state) => ({
      ...state,
      fetchingInitialCaseListFacetsState: FetchingState.ERROR,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [fetchInitialCaseListFacetsSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      fetchingInitialCaseListFacetsState: FetchingState.SUCCESS,
      subStatusFacets: payload.facetsResponse.facets,
    }),
    [fetchCaseListFacetsInitiate.toString()]: (state) => ({
      ...state,
      fetchingCaseListFacetsState: FetchingState.LOADING,
    }),
    [fetchCaseListFacetsFailure.toString()]: (state) => ({
      ...state,
      fetchingCaseListFacetsState: FetchingState.ERROR,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [fetchCaseListFacetsSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      fetchingCaseListFacetsState: FetchingState.SUCCESS,
      facets: payload.facetsResponse.facets,
      facetedHits: payload.facetsResponse.totalHits,
    }),
    [fetchCaseListInitiate.toString()]: (state) => ({ ...state, fetchingCaseListState: FetchingState.LOADING }),
    [fetchCaseListFailure.toString()]: (state) => ({ ...state, fetchingCaseListState: FetchingState.ERROR }),
    [fetchCaseListSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingCaseListState: FetchingState.SUCCESS,
      caseObject: { ...action.payload.caseObject, arenden: filterId(action.payload.caseObject.arenden, state.pendingArendeId) },
    }),

    [deleteTiaRadgivareFailure.toString()]: (state) => ({ ...state, deleteTiaRadgivareState: FetchingState.ERROR }),
    [deleteTiaRadgivareInitiate.toString()]: (state) => ({ ...state, deleteTiaRadgivareState: FetchingState.LOADING }),
    [deleteTiaRadgivareSuccess.toString()]: (state) => ({ ...state, deleteTiaRadgivareState: FetchingState.SUCCESS }),

    [putTiaRadgivareFailure.toString()]: (state) => ({ ...state, putTiaRadgivareState: FetchingState.ERROR }),
    [putTiaRadgivareInitiate.toString()]: (state) => ({ ...state, putTiaRadgivareState: FetchingState.LOADING }),
    [putTiaRadgivareSuccess.toString()]: (state) => ({ ...state, putTiaRadgivareState: FetchingState.SUCCESS }),
  },
  initialState
)

export default arendenReducer
