import { path } from 'ramda'
import { FetchingState } from '@local/src/Common.types'

import { ArendeTyp, OmfattningsbeslutStatus } from '../Arenden/Arenden.types'

import {
  ICaseDetails,
  INastaMote,
  IKlient,
  IAgeArende,
  IAGEInformation,
  IOmfattningspaket,
  IMarkning,
  IWarningTexts,
  IMeeting,
  IAnstallningsAvslutsInformation,
  IOtherCase,
  IKarriarVagledningProgression,
} from './Case.types'
import { IEvent } from './EventLog/EventLog.types'

export const fetchingStateSelector = path<FetchingState>(['selectedCase', 'caseFetchingState'])
export const caseDetailsSelector = path<ICaseDetails>(['selectedCase', 'caseDetails'])
export const klientSelector = path<IKlient>(['selectedCase', 'caseDetails', 'klient'])
export const klientIdSelector = path<string>(['selectedCase', 'caseDetails', 'klient', 'id'])
export const samtyckeHalsaSelector = path<boolean>(['selectedCase', 'caseDetails', 'klient', 'samtyckeHalsa'])
export const nastaMoteSelector = path<INastaMote>(['selectedCase', 'caseDetails', 'klient', 'nastaMote'])
export const arendeTypSelector = path<ArendeTyp>(['selectedCase', 'caseDetails', 'arendeTyp'])
export const arendeNummerSelector = path<string>(['selectedCase', 'caseDetails', 'arendeNummer'])
export const klientBildFilnamnSelector = path<string>(['selectedCase', 'caseDetails', 'klient', 'klientBildFilnamn'])
export const fornamnSelector = path<string>(['selectedCase', 'caseDetails', 'klient', 'fornamn'])
export const efternamnSelector = path<string>(['selectedCase', 'caseDetails', 'klient', 'efternamn'])
export const folkbokforingsStatusSelector = path<string>(['selectedCase', 'caseDetails', 'klient', 'folkbokforingsStatus'])
export const paketSelector = path<IOmfattningspaket[]>(['selectedCase', 'caseDetails', 'omfattningspaket'])
export const infoTextSelector = path<string>(['selectedCase', 'caseDetails', 'infoText'])
export const warningTextsSelector = path<IWarningTexts>(['selectedCase', 'caseDetails', 'warningTexts'])
export const omfattningsbeslutStatusSelector = path<OmfattningsbeslutStatus>(['selectedCase', 'caseDetails', 'omfattningsbeslutStatus'])
export const ageArendeMedRattTillAgeSelector = path<IAgeArende>(['selectedCase', 'ageArendeMedRattTillAge'])
export const ageInformationSelector = path<IAGEInformation>(['selectedCase', 'caseDetails', 'omstallningsArende', 'age'])
export const inbokadeMotenSelector = path<IMeeting[]>(['selectedCase', 'caseDetails', 'inbokadeMoten'])
export const arendeMarkningarSelector = path<IMarkning[]>(['selectedCase', 'caseDetails', 'arendeMarkningar'])
export const changingAdvisorStateSelector = path<FetchingState>(['selectedCase', 'changingAdvisorState'])
export const changingTeamsStateSelector = path<FetchingState>(['selectedCase', 'changingTeamState'])
export const fetchEventsStateSelector = path<FetchingState>(['selectedCase', 'fetchEventsState'])
export const deleteEventStateSelector = path<FetchingState>(['selectedCase', 'deleteEventState'])
export const createEventStateSelector = path<FetchingState>(['selectedCase', 'createEventState'])
export const updateEventStateSelector = path<FetchingState>(['selectedCase', 'updateEventState'])
export const eventsSelector = path<IEvent[]>(['selectedCase', 'events'])
export const kompetensstodAvslutsInfoSelector = path<IAnstallningsAvslutsInformation>([
  'selectedCase',
  'caseDetails',
  'anstallningsArende',
  'avslutsInformation',
])
export const ovrigaArendenSelector = path<IOtherCase[]>(['selectedCase', 'caseDetails', 'ovrigaArenden'])
export const avslutsdatumSelector = path([
  'selectedCase',
  'caseDetails',
  'omstallningsArende',
  'radgivning',
  'avslutsInformation',
  'avslutsDatum',
])
export const updatePreferredLanguageState = path<FetchingState>(['selectedCase', 'updatePreferredLanguageState'])
export const fetchUserStatusStateSelector = path<FetchingState>(['selectedCase', 'fetchUserStatusState'])
export const karriarVagledningProgressionSelector = path<IKarriarVagledningProgression>(['selectedCase', 'karriarVagledningProgression'])
