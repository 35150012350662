/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/display-name */
import React, { ReactElement } from 'react'
import classNames from 'classnames'
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser'
import DOMPurify from 'dompurify'
import ReactDOMServer from 'react-dom/server'
import { replace, isEmpty, keys, isNil } from 'ramda'
import { EpiImage, ExpandingContent, Link, Typography, TypographyGroup } from '@trr/common-component-library'
import getConfig from '@local/Utils/ConfigService'

import { isLinkExternal, isLinkAnchor, getImageQuery } from '../helpers'

// eslint-disable-next-line @typescript-eslint/ban-types
interface ReplaceAttributes<Content extends {} = {}> {
  attribs: Content
  children: Element[]
}

interface HeadlineAttributes {
  id: string
}

interface LinkAttributes {
  href?: string
  title?: string
}

interface ImageAttributes {
  alt: string
  src: string
  height: string
  width: string
  class: string
}

const domPurifyConfig = {
  ADD_TAGS: ['expanding-content', 'iframe', 'factbox', 'accordion'],
}

const options: HTMLReactParserOptions = {
  replace: ({ attribs, children, name = '' }: Element) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const element = elementEnumeration.fromValue(name)
    if (!element) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return element.replace({ attribs, children })
  },
}

const getHtmlString = (children: Element[]) => ReactDOMServer.renderToStaticMarkup(domToReact(children) as ReactElement)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asEnumeration = (dictionary: { [key: string]: { replace: any } }) =>
  Object.freeze({
    fromValue: (value: string) => dictionary[value],
  })

export const noCookie = (youtubeLink: string): string =>
  youtubeLink
    .replace('youtu.be', 'youtube.com/embed')
    .replace('watch?v=', 'embed/')
    .replace('youtube.com', 'youtube-nocookie.com')
    .replace('//youtube-nocookie', '//www.youtube-nocookie')
    .concat('?rel=0')

const expandingContentMapper = {
  replace: ({ children }: ReplaceAttributes) => {
    const headingMatch = /(<h[1-6]>(.+)<\/h[1-6]>)/gim
    const htmlChildren = getHtmlString(children)

    if (htmlChildren.length === 0) {
      return <></>
    }

    const title = headingMatch.exec(htmlChildren)

    if (isNil(title)) {
      return <></>
    }

    const content = htmlChildren.replace(headingMatch, '')

    return (
      <ExpandingContent title={title[2]} className="expanding-content">
        {parse(content, options)}
      </ExpandingContent>
    )
  },
}

const elementEnumeration = asEnumeration({
  /**
   * @deprecated Old template name in EPI, should be changed to use 'expanding-content'
   */
  accordion: expandingContentMapper,
  'expanding-content': expandingContentMapper,
  a: {
    replace: ({ attribs: { href, title }, children }: ReplaceAttributes<LinkAttributes>) => (
      <Link
        hash={isLinkAnchor(href)}
        external={isLinkExternal(href)}
        noChevron={isLinkExternal(href)}
        link={href}
        customClass={'epiLink'}
        title={title}
      >
        {domToReact(children, options)}
      </Link>
    ),
  },

  h1: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography variant="h1" {...attribs}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h2: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography variant="h2" {...attribs}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h3: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography variant="h3" {...attribs}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h4: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography variant="h4" {...attribs}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  h5: {
    replace: ({ attribs, children }: ReplaceAttributes<HeadlineAttributes>) => (
      <Typography variant="h5" {...attribs}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  p: {
    replace: ({ children }: ReplaceAttributes) => (
      <Typography variant="body2" gutterBottom={false}>
        {domToReact(children, options)}
      </Typography>
    ),
  },
  ul: {
    replace: ({ children }: ReplaceAttributes) => <ul>{domToReact(children, options)}</ul>,
  },
  ol: {
    replace: ({ children }: ReplaceAttributes) => <ol>{domToReact(children, options)}</ol>,
  },
  li: {
    replace: ({ children }: ReplaceAttributes) => (
      <Typography gutterBottom={false} component="li" variant="body1">
        {domToReact(children, options)}
      </Typography>
    ),
  },
  img: {
    replace: ({ attribs: { src, alt, width, class: customClass = '' } }: ReplaceAttributes<ImageAttributes>) => (
      <span
        style={{
          ...(width ? { maxWidth: parseInt(width, 10) } : null),
        }}
        className={classNames('image', customClass)}
      >
        <EpiImage baseUrl={getConfig().MEDIA_URL} src={getImageQuery(src, width ? parseInt(width, 10) : 1000)} alt={alt} />
      </span>
    ),
  },
})

type wrapperTypes = 'span' | 'section' | 'div'

interface IHTMLMapperProps {
  body: string
  replaceValues?: { [key: string]: string }
  wrapper?: wrapperTypes
  className?: string
}

const HTMLMapper = ({ body, replaceValues = {}, wrapper, className = '' }: IHTMLMapperProps): JSX.Element => {
  const Wrapper: wrapperTypes = wrapper || 'span'

  const sanitized = DOMPurify.sanitize(body, domPurifyConfig)
  const output = isEmpty(replaceValues)
    ? sanitized
    : replace(new RegExp(keys(replaceValues).join('|'), 'gi'), (matched: string) => replaceValues[matched])(sanitized)
  return (
    <Wrapper className={classNames('HTMLMapper', className)}>
      <TypographyGroup>{parse(output, options)}</TypographyGroup>
    </Wrapper>
  )
}

export default HTMLMapper
