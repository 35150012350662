import React from 'react'
import { Button } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface FilterButtonProps {
  isFilterVisible?: boolean
  isSmallScreen: boolean
  onClick(): void
}

const FilterButton = ({
  isFilterVisible,
  isSmallScreen,
  onClick
}: FilterButtonProps): JSX.Element => {
  const complementaryIcon = isFilterVisible ? (
    <CloseIcon fontSize='small' />
  ) : (
    <ArrowBackIcon fontSize='small' />
  )

  return (
    <Button
      size='small'
      variant='outlined'
      startIcon={<FilterAltIcon />}
      onClick={onClick} aria-expanded={isFilterVisible}
    >
      {!isSmallScreen && complementaryIcon}
      Filter
    </Button>
  )
}

export default FilterButton
