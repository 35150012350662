import { AutocompleteChangeReason, Chip, CircularProgress, Autocomplete as MuiAutocomplete, SxProps, TextField, Theme } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { debounce } from '@mui/material/utils'

interface AutocompleteProps {
  label: string
  options: unknown[]
  value: unknown
  multiple?: boolean
  error?: boolean
  errorText?: string
  loading?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean
  name?: string
  size?: 'small' | 'medium'
  getOptionLabel: (option: unknown) => string
  inputValueChanged?: (value: string) => void
  onChange?: (value: unknown) => void
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
}

const Autocomplete = ({
  label,
  options,
  value,
  multiple = false,
  error = false,
  errorText,
  loading = false,
  sx,
  name,
  size = 'medium',
  disabled = false,
  getOptionLabel,
  inputValueChanged,
  onChange,
  onBlur,
}: AutocompleteProps): JSX.Element => {

  const [inputValue, setInputValue] = React.useState('')

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason) => {
    if (reason === 'clear') {
      if (multiple) onChange([])
      else onChange(null)
      return
    }
    onChange(value)
  }

  const debounceValue = useMemo(() =>
    debounce((request: string,callback: (value: string) => void) => {
      callback(request)
    }, 400),[]
  )

  useEffect(() => {
    if (inputValue?.length > 0) {
      inputValueChanged && debounceValue(inputValue, inputValueChanged)
    }
  }, [inputValue])

  return (
    <MuiAutocomplete
      disabled={disabled}
      sx={sx}
      size={size}
      multiple={multiple}
      defaultValue={multiple ? [] : null}
      value={value || null}
      loading={loading}
      options={loading ? [] : options}
      onChange={handleChange}
      onBlur={onBlur}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      getOptionLabel={getOptionLabel}
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          name={name}
          helperText={error && errorText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          sx={{ zIndex: 0 }} /* This can be removed when we remove CCL-dependency. */
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option: unknown, index: number) => (
          <Chip
            size={size}
            key={index}
            label={getOptionLabel(option)}
            title={getOptionLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  )
}

export default Autocomplete