import React from 'react'
import { Box, CircularProgress, Divider, Tab, Tabs } from '@mui/material'

import useTabs from './useTabs'

export interface ITabItem {
  heading: React.ReactNode
  content?: React.ReactNode
  url: string
}

export interface ITabsContainer extends Partial<JSX.IntrinsicElements['div']> {
  items: ITabItem[]
  purpose?: string
}

export const TabsContainer = ({ items, purpose }: ITabsContainer) => {
  const { currentIndex, handleChange, currentPath, getA11yProps } = useTabs(items)
  const currentTabComponent = items.find((item) => item.url.includes(currentPath))

  return (
    <>
      <Box>
        <Tabs value={currentIndex} onChange={handleChange} aria-label={purpose} variant="scrollable">
          {items.map((tabItem, index) => (
            <Tab sx={{ textTransform: 'none', fontSize: 16 }} key={tabItem.url} label={tabItem.heading} {...getA11yProps(index)} />
          ))}
        </Tabs>
        <Divider sx={{ mb: 2 }} />
      </Box>
      {currentTabComponent?.content ?? <CircularProgress />}
    </>
  )
}
