import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone'
import { useIsSmallScreen } from '@local/src/Hooks'
import s from '@local/Components/IconWithText/IconWithText.module.scss'
import { Link, Typography } from '@mui/material'

import IconWithText from '../IconWithText'

interface IPhoneNumber {
  children: string
}

const MISSING_OR_INVALID_PHONE_NUMBER = 'Information saknas'

const formatPhoneNumber = (probablyAPhoneNumber: string) => {
  if (!probablyAPhoneNumber) {
    return MISSING_OR_INVALID_PHONE_NUMBER
  }
  const isSwedishMobilePhoneNumber =
    (probablyAPhoneNumber.startsWith('+467') && probablyAPhoneNumber.length === 12) ||
    (probablyAPhoneNumber.startsWith('07') && probablyAPhoneNumber.length === 10)
  if (!isSwedishMobilePhoneNumber) {
    return probablyAPhoneNumber
  }
  const significantPartOfPhoneNumber = probablyAPhoneNumber.substring(probablyAPhoneNumber.indexOf('7'))
  const formattedPhoneNumber = `0${significantPartOfPhoneNumber.substring(0, 2)}-${significantPartOfPhoneNumber.substring(
    2,
    5
  )} ${significantPartOfPhoneNumber.substring(5, 7)} ${significantPartOfPhoneNumber.substring(7, 9)}`
  return formattedPhoneNumber
}

const PhoneNumber = ({ children }: IPhoneNumber): JSX.Element => {
  const formattedPhoneNumber = formatPhoneNumber(children)
  const linkFriendlyPhoneNumber = formattedPhoneNumber.replace(/\s/g, '')
  const isSmallScreen = useIsSmallScreen()

  const renderPhoneNumberWithIcon = () => {
    return <IconWithText icon={<PhoneIcon sx={{ color: s.textColor }} fontSize='small' />}><Typography variant='body1' color='text.secondary'>{formattedPhoneNumber}</Typography></IconWithText>
  }

  const renderPhoneNumberWithLink = () => {
    return (
      <Link
        component='a'
        href={`tel:${linkFriendlyPhoneNumber}`}
        sx={{ display: 'flex', gap: 1, alignSelf: 'baseline' }}>
        <PhoneIcon sx={{ color: s.linkColor }} fontSize='small' />
        <Typography variant='body1' color='text.secondary'>{formattedPhoneNumber}</Typography>
      </Link>
    )
  }

  return isSmallScreen && formattedPhoneNumber !== MISSING_OR_INVALID_PHONE_NUMBER
    ? renderPhoneNumberWithLink()
    : renderPhoneNumberWithIcon()
}

export default PhoneNumber
