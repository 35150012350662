import React from 'react'
import IconWithText from '@local/Components/IconWithText'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'

interface DateWrapperProps {
  children: string
  title?: string
  filled?: boolean
}

const DateWrapper = ({
  children,
  title = 'Datum',
  filled = false,
}: DateWrapperProps): JSX.Element => {
  const icon = filled
    ? <CalendarMonthIcon aria-label={title} fontSize='small' />
    : <CalendarMonthOutlinedIcon aria-label={title} fontSize='small' />

  return <IconWithText icon={icon}>{children}</IconWithText>
}
export default DateWrapper
