import { CollapsibleList } from '@trr/common-component-library'
import React from 'react'
import { IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import { Box, Chip } from '@mui/material'

import s from './FilterTags.module.scss'

interface FilterTagsProps {
  filterTags: IFilterTag[]
  resetFilter?(): void
  removeFilterParameterValuePair(relevantPartOfTag: Omit<IFilterTag, 'displayValue'>): void
  halfPadding?: boolean
}

const FilterTags = ({
  filterTags,
  removeFilterParameterValuePair,
  resetFilter,
  halfPadding = false,
}: FilterTagsProps): JSX.Element => (

  <Box pb={halfPadding ? 1 : 2}>
    {resetFilter && (
      <button onClick={resetFilter} className={s.ResetButton}>
        Återställ
      </button>
    )}
    <CollapsibleList classNames={{ list: s.FilterChipGroup }}>
      {filterTags.map((tag) => {
        return (
          <Chip
            key={`${tag.filterParameter}${tag.filterValue}`}
            data-tag={`${tag.filterParameter}${tag.filterValue}`}
            onDelete={() => removeFilterParameterValuePair(tag)}
            label={tag.displayValue}
            variant="filled"
          />
        )
      })}
    </CollapsibleList>
  </Box>
)

export default FilterTags
