import { KarriarVagledningStatus } from '@local/src/Views/Case/Case.types'
import { Theme } from '@mui/material'

export interface KarriarVagledningProgression {
  type: string
  label: string
  chipText: string
  chipVariant: 'filled' | 'outlined' | 'status-info' | 'status-error' | 'status-success' | 'status-warning'
  backgroundColor: string
}

const labelMapping: Record<string, string> = {
  livslinjen: 'Livslinjen',
  personligSpegling: 'Personlig spegling',
  varderingskompass: 'Värderings-kompassen',
  karriarguide: 'JobMatch Career',
  yrkesprognoser: 'Yrkesprognoser',
  karriarsok: 'Karriärsökning',
  utbildning: 'Kurser och utbildningar',
  framtidsmal: 'Framtidsmål',
}

export const getKarriarVagledningProgression = (
  type: string,
  status: KarriarVagledningStatus,
  theme: Theme
): KarriarVagledningProgression => {
  const chipStatus: {
    [key in string]: {
      displayText: string
      chipVariant: 'filled' | 'outlined' | 'status-info' | 'status-error' | 'status-success' | 'status-warning'
      backgroundColor?: string
    }
  } = {
    NotStarted: { displayText: 'Ej påbörjad', chipVariant: 'status-error', backgroundColor: theme.palette.text.disabled },
    InProgress: { displayText: 'Pågående', chipVariant: 'status-info' },
    Completed: { displayText: 'Klar', chipVariant: 'status-success' },
  }

  return {
    type,
    label: labelMapping[type],
    chipText: chipStatus[status].displayText,
    chipVariant: chipStatus[status].chipVariant,
    backgroundColor: chipStatus[status].backgroundColor,
  }
}
