import React, { useEffect, useState } from 'react'
import { formatDateFromDateTime } from '@local/src/Utils/DateTime'
import { ListItem, ListItemText, Typography } from '@mui/material'

import { Avslutshandelse, Bransch, companyTypes, registrationAfterCeasedEmploymentOptions, workedOptions } from '../../../Avslut.types'
import { getLabelByValue } from '../Common'
import { AvslutshandelseCardBase } from '../Common/AvslutshandelseCardBase'

export interface ErEmploymentDetails {
  companyName: string
  dateOfRegistration: string
  spareTimeJobSinceBefore: string
  companyType: string
  industry: string
  isTownAbroad: string
  town: string
  partnersIncludingClient: string
  previouslyWorkedInIndustry: string
  previouslyWorkedInProfession: string
  registrationAfterCeasedEmployment: string
  foundEmployerAtTRR: string
}

export type ErEmploymentDetailsKey = keyof ErEmploymentDetails

interface AvslutshandelseProps {
  avslutshandelse: Avslutshandelse
  branscher: Bransch[]
}

export const ErAvslutshandelse = ({ avslutshandelse, branscher }: AvslutshandelseProps) => {
  const [industry, setIndustry] = useState<string[]>([])

  const answers: ErEmploymentDetails = Object.fromEntries(
    avslutshandelse.avslutsFragorSvar?.map((fragorSvar) => [fragorSvar.key, fragorSvar.value])
  ) as Record<ErEmploymentDetailsKey, string>

  const previouslyWorkedInIndustry = getLabelByValue(workedOptions, answers.previouslyWorkedInIndustry)
  const previouslyWorkedInProfession = getLabelByValue(workedOptions, answers.previouslyWorkedInProfession)
  const registrationAfterCeasedEmployment = getLabelByValue(
    registrationAfterCeasedEmploymentOptions,
    answers.registrationAfterCeasedEmployment
  )
  const companyType = getLabelByValue(companyTypes, answers.companyType)

  useEffect(() => {
    setIndustry(branscher.filter((bransch: Bransch) => bransch.trrCode === answers.industry).map((industry: Bransch) => industry.name))
  }, [answers.industry, branscher])

  return (
    <AvslutshandelseCardBase avslutshandelse={avslutshandelse}>
      <Typography variant="h6">Företagsuppgifter</Typography>
      <ListItem>
        <ListItemText primary="Företagsnamn" secondary={answers.companyName} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Registreringsdatum" secondary={formatDateFromDateTime(answers.dateOfRegistration)} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Bisyssla sedan tidigare" secondary={answers.spareTimeJobSinceBefore === 'true' ? 'Ja' : 'Nej'} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Bolagsform" secondary={companyType} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Bransch" secondary={industry} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Stationeringsort" secondary={answers.isTownAbroad === 'true' ? 'Utlandsort' : answers.town} />
      </ListItem>
      <ListItem data-testid="utlandsort" sx={{ marginBottom: 2 }}>
        <ListItemText primary="Utlandsort" secondary={answers.isTownAbroad === 'true' ? 'Ja' : 'Nej'} />
      </ListItem>
      <Typography variant="h6">Anställning</Typography>
      <ListItem>
        <ListItemText primary="Har jobbat i branschen tidigare" secondary={previouslyWorkedInIndustry} />
      </ListItem>
      <ListItem>
        <ListItemText primary="Har jobbat med samma yrke tidigare" secondary={previouslyWorkedInProfession} />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Hur snabbt efter anställningens upphörande registrerades (startades) företaget"
          secondary={registrationAfterCeasedEmployment}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Har träffat uppdragsgivaren genom en Matchningsaktivitet hos TRR"
          secondary={answers.foundEmployerAtTRR === 'true' ? 'Ja' : 'Nej'}
        />
      </ListItem>
    </AvslutshandelseCardBase>
  )
}

export default ErAvslutshandelse
