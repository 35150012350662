import React, { useEffect, useState, SyntheticEvent } from 'react'
import { Checkbox, Dropdown, Loading, Button, SystemFeedback } from '@trr/common-component-library'
import NoResult from '@local/Components/NoResult'
import AllocateCaseListItem from '@local/src/Views/AllocateCases/AllocateCaseList/AllocateCaseListItem'
import PickAdvisor from '@local/Components/AdvisorSelectors/AdvisorSelectorDesktop/PickAdvisor'
import AllocateDetailsDate from '@local/Components/AllocateDetailsDate'
import { partial } from 'ramda'
import { ITeam, ISelectOption, FetchingState } from '@local/src/Common.types'
import { useIsSmallScreen } from '@local/src/Hooks'

import { IAdvisor, IAllocateCaseListItem } from '../AllocateCases.types'

import s from './AllocateCaseList.module.scss'
import { IAllocateCaseList, IStagedCase } from './AllocateCaseList.types'

interface ErrorCaseId {
  id: string
  error: string
}

const AllocateCaseList = ({
  advisorList,
  allocatingCasesState,
  cases,
  filteredCases,
  team,
  allocatableTeams,
  openModal,
  selectedCaseIds,
  setTeam,
  setSelectedCaseIds,
  setStagedCases,
  stagedCases,
  fetchingCaseListState,
  allTeams,
  changeTeam,
  changingClientAllocationTeamState,
  caseAllocationResponse,
  fetchAllocationDetails,
  fetchingAllocationDetails,
}: IAllocateCaseList): JSX.Element => {
  const isSmallScreen = useIsSmallScreen()

  const [allocateErrors, setAllocateErrors] = useState<ErrorCaseId[]>(null)
  const appendToStagedCases = (caseToAppend: IAllocateCaseListItem, advisorToAppend: IAdvisor) => {
    const caseWithAdvisorToAppend = {
      case: caseToAppend,
      advisor: advisorToAppend,
    }
    setStagedCases([...stagedCases.filter((stagedCase: IStagedCase) => stagedCase.case.id !== caseToAppend.id), caseWithAdvisorToAppend])
  }

  const caseListCounter = {
    statusSingle: 'ärende',
    statusSeveral: 'ärenden',
  }

  const removeAdvisor = (caseId: string, advisorIdToRemove: string) => {
    setStagedCases([
      ...stagedCases.filter((stagedCase: IStagedCase) => !(stagedCase.advisor.id === advisorIdToRemove && stagedCase.case.id === caseId)),
    ])
  }

  useEffect(() => {
    if (caseAllocationResponse) {
      setAllocateErrors(null)
      if (caseAllocationResponse.find((row) => row.responseCode === 'ERROR')) {
        const errorredCaseIds = caseAllocationResponse
          .filter((row) => row.responseCode === 'ERROR')
          .map((row) => {
            return {
              id: row.arendeId,
              error: row.errorCode,
            }
          })
        setAllocateErrors(errorredCaseIds)
      }
    }
  }, [caseAllocationResponse])

  useEffect(() => {
    isSmallScreen ? setStagedCases([]) : setSelectedCaseIds([])
  }, [isSmallScreen, setStagedCases, setSelectedCaseIds])

  const onCheck = (event: SyntheticEvent) => {
    const eventTarget = event.target as HTMLInputElement
    const checkboxId = eventTarget.name
    if (checkboxId === 'all') {
      if (selectedCaseIds.length !== filteredCases.length) {
        setSelectedCaseIds(filteredCases.map(({ id }) => id))
      } else {
        setSelectedCaseIds([])
      }
    } else {
      const unCheck = selectedCaseIds.find((selectedCaseId: string) => selectedCaseId === checkboxId)
      if (unCheck) {
        setSelectedCaseIds(selectedCaseIds.filter((selectedCaseId: string) => selectedCaseId !== checkboxId))
      } else {
        setSelectedCaseIds([...selectedCaseIds, checkboxId])
      }
    }
  }

  const onTeamChange = (event: ISelectOption) => {
    setTeam(allocatableTeams.find((teamFromList: ITeam) => teamFromList.id === event.value))
  }

  return allocatingCasesState === FetchingState.LOADING ? (
    <Loading />
  ) : (
    <div className={s.AllocateCaseList}>
      <header className={s.ListHeader}>
        <h1>Ofördelade ärenden</h1>
        <div className={s.DropdownWrapper}>
          <Dropdown
            disabled={fetchingCaseListState === FetchingState.LOADING}
            onChange={onTeamChange}
            label="Fördelningsteam"
            placeholder="Fördelningsteam"
            isSearchable={!isSmallScreen}
            selectOptions={allocatableTeams.map((teamFromList: ITeam) => ({
              value: teamFromList.id,
              label: teamFromList.namn,
            }))}
            value={(({ id, namn }) => ({ value: id, label: namn }))(team)}
          />
        </div>
      </header>

      <div className={s.ListAndButton}>
        <div className={s.ListTop}>
          {isSmallScreen && (
            <div className={s.CheckboxAll}>
              <Checkbox
                checked={selectedCaseIds.length === filteredCases.length && filteredCases.length !== 0}
                onChange={onCheck}
                name="all"
                label="Markera alla"
              />
            </div>
          )}
          <p className={s.CaseCount}>
            <span data-totalclients={cases.length}>
              {`${filteredCases.length + stagedCases.length} ${
                filteredCases.length + stagedCases.length === 1 ? caseListCounter.statusSingle : caseListCounter.statusSeveral
              }`}
              {filteredCases.length + stagedCases.length !== cases.length && <span> {'av'} {cases.length}</span>}
            </span>
          </p>
        </div>
        <ol className={s.ListWrapper} aria-label="Ärendelista">
          {allocateErrors &&
            !isSmallScreen &&
            allocateErrors.map((error: ErrorCaseId) => (
              <SystemFeedback key={error.id} type={'error'} content={`${error.error}, ärende-id: ${error.id}`} />
            ))}

          {filteredCases.length + stagedCases.length === 0 && fetchingCaseListState === FetchingState.SUCCESS && (
            <NoResult>Inga ärenden överensstämmer med nuvarande filter.</NoResult>
          )}
          {fetchingCaseListState === FetchingState.LOADING ? (
            <Loading />
          ) : (
            stagedCases
              .map((c: IStagedCase) => (
                <AllocateCaseListItem
                  item={c.case}
                  isStaged
                  key={`staged${c.case.id}`}
                  fetchAllocationDetails={fetchAllocationDetails}
                  fetchingAllocationDetails={fetchingAllocationDetails}
                  allTeams={allTeams}
                  changeTeam={changeTeam}
                  changingClientAllocationTeamState={changingClientAllocationTeamState}
                  allocatableTeams={allocatableTeams}
                >
                  <div className={s.Status}>
                    <PickAdvisor
                      advisorList={advisorList}
                      choose={partial(appendToStagedCases, [c.case])}
                      chosenAdvisor={c.advisor}
                      remove={partial(removeAdvisor, [c.case.id])}
                    />
                    <AllocateDetailsDate lastDayOfEmployment={c.case.sistaAnstallningsdag} />
                  </div>
                </AllocateCaseListItem>
              ))
              .concat(
                filteredCases.map((c: IAllocateCaseListItem) => (
                  <AllocateCaseListItem
                    item={c}
                    checked={!!selectedCaseIds.find((id: string) => id === c.id)}
                    fetchAllocationDetails={fetchAllocationDetails}
                    fetchingAllocationDetails={fetchingAllocationDetails}
                    isSmallScreen={isSmallScreen}
                    key={c.id}
                    onCheck={onCheck}
                    allTeams={allTeams}
                    changeTeam={changeTeam}
                    changingClientAllocationTeamState={changingClientAllocationTeamState}
                    allocatableTeams={allocatableTeams}
                  >
                    <div className={s.Status}>
                      {!isSmallScreen ? (
                        <>
                          <PickAdvisor advisorList={advisorList} choose={partial(appendToStagedCases, [c])} />
                          <AllocateDetailsDate lastDayOfEmployment={c.sistaAnstallningsdag} />
                        </>
                      ) : null}
                    </div>
                  </AllocateCaseListItem>
                ))
              )
          )}
        </ol>
        {isSmallScreen && (
          <div className={s.ContainedButtonWrapper}>
            <Button
              customClass={s.ListButtonMobile}
              onClick={openModal}
              primary
              disabled={selectedCaseIds.length <= 0}
              testSelector="primaryselector"
            >
              {selectedCaseIds.length > 0 ? `Till fördelning (${selectedCaseIds.length})` : 'Välj ärenden'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default AllocateCaseList
