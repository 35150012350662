import React from 'react'
import { Box } from '@mui/material'

const AnmalanContentItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        maxWidth: '600px',
        width: '100%',
        mt: { xs: 4, md: 2 },
        mx: { xs: 2, md: 0 },
        mb: { xs: 4, md: 6 },
      }}
    >
      {children}
    </Box>
  )
}

export default AnmalanContentItem
