import React, { useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Chip } from '@mui/material'

export interface IIncrementationChip {
  number?: number
  onIncrease: (number: number) => void
  callToActionText?: string
}

const IncrementationChip = ({ number = 0, onIncrease, callToActionText = 'Samtal' }: IIncrementationChip): JSX.Element => {
  const [displayNumber, setDisplayNumber] = useState<number>(number)

  const handleIncrease = () => {
    setDisplayNumber(displayNumber + 1)
    onIncrease(displayNumber + 1)
  }

  return (
    <Chip
      variant={displayNumber > 0 ? 'filled' : 'outlined'}
      icon={<AddCircleIcon />}
      onClick={handleIncrease}
      label={`${displayNumber > 0 ? displayNumber : ''} ${callToActionText}`}
    />
  )
}
export default IncrementationChip
