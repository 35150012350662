import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import SearchField from '@local/src/Components/SearchField'
import SearchResultSnippet from '@local/src/Components/SearchResultSnippet'

import StartklarMobileFilter from './StartklarMobileFilter/StartklarMobileFilter'
import StartklarDesktopFilter from './StartklarDesktopFilter/StartklarDesktopFilter'
import { useStartklarFilterContext } from './StartklarFilterContext'

const StartklarFilters = () => {

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const {
    filters,
    currentFacets,
    setFilter: handleFilter,
  } = useStartklarFilterContext()

  return (
    <Box display="flex" flexDirection='column' gap={2}>
      <Box display='flex' flexDirection='column' gap={1}>
        <Box maxWidth={752}>
          <SearchField value={filters.searchText} search={(searchText) => handleFilter('searchText', searchText)} />
        </Box>
        { filters.searchText && currentFacets?.totalHits !== undefined && <SearchResultSnippet pluralName='ärenden' singularName='ärende' searchQuery={filters.searchText} totalHits={currentFacets.totalHits} />}
      </Box>
      {isSmallScreen ? <StartklarMobileFilter /> : <StartklarDesktopFilter />}
    </Box>
  )
}

export default StartklarFilters
