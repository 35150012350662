import { Loading, Typography } from '@trr/common-component-library'
import React, { Fragment } from 'react'

import styles from './PageWrapper.module.scss'

interface Props {
  errorLoadingData?: boolean
  errorText?: string
  isLoading?: boolean
  headerText?: string
  header?: React.ReactNode
  bodyText?: string
  body?: React.ReactNode
  classNames?: string
  testId?: string
  children: React.ReactNode | React.ReactNode[]
}

const PageWrapper = ({
  errorLoadingData,
  isLoading,
  errorText,
  headerText,
  bodyText,
  classNames = '',
  testId = '',
  children,
  header,
  body,
}: Props) => {
  return (
    <div className={classNames} data-testid={testId}>
      {headerText && (
        <Typography classNames={{ root: styles.HeaderText }} variant="h3">
          {headerText}
        </Typography>
      )}
      {header && <Fragment>{header}</Fragment>}
      {bodyText && (
        <Typography classNames={{ root: styles.BodyText }} variant="body1">
          {bodyText}
        </Typography>
      )}
      {body && <Fragment>{body}</Fragment>}
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {errorLoadingData ? (
            <Typography variant="body2" classNames={{ root: styles.ErrorLoadingData }} data-testid="error-loading-data">
              {errorText}
            </Typography>
          ) : (
            children
          )}
        </Fragment>
      )}
    </div>
  )
}

export default PageWrapper
