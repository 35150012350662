import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, Typography, styled, AccordionProps, AccordionDetailsProps } from '@mui/material'
import React from 'react'
import { ExpandMore } from '@mui/icons-material'

import { FilterSelectionIndicator } from '../FilterSelectionIndicator/FilterSelectionIndicator'

interface FilterAccordionProps {
  open: boolean
  label: string
  indicatorCount: number
  children: React.ReactNode | JSX.Element
  onOpen?: () => void
  onClose?: () => void
}

const FilterAccordion = ({ open, label, indicatorCount, children, onOpen, onClose }: FilterAccordionProps) => {

  const accordionOnChange = (_: React.SyntheticEvent, expanded: boolean) => {
    expanded ? onOpen() : onClose()
  }

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={open} onChange={accordionOnChange}
    >
      <AccordionSummary>
        <Typography variant='subtitle1'>{label}</Typography>
        {indicatorCount > 0 && (
          <FilterSelectionIndicator>{indicatorCount}</FilterSelectionIndicator>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMore />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(1)
  },
}))

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: theme.spacing(0.5)
}))

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    {...props}
  />
))(() => ({
  '&::before': {
    opacity: '1 !important'
  },
}))

export default FilterAccordion