import { createContext, useContext } from 'react'

import { ICaseListStartklar, IHandlaggare, StartklarFilter } from '../Startklar.types'

export interface StartklarFilterContextState {
  filters: Partial<StartklarFilter>
  currentFacets: ICaseListStartklar
  initialFacets: ICaseListStartklar
  handlaggareList: IHandlaggare[]
  isFilterActive: boolean
  setFilter: (key: keyof StartklarFilter, values: string | string[]) => void
  clearFilter: () => void
  clearHandlaggareFilter: () => void
}

const StartklarFilterContext = createContext<StartklarFilterContextState | null>(null)

export const useStartklarFilterContext = (): StartklarFilterContextState => {
  const context = useContext(StartklarFilterContext)
  if (context === null) {
    console.error('useStartklarFilterContext must be used within a FilterContextProvider')
  }
  return context
}

export default StartklarFilterContext