/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React, { FunctionComponent } from 'react'
import { curry } from 'ramda'

const withHooks = curry((hooks: Record<string, any>, Component: FunctionComponent<React.PropsWithChildren<any>>) => {
  if (!Component) {
    throw new Error('Component must be provided to compose')
  }

  if (!hooks) {
    return Component
  }

  return (props: any) => {
    const hooksObject: Record<string, any> = typeof hooks === 'function' ? hooks(props) : hooks

    const hooksProps = Object.entries(hooksObject).reduce<Record<string, any>>(
      (acc, [hookKey, hook]): Record<string, any> => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        let hookValue: Record<string, any> = hook()

        if (Array.isArray(hookValue) || typeof hookValue !== 'object') {
          hookValue = { [hookKey]: hookValue }
        }

        Object.entries(hookValue).forEach(([key, value]) => {
          acc[key] = value
        })

        return acc
      },
      {}
    )

    const compoundProps = { ...props, ...hooksProps }
    return <Component {...compoundProps} />
  }
})

export { withHooks }
/* eslint-enable */
