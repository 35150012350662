import React from 'react'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import Home from '@local/Views/Home/Home'
import Arenden from '@local/src/Views/Arenden/Arenden'
import Case from '@local/src/Views/Case/Case'
import DinaArenden from '@local/Views/DinaArenden'
import AllocateCases from '@local/Views/AllocateCases'
import Startklar from '@local/Views/Startklar'
import StartklarForm from '@local/Views/Startklar/StartklarForm'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { sv } from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import UserFeedback from '@local/src/Blocks/UserFeedback/UserFeedback'

import { AppShellRouter, KeyRoute } from './AppShellRouter'
import Store from './Store/configureStore'
import sanitizeLocalStorage from './Utils/helpers/LocalStorageSanitizer'
import { MicroFrontendLoaderProvider } from './AppShellData'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)
  sanitizeLocalStorage()

  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <MicroFrontendLoaderProvider value={appShellData}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <Provider store={Store}>
              <UserFeedback />
              <AppShellRouter currentKey={appShellData.currentKey}>
                <KeyRoute urlKey={'medarbetare'}>
                  <Home />
                </KeyRoute>
                <KeyRoute urlKey={'arenden'}>
                  <Arenden key="arenden" />
                </KeyRoute>
                <KeyRoute urlKey={'alla-arenden'}>
                  <Arenden key="alla-arenden" />
                </KeyRoute>
                <KeyRoute urlKey={'omstallning'}>
                  <Arenden key="omstallning" />
                </KeyRoute>
                <KeyRoute urlKey={'kompetensstod'}>
                  <Arenden key="kompetensstod" />
                </KeyRoute>
                <KeyRoute urlKey={'att-gora'}>
                  <Arenden key="att-gora" />
                </KeyRoute>
                <KeyRoute urlKey={'arende'}>
                  <Case />
                </KeyRoute>
                <KeyRoute urlKey={'dina-arenden'}>
                  <DinaArenden />
                </KeyRoute>
                <KeyRoute urlKey={'fordela-arenden'}>
                  <AllocateCases />
                </KeyRoute>
                <KeyRoute urlKey={'startklar'}>
                  <Startklar />
                </KeyRoute>
                <KeyRoute urlKey={'startklar-arende'}>
                  <StartklarForm />
                </KeyRoute>
              </AppShellRouter>
            </Provider>
          </LocalizationProvider>
        </MicroFrontendLoaderProvider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default App
