import React from 'react'
import { Alert, Snackbar } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../Store/configureStore'

import { errorMessagesSelector, successMessagesSelector } from './UserFeedback.selectors'
import { SuccessMessage } from './UserFeedback.types'
import { removeErrorMessage, removeSuccessMessage } from './UserFeedback.actions'

const UserFeedback = () => {

  const dispatch = useAppDispatch()
  const successMessages = useAppSelector(successMessagesSelector)
  const errorMessages = useAppSelector(errorMessagesSelector)

  const handleSuccessClose = (id: number) => {
    dispatch(removeSuccessMessage(id))
  }

  const handleErrorClose = (id: string) => {
    dispatch(removeErrorMessage(id))
  }

  return (
    <>
      { successMessages.map((message: SuccessMessage) => (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={message.title}
          onClose={() => handleSuccessClose(message.id)}
          key={message.id}
          autoHideDuration={4000}
        />
      ))}
      { errorMessages.map((message: string) => (
        <Alert
          key={message}
          onClose={() => handleErrorClose(message)}
          severity='error'
          sx={{ width: '100%', mb: 1 }}
        >
          {message}
        </Alert>
      ))}
    </>
  )
}

export default UserFeedback
