import React from 'react'
import { IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import { Box, Button, Chip } from '@mui/material'

interface FilterTagsProps {
  filterTags: IFilterTag[]
  resetFilter?(): void
  removeFilterParameterValuePair(relevantPartOfTag: Omit<IFilterTag, 'displayValue'>): void
  halfPadding?: boolean
}

const FilterTags = ({
  filterTags,
  removeFilterParameterValuePair,
  resetFilter,
  halfPadding = false,
}: FilterTagsProps): JSX.Element => (

  <Box pb={halfPadding ? 1 : 2}>
    {resetFilter && (
      <Button variant='text' size='small' onClick={resetFilter}>
        Återställ
      </Button>
    )}
    <Box display='flex' flexWrap='wrap' gap={1} mt={1}>
      {filterTags.map((tag) => {
        return (
          <Chip
            key={`${tag.filterParameter}${tag.filterValue}`}
            data-tag={`${tag.filterParameter}${tag.filterValue}`}
            onDelete={() => removeFilterParameterValuePair(tag)}
            title={tag.displayValue}
            label={tag.displayValue}
            variant="filled"
          />
        )
      })}
    </Box>
  </Box>
)

export default FilterTags
