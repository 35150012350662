export enum TimeOfDay {
  EARLY_MORNING,
  MORNING,
  LUNCH,
  AFTERNOON,
  NIGHT,
}

const baseEmojis: string[]      = ["😎", "💪", "🧑‍💻", "🫶", "🎉", "🌟", "💫", "🌈", "🍀"]
const morningEmojis: string[]   = ["🌅", "☕️", "🌞", "🍵", "🍳", ...baseEmojis]
const lunchEmojis: string[]     = ["🍔", "🥗", "🥪", "🥙", "🍝", ...baseEmojis]
const afternoonEmojis: string[] = ["🌤️", "⛅", "🚴‍♂️", "📚", "💻", ...baseEmojis]
const nightEmojis: string[]     = ["🌇", "🌃", "🌙", "🍽️", "🌄", ...baseEmojis]


export const getGreetingTime = (date: Date): TimeOfDay => {
  const currentHour = date.getHours()
  const currentMinutes = date.getMinutes()
  if (currentHour >= 5 && currentHour < 9) {
    return TimeOfDay.EARLY_MORNING
  } else if ((currentHour >= 9 && currentHour < 11) || (currentHour === 11 && currentMinutes < 30)) {
    return TimeOfDay.MORNING
  } else if ((currentHour === 11 && currentMinutes >= 30) || (currentHour === 12)) {
    return TimeOfDay.LUNCH
  } else if (currentHour >= 13 && currentHour < 17) {
    return TimeOfDay.AFTERNOON
  } else {
    return TimeOfDay.NIGHT
  }
}

export const getGreeting = (timeOfDay: TimeOfDay): string => {
  switch (timeOfDay) {
    case TimeOfDay.EARLY_MORNING:
      return 'God morgon'
    case TimeOfDay.MORNING:
      return 'God förmiddag'
    case TimeOfDay.LUNCH:
      return 'Trevlig lunch'
    case TimeOfDay.AFTERNOON:
      return 'God eftermiddag'
    case TimeOfDay.NIGHT:
      return 'God kväll'
    default:
      return 'Goddag'
  }
}

export const getRandomEmoji = (timeOfDay: TimeOfDay): string => {
  let emojis: string[]

  switch (timeOfDay) {
    case TimeOfDay.EARLY_MORNING:
    case TimeOfDay.MORNING:
      emojis = morningEmojis
      break
    case TimeOfDay.LUNCH:
      emojis = lunchEmojis
      break
    case TimeOfDay.AFTERNOON:
      emojis = afternoonEmojis
      break
    case TimeOfDay.NIGHT:
      emojis = nightEmojis
      break
    default:
      emojis = baseEmojis;
  }

  const randomIndex = Math.floor(Math.random() * emojis.length)
  return emojis[randomIndex]
}

