import { useLazyGetSuggestionsQuery } from '@local/src/Api/RadgivningsArende/radgivningsArendeApi'
import { IStartklarFilterState } from '@local/src/Views/Startklar/Startklar.types'
import React, { useEffect, useState } from 'react'

import { IArendenFilterState, IDinaArendenFilterState } from '../CasesFilter/CasesFilter.types'
import Autocomplete from '../Autocomplete/Autocomplete'

interface SuggestionsAutocompleteProps {
  label: string
  value: string | string[]
  propertyName: string
  filterState: IArendenFilterState | IDinaArendenFilterState | IStartklarFilterState
  getSelectedValues: (values: string | string[]) => void
  multiple?: boolean
}

const SuggestionsAutocomplete = ({
  label,
  value,
  propertyName,
  filterState,
  getSelectedValues,
  multiple = false,
}: SuggestionsAutocompleteProps): JSX.Element => {

  const [values, setValues] = useState<string | string[]>(value)
  const [fetchSuggestions, response] = useLazyGetSuggestionsQuery()

  const onValueChange = (value: string | string[]) => {
    setValues(value)
  }

  useEffect(() => {
    setValues(value)
  }, [value])

  useEffect(() => {
    getSelectedValues(values)
  }, [values])

  return (
    <Autocomplete
      label={label}
      multiple={multiple}
      loading={response.isFetching || response.isLoading}
      options={response?.data ?? []}
      getOptionLabel={(value: string) => value}
      value={values}
      onChange={onValueChange}
      inputValueChanged={(value) => void fetchSuggestions({ filterState, propertyName, searchText: value })}
    />
  )
}

export default SuggestionsAutocomplete