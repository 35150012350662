import { FordelningsRadgivare } from '@local/src/Views/Fordelning/Fordelning.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v1.0/radgivare'

export const radgivareApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAktivaRadgivare: builder.query<FordelningsRadgivare[], string>({
      query: (teamId) => `${baseUrl}/aktivaradgivare?teamId=${teamId}`
    })
  })
})

export const {
  useGetAktivaRadgivareQuery,
} = radgivareApi