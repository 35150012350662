import React from 'react'
import { Box, List, Typography } from '@mui/material'

import { ActivityRound } from '../Activities.types'
import ActivityListItem from '../ActivityListItem/ActivityListItem'

interface ActivityListProps {
  title: string
  emptyListText: string
  activities: ActivityRound[]
}

const ActivityList = ({
  title,
  emptyListText,
  activities,
}: ActivityListProps) => {

  return (
    <Box>
      <Typography variant='h6'>{title}</Typography>
      { activities.length > 0 ? (
        <List>
          {activities.map((activity) => <ActivityListItem key={activity.id} activity={activity} />)}
        </List>
      ) : (
        <Typography variant='body1' color='text.secondary' pt={1}>{emptyListText}</Typography>
      )}
    </Box>
  )
}

export default ActivityList