type QueryStringParamValue = string | string[] | number
type QueryStringParams<TValue = QueryStringParamValue> = Record<string, TValue | undefined>

export class ArgumentError extends Error {
  public constructor(public argument: string, message = 'Not a valid value') {
    super(`Argument '${argument}': ${message}`)
  }
}

export const VALUE_SEPARATOR = ';'
export const ESCAPED_VALUE_SEPARATOR = '%' + VALUE_SEPARATOR.charCodeAt(0).toString(16).toUpperCase()

export const stringify = (params: QueryStringParams): string => {
  const joined = Object.entries(params)
    // Only stringify params with values.
    .filter(([, value]) => typeof value === 'number' || (value.length && value[0]))
    .map(([name, paramValue]) => {
      paramValue = typeof paramValue === 'number' ? String(paramValue) : paramValue
      const values: string[] = [].concat(paramValue)
      values.forEach((value) => {
        if (value.includes(VALUE_SEPARATOR)) {
          throw new ArgumentError('params', `A value may not contain a semicolon: '${value}'`)
        }
      })
      return [name, values.join(VALUE_SEPARATOR)]
    })

  const qs = new URLSearchParams(joined)
  const qsString = qs.toString()
  return qsString ? `?${qsString}` : ''
}

export interface ParseOptions<B = boolean> {
  forceArray?: B
}

export const parse = <B extends boolean>(
  query: string,
  { forceArray }: ParseOptions<B> = {}
): B extends true ? QueryStringParams<string[]> : QueryStringParams | null => {
  const entries = Array.from(new URLSearchParams(query).entries()).filter(([, value]) => value)
  const qs = entries.reduce((obj: Record<string, unknown>, [key, values]) => {
    const decoded = values.split(VALUE_SEPARATOR)
    if (decoded.length === 1 && !forceArray) {
      obj[key] = decoded[0]
    } else {
      obj[key] = decoded
    }
    return obj
  }, {}) as B extends true ? QueryStringParams<string[]> : QueryStringParams

  return qs
}
