import { baseApi } from '../baseApi'


interface GetHasRattTillAgeRequest {
  klientId: string
  arendeId: string
}

interface GetHasRattTillAgeResponse {
  arendeId: string
  arendenummer: string
  hasRattTillAgeForArendekontext: boolean
}

export enum AgeFlag {
  Ja = 'Ja',
  JaTidigare = 'Ja, från tidigare',
  Nej = 'Nej',
}

const baseUrl = '/v1.0/agearende'

export const ageArendeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getHasRattTillAge: builder.query<string, GetHasRattTillAgeRequest>({
      query: (params) => `${baseUrl}/klient/${params.klientId}/arende/${params.arendeId}/rattTillAge`,
      transformResponse: (response: GetHasRattTillAgeResponse) => {
        const hasAge = response?.hasRattTillAgeForArendekontext
        return !response ? AgeFlag.Nej : hasAge ? AgeFlag.Ja : AgeFlag.JaTidigare
      },
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useGetHasRattTillAgeQuery,
} = ageArendeApi