import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FetchingState } from '@local/src/Common.types'

import { Avslutskod } from '../../Avslut.types'
import Anstallning from '../AvslutForms/Anstallning/Anstallning'
import EgenRorelse from '../AvslutForms/EgenRorelse/EgenRorelse'
import { submittingQuestionResponsesStateSelector } from '../../Avslut.selectors'

import { getAvslutBaseUrl } from './Common'

const UnhandledAvslutshandelseForm = () => {
  const location = useLocation()
  const history = useHistory()
  const submittingQuestionResponsesState = useSelector(submittingQuestionResponsesStateSelector)
  const prevSubmittingQuestionResponsesStateRef = useRef<FetchingState>(submittingQuestionResponsesState)

  const { avslutsId, arendeId } = useParams<{ avslutsId: string; arendeId: string }>()
  const searchParams = new URLSearchParams(location.search)
  const avslutskod = searchParams.get('avslutskod')
  const avslutsdatum = new Date(searchParams.get('avslutsdatum'))

  useEffect(() => {
    if (
      submittingQuestionResponsesState === FetchingState.SUCCESS &&
      prevSubmittingQuestionResponsesStateRef.current === FetchingState.LOADING
    ) {
      const avslutUrl = getAvslutBaseUrl(history.location.pathname)
      history.push(avslutUrl)
    }
    prevSubmittingQuestionResponsesStateRef.current = submittingQuestionResponsesState
  }, [submittingQuestionResponsesState])

  return (
    <>
      {avslutskod === Avslutskod.NyAnstallning && (
        <Anstallning
          key="NA"
          arendeId={arendeId}
          avslutskod={Avslutskod.NyAnstallning}
          requireLosningStartdatum={false}
          isUnhandledAvslutshandelse
          avslutsdatum={avslutsdatum}
          avslutsId={avslutsId}
          title="Avslutsfrågor för Ny anställning - Tillsvidare"
          description="Avslutsdatum är tidigast det datum då omställningskunden undertecknar sitt anställningskontrakt.
              Det styr även beräkningen av omställningskundens kvarvarande tid för omställningsstöd.

              Om omställningskunden behöver fortsatt stöd i form av rådgivning eller deltar i aktiviteter på plats ska istället sista dag för detta anges som avslutsdatum. Dock senast när anställningen påbörjas."
        />
      )}

      {avslutskod === Avslutskod.OvrigAnstallning && (
        <Anstallning
          key="OA"
          arendeId={arendeId}
          avslutskod={Avslutskod.OvrigAnstallning}
          requireLosningStartdatum={false}
          isUnhandledAvslutshandelse
          avslutsdatum={avslutsdatum}
          avslutsId={avslutsId}
          title="Avslutsfrågor för Övrig anställning - Visstid"
          description="Avslutsdatum är tidigast det datum då omställningskunden undertecknar sitt anställningskontrakt.
              Det styr även beräkningen av omställningskundens kvarvarande tid för omställningsstöd.

              Om omställningskunden behöver fortsatt stöd i form av rådgivning eller deltar i aktiviteter på plats ska istället sista dag för detta anges som avslutsdatum. Dock senast när anställningen påbörjas."
        />
      )}

      {avslutskod === Avslutskod.EgenRorelse && (
        <EgenRorelse
          title="Avslutsfrågor för Egen rörelse"
          arendeId={arendeId}
          isUnhandledAvslutshandelse
          avslutsdatum={avslutsdatum}
          avslutsId={avslutsId}
        />
      )}
    </>
  )
}

export default UnhandledAvslutshandelseForm
