import React from 'react'
import { Box, CircularProgress, Divider, Tab, Tabs } from '@mui/material'

import useTabs from './useTabs'

export interface ITabItem {
  heading: React.ReactNode
  content?: React.ReactNode
  icon?: React.ReactElement
  iconPosition?: 'top' | 'bottom' | 'start' | 'end'
  url: string
}

export interface ITabsContainer extends Partial<JSX.IntrinsicElements['div']> {
  items: ITabItem[]
  purpose?: string
  size?: 'small' | null
}

export const TabsContainer = ({ items, purpose, size }: ITabsContainer) => {
  const { currentIndex, handleChange, currentPath, getA11yProps } = useTabs(items)
  const currentTabComponent = items.find((item) => item.url.includes(currentPath))

  return (
    <>
      <Box>
        <Tabs value={currentIndex} onChange={handleChange} allowScrollButtonsMobile variant='scrollable' size={size} aria-label={purpose}>
          {items.map((tabItem, index) => (
            <Tab sx={{ textTransform: 'none', fontSize: 16, minHeight: 0 }} icon={tabItem.icon} iconPosition={tabItem.iconPosition} key={tabItem.url} label={tabItem.heading} {...getA11yProps(index)} />
          ))}
        </Tabs>
        <Divider sx={{ mb: 2, mt: size === 'small' ? -0.5 : 0 }} />
      </Box>
      {currentTabComponent?.content ?? <CircularProgress />}
    </>
  )
}
