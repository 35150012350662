import React from 'react'
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { formatDateFromDateTime } from '@local/src/Utils/DateTime'
import { ArendeTyp } from '@local/src/Views/Arenden/Arenden.types'
import { getUrlByCaseEnv } from '@local/src/basename'
import { useCaseEnvBasePath } from '@local/src/Hooks'
import { useSelector } from 'react-redux'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { IOtherCase } from '../../Case.types'
import { ovrigaArendenSelector } from '../../Case.selectors'

const OvrigaArenden = () => {

  const arenden = useSelector(ovrigaArendenSelector)
  const caseEnvBasePath = useCaseEnvBasePath()

  const setOvrigtArendeDisplayText = (arende: IOtherCase) => {
    let displayText = arende.avslutsDatum ? formatDateFromDateTime(arende.avslutsDatum) : 'Pågående'
    if (arende.arendeTyp === ArendeTyp.Omstallning) {
      displayText += arende.radgivareFornamn || arende.radgivareEfternamn
        ? ` - ${arende.radgivareFornamn} ${arende.radgivareEfternamn}`
        : ' - Rådgivare saknas'
    }
    else if (arende.arendeTyp === ArendeTyp.Kompetensstod) {
      return `${displayText} - Kompetensstödsärende`
    }
    return displayText
  }

  return (
    <>
      {arenden?.length > 0 && (
        <>
          <Typography variant='h4' mb={1}>Övriga ärenden</Typography>
          <List disablePadding>
            {arenden.map((arende) => {
              return (
                <ListItemButton
                  key={arende.arendeId}
                  href={getUrlByCaseEnv(caseEnvBasePath, arende.arendeId)}
                  target='_blank'
                >
                  <ListItemIcon>
                    <OpenInNewIcon />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body1' }}
                    primary={setOvrigtArendeDisplayText(arende)}
                  />
                </ListItemButton>
              )
            })}
          </List>
          <Divider sx={{ my: 5 }} />
        </>
      )}
    </>
  )
}

export default OvrigaArenden