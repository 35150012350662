import { List, ListItem, ListItemText } from '@mui/material'
import React from 'react'

import { useEpiString } from '../../../../../Context/EpiStringContext'
import { IStudieAnmalan } from '../../../types/StudieAnmalan.types'
import AnmalanContentItem from '../AnmalanContentItem/AnmalanContentItem'

import s from './CourseFees.module.scss'

interface ICourseFeesProps {
  studieAnmalan: IStudieAnmalan
}

const CourseFees: React.FC<React.PropsWithChildren<ICourseFeesProps>> = ({ studieAnmalan }) => {
  const epi = useEpiString()
  const { courseFee } = studieAnmalan
  if (!courseFee) return null
  return (
    <ListItem>
      <AnmalanContentItem>
        <ListItemText primaryTypographyProps={{ variant: 'h6' }} primary={epi('common.Costs')} />
        <List dense>
          <ListItem>
            <ListItemText primary={`${epi('common.CourseFee')}:`} secondary={!!courseFee?.fee && `${courseFee.fee} SEK`} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`${epi('common.VAT')}:`}
              secondary={courseFee?.vat > 0 ? `${courseFee?.vat} %` : epi('common.VATFree')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              className={s.bold}
              primary={`${epi('common.TotalCost')}:`}
              secondary={!!courseFee?.totalFee && `${courseFee.totalFee} SEK`}
            />
          </ListItem>
        </List>
      </AnmalanContentItem>
    </ListItem>
  )
}

export default CourseFees
