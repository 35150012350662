import { FetchingState } from '@local/src/Common.types'
import { IDokumentEPIContent } from '@local/src/Views/Case/Documents/Documents.types'

import { ArendeMarkning, ArendeTyp, OmfattningsbeslutStatus } from '../Arenden/Arenden.types'

import { IDocument } from './Documents/Documents'

export interface IAGEInformation {
  mojligAge: number
  beslut: IAGEBeslut[]
  startdatum: Date
  statusText: string
  avslutsdatum: Date
  avslutad: boolean
  harSamtyckeAkassa: boolean
}

export interface IAGEBeslut {
  krPerDag: number
  antalDagar: number
  summa: number
}

export interface IOtherCase {
  arendeId: string
  arendeTyp: ArendeTyp
  avslutsDatum: string
  radgivareId: string
  radgivareFornamn: string
  radgivareEfternamn: string
}

export interface IMarkning {
  id: ArendeMarkning
  displayText: string
  isSet: boolean
  editable: boolean
}

export interface IMeeting {
  id: string
  klientId: string
  startDatumTid: string
  slutDatumTid: string
  radgivareNamn: string
  motesTyp: MotesTyp
}

export enum MotesTyp {
  Okand = 'Okand',
  Fysiskt = 'Fysiskt',
  Online = 'Online',
  Telefon = 'Telefon',
}

export interface PayloadAction<T> {
  type: string
  payload: T
}

export interface UpdateMarkningInitializedActionPayload {
  markningId: string
  value: boolean
}

export interface ICaseDetails {
  id: string
  arendeNummer: string
  arendeTyp: ArendeTyp
  omfattningsbeslutStatus: OmfattningsbeslutStatus
  klient: IKlient
  omstallningsArende: IOmstallningsArende
  anstallningsArende: IKompetensstodsArende
  ovrigaArenden: IOtherCase[]
  hasStudieansokningar: boolean
  arendeMarkningar: IMarkning[]
  omfattningspaket: IOmfattningspaket[]
  statusDisplayText: string
  infoText: string
  warningTexts: IWarningTexts
  inbokadeMoten: IMeeting[]
  ateroppnaAGEValid: boolean
  ateroppnaRDGValid: boolean
  karriarVagledningProgression: IKarriarVagledningProgression
}

export interface IWarningTexts {
  bedomningPagar: string
}

interface IForetag {
  id: string
  namn: string
  organisationsnummer: string
}

interface IAnstallningsuppgifter {
  avgangsvederlagSlutDatum?: Date
  anstallningsDatum: string
  anstallningsgrad: number
  arbetstimmarPerVecka: number
  avgangsvederlagBelopp: number
  befattning: string
  foretag: IForetag
  uppsagningsgrad: number
  uppsagningsDatum: string
  undantagsgrupp: boolean
}

interface IInkomstuppgifter {
  harUppdateratLon: boolean
  jour: number
  kostLogi: number
  lonerevisionsDatum: string
  manadsLon: number
  provision: number
  sistaDagMedLon: string
  totalLon: number
}

interface IProjekt {
  id: string
  namn: string
  nummer: string
}

export interface IAnstallningsAvslutsInformation {
  avslutadAv: string
  avslutsDatum: string
  avslutskod: string
}

export interface IRadgivare {
  id: string
  teamId: string
  fornamn: string
  efternamn: string
  radgivarStatistik: {
    idag: number
    in: number
    inPlusMinus: number
    aktuella: number
  }
}

interface IKompetensstodsArende {
  anstallningsuppgifter: IAnstallningsuppgifter
  attesteringsDatum: string
  inkomstuppgifter: IInkomstuppgifter
  ansokanKomplettDatum: string
  arendeStatus: string
  team: {
    id: string
    namn: string
  }
  avslutsInformation: IAnstallningsAvslutsInformation
  radgivare: IRadgivare
  meddelandeFranHandlaggare: string
}

export interface IOmstallningsArende {
  anstallningsuppgifter: IAnstallningsuppgifter
  attesteringsDatum: string
  inkomstuppgifter: IInkomstuppgifter
  ansokanKomplettDatum: string

  hasActiveMatchProfile: boolean
  nonActiveMatchProfileReasons: string[]
  age: IAGEInformation
  meddelandeFranAgeHandlaggare: string
  orsakAnsokan: string
  orsakAnsokanDisplayText: string
  projekt: IProjekt
  radgivare: IRadgivare
  radgivning: IRadgivning
  senasteKontaktDatum: string
  tidigareRadgivare: ITidigareRadgivare[]
}
export interface ITidigareRadgivare {
  id: string
  fornamn: string
  efternamn: string
}
export interface IRadgivning {
  arbetsmarknadsomrade: {
    id: string
    namn: string
  }
  avslutsInformation: {
    avslutsKod: string
    avslutsDatum: string
    radgivareFarAteroppna: boolean
    ageHandlaggareFarAteroppna: boolean
  }
  antalKvarvarandeRadgivningsDagar: number
  team: {
    id: string
    namn: string
  }
  fordelningTeam: {
    id: string
    namn: string
  }
  slutDatum: string
  slutdatumTyp: string
  subStatus: string
  subStatusDisplayText: string
}

export interface IKlient {
  id: string
  fornamn: string
  efternamn: string
  alder: number
  kommun: string
  lan: string
  gatuadress: string
  postnummer: string
  ort: string
  linkedInUrl: string
  telefonnummer: string
  samtyckeHalsa: boolean
  epost: string
  personnummer: string
  folkbokforingsStatus: string
  klientBildFilnamn: string
  foredragetSprak: ForedragetSprak
}

export interface INastaMote {
  startDatumTid: string
  slutDatumTid: string
}

export interface ICaseState {
  readonly caseDetails: ICaseDetails
  readonly changingAdvisorState: FetchingState
  readonly changingTeamState: FetchingState
  readonly caseFetchingState: FetchingState
  fetchUtbetalningsbeslutState: FetchingState
  fetchEventsState: FetchingState
  events: []
  removeEventState: FetchingState
  createEventState: FetchingState
  updateEventState: FetchingState
  documents: IDocument[]
  fetchDocumentsState: FetchingState
  postDocumentState: FetchingState
  fetchDocumentState: FetchingState
  deleteDocumentState: FetchingState
  reload: boolean
  studiestodAnsokningarFetchingState: FetchingState
  fetchStudieansokanState?: FetchingState
  lockStudiestodAnsokanState?: FetchingState
  updatePreferredLanguageState: FetchingState
  karriarVagledningProgression: IKarriarVagledningProgression
}

export interface IMarkningResBody {
  arendeId: string
  isSet: boolean
  markning: string
}

export interface IArendeTypTabs {
  caseId: string
  klientId: string
  samtyckeHalsa: boolean
  arendeTyp: string
  inbokadeMoten: IMeeting[]
  caseDetails: ICaseDetails
  karriarVagledningProgression: IKarriarVagledningProgression
}

// Interface for new EPI-content, clean up the other ones later
export interface IArendeContent {
  dokument: { dokumentLista: IDokumentEPIContent }
}

export enum OmstallningsArende {
  TIDSLINJE = 'Tidslinje',
  AKTIVITETER = 'Aktiviteter',
  EKONOMI = 'Ekonomi',
  STUDIER = 'Studier',
  AVSLUT = 'Avslut',
  DOKUMENT = 'Dokument',
  ANDRA = 'Ändra',
}

export enum KompetensstodsArende {
  TIDSLINJE = 'Tidslinje',
  EKONOMI = 'Ekonomi',
  STUDIER = 'Studier',
  AVSLUT = 'Avslut',
  DOKUMENT = 'Dokument',
}

export enum ForedragetSprak {
  SWEDISH = 'SV',
  ENGLISH = 'EN',
}

export const Tabs = { OmstallningsArende, KompetensstodsArende }

export interface ICategory {
  kategori: ArendeMarkning
  kategoriDisplayText: string
  isSet: boolean
  editable: boolean
}
export type MarkningType = 'Studiespecialist'

export interface IOmfattningspaket {
  id: string
  namn: Omfattningspaket
  produkt: IProdukt
  content: string | null
}

interface IProdukt {
  id: string
  namn: string
  tjanster: ITjanst[]
}

export interface ITjanst {
  id: string
  namn: string
}

export enum Omfattningspaket {
  Studiestod = 'Studiestöd',
  Kompetensstod = 'Kompetensstöd',
  KompetensstodBas = 'Kompetensstöd Bas',
  Omstallningsstod = 'Omställningsstöd',
  OmstallningsstodBas = 'Omställningsstöd Bas',
  OmstallningsstodRisk = 'Omställningsstöd Risk',
}

export interface IKarriarVagledningProgression {
  livslinjen: KarriarVagledningStatus
  personligSpegling: KarriarVagledningStatus
  varderingskompass: KarriarVagledningStatus
  karriarguide: KarriarVagledningStatus
  yrkesprognoser: KarriarVagledningStatus
  utbildning: KarriarVagledningStatus
  karriarsok: KarriarVagledningStatus
  framtidsmal: KarriarVagledningStatus
}
export enum KarriarVagledningStatus {
  NOTSTARTED = 'NotStarted',
  INPROGRESS = 'InProgress',
  COMPLETED = 'Completed',
}

export interface IJmtUserStatusRes {
  status: IJmtUserStatus
}

interface IJmtUserStatus {
  id: string
  status: number
}
