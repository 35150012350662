import React, { useState, useEffect, useCallback, useRef } from 'react'
import { FetchingState, ISelectOption } from '@local/src/Common.types'
import StartklarCaseList from '@local/Views/Startklar/StartklarCaseList/StartklarCaseList.container'
import { findSelectOption, formatAmount, scrollToRef } from '@local/Utils/smallfuncs'
import OrderBy from '@local/src/Components/OrderBy'
import SearchField from '@local/src/Components/SearchField'
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'
import SearchResultSnippet from '@local/src/Components/SearchResultSnippet'
import Pagination from '@local/src/Components/Pagination/Pagination'
import EmptyResult from '@local/src/Components/EmptyResult/EmptyResult'

import { ICaseStartklar, IStartklarFilterState } from '../Startklar.types'

const SortingOptions = [
  { value: 'SubStatusDatum asc', label: 'Dagar - äldsta' },
  { value: 'SubStatusDatum desc', label: 'Dagar - nyaste' },
  { value: 'StartklarAntalUppringningsforsok asc', label: 'Samtal - stigande' },
  { value: 'StartklarAntalUppringningsforsok desc', label: 'Samtal - fallande' },
]

export interface StartklarListWrapperProps {
  setStartklarPage(page: number): void
  setStartklarPageSize(page: number): void
  startklarCases: ICaseStartklar[]
  filterState: IStartklarFilterState
  facetedTotalHits: number
  totalHits: number
  fetchingCaseListStartklar: FetchingState
  searchText: string
  setStartklarFilterParameters: (filterParams: Partial<IArendenFilterState>) => void
  onSearch: (searchText: string) => void
}

const StartklarListWrapper = ({
  startklarCases,
  setStartklarPage,
  setStartklarPageSize,
  fetchingCaseListStartklar,
  filterState,
  facetedTotalHits,
  totalHits,
  searchText,
  setStartklarFilterParameters,
  onSearch,
}: StartklarListWrapperProps): JSX.Element => {

  const arendeTableRef = useRef<HTMLDivElement>(null)
  const [dropdownSortingValue, setDropdownSortingValue] = useState<ISelectOption>(findSelectOption(filterState.orderBy, SortingOptions))
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setDropdownSortingValue(findSelectOption(filterState.orderBy, SortingOptions))
  }, [filterState.orderBy])

  const setSortingValue = useCallback(
    (sortingValue: ISelectOption) => setStartklarFilterParameters({ orderBy: sortingValue.value as string }),
    [setStartklarFilterParameters]
  )

  const pagination = (showPagination = false, showRowsPerPage = false) => (
    <Pagination
      count={totalHits}
      page={filterState.page}
      pageSize={filterState.pageSize}
      labelRowsPerPage="Ärenden per sida:"
      showPagination={showPagination}
      showRowsPerPage={showRowsPerPage}
      onPageChange={changePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setStartklarPageSize(rowsPerPage)
  }

  const changePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => {
    setStartklarPage(page + 1)
    scrollToRef(arendeTableRef)
  }

  const searchSnippet = searchText && (
    <SearchResultSnippet pluralName="ärenden" searchQuery={searchText} singularName="ärende" totalHits={totalHits} />
  )

  return (
    <Box flex={1} maxWidth={1200}>
      <Box display='flex' flexDirection='column'>
        <Typography variant='h3' mb={1}>Startklar</Typography>
        <Box width={{ lg: '70%', xl: '70%' }} display='flex' gap={2} flexDirection='column' ref={arendeTableRef}>
          <SearchField value={''} search={onSearch} />
        </Box>
        <Box paddingLeft={isMobile ? 0 : 1} paddingTop={2}>
          {searchSnippet}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          flexDirection={{xs: 'column', md: 'row' }}
        >
          <Typography variant="body2" padding={1}>
            {`${formatAmount(totalHits)} ärenden av ${
              facetedTotalHits === null ? 0 : formatAmount(facetedTotalHits)
            }`}
          </Typography>
          <Box display='flex' flexDirection={{xs: 'column', md: 'row'}} alignItems='center'>
            {pagination(false, true)}
            <OrderBy selectOptions={SortingOptions} value={dropdownSortingValue} onChange={setSortingValue} />
          </Box>
        </Box>
      </Box>
      <Divider />
      <StartklarCaseList />
      {startklarCases.length === 0 && fetchingCaseListStartklar !== FetchingState.LOADING && (
        <EmptyResult sx={{ pt: 5 }} />
      )}
      {pagination(true, !isMobile)}
    </Box>
  )
}

export default StartklarListWrapper
