/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { midnightToday, justBeforeMidnightTomorrow } from '@local/Utils/DateTime'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { isNil } from 'ramda'
import { IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import { IAllocateCaseListItem } from '@local/Views/AllocateCases/AllocateCases.types'
import { SyntheticEvent } from 'react'

import { formatAmount } from '../smallfuncs'

export interface ITag {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  removeTag?(event: SyntheticEvent): void
}

export const createTags = (prefilterValues: ITag[], postfilterValues: ITag[]): IFilterTag[] => {
  const tags: IFilterTag[] = []
  postfilterValues.forEach((tag, i) => {
    if (Array.isArray(tag.value)) {
      if (tag.name === 'Nästa möte') {
        if (!isNil(tag.value[1])) {
          tags.push({
            filterParameter: tag.name,
            filterValue: `${tag.name} ${format(tag.value[0], 'd MMM', {
              locale: sv,
            })} - ${format(tag.value[1], 'd MMM', { locale: sv })}`,
            displayValue: `${tag.name} ${format(tag.value[0], 'd MMM', {
              locale: sv,
            })} - ${format(tag.value[1], 'd MMM', { locale: sv })}`,
          })
        } else if (tag.value[0] !== midnightToday) {
          tags.push({
            filterParameter: tag.name,
            filterValue: `${tag.name} efter ${format(tag.value[0], 'd MMM', {
              locale: sv,
            })}`,
            displayValue: `${tag.name} efter ${format(tag.value[0], 'd MMM', {
              locale: sv,
            })}`,
          })
        }
      } else if (tag.name === 'Senaste kontakt') {
        if (!isNil(tag.value[0])) {
          tags.push({
            filterParameter: tag.name,
            filterValue: `${tag.name} ${format(tag.value[0], 'd MMM', {
              locale: sv,
            })} - ${format(tag.value[1], 'd MMM', { locale: sv })}`,
            displayValue: `${tag.name} ${format(tag.value[0], 'd MMM', {
              locale: sv,
            })} - ${format(tag.value[1], 'd MMM', { locale: sv })}`,
          })
        } else if (tag.value[1] !== justBeforeMidnightTomorrow) {
          tags.push({
            filterParameter: tag.name,
            filterValue: `${tag.name} innan ${format(tag.value[1], 'd MMM', {
              locale: sv,
            })}`,
            displayValue: `${tag.name} innan ${format(tag.value[1], 'd MMM', {
              locale: sv,
            })}`,
          })
        }
      } else if (tag.value[0] !== prefilterValues[i].value[0] || tag.value[1] < prefilterValues[i].value[1]) {
        tags.push({
          filterParameter: tag.name,
          filterValue: `${tag.name} ${formatAmount(tag.value[0])} - ${formatAmount(tag.value[1])}`,
          displayValue: `${tag.name} ${formatAmount(tag.value[0])} - ${formatAmount(tag.value[1])}`,
        })
      }
    } else if (typeof tag.value === 'boolean') {
      if (tag.value) {
        tags.push({
          filterParameter: tag.name,
          filterValue: tag.name,
          displayValue: tag.name,
        })
      }
    } else if (tag.value !== prefilterValues[i].value) {
      {
        tags.push({
          filterParameter: tag.name,
          filterValue: tag.value,
          displayValue: tag.value,
        })
      }
    }
  })
  return tags
}

export const pluckCompany = (cases: IAllocateCaseListItem[]): string[] => {
  const companies = cases.map(({ arbetsstalle }) => arbetsstalle)
  return companies
}

export const pluckRole = (cases: IAllocateCaseListItem[]): string[] => {
  const roles = cases.map(({ befattning }) => befattning)
  return roles
}

export const pluckResidence = (cases: IAllocateCaseListItem[]): string[] => {
  const residences = cases.map(({ ort }) => ort)
  return residences
}
