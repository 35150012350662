import React, { SyntheticEvent } from 'react'
import { Icons } from '@trr/common-component-library'
import classnames from 'classnames'
import { Box } from '@mui/material'

import s from './Panel.module.scss'

interface IPanel {
  title: string
  children: React.ReactNode
  isInUse?: boolean
  isOpen: boolean
  toggleIndex: (number: number) => void
  index: number
  renderOrder?: number
}

const Panel = ({ title, children, isInUse, isOpen, toggleIndex, index, renderOrder }: IPanel): JSX.Element => {
  const handleToggle = (index: number) => (e: SyntheticEvent) => {
    toggleIndex(index)
    e.preventDefault()
  }
  return (
    <Box className={s.PanelWrapper} sx={{ gridRow: renderOrder ?? undefined }}>
      <button className={classnames(s.PanelButton, isOpen && s.Open, isInUse && s.IsInUse)} onClick={handleToggle(index)}>
        {title} <Icons.ArrowDownIcon color={s.textColor} size={16} />
      </button>
      <div className={s.Panel}>
        <div className={classnames(s.Content, !isOpen && s.Closed)}>{children}</div>
      </div>
    </Box>
  )
}

export default Panel
