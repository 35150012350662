import { ISelectOption } from '@local/src/Common.types'
import { IDinaArendenFilterState, IArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'

import { stringifyFilter } from '../helpers'
import { baseURL } from '../network'

export const suggestionsLoader = (filter: IArendenFilterState | IDinaArendenFilterState, id_token: string) =>
  (propertyNameInPlural: string, minLength = 1, propertySearchText: string, callback: (options: ISelectOption[]) => void) => {

    if (propertySearchText.length < minLength) return

    filter[propertyNameInPlural] = [] // remove current filter
    const authOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${id_token}`,
      },
    }

    const filterQuery = stringifyFilter(filter)
    const uri = `/radgivningsarende/${propertyNameInPlural.toLowerCase()}/suggestions?searchText=${propertySearchText}&${filterQuery}`
    const url = baseURL(uri, '1.0')
    fetch(url, authOptions).then(data => data.json()).then((data: string[] | null) => {
      callback(
        data?.map((value: string) => ({
          value,
          label: value,
        }))
      )
    })
  }
