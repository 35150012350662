import { ICaseListStartklar, IHandlaggare } from '@local/src/Views/Startklar/Startklar.types'
import { sortAscendingArrayOfObjectsByKey as sortAsc } from '@local/src/Utils/helpers'

import { baseApi } from '../baseApi'

export interface StartklarFormApiValues {
  beskrivning: string
  ejRedo: boolean
  engelsktalande: boolean
  klientId: string
  pratatOmTrrSe: boolean
  klientenSvarade: boolean
  sokerJobb: boolean
}

interface StartklarFormRequest {
  arendeId: string
  klientId: string
  body: StartklarFormApiValues
}

interface UpdateNoOfUppringningsforsokRequest {
  arendeId: string
  number: number
}

interface AssignHandlaggareRequest {
  arendeId: string
  handlaggare: {
    id: string
    fornamn: string
    efternamn: string
  }
}

export const startklarApi = baseApi.enhanceEndpoints({ addTagTypes: ['StartklarArenden'] }).injectEndpoints({
  endpoints: (builder) => ({
    getStartklarList: builder.query<ICaseListStartklar, string>({
      query: (queryParams) => `/v1.0/radgivning/arenden/startklar/${queryParams ? queryParams : ''}`,
      providesTags: (result) =>
        result
          ? [
              ...result.arenden.map((item) => ({ type: 'StartklarArenden', id: item.id } as const)),
              { type: 'StartklarArenden', id: 'LIST' },
            ]
          : [{ type: 'StartklarArenden', id: 'LIST' }],
    }),
    getStartklarInitialFacets: builder.query<ICaseListStartklar, void>({
      query: () => '/v1.0/radgivning/arenden/startklar?subStatusar=Nytt',
      keepUnusedDataFor: 0,
    }),
    getHandlaggare: builder.query<IHandlaggare[], string>({
      query: () => `/v1.0/radgivning/arenden/handlaggare`,
      transformResponse: (response: IHandlaggare[], _, args) => {
        const sortedResponse = sortAsc('fornamn', response) as IHandlaggare[]
        const indexOfCurrentRadgivare = sortedResponse.findIndex((obj) => obj.id === args)
        if (indexOfCurrentRadgivare !== -1) {
          const currentRadgivare = sortedResponse.splice(indexOfCurrentRadgivare, 1)[0]
          sortedResponse.unshift(currentRadgivare)
        }
        return sortedResponse
      }
    }),
    updateNoOfUppringningsforsok: builder.mutation<void, UpdateNoOfUppringningsforsokRequest>({
      query: (body) => ({
        url: `/v1.0/radgivning/arenden/${body.arendeId}/antaluppringningsforsok`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: { AntalUppringningsforsok: body.number },
      }),
      onQueryStarted: (args, api) => {
        const queryCache = startklarApi.util.selectInvalidatedBy(api.getState(), ['StartklarArenden'])
        queryCache.forEach((cache) => {
          const patchResult = api.dispatch(
            startklarApi.util.updateQueryData(
              'getStartklarList',
              cache.originalArgs as string,
              (data) => {
                /* update number of calls in all caches where arende exists */
                const index = data.arenden.findIndex((item) => item.id === args.arendeId)
                if (data.arenden[index]) {
                  data.arenden[index].antalUppringningsforsok = args.number
                }
                return data
              }
            )
          )
          api.queryFulfilled.catch(patchResult.undo)
        })
      },
    }),
    assignHandlaggare: builder.mutation<void, AssignHandlaggareRequest>({
      query: (body) => ({
        url: `/v1.0/radgivning/arenden/${body.arendeId}/handlaggare`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: body.handlaggare,
      }),
      onQueryStarted: (args, api) => {
        const queryCache = startklarApi.util.selectInvalidatedBy(api.getState(), ['StartklarArenden'])
        queryCache.forEach((cache) => {
          const patchResult = api.dispatch(
            startklarApi.util.updateQueryData(
              'getStartklarList',
              cache.originalArgs as string, // to correctly update the current cache (depending on queryParams)
              (data) => {
                /* add the handlaggare in all caches where arende exists */
                const index = data.arenden.findIndex((item) => item.id === args.arendeId)
                if (data.arenden[index]) {
                  data.arenden[index].handlaggare = args.handlaggare
                }
                return data
              }
            )
          )
          api.queryFulfilled.catch(patchResult.undo)
        })
      },
    }),
    unassignHandlaggare: builder.mutation<void, string>({
      query: (arendeId) => ({
        url: `/v1.0/radgivning/arenden/${arendeId}/handlaggare`,
        method: 'DELETE',
      }),
      onQueryStarted: (args, api) => {
        const queryCache = startklarApi.util.selectInvalidatedBy(api.getState(), ['StartklarArenden'])
        queryCache.forEach((cache) => {
          const patchResult = api.dispatch(
            startklarApi.util.updateQueryData(
              'getStartklarList',
              cache.originalArgs as string, // to correctly update the current cache (depending on queryParams)
              (data) => {
                /* remove the handlaggare from all caches */
                const index = data.arenden.findIndex((item) => item.id === args)
                if (data.arenden[index]) {
                  data.arenden[index].handlaggare = null
                }
                return data
              }
            )
          )
          api.queryFulfilled.catch(patchResult.undo)
        })
      },
    }),
    postStartklarForm: builder.mutation<void, StartklarFormRequest>({
      query: (body) => ({
        url: `/v1.0/radgivning/arenden/${body.arendeId}/startklar`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: {...body.body, arendeId: body.arendeId, klientId: body.klientId },
      }),
      onQueryStarted: (args, api) => {
        const queryCache = startklarApi.util.selectInvalidatedBy(api.getState(), ['StartklarArenden'])
        queryCache.forEach((cache) => {
          const patchResult = api.dispatch(
            startklarApi.util.updateQueryData(
              'getStartklarList',
              cache.originalArgs as string, // to correctly update the current cache (depending on queryParams)
              (data) => {
                /* remove the arende from all cached lists */
                const index = data.arenden.findIndex((item) => item.id === args.arendeId)
                if (data.arenden[index]) {
                  data.arenden.splice(index, 1)
                  data.totalHits -= 1
                }
                return data
              }
            )
          )
          api.queryFulfilled.catch(patchResult.undo)
        })
      },
    })
  }),
})

export const {
  useGetStartklarListQuery,
  useGetStartklarInitialFacetsQuery,
  useGetHandlaggareQuery,

  useAssignHandlaggareMutation,
  useUnassignHandlaggareMutation,
  useUpdateNoOfUppringningsforsokMutation,
  usePostStartklarFormMutation,
} = startklarApi