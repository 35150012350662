import React from 'react'
import { Icons } from '@trr/common-component-library'
import Filter from '@local/Blocks/Filter'
import { IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import FilterTags from '@local/Components/FilterTags'
import SidebarButton from '@local/Components/SidebarButton'
import classnames from 'classnames'

import StartklarFilter from '../StartklarFilter'

import s from './StartklarFilterBar.module.scss'

interface StartklarFilterBarProps {
  toggleFilter(): void
  visible: boolean
  filterTags?: IFilterTag[]
  resetFilter(): void
  removeFilterParameterValuePair(tag: IFilterTag): void
}

export const StartklarFilterBar = ({
  toggleFilter,
  visible,
  filterTags,
  resetFilter,
  removeFilterParameterValuePair,
}: StartklarFilterBarProps): JSX.Element => (
  <div className={classnames(s.isStickyBar, visible || s.Shrink)}>
    <div className={classnames(s.FilterBarTop, visible ? s.BorderBottom : '')}>
      <SidebarButton
        name={visible && 'Filter'}
        onClick={toggleFilter}
        toggleIcon={visible && <Icons.CloseIcon size={16} color={s.textColor} />}
        sidebarPosition="right"
        icon={<Icons.FilterIcon size={24} color={s.textColor} />}
      />
    </div>
    <Filter visible={visible}>
      <>
        {filterTags.length !== 0 && (
          <div className={s.FilterWrapper}>
            <FilterTags
              filterTags={filterTags}
              resetFilter={resetFilter}
              removeFilterParameterValuePair={removeFilterParameterValuePair}
            />
          </div>
        )}
        <StartklarFilter />
      </>
    </Filter>
  </div>
)

export default StartklarFilterBar
