import React from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'

import { FordelningsRadgivare, Team } from '../Fordelning.types'

interface RadgivarStatistikProps {
  allTeams: Team[]
  radgivareList: FordelningsRadgivare[]
}

const RadgivarStatistik = ({
  allTeams,
  radgivareList,
}: RadgivarStatistikProps) => {
  return (
    <Paper sx={{ backgroundColor: 'surface.blue', maxWidth: '100%', p: 2 }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">Rådgivare</Typography>
        {allTeams && (
          <Box width="131px">
            <Dropdown
              options={allTeams}
              getOptionLabel={(team: Team) => team.namn}
              getOptionValue={(team: Team) => team.id}
              value={undefined}
              label="Välj team"
              size="small"
              onChange={(event) => event.target.value}
            />
          </Box>
        )}
      </Box>
      <Table size="small" sx={{ maxWidth: '100%', overflow: 'hidden' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={{ textAlign: 'center' }}>
              <Typography variant="caption">In idag</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              <Typography variant="caption">In +/-</Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              <Typography variant="caption">Aktuella</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {radgivareList?.map((radgivare) => (
            <TableRow key={radgivare.id}>
              <TableCell sx={{ maxWidth: '144px' }}>
                <Typography variant="body2">{`${radgivare.fornamn} ${radgivare.efternamn}`}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {radgivare.radgivarStatistik.idag}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {radgivare.radgivarStatistik.inPlusMinus}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {radgivare.radgivarStatistik.aktuella}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default RadgivarStatistik
