import React from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Chip } from '@mui/material'

interface RadgivarChipProps {
  radgivareNamn: string
  className?: string
  testSelector?: string
}

export const RadgivarChip = ({
  radgivareNamn,
  className,
  testSelector,
 }: RadgivarChipProps) =>
  radgivareNamn ? <Chip label={radgivareNamn} data-testid={testSelector} className={className} icon={<AccountCircleOutlinedIcon />} /> : null
