import React from 'react'
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { SwapVert } from '@mui/icons-material'
import { ISelectOption } from '@local/src/Common.types'

interface OrderByProps {
  selectOptions: ISelectOption[]
  value: ISelectOption
  onChange: (option: ISelectOption) => void
}

const OrderBy = ({
  selectOptions,
  value,
  onChange
}: OrderByProps) => {

  const handleChange = (event: SelectChangeEvent<string>) => {
    const chosen = selectOptions.find(item => item.value === event.target.value)
    chosen !== undefined && onChange(chosen)
  }

  const getLabelFromValue = (value: string) => {
    return selectOptions.find(item => item.value === value).label
  }

  return (
    <Select
      id='order-by'
      variant='standard'
      disableUnderline
      IconComponent={SwapVert}
      onChange={handleChange}
      value={value.value}
      defaultValue={value.value}
      MenuProps={{ PaperProps: { sx: { maxHeight: 240 } } }}
      renderValue={(selected: string) => (
        <Typography variant='body2' padding={1}>{getLabelFromValue(selected)}</Typography>
      )}
    >
      {selectOptions.map((item: ISelectOption, index: number) => (
        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
      ))}
    </Select>
  )
}

export default OrderBy
