import React, { useState } from 'react'
import { Collapse, Divider, ListItemButton, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

interface PanelProps {
  title: string
  children: React.ReactNode
  isInUse: boolean
  defaultOpen?: boolean
}

const Panel = ({ title, children, isInUse, defaultOpen = false }: PanelProps): JSX.Element => {

  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <>
      <ListItemButton onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={title} primaryTypographyProps={{ fontWeight: isInUse || isOpen ? 'bold' : 'inherit' }} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit sx={{ mr: 0.5, mb: 1 }}>
        {children}
      </Collapse>
      <Divider />
    </>
  )
}

export default Panel
