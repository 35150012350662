import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'
import { filterId } from '@local/Utils/Filters'
import { arendenEmptyFilterState, initialState } from '@local/src/Views/Arenden/Arenden.reducers'
import { IDinaArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'

import {
  fetchMyCaseListInitiate,
  fetchMyCaseListFailure,
  fetchMyCaseListSuccess,
  fetchMyCaseListFacetsInitiate,
  fetchMyCaseListFacetsFailure,
  fetchMyCaseListFacetsSuccess,
  fetchInitialMyCaseListFacetsInitiate,
  fetchInitialMyCaseListFacetsFailure,
  fetchInitialMyCaseListFacetsSuccess,
} from './DinaArenden.actions'

export const dinaArendenInitialFilterState: IDinaArendenFilterState = {
  ...arendenEmptyFilterState,
  saknarInbokatMote: false,
  arendeTyper: 'OmstallningsArende',
  subStatusar: ['NyttForMig', 'KlientEjKontaktad', 'Aktuellt'],
}
export const dinaArendenEmptyFilterState: IDinaArendenFilterState = {
  ...dinaArendenInitialFilterState,
  subStatusar: [],
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const dinaArendenReducer = handleActions(
  {
    [fetchInitialMyCaseListFacetsInitiate.toString()]: (state) => ({
      ...state,
      fetchingInitialCaseListFacetsState: FetchingState.LOADING,
    }),
    [fetchInitialMyCaseListFacetsFailure.toString()]: (state) => ({
      ...state,
      fetchingInitialCaseListFacetsState: FetchingState.ERROR,
    }),
    [fetchInitialMyCaseListFacetsSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      fetchingInitialCaseListFacetsState: FetchingState.SUCCESS,
      subStatusFacets: payload.facetsResponse.facets,
    }),
    [fetchMyCaseListFacetsInitiate.toString()]: (state) => ({
      ...state,
      fetchingCaseListFacetsState: FetchingState.LOADING,
    }),
    [fetchMyCaseListFacetsFailure.toString()]: (state) => ({
      ...state,
      fetchingCaseListFacetsState: FetchingState.ERROR,
    }),
    [fetchMyCaseListFacetsSuccess.toString()]: (state, { payload }: any) => ({
      ...state,
      fetchingCaseListFacetsState: FetchingState.SUCCESS,
      facets: payload.facetsResponse.facets,
      facetedHits: payload.facetsResponse.totalHits,
    }),
    [fetchMyCaseListInitiate.toString()]: (state) => ({
      ...state,
      fetchingCaseListState: FetchingState.LOADING,
    }),
    [fetchMyCaseListFailure.toString()]: (state) => ({
      ...state,
      fetchingCaseListState: FetchingState.ERROR,
    }),
    [fetchMyCaseListSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingCaseListState: FetchingState.SUCCESS,
      caseObject: {
        ...action.payload.caseObject,
        arenden: filterId(action.payload.caseObject.arenden, state.pendingArendeId),
      },
    }),
  },
  initialState
)
/* eslint-enable @typescript-eslint/no-explicit-any */

export default dinaArendenReducer
