import { Box } from '@mui/material'
import React from 'react'
import ClearFilterButton from '@local/src/Components/ClearFilterButton/ClearFilterButton'

import AssigneeChip from '../Chips/AssigneeChip'
import CategoryChip from '../Chips/CategoryChip'
import StatusChip from '../Chips/StatusChip'
import { useArbetsuppgiftFilterContext } from '../ArbetsuppgiftFilterContext'
import useIsArbetsuppgiftFilterActive from '../Hooks/useIsArbetsuppgiftFilterActive'

const ArbetsuppgiftDesktopFilter = () => {
  const {
    filters,
    advisors,
    initialFacets,
    setFilter: handleFilter,
    clearFilter,
  } = useArbetsuppgiftFilterContext()
  const filtersActive = useIsArbetsuppgiftFilterActive(filters)

  return (
    <Box display="flex" gap={3}>
      <Box display="flex" gap={1}>
        <AssigneeChip
          filter={filters}
          advisors={advisors}
          selectedAssignees={filters.ownerIds}
          onChange={handleFilter}
          onClear={clearFilter}
        />
        <StatusChip selectedStatuses={filters.statusar} filter={filters} onChange={handleFilter} />
        <CategoryChip filter={filters} initialCategories={initialFacets?.Category} selectedCategories={filters.uppgiftsTyper} onChange={handleFilter} />
      </Box>
      <ClearFilterButton clearFilter={clearFilter} filtersActive={filtersActive} sx={{ p: 0, px: 1.5 }} />
    </Box>
  )
}

export default ArbetsuppgiftDesktopFilter
