import { Box, Typography } from '@mui/material'
import React from 'react'
import { useQuery } from '@tanstack/react-query'

import StudieAnmalanListItem from '../StudieAnmalanListItem/StudieAnmalanListItem'
import { PageWrapper } from '../../../Components/PageWrapper'
import { IStudieAnmalanListItemGetModel, MapToView } from '../types/StudieAnmalanListGetModel.types'
import { sortStudieAnmalanListItemByBookingDate } from '../utils/helpers'
import { useEpiString } from '../../../Context/EpiStringContext'
import { getStudieAnmalanListByCaseId } from '../StudieAnmalanApi'

interface IStudieAnmalanListProps {
  arendeId: string
}

const StudieAnmalanList: React.FC<React.PropsWithChildren<IStudieAnmalanListProps>> = ({ arendeId }) => {
  const { data, isError, isFetching, refetch } = useQuery(
    ['getStudieAnmalanList', arendeId],
    () => getStudieAnmalanListByCaseId(arendeId),
    {
      placeholderData: [],
    }
  )
  const epi = useEpiString()
  return (
    <div>
      <Typography sx={{ fontSize: '22px' }} variant="h3">
        {epi('common.privateEducation')}
      </Typography>
      <Box sx={{ my: 2, mx: 0 }}>
        <PageWrapper
          isLoading={isFetching}
          errorLoadingData={isError}
          errorText={epi('common.errorFetching')}
          testId="studieAnmalan-list-wrapper"
        >
          {data?.length > 0 ? (
            <>
              {data.sort(sortStudieAnmalanListItemByBookingDate).map((anmalan: IStudieAnmalanListItemGetModel) => (
                <StudieAnmalanListItem refetch={refetch} key={anmalan.id} studieAnmalan={MapToView(anmalan)} />
              ))}
            </>
          ) : (
            <Typography data-testid="studie-anmalan-no-result-header" variant="body1">
              {epi('common.noBookings')}
            </Typography>
          )}
        </PageWrapper>
      </Box>
    </div>
  )
}

export default StudieAnmalanList
