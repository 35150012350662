import React from 'react'
import HTMLMapper from '@local/src/Utils/HTMLMapper'
import { Alert as MuiAlert, AlertProps, styled } from '@mui/material'

const Alert = styled((props: AlertProps) => (
  <MuiAlert {...props} />
))(({ theme }) => ({
  order: 3,
  /* Custom styles inside HTMLMapper */
  '& .MuiAlert-message > div > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

interface ArendeInfoTextProps {
  content: string
}

const ArendeInfoText = ({ content }: ArendeInfoTextProps) => (
  <Alert severity="info" variant='outlined' sx={{ borderRadius: 2 }}>
    <HTMLMapper body={content} wrapper='div' />
  </Alert>
)

export default ArendeInfoText
