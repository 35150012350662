import { FordelningsArende } from '@local/src/Views/Fordelning/Fordelning.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v1.0/fordelning'

export const fordelningApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFordelningsArenden: builder.query<FordelningsArende[], string>({
      query: (teamId) => `${baseUrl}/fordelningsarenden?teamId=${teamId}`
    })
  })
})

export const {
  useGetFordelningsArendenQuery,
} = fordelningApi