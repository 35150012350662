import React, { useState, useMemo, SyntheticEvent } from 'react'
import { Typography, TextField, Popover, Box, ButtonBase, Chip } from '@mui/material'
import { AccountCircleOutlined, AddCircle, Close } from '@mui/icons-material'
import NoResult from '@local/Components/NoResult'
import { filterOnName } from '@local/Utils/smallfuncs'
import { IAdvisor } from '@local/Views/AllocateCases/AllocateCases.types'
import { useUser } from '@trr/app-shell-data'
import { ExtendedUser } from '@local/src/App.types'

interface IPickAdvisor {
  advisorList: IAdvisor[]
  choose(advisor: IAdvisor): void
  chosenAdvisor?: IAdvisor
  remove?(advisorId?: string): void
  handlaggare?: boolean
}

const PickAdvisor = ({ advisorList, choose, chosenAdvisor, remove, handlaggare }: IPickAdvisor): JSX.Element => {
  const userId = (useUser() as ExtendedUser).mdid
  const [searchValue, setSearchValue] = useState<string>('')
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null)

  const nonChosenAdvisorList = useMemo(
    () => (chosenAdvisor ? advisorList.filter((advisor: IAdvisor) => advisor.id !== chosenAdvisor.id) : advisorList),
    [advisorList, chosenAdvisor]
  )

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
    setSearchValue('')
  }

  const open = Boolean(anchorElement)
  const id = open ? 'advisor-popover' : undefined

  const filteredAdvisorList = filterOnName(nonChosenAdvisorList, searchValue)
  const updateSearchValue = (event: SyntheticEvent) => {
    const eventTarget = event.target as HTMLInputElement
    setSearchValue(eventTarget.value)
  }

  const pickAdvisor = (advisorId: string) => {
    choose(advisorList.find(({ id }: Partial<IAdvisor>) => id === advisorId))
    setAnchorElement(null)
  }

  const removeAdvisor = (advisorId: string) => remove(advisorId)

  const advisorName = `${chosenAdvisor?.fornamn} ${chosenAdvisor?.efternamn}`
  const handlaggareRadgivare = handlaggare ? 'handläggare' : 'rådgivare'

  const indexOfCurrentRadgivare = advisorList.findIndex((obj) => obj.id === userId)
  if (indexOfCurrentRadgivare !== -1) {
    const currentRadgivare = advisorList.splice(indexOfCurrentRadgivare, 1)[0]
    advisorList.unshift(currentRadgivare)
  }

  return (
    <div data-testid="pick-advisor">
      {chosenAdvisor ? (
        <Chip
          onDelete={() => {
            removeAdvisor(chosenAdvisor.id)
            handleClose()
          }}
          icon={<AccountCircleOutlined />}
          label={advisorName}
          variant="filled"
        />
      ) : (
        <Chip icon={<AddCircle />} label={`Välj ${handlaggareRadgivare}`} onClick={handleClick} clickable variant='outlined' />
      )}
      <Popover
        id={id}
        role={'dialog'}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300, maxHeight: 350, padding: 2, display: 'flex', flexDirection: 'column' }} onClick={(event: React.MouseEvent) => event.stopPropagation()}>
          <Box sx={{ position: 'sticky', top: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h5" sx={{ fontSize: 18 }}>
                Välj {handlaggareRadgivare}
              </Typography>
              <span
                aria-label="Stäng väljare"
                role="button"
                tabIndex={0}
                onClick={handleClose}
                onKeyDown={(e) => e.key === 'Enter' && handleClose()}
              >
                <Close sx={{ alignSelf: 'flex-start' }} />
              </span>
            </Box>
            <TextField
              label={`Sök ${handlaggareRadgivare}`}
              value={searchValue}
              // we really do want to autoFocus the user, hence the eslint-disable.
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onFocus={(event: React.FocusEvent) => {event.stopPropagation()}}
              onChange={updateSearchValue}
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
              inputProps={{ 'data-testid': 'search-advisor-textfield' }}
            />
          </Box>
          <Box data-testid="rådgivare-lista" sx={{ overflow: 'auto' }}>
            {filteredAdvisorList.length === 0 && <NoResult>Inga resultat</NoResult>}
            {chosenAdvisor && (
              <Chip onDelete={() => removeAdvisor(chosenAdvisor.id)} icon={<AccountCircleOutlined />} label={advisorName} />
            )}
            {filteredAdvisorList.map(({ id: fid, fornamn, efternamn }: IAdvisor) => (
              <ButtonBase
                data-testid='pick-advisor-button'
                onClick={() => {pickAdvisor(fid)}}
                key={fid}
                sx={{
                  width: '100%',
                  padding: 1,
                  '&:hover': {
                    backgroundColor: 'surface.grey',
                  },
                }}
              >
                <Box sx={{ display: 'flex', width: '100%', gap: 1, alignItems: 'center' }}>
                  <AccountCircleOutlined sx={{ fontSize: 28 }} />
                  <Typography sx={{ fontSize: 18, textAlign: 'left' }}>{`${fornamn} ${efternamn}`}</Typography>
                </Box>
              </ButtonBase>
            ))}
          </Box>
        </Box>
      </Popover>
    </div>
  )
}

export default PickAdvisor
