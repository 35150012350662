import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { kompetensstodAvslutsInfoSelector, inbokadeMotenSelector } from '@local/src/Views/Case/Case.selectors'
import { formatDateFromDateTime as formatDate } from '@local/src/Utils/DateTime'
import DateWrapper from '@local/src/Components/DateWrapper'
import { Box, Button, Card, ListItemText, Typography } from '@mui/material'
import { isAvslutatArende } from '@local/src/Utils/SubStatus'
import { avslutaArendeStateSelector } from '@local/src/Views/Case/Avslut/Avslut.selectors'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'

import { avslutaKompetensstodsArende } from '../../Avslut.actions'

export interface AvslutaKompetensstodsArendeProps {
  arendeId: string
  subStatus: string
}

const AvslutaKompetensstodsArende = ({
  arendeId,
  subStatus,
}: AvslutaKompetensstodsArendeProps): JSX.Element => {

  const dispatch = useDispatch()
  const avslutsInfo = useSelector(kompetensstodAvslutsInfoSelector)
  const inbokadeMoten = useSelector(inbokadeMotenSelector)
  const fetchingState = useSelector(avslutaArendeStateSelector)
  const isClosed = isAvslutatArende(subStatus)

  const closeCase = () => {
    dispatch(avslutaKompetensstodsArende(arendeId))
  }

  if (fetchingState === FetchingState.LOADING) {
    return <Loading />
  }

  return (
    <Box mt={4} mx={{xs: 2, md: 0}}>
      {isClosed ? (
        <>
          <Typography variant='h4'>Avslut</Typography>
          <Card variant='outlined' sx={{ p: 2, mt: 2 }}>
            <Typography variant='h6'>{avslutsInfo?.avslutskod}</Typography>
            <Box display='flex' flexDirection={{xs: 'column', sm: 'row'}} justifyContent='space-between'>
              <ListItemText
                primary='Avslutat av'
                secondary={avslutsInfo?.avslutadAv}
                sx={{flexGrow: 0}}
              />
              <ListItemText
                primary='Avslutsdatum'
                secondary={
                  avslutsInfo?.avslutsDatum && <DateWrapper>{formatDate(avslutsInfo?.avslutsDatum)}</DateWrapper>
                }
                sx={{flexGrow: 0}}
              />
            </Box>
          </Card>
        </>
      ) : (
        <>
          {inbokadeMoten?.length > 0 ? (
            <Typography variant='body1'>Det går inte att avsluta ärendet eftersom omställningskunden har ett inbokat möte.</Typography>
          ) : (
            <>
              <Typography variant='body1' mb={3}>Observera att ett ärende med en pågående studieansökan inte ska avslutas.</Typography>
              <Button size='large' onClick={closeCase}>Avsluta ärendet</Button>
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default AvslutaKompetensstodsArende
