import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'
import { Box, Button, Stack, Typography } from '@mui/material'
import { DateValidationError, DesktopDatePicker, PickerChangeHandlerContext } from '@mui/x-date-pickers'

import { AvslutaArendeWithDateRequest, Avslutskod } from '../../../Avslut.types'
import { avslutaArendeStateSelector } from '../../../Avslut.selectors'
import HandleActivities from '../../HandleActivities/HandleActivities'
import { avslutaArendeWithDate } from '../../../Avslut.actions'

interface AvslutWithDateProps {
  arendeId: string
  avslutskod: Avslutskod
  title: string
}

const AvslutWithDate = ({
  arendeId,
  avslutskod,
  title,
}: AvslutWithDateProps) => {

  const dispatch = useDispatch()
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)
  const [avslutsdatum, setAvslutsdatum] = useState<Date>(null)
  const [dateHasError, setDateHasError] = useState(false)
  const [activitiesHandled, setActivitiesHandled] = useState(false)
  const [activitiesHasError, setActivitiesHasError] = useState(false)

  if (avslutaArendeState === FetchingState.LOADING) {
    return <Loading />
  }

  const handleSubmit = () => {
    setDateHasError(!avslutsdatum)
    setActivitiesHasError(activitiesHandled === false)
    if (activitiesHandled && avslutsdatum) {
      const request: AvslutaArendeWithDateRequest = {
        arendeId: arendeId,
        radgivningAvslutskod: avslutskod,
        radgivningAvslutsdatum: avslutsdatum.toISOString(),
      }
      dispatch(avslutaArendeWithDate(request))
    }
  }

  const handleChangeDate = (value: Date, context: PickerChangeHandlerContext<DateValidationError>) => {
    setAvslutsdatum(value)
    setDateHasError(context.validationError !== null)
  }

  return (
    <Stack spacing={5}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant='h4'>{title}</Typography>
        <Typography variant='body1'>Beräkningen av omställningskundens kvarvarande tid sker från det datum du anger.</Typography>
        <DesktopDatePicker
          label='Avslutsdatum'
          disableFuture
          onChange={handleChangeDate}
          sx={{ maxWidth: '220px' }}
          slotProps={{
            textField: {
              name: 'startDatum',
              error: dateHasError,
              helperText: dateHasError && 'Avslutsdatum måste anges',
            }
          }}
        />
      </Box>
      <HandleActivities setActivitiesHandled={setActivitiesHandled} showErrorMessage={activitiesHasError} />
      <Button onClick={handleSubmit}>Avsluta ärendet</Button>
    </Stack>
  )

}

export default AvslutWithDate