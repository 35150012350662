import { Box } from '@mui/material'
import React from 'react'
import ClearFilterButton from '@local/src/Components/ClearFilterButton/ClearFilterButton'

import { useStartklarFilterContext } from '../StartklarFilterContext'
import ForetagFilter from '../Filters/ForetagFilter'
import ArbetsmarknadsomradeFilter from '../Filters/ArbetsmarknadsomradeFilter'
import RadgivareFilter from '../Filters/RadgivareFilter'

const StartklarDesktopFilter = () => {

  const {
    filters,
    isFilterActive,
    handlaggareList,
    initialFacets,
    setFilter: handleFilter,
    clearFilter,
    clearHandlaggareFilter,
  } = useStartklarFilterContext()

  const setEjTilldeladFilter = () => {
    filters.ejTilldeladHandlaggare === 'true'
    ? handleFilter('ejTilldeladHandlaggare', '')
    : handleFilter('ejTilldeladHandlaggare', 'true')
  }

  return (
    <Box display="flex" gap={1} alignItems='center'>
      <RadgivareFilter
        chosenRadgivare={filters.handlaggareIdn}
        radgivareList={handlaggareList}
        clearFilter={clearHandlaggareFilter}
        count={filters.handlaggareIdn.length + (filters.ejTilldeladHandlaggare === 'true' ? 1 : 0)}
        isMobile={false}
        setFilter={handleFilter}
        enableNotSetOption
        notSetChecked={filters.ejTilldeladHandlaggare === 'true'}
        notSetOptionClicked={() => setEjTilldeladFilter()}
      />
      <ArbetsmarknadsomradeFilter
        count={filters.arbetsmarknadsomraden.length}
        availableValues={initialFacets?.facets.ArbetsmarknadsomradeNamn}
        chosenValues={filters.arbetsmarknadsomraden}
        setFilter={handleFilter}
        isMobile={false}
      />
      <ForetagFilter
        count={filters.foretagNamn.length}
        filterState={filters}
        value={filters.foretagNamn}
        setFilter={handleFilter}
        isMobile={false}
      />
      <ClearFilterButton clearFilter={clearFilter} filtersActive={isFilterActive} />
    </Box>
  )
}

export default StartklarDesktopFilter