import { IAdvisor } from '@local/src/Views/AllocateCases/AllocateCases.types'
import { createContext, useContext } from 'react'

import { ArbetsuppgiftFacets, ArbetsuppgiftFilter } from '../types'

export interface ArbetsuppgiftFilterContextState {
  filters: Partial<ArbetsuppgiftFilter>
  advisors: IAdvisor[]
  initialFacets: ArbetsuppgiftFacets
  setFilter: (key: keyof ArbetsuppgiftFilter, values: string | string[]) => void
  clearFilter: () => void
}

const ArbetsuppgiftFilterContext = createContext<ArbetsuppgiftFilterContextState | null>(null)

export const useArbetsuppgiftFilterContext = (): ArbetsuppgiftFilterContextState => {
  const context = useContext(ArbetsuppgiftFilterContext)
  if (context === null) {
    console.error('useArbetsuppgiftContext must be used within a FilterContextProvider')
  }
  return context
}

export default ArbetsuppgiftFilterContext
