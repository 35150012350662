import Modal from '@local/src/Components/Modal/Modal'
import { Box, Button, Typography } from '@mui/material'
import { DateValidationError, DesktopDatePicker, PickerChangeHandlerContext } from '@mui/x-date-pickers'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'

import { avslutaArendeWithReminder } from '../../../../Avslut.actions'
import { AvslutaArendeWithReminderRequest, Avslutskod } from '../../../../Avslut.types'
import { avslutaArendeStateSelector } from '../../../../Avslut.selectors'

interface AvslutaWithReminderProps {
  arendeId: string
  avslutsKod: Avslutskod
  avslutsdatum: Date
  losningStartdatum?: Date
  requireLosningStartdatum?: boolean
  hasError?: boolean
  onClick?: () => void
}

const AvslutaWithReminder = ({
  arendeId,
  avslutsKod,
  avslutsdatum,
  losningStartdatum = null,
  requireLosningStartdatum = false,
  hasError = false,
  onClick,
}: AvslutaWithReminderProps) => {

  const dispatch = useDispatch()
  const today = new Date()
  today.setDate(today.getDate() + 1)
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [reminderDate, setReminderDate] = useState<Date>(null)
  const [dateHasError, setDateHasError] = useState(false)

  const handleCancel = () => {
    setModalIsOpen(false)
    setReminderDate(null)
    setDateHasError(false)
  }

  const handleOpen = () => {
    if (!hasError) {
      setModalIsOpen(true)
    } else {
      onClick()
    }
  }

  const handleConfirm = () => {
    const request: AvslutaArendeWithReminderRequest = {
      arendeId,
      radgivningAvslutskod: avslutsKod,
      radgivningAvslutsdatum: avslutsdatum,
      besvaraAvslutsfragorDatum: reminderDate,
      losningStartdatum: requireLosningStartdatum ? losningStartdatum.toISOString() : null,
    }
    dispatch(avslutaArendeWithReminder(request))
  }

  const handleChangeDate = (value: Date, context: PickerChangeHandlerContext<DateValidationError>) => {
    setReminderDate(value)
    setDateHasError(context.validationError !== null)
  }

  return (
    <>
      <Button variant='text' onClick={handleOpen}>Avsluta och besvara senare</Button>
      <Modal
        label='Avsluta'
        open={modalIsOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        confirmText='Avsluta'
        disableSubmit={
          reminderDate === null ||
          avslutaArendeState === FetchingState.LOADING ||
          dateHasError
        }
      >
        { avslutaArendeState === FetchingState.LOADING ? (
          <Loading />
        ) : (
        <Box display='flex' flexDirection='column' gap={3}>
          <Typography variant='body1'>För att avsluta utan att fylla i formuläret bör du skapa en påminnelse för att svara på frågorna senare.</Typography>
          <DesktopDatePicker
            disableHighlightToday
            minDate={today}
            label='Påminnelsedatum'
            value={reminderDate}
            sx={{ maxWidth: '228px' }}
            onChange={handleChangeDate}
          />
        </Box>
        )}
      </Modal>
    </>
  )
}

export default AvslutaWithReminder