import React, { ReactElement } from 'react'

import IconWithText from '../IconWithText'

import s from './SidebarButton.module.scss'

export interface ISideBarButton {
  onClick: () => void
  icon?: ReactElement
  name: string
  toggleIcon?: ReactElement
  sidebarPosition?: 'left' | 'right'
}

// TODO: använd för alla filter?
const SidebarButton = ({ onClick, name, icon, toggleIcon, sidebarPosition }: ISideBarButton): JSX.Element => (
  <button className={s.SideBarButton} onClick={onClick}>
    {sidebarPosition === 'left' && toggleIcon}
    <IconWithText icon={icon}>{name}</IconWithText>
    {sidebarPosition === 'right' && toggleIcon}
  </button>
)

export default SidebarButton
