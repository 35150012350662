import { Box, Stack, Typography, Chip } from '@mui/material'
import React from 'react'
import { useQuery } from '@tanstack/react-query'

import { useEpiString } from '../../../../../Context/EpiStringContext'
import { IStudieAnmalanStatuses } from '../../../types/IStudieAnmalanStatuses'
import { IAdvisorDecision } from '../../../types/IAdvisorDecision'
import { getActiveAdvisors } from '../../../StudieAnmalanApi'

interface IStudieAnmalanStatusesProps {
  statuses: IStudieAnmalanStatuses
  advisorDecision?: IAdvisorDecision
}
interface IStatus {
  isSet: boolean
  label: string
  color: 'success' | 'error' | 'info'
  advisorDecision?: IAdvisorDecision
}
const StudieAnmalanStatuses: React.FC<IStudieAnmalanStatusesProps> = ({ statuses, advisorDecision }) => {
  const epi = useEpiString()
  const supplier: { header: string; statuses: IStatus[] } = {
    header: epi('common.InstituteStatus'),
    statuses: [
      { isSet: statuses.isAcceptedBySupplier, label: epi('common.Booked'), color: 'success' },
      { isSet: statuses.isCancelledBySupplier, label: epi('common.Cancelled'), color: 'error' },
      { isSet: statuses.isRejectedBySupplier, label: epi('common.Rejected'), color: 'error' },
      { isSet: statuses.isWaitingForSupplierConfirmation, label: epi('common.noInformation'), color: 'info' },
    ],
  }
  const advisor: { header: string; statuses: IStatus[] } = {
    header: epi('common.AdvisorDecision'),
    statuses: [
      { isSet: statuses.isApprovedByAdvisor, label: epi('common.Approved'), color: 'success', advisorDecision },
      { isSet: statuses.isRejectedByAdvisor, label: epi('common.Rejected'), color: 'error', advisorDecision },
      { isSet: statuses.isWaitingForAdvisorApproval, label: epi('common.waiting'), color: 'info' },
    ],
  }
  const client: {
    header: string
    statuses: IStatus[]
  } = {
    header: epi('common.HasClientCancelled'),
    statuses: [
      { isSet: statuses.isCancelledByClient, label: epi('common.YesOkayCancellation'), color: 'error' },
      { isSet: statuses.isLateCancelledByClient, label: epi('common.YesLateCancellation'), color: 'error' },
      { isSet: true, label: epi('common.No'), color: 'success' },
    ],
  }
  const { data } = useQuery(['activeAdvisors'], () => getActiveAdvisors())

  const decidedByAdvisor = data?.find((a) => a.id === advisorDecision?.decidedBy)
  return (
    <Stack flexWrap={'wrap'} sx={{ mt: 2, mb: 4 }} direction="row" gap={5}>
      {[advisor, supplier, client]
        .map((o) => ({ header: o.header, status: o.statuses.find((s) => s.isSet) }))
        .map(({ status, header }) => {
          if (status)
            return (
              <Box key={status.label}>
                <Typography variant="body1">{header}</Typography>
                <Chip variant={`status-${status.color}`} label={status.label} />
                {status.advisorDecision && decidedByAdvisor && (
                  <Stack direction="column">
                    <Typography variant="body2">
                      {advisorDecision.decidedAt?.toLocaleDateString()} {advisorDecision.decidedAt?.toLocaleTimeString()}
                    </Typography>
                    <Typography variant="body2">
                      {advisorDecision.decision ? 'Godkänd' : 'Avböjd'} av {decidedByAdvisor?.fornamn} {decidedByAdvisor?.efternamn}
                    </Typography>
                  </Stack>
                )}
              </Box>
            )
        })}
    </Stack>
  )
}

export default StudieAnmalanStatuses
