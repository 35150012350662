import React from 'react'
import { Box, ListItemText, Stack, Typography } from '@mui/material'
import { formatDateFromDateTime as formatDate } from '@local/src/Utils/DateTime'
import { OmfattningsbeslutStatus } from '@local/src/Views/Arenden/Arenden.types'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkWithIcon from '@local/src/Components/LinkWithIcon/LinkWithIcon'
import { orgCompanyLinkBuilder } from '@local/src/basename'
import { getCurrentEnvironment } from '@local/src/Utils/helpers'
import getConfig from '@local/src/Utils/ConfigService'
import { formatAmount } from '@local/src/Utils/smallfuncs'
import Personnummer from '@local/src/Views/Case/ArendeHeader/Details/Common/Personnummer/Personnummer'
import SetPreferredLanguage from '@local/src/Components/SetPreferredLanguage/SetPreferredLanguage'

import { ICaseDetails } from '../../../Case.types'
import ArendeMarkningar  from '../Common/ArendeMarkningar/ArendeMarkningar'

interface KompetensstodDetailsProps {
  arende: ICaseDetails
}

const KompetensstodDetails = ({
  arende,
}: KompetensstodDetailsProps): JSX.Element => {

  const isAttesterad = arende.omfattningsbeslutStatus === OmfattningsbeslutStatus.Attesterad
  const { klient, anstallningsArende } = arende
  const { team, anstallningsuppgifter, inkomstuppgifter } = anstallningsArende

  return (
    <Stack display='grid' gridTemplateColumns={{xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr'}}>
      <Typography variant='body1' fontWeight='bold' gridColumn={{xs: '1', sm: '1/-1'}}>Personinformation</Typography>
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Personnummer</Typography>}
        secondary={<Typography variant='body1' color='text.secondary'><Personnummer personnummer={klient.personnummer} alder={klient.alder} /></Typography>}
      />
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Team</Typography>}
        secondary={<Typography variant='body1' color='text.secondary'>{team?.namn ?? 'Inget team'}</Typography>}
      />
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Attestdatum</Typography>}
        secondary={<Typography variant='body1' color='text.secondary'>{isAttesterad ? formatDate(anstallningsArende.attesteringsDatum) : '-'}</Typography>}
      />
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Kommun</Typography>}
        secondary={<Typography variant='body1' color='text.secondary'>{klient.kommun}</Typography>}
      />
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Föredraget språk</Typography>}
        secondary={<SetPreferredLanguage disablePadding/>}
      />
      <Box display='flex' flexDirection='column' mb={{xs: 1.5, sm: 0}}
        sx={{
          gridColumnStart: {xs: 1, sm: 2, md: 3, lg: 4},
          gridRowStart: {xs: 'auto', sm: 2},
          gridRowEnd: {xs: 'auto', md: 5}
        }}
      >
        <ArendeMarkningar arendeId={arende.id} arendeMarkningar={arende.arendeMarkningar} />
      </Box>
      <Typography variant='body1' fontWeight='bold' gridColumn={{xs: '1', sm: '1/-1'}}>Anställningsuppgifter</Typography>
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Arbetsställe</Typography>}
        secondary={
          <LinkWithIcon
            icon={<LaunchIcon />}
            text={anstallningsuppgifter.foretag.namn}
            external
            typographyVariant='body1'
            href={orgCompanyLinkBuilder(getCurrentEnvironment(getConfig().API_URL), anstallningsuppgifter.foretag.id)}
          />
        }
      />
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Befattning</Typography>}
        secondary={<Typography variant='body1' color='text.secondary'>{anstallningsuppgifter.befattning}</Typography>}
      />
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Anställningsdatum</Typography>}
        secondary={<Typography variant='body1' color='text.secondary'>{formatDate(anstallningsuppgifter.anstallningsDatum) || 'Ej angivet'}</Typography>}
      />
      <ListItemText
        disableTypography
        primary={<Typography variant='subtitle1'>Totallön</Typography>}
        secondary={<Typography variant='body1' color='text.secondary'>{inkomstuppgifter.totalLon ? formatAmount(arende.anstallningsArende.inkomstuppgifter.totalLon) : 'Ej angivet'}</Typography>}
      />
    </Stack>
  )
}

export default KompetensstodDetails