import { formatDateFromDateTime } from "@local/src/Utils/DateTime"
import { Box, Typography } from "@mui/material"
import React from "react"

interface LatestContactProps {
  date: string
}

const LatestContact = ({
  date,
 }: LatestContactProps): JSX.Element => {
  return (
    <Box width='82px' display='grid' alignContent='center'>
      <Typography variant="body2">Kontaktad</Typography>
      <Typography variant="caption" color='text.secondary'>{date ? formatDateFromDateTime(date, true) : 'Ej kontaktad'}</Typography>
    </Box>
  )
}

export default LatestContact
