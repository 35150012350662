import { useGetFordelningsArendenQuery } from '@local/src/Api/Fordelning/fordelningApi'
import { useGetAktivaRadgivareQuery } from '@local/src/Api/Radgivare/radgivareApi'
import {
  useGetAllTeamsQuery,
  useGetMinaTeamsQuery,
} from '@local/src/Api/Team/teamApi'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Loading from '@local/src/Components/Loading/Loading'

import { Team } from './Fordelning.types'
import FordelningTable from './FordelningTable/FordelningTable'
import RadgivarStatistik from './RadgivarStatistik/RadgivarStatistik'

const Fordelning = () => {
  const [selectedTeam, setSelectedTeam] = useState<Team>(null)
  const { data: allTeams } = useGetAllTeamsQuery()
  const { data: minaTeam } = useGetMinaTeamsQuery()

  const { data: fordelningsArenden, isFetching: isFetchingFordelningsArenden } =
    useGetFordelningsArendenQuery(selectedTeam?.id, {
      skip: selectedTeam === null || selectedTeam === undefined,
    })
  const { data: radgivareList, isFetching: isFetchingRadgivareList } =
    useGetAktivaRadgivareQuery(selectedTeam?.id, {
      skip: selectedTeam === null || selectedTeam === undefined,
    })

  useEffect(() => {
    setSelectedTeam(minaTeam?.find((team) => team.default))
  }, [minaTeam])

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <FordelningTable
          arenden={fordelningsArenden}
          isFetching={isFetchingFordelningsArenden}
          selectedTeam={selectedTeam}
          teams={minaTeam}
          selectedTeamOnChange={(teamId) =>
            setSelectedTeam(minaTeam.find((team) => team.id === teamId))
          }
        />
      </Grid>
      <Grid item xs={3}>
        {isFetchingRadgivareList ? (
          <Loading />
        ) : (
          <RadgivarStatistik
            allTeams={allTeams}
            radgivareList={radgivareList}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Fordelning
