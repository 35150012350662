import { getCurrentEnvironment } from '@local/src/Utils/helpers'
import getConfig from '@local/src/Utils/ConfigService'

import { Avslutshandelse } from '../../../Avslut.types'
import { requireAvslutsfragor } from '../../AvslutBaseForm/AvslutBaseForm'

export const getLabelByValue = (options: { label: string; value: string }[], value: string): string[] => {
  return options.filter((item) => item.value === value).map((item) => item.label)
}

export const isMigratedBeforeGoLiveDate = (date: Date): boolean => {
  const PROD_GO_LIVE_DATE = new Date(2021, 5, 7)
  const env = getCurrentEnvironment(getConfig().API_URL)

  const envSpecificMigratedDate = (env: 'dev' | 'test' | 'prod') => (env === 'prod' ? PROD_GO_LIVE_DATE : new Date(2021, 4, 1))
  return date < envSpecificMigratedDate(env)
}

export const avslutskodRequiresAnswers = (avslutshandelse: Avslutshandelse): boolean =>
  requireAvslutsfragor.includes(avslutshandelse.radgivningAvslutskod)

export const isMigratedAndRequiresAnswers = (avslutshandelse: Avslutshandelse): boolean => {
  return isMigratedBeforeGoLiveDate(new Date(avslutshandelse.registreringsDatum)) && avslutskodRequiresAnswers(avslutshandelse)
}

export const getAvslutshandelseUrl = (locationPathname: string, ateroppnaGuid: string, queryParams: URLSearchParams) => {
  const newPath = `${locationPathname.endsWith('/') ? locationPathname : locationPathname + '/'}${ateroppnaGuid}?${queryParams.toString()}`
  return newPath
}

export const getAvslutBaseUrl = (locationPathname: string) => {
  const pathSegments = locationPathname.split('/')
  pathSegments.pop()
  return pathSegments.join('/')
}
