import { EArendenEnv } from '../../Views/Arenden/Arenden.types'

const deprecatedOrderBy = '["HasNastaMote desc","NastaMote asc"]'
const newOrderBy = '["NastaMote asc"]'

export default () => {
  for (const env in EArendenEnv) {
    const key = `${env}.orderBy`
    const orderByFilter = window.localStorage.getItem(key)

    if (orderByFilter === deprecatedOrderBy) {
      window.localStorage.setItem(key, newOrderBy)
    }
  }
}