import React, { useEffect, useState } from 'react'
import { useLazyGetFacetsForPropertyQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useToggleFilterOption } from '@local/src/Hooks'
import FilterAccordion from '@local/src/Components/FilterAccordion/FilterAccordion'

import StatusFilter from '../FilterTypes/StatusFilter'
import { useArbetsuppgiftFilterContext } from '../ArbetsuppgiftFilterContext'

interface StatusFilterAccordionProps {
  open: boolean
  onOpen: () => void
}

const StatusFilterAccordion = ({ open, onOpen }: StatusFilterAccordionProps) => {

  const [isOpen, setIsOpen] = useState(open)
  const { filters, setFilter: handleFilter } = useArbetsuppgiftFilterContext()

  const [fetchFacets, data] = useLazyGetFacetsForPropertyQuery()
  const statusKey = 'statusar'
  const { toggleFilterOption: toggleStatusFilter } = useToggleFilterOption(filters.statusar, statusKey, handleFilter)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (isOpen) {
      onOpen()
      fetchFacets({key: 'Status', filter: filters })
    }
  }, [isOpen])

  return (
    <FilterAccordion
      label='Status'
      open={isOpen}
      indicatorCount={filters.statusar?.length}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <StatusFilter
        handleCheckbox={toggleStatusFilter}
        selectedStatuses={filters.statusar}
        statuses={data?.data?.Status}
      />
    </FilterAccordion>
  )
}

export default StatusFilterAccordion
