/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { sortWith, prop, ascend } from 'ramda'
import { isMinMaxArray, isStringArray } from '@local/Utils/TypeGuards'
import { IArendenFilterState, IDinaArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'
import { ArendeTyp } from '@local/src/Views/Arenden/Arenden.types'
import { StartklarFilter } from '@local/src/Views/Startklar/Startklar.types'

const createLinkedSignal = (...signals: any) => {
  signals = signals.filter((s: any) => !!s)
  if (signals.length === 1) {
    return signals[0] // Debugging is easier when we can avoid wrapping
  }

  const controller = new AbortController()
  for (const signal of signals) {
    signal.addEventListener('abort', () => controller.abort())
  }
  return controller.signal
}

// https://www.codetinkerer.com/2019/01/14/cancel-async-requests-pattern.html
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const abortController = (wrappedFunc: any): any => {
  let currentAbort = new AbortController()

  return (...args: any) => {
    currentAbort.abort()
    currentAbort = new AbortController()

    const mySignal = currentAbort.signal

    const injectedFetch = (input: any, init = {} as any) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      fetch(input, {
        ...init,
        signal: createLinkedSignal(mySignal, init.signal),
      })
    return wrappedFunc(injectedFetch)(...args)
  }
}

export const getCurrentEnvironment = (stringThatContainsEnvironmentName: string): 'dev' | 'test' | 'prod' =>
  /dev/.exec(stringThatContainsEnvironmentName) ? 'dev' : /test/.exec(stringThatContainsEnvironmentName) ? 'test' : 'prod'

export const stringifyFilter = (filter: Record<string, string[] | [number, number] | string | boolean | number> | Partial<StartklarFilter>, excludeFilterKey?: string): string => {
  const queryStringSegments = []
  for (const [key, value] of Object.entries(filter)) {
    if (excludeFilterKey && excludeFilterKey.toString() === key) {
      continue
    }
    if (Array.isArray(value)) {
      if (isStringArray(value)) {
        value.forEach((item: string) => {
          queryStringSegments.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
        })
      }
      if (isMinMaxArray(value)) {
        queryStringSegments.push(`${encodeURIComponent(key)}Min=${encodeURIComponent(value[0])}`)
        queryStringSegments.push(`${encodeURIComponent(key)}Max=${encodeURIComponent(value[1])}`)
      }
    } else {
      if (value) {
        queryStringSegments.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    }
  }
  return queryStringSegments.join('&')
}

export const sortAscendingArrayOfObjectsByKey = (key: string, arr: Record<string, any>[]): Record<string, any>[] =>
  sortWith([ascend(prop(key))])(arr)

export const guidRegex = new RegExp('({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}', 'g')

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deepEqual = (obj1: any, obj2: any): boolean => JSON.stringify(obj1) === JSON.stringify(obj2)

export const buildFetchArendenQuery = (
  filter: IDinaArendenFilterState | IArendenFilterState,
  searchText: string,
  page: number,
  pageSize: number,
  orderBy: string[],
  radgivarId?: string
): string => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(filter)) {
    if (Array.isArray(value)) {
      if (isStringArray(value)) {
        value.forEach((item: string) => {
          params.append(key, item)
        })
      }
    } else {
      if (value) {
        params.append(key, String(value))
      }
    }
  }

  if (radgivarId) {
    params.append('radgivarId', radgivarId)
  }

  params.append('searchText', searchText)
  params.append('page', page.toString())

  for (const item of orderBy) {
    params.append('orderBy', item)
  }

  params.append('pageSize', pageSize.toString())

  return `?${params.toString()}`
}

export const removeTrailingSlash = (url: string): string => url.replace(/\/$/, '')

export const isLinkExternal = (href: string): boolean =>
  href.substr(0, 4) === 'http' || href.startsWith('mailto:') || href.startsWith('tel:')

export const isLinkAnchor = (href: string): boolean => href.startsWith('#')

export const getImageQuery = (imageUrl: string, width: number): string => {
  const searchParams = new URLSearchParams({
    width: width.toString(),
    dpr: window.devicePixelRatio.toString(),
  })

  return `${imageUrl}?${searchParams.toString()}`
}

export const buildArendenInitialFacetsQuery = (arendeTyp: ArendeTyp) => {
  const params = new URLSearchParams()

  const defaultStatuses = ['NyttForMig', 'Aktuellt', 'KlientEjKontaktad', 'Nytt', 'Fordelning', 'ForOmfordelning', 'Avslutat']

  defaultStatuses.forEach((status) => params.append('subStatusar', status))
  arendeTyp !== ArendeTyp.Alla && params.append('arendeTyper', arendeTyp)

  return `?${params.toString()}`
}

export const buildArendenFacetsQuery = (arendeTyp: ArendeTyp, subStatusar: string[]) => {
  const params = new URLSearchParams()

  subStatusar.forEach((status) => params.append('subStatusar', status))
  arendeTyp !== ArendeTyp.Alla && params.append('arendeTyper', arendeTyp)

  return `?${params.toString()}`
}

export const buildInitialDinaArendenFacetsQuery = (radgivarId: string) => {
  const params = new URLSearchParams()

  const defaultStatuses = ['NyttForMig', 'Aktuellt', 'KlientEjKontaktad', 'Avslutat']

  defaultStatuses.forEach((status: string) => params.append('subStatusar', status))
  params.append('arendeTyper', 'OmstallningsArende')
  params.append('radgivarId', radgivarId)

  return `?${params.toString()}`
}

export const buildDinaArendenFacetsQuery = (radgivarId: string, subStatusar: string[]) => {
  const params = new URLSearchParams()

  subStatusar.forEach((status: string) => params.append('subStatusar', status))
  params.append('arendeTyper', 'OmstallningsArende')
  params.append('radgivarId', radgivarId)

  return `?${params.toString()}`
}

export const generateRandomId = (): string => {
  return window.crypto.getRandomValues(new Uint32Array(1)).toString()
}