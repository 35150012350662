import React from 'react'
import { Icons } from '@trr/common-component-library'
import Filter from '@local/Blocks/Filter'
import FilterTags from '@local/Components/FilterTags'
import SidebarButton from '@local/Components/SidebarButton'
import CasesFilter from '@local/src/Components/CasesFilter/CasesFilter'
import { Facets } from '@local/src/Common.types'
import { IArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'
import classnames from 'classnames'

import { IFilterTag } from '../Arenden.types'
import { arendenEmptyFilterState } from '../Arenden.reducers'

import s from './ArendenFilterPanel.module.scss'

export interface IArendenFilterPanel {
  toggleFilter(): void
  visible: boolean
  resetArendenFilter(): void
  removeFilterParameterValuePair(tag: IFilterTag): void
  filterTags?: IFilterTag[]
  facets: Facets
  subStatusFacets: Facets
  filter: IArendenFilterState
  setFilterParameters(filterParams: Partial<IArendenFilterState>): void
  isDinaArenden?: boolean
}

export const ArendenFilterPanel = ({
  filterTags,
  resetArendenFilter,
  removeFilterParameterValuePair,
  toggleFilter,
  visible,
  facets,
  subStatusFacets,
  filter,
  setFilterParameters,
  isDinaArenden,
}: IArendenFilterPanel): JSX.Element => (
  <aside className={classnames(s.StickyPanel, !visible && s.Shrink)} data-testid="arende-filter-panel">
    <header className={classnames(s.FilterPanelHeader, visible && s.BorderBottom)}>
      <SidebarButton
        name={visible && 'Filter'}
        onClick={toggleFilter}
        toggleIcon={visible && <Icons.CloseIcon size={16} color={s.textColor} />}
        sidebarPosition="right"
        icon={<Icons.FilterIcon size={24} color={s.textColor} title="Filter" />}
      />
    </header>
    <Filter visible={visible}>
      <>
        {filterTags.length !== 0 && (
          <FilterTags
            filterTags={filterTags}
            removeFilterParameterValuePair={removeFilterParameterValuePair}
            resetFilter={resetArendenFilter}
          />
        )}

        <CasesFilter
          isDinaArenden={isDinaArenden}
          emptyFilterState={arendenEmptyFilterState}
          facets={facets}
          subStatusFacets={subStatusFacets}
          filter={filter}
          setFilterParameters={setFilterParameters}
        />
      </>
    </Filter>
  </aside>
)

export default ArendenFilterPanel
