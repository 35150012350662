import ChipWithPopper from '@local/src/Components/Chips/ChipWithPopover/ChipWithPopper'
import SuggestionsAutocomplete from '@local/src/Components/SuggestionsAutocomplete/SuggestionsAutocomplete'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import FilterAccordion from '@local/src/Components/FilterAccordion/FilterAccordion'

import { StartklarFilter } from '../../Startklar.types'

interface ForetagFilterProps {
  count: number
  filterState: Partial<StartklarFilter>
  value: string | string[]
  isMobile: boolean
  setFilter: (key: keyof StartklarFilter, values: string | string[]) => void
}

const ForetagFilter = ({
  count,
  filterState,
  value,
  isMobile,
  setFilter,
}: ForetagFilterProps) => {

  const [open, setOpen] = useState(false)

  const filter = (
    <Box px={2} pb={1}>
      <SuggestionsAutocomplete
        multiple
        propertyName='foretagNamn'
        label='Företag'
        value={value}
        getSelectedValues={(foretagNamn: string | string[]) => setFilter('foretagNamn', foretagNamn)}
        filterState={filterState}
      />
    </Box>
  )

  if (isMobile) {
    return (
      <FilterAccordion open={open} label='Företag' indicatorCount={count} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
        {filter}
      </FilterAccordion>
    )
  } else {
    return (
      <ChipWithPopper
        label='Företag'
        width={360}
        onClear={() => setFilter('foretagNamn', [])}
        count={count}
      >
        {filter}
      </ChipWithPopper>
    )
  }
}

export default ForetagFilter