export interface AvslutOption {
  key: Avslutskod
  description: string
  provideDate: boolean
  requireNumberOfMonths: boolean
  requireNumberOfMonthsMin?: number
  requireNumberOfMonthsMax?: number
  requireLosningStartdatum: boolean
}

export enum Avslutskod {
  NyAnstallning = 'NA',
  OvrigAnstallning = 'ÖA',
  FortsattAnstallning = 'FA',
  EgenRorelse = 'ER',
  StudierLangreTid = 'STL',
  RadgivningAvslutad = 'RAS',
  OmstallningsstodAvslutat = 'OS',
  Foraldraledig = 'FLL',
  PersonenOantraffbar = 'PO',
  Pension = 'PE',
  SjukLangreTid = 'SJ',
  Avliden = 'AL',
  RadgivningUppskjuten = 'RUS',
}

export interface AteroppnaArendeRequestParameters {
  arendeId: string
  radgivarId?: string
  omfordela: boolean
}

export interface AteroppnaPreconditions {
  teamIsValid: boolean
  radgivareIsValid: boolean
}

export interface Bransch {
  id: string
  name: string
  description: string
  trrCode: string
}

export interface Befattning {
  id: string
  jobTitle: string
  jobTitleWithHighLightedSearchTerm: string
}

interface AvslutBaseRequest {
  arendeId: string
  radgivningAvslutskod: string
  losningStartdatum?: string | null // age startdatum
}

export interface AvslutaArendeWithReminderRequest extends AvslutBaseRequest {
  radgivningAvslutsdatum: Date
  besvaraAvslutsfragorDatum: Date
}

export interface AvslutaArendeWithQuestionsRequest extends AvslutBaseRequest {
  radgivningAvslutsdatum: string
  avslutsFragorSvar: AvslutsFragaSvar[]
}

// no additional params needed for avslut without date.
export type AvslutaArendeWithoutDateRequest = AvslutBaseRequest

export interface AvslutaArendeWithDateRequest extends AvslutBaseRequest {
  radgivningAvslutsdatum: string
}

export interface AvslutaArendeWithNumberOfMonthsRequest extends AvslutBaseRequest {
  radgivningAvslutsdatum: string
  radgivningUppskjutenManader: number
}

export interface JobFindingOption {
  value: string
  label: string
  description: string
  isMatchningstjanst: boolean
}

export interface AnstallningFormValues {
  avslutsDatum: Date
  startDatum: Date
  requireLosningStartdatum: boolean
  companyName: string
  industry: Bransch
  title: Befattning
  publicSector: boolean
  employmentDate: Date
  isWhiteCollar: boolean
  relativeSalary: string
  relativeLevel: string
  weeklyHours: number
  isTownAbroad: boolean
  town: string
  requiresMoving: boolean
  amountOfEmployees: string
  staffingCompany: boolean
  howKundFoundJob: JobFindingOption
}

export interface EgenRorelseFormValues {
  avslutsDatum: Date
  companyName: string
  dateOfRegistration: Date
  spareTimeJobSinceBefore: boolean
  companyType: string
  industry: Bransch
  town: string
  isTownAbroad: boolean
  previouslyWorkedInIndustry: string
  previouslyWorkedInProfession: string
  registrationAfterCeasedEmployment: string
  foundEmployerAtTRR: boolean
}

export interface AvslutsFragaSvar {
  id?: string
  key: string
  value: string
}

export const avslutskoderRequiringAnswers = [
  Avslutskod.NyAnstallning,
  Avslutskod.OvrigAnstallning,
  Avslutskod.EgenRorelse,
  Avslutskod.StudierLangreTid,
]

export interface Avslutshandelse {
  arendeGuid: string
  radgivningAvslutskod: Avslutskod
  radgivningAvslutsBeskr: string
  radgivningAvslutsdatum: string
  radgivningAvslutskommentar: string
  radgivningUppskjutenManader: number
  radgivningAvslutsOrsak: string
  registreradAv: string
  registreringsDatum: string
  radgivareNamn: string
  ateroppnadDatum: string
  ateroppnasDatumAuto?: string
  ateroppnaGuid: string
  avslutsFragorSvar?: AvslutsFragaSvar[]
}

export const companyTypes = [
  { value: 'AB', label: 'Aktiebolag' },
  { value: 'EK', label: 'Ekonomisk förening' },
  { value: 'EN', label: 'Enskild firma' },
  { value: 'HB', label: 'Handelsbolag' },
  { value: 'KB', label: 'Kommanditbolag' },
]

export const relativeOptions = [
  { value: '1', label: 'Lika' },
  { value: '2', label: 'Högre' },
  { value: '0', label: 'Lägre' },
]

export const jobFindingOptions = [
  { value: '0', label: 'Övrigt ' },
  { value: '10', label: 'Annons i tidning' },
  { value: '70', label: 'Via bemannings-/rekryteringsföretag' },
  { value: '80', label: 'Sökte upp arbetsgivaren' },
  { value: '90', label: 'TRR Matchning' },
  { value: '100', label: 'Eget kontaktnät: Yrkeskontakt' },
  { value: '110', label: 'Eget kontaktnät: Privat kontakt' },
  { value: '130', label: 'Annons internet' },
  { value: '141', label: 'LinkedIn' },
  { value: '142', label: 'Facebook' },
  { value: '143', label: 'Twitter' },
  { value: '144', label: 'Andra sociala medier' },
  { value: '150', label: 'Anvisning från arbetsförmedlingen' },
  { value: '160', label: 'Återanställning' },
  { value: '170', label: 'Jobbannons på annan plats' },
  { value: '171', label: 'Jobbannons på LinkedIn' },
  { value: '172', label: 'Jobbannons på Mitt TRR' },
  { value: '181', label: 'Speedintervju' },
  { value: '182', label: 'Företagspresentation' },
  { value: '183', label: 'Rekryteringsträff' },
  { value: '184', label: 'Tips om jobb från TRR-medarbetare' },
  { value: '185', label: 'Nätverk via TRR' },
  { value: '186', label: 'Snabbansökan utan CV på Mitt TRR' },
  { value: '190', label: 'Arbetsgivaren kontaktade' },
  { value: '191', label: 'Uppsökt av arbetsgivaren/rekryteringsföretaget' },
  { value: '192', label: 'Arbetsgivaren tog kontakt efter tips från TRR' },
]

export const amountsOfEmployees = [
  { value: '0', label: 'Färre än 25' },
  { value: '1', label: '25-50' },
  { value: '2', label: '51-100' },
  { value: '3', label: 'Fler än 100' },
]

export const typeOfEducationOptions = [
  { value: '00001', label: 'Högskola/Universitet' },
  { value: '00002', label: 'Yrkeshögskola' },
  { value: '00003', label: 'Gymnasienivå, teoretisk inriktning' },
  { value: '00004', label: 'Gymnasienivå, yrkesinriktning' },
  { value: '00005', label: 'Arbetsmarknadsutbildning' },
  { value: '00006', label: 'Annat' },
]

export const educationLengthOptions = [
  { value: '1', label: 'En termin' },
  { value: '2', label: '2-4 terminer' },
  { value: '3', label: '5-8 terminer' },
  { value: '4', label: 'Mer än 8 terminer' },
]

export const sunCodeOptions = [
  { value: '1', label: 'Bred, generell utbildning' },
  { value: '8', label: 'Läs- och skrivinlärning för vuxna' },
  { value: '9', label: 'Personlig utveckling' },
  { value: '14', label: 'Pedagogik och lärarutbildning' },
  { value: '21', label: 'Konst och media' },
  { value: '22', label: 'Humaniora' },
  { value: '31', label: 'Samhälls- och beteendevetenskap' },
  { value: '32', label: 'Journalistik och information' },
  { value: '34', label: 'Företagsekonomi, handel och admistration' },
  { value: '38', label: 'Juridik och rättsvetenskap' },
  { value: '42', label: 'Biologi och miljövetenskap' },
  { value: '44', label: 'Fysik, kemi och geovetenskap' },
  { value: '46', label: 'Matematik och övrig naturvetenskap' },
  { value: '48', label: 'Informations- och kommunikationsteknik (IKT)' },
  { value: '52', label: 'Teknik och teknisk industri' },
  { value: '54', label: 'Material och tillverkning' },
  { value: '58', label: 'Samhällsbyggnad och byggnadsteknik' },
  { value: '62', label: 'Lantbruk, trädgård, skog och fiske' },
  { value: '64', label: 'Djursjukvård' },
  { value: '72', label: 'Hälso- och sjukvård' },
  { value: '76', label: 'Socialt arbete och omsorg' },
  { value: '81', label: 'Personliga tjänster' },
  { value: '84', label: 'Transporttjänster' },
  { value: '85', label: 'Arbetsmiljö och renhållning' },
  { value: '86', label: 'Säkerhetstjänster' },
  { value: '99', label: 'Okänd' },
]

export const workedOptions = [
  { value: '0', label: 'Aldrig' },
  { value: '4', label: 'Under 5 år' },
  { value: '5', label: 'Mellan 5-10 år' },
  { value: '6', label: 'Över 10 år' },
]

export const registrationAfterCeasedEmploymentOptions = [
  { value: '0', label: 'Innan sista anställningsdagen' },
  { value: '1', label: 'Innan 6 månader' },
  { value: '2', label: 'Mellan 6-12 månader' },
  { value: '3', label: 'Mer än 12 månader' },
]
