import { IStartklarFilterState } from '@local/src/Views/Startklar/Startklar.types'
import { IArendenFilterState, IDinaArendenFilterState } from '@local/src/Components/CasesFilter/CasesFilter.types'
import { stringifyFilter } from '@local/src/Utils/helpers'

import { baseApi } from '../baseApi'

export interface GetSuggestionsRequest {
  propertyName: string
  searchText: string
  filterState: IArendenFilterState | IDinaArendenFilterState | IStartklarFilterState
}

const baseUrl = '/v1.0/radgivningsarende'

export const radgivningsArendeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestions: builder.query<string[], GetSuggestionsRequest>({
      query: (params) => {
        const stringifiedFilter = stringifyFilter(params.filterState, params.propertyName)
        return `${baseUrl}/${params.propertyName.toLowerCase()}/suggestions?searchText=${params.searchText}&${stringifiedFilter}`
      },
    }),
  }),
})

export const {
  useGetSuggestionsQuery,
  useLazyGetSuggestionsQuery,
} = radgivningsArendeApi