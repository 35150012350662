import CheckboxFacetable from '@local/src/Components/Chips/ChipWithPopover/PopoverInputs/CheckboxFacetable'
import { List } from '@mui/material'
import React from 'react'

import { ArbetsuppgiftStatus, ArbetsuppgiftStatusFacet } from '../../types'

interface StatusFilterProps {
  statuses: ArbetsuppgiftStatusFacet[]
  selectedStatuses: string[]
  handleCheckbox: (value: string) => void
}

const StatusFilter = ({ statuses, selectedStatuses, handleCheckbox }: StatusFilterProps) => {
  const nyFacet = statuses?.find((s) => s.value === ArbetsuppgiftStatus.Ny)
  const pagaendeFacet = statuses?.find((s) => s.value === ArbetsuppgiftStatus.Pagaende)
  const klarFacet = statuses?.find((s) => s.value === ArbetsuppgiftStatus.Klar)

  return (
    <List data-testid="status-lista">
      <CheckboxFacetable
        label="Ny"
        facetCount={nyFacet?.count ?? 0}
        checked={selectedStatuses.some((s) => s === ArbetsuppgiftStatus.Ny)}
        onClick={() => handleCheckbox(ArbetsuppgiftStatus.Ny)}
      />
      <CheckboxFacetable
        label="Pågående"
        facetCount={pagaendeFacet?.count ?? 0}
        checked={selectedStatuses.some((s) => s === ArbetsuppgiftStatus.Pagaende)}
        onClick={() => handleCheckbox(ArbetsuppgiftStatus.Pagaende)}
      />
      <CheckboxFacetable
        label="Klar"
        facetCount={klarFacet?.count ?? 0}
        checked={selectedStatuses.some((s) => s === ArbetsuppgiftStatus.Klar)}
        onClick={() => handleCheckbox(ArbetsuppgiftStatus.Klar)}
      />
    </List>
  )
}

export default StatusFilter
