import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { Alert, AlertTitle, Box, Button, FormHelperText } from '@mui/material'
import { FetchingState } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'
import { scrollToRef } from '@local/src/Utils/smallfuncs'

import { currentActivitiesSelector, fetchingActivitiesStateSelector } from '../../../Activities/Activities.selectors'
import { cancelActivityBookings, fetchCurrentActivities } from '../../../Activities/Activities.actions'
import { klientIdSelector } from '../../../Case.selectors'
import { ActivityRound } from '../../../Activities/Activities.types'

import HandleActivitiesModal from './HandleActivitiesModal/HandleActivitiesModal'

interface HandleActivitiesProps {
  setActivitiesHandled(isHandled: boolean): void
  showErrorMessage?: boolean
  scrollIntoViewOnError?: boolean
}

const HandleActivities = ({
  setActivitiesHandled,
  showErrorMessage,
  scrollIntoViewOnError = true,
}: HandleActivitiesProps) => {

  const dispatch = useDispatch()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const allCurrentActivities = useSelector(currentActivitiesSelector)
  const fetchingActivitiesState = useSelector(fetchingActivitiesStateSelector)
  const klientId = useSelector(klientIdSelector)
  const alertRef = useRef<HTMLDivElement>()

  const filteredActivities = allCurrentActivities?.filter(
    (activity: ActivityRound) =>
      activity.registration.userRegistration.status === 'Registered' ||
      activity.registration.userRegistration.status === 'Reserve'
  )

  useEffect(() => {
    dispatch(fetchCurrentActivities(klientId))
  }, [klientId])

  useEffect(() => {
    if (scrollIntoViewOnError && showErrorMessage) {
      scrollToRef(alertRef, 40)
    }
  }, [showErrorMessage])

  useEffect(() => {
    if (filteredActivities.length === 0 && !hasSubmitted && fetchingActivitiesState === FetchingState.SUCCESS) {
      setActivitiesHandled(true)
    }
  }, [filteredActivities, hasSubmitted, fetchingActivitiesState])

  const onCancel = () => {
    // we dont want to trigger error message if
    // user already has handled activities and opens modal again.
    !hasSubmitted && setActivitiesHandled(false)
    setIsModalOpen(false)
  }

  const onSubmit = (activitiesToCancel: string[]) => {
    activitiesToCancel.length > 0 && dispatch(cancelActivityBookings(klientId, activitiesToCancel))
    setActivitiesHandled(true)
    setIsModalOpen(false)
    setHasSubmitted(true)
  }

  if (filteredActivities.length === 0 && !hasSubmitted && fetchingActivitiesState === FetchingState.SUCCESS) {
    return <></>
  }

  if (fetchingActivitiesState === FetchingState.LOADING) {
    return <Loading />
  }

  return (
    <>
      {hasSubmitted ? (
        <Alert
          severity='success'
          action={
            filteredActivities.length > 0 && <Button variant='text' color="inherit" size="small" onClick={() => setIsModalOpen(true)}>Avboka aktiviteter</Button>
          }
          sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: 2}}
        >
          {filteredActivities.length > 0 ? (
            <>
              <AlertTitle>Aktiviteter är hanterade</AlertTitle>
              Omställningskunden har inbokade aktiviteter som du valt att inte avboka.
            </>
          ) : (
            <>Aktiviteter är hanterade</>
          )}
        </Alert>
      ) : (
        <Box>
          <Alert
            severity='warning'
            ref={alertRef}
            action={
              filteredActivities.length > 0 && <Button variant='text' color="inherit" size="small" onClick={() => setIsModalOpen(true)}>Hantera aktiviteter</Button>
            }
            sx={{ flexDirection: { xs: 'column', sm: 'row' }, mt: 2}}
          >
            <AlertTitle>Omställningskunden har inbokade aktiviteter</AlertTitle>
              Dessa behöver hanteras innan ärendet kan avslutas.
          </Alert>
          { showErrorMessage && (
            <FormHelperText sx={{ color: 'error.main', mt: '8px' }}>
              Inbokade aktiviteter behöver hanteras innan ärendet kan avslutas
            </FormHelperText>
          )}
        </Box>
      )}
      <HandleActivitiesModal
        activities={filteredActivities}
        open={isModalOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  )

}

export default HandleActivities