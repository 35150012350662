import React, { useState } from 'react'
import { Link, List, ListItemText } from '@mui/material'
import Modal from '@local/src/Components/Modal/Modal'
import InfoIcon from '@mui/icons-material/Info'
import { useIsSmallScreen } from '@local/src/Hooks'

import { JobFindingOption } from '../../../../Avslut.types'

interface MatchningModalProps {
  linkLabel: string
  modalLabel: string
  options: JobFindingOption[]
}

const MatchningModal = ({
  linkLabel,
  modalLabel,
  options,
}: MatchningModalProps) => {

  const isMobile = useIsSmallScreen()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <Link
        display='flex'
        pl={0}
        gap={1}
        sx={{cursor: 'pointer'}}
        onClick={() => setModalIsOpen(true)}
        variant='body1'
      >
        <InfoIcon />
        {linkLabel}
      </Link>
      <Modal
        open={modalIsOpen}
        label={modalLabel}
        onClose={() => setModalIsOpen(false)}
        onCancel={() => setModalIsOpen(false)}
        cancelText='Stäng'
        fullScreen={isMobile}
      >
        <List sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
          { options.map(option => (
            <ListItemText key={option.value} primary={option.label} secondary={option.description} />
          ))}
        </List>
      </Modal>
    </>
  )
}

export default MatchningModal