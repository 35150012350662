
import { Team } from '@local/src/Views/Fordelning/Fordelning.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v1.0'

export const teamApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTeams: builder.query<Team[], void>({
      query: () => `${baseUrl}/teams`
    }),
    getMinaTeams: builder.query<Team[], void>({
      query: () => `${baseUrl}/fordelningsansvarig/minateam`
    }),
  })
})

export const {
  useGetAllTeamsQuery,
  useGetMinaTeamsQuery,
} = teamApi