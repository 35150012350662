import { Typography } from '@mui/material'
import React from 'react'

import { getGreeting, getGreetingTime, getRandomEmoji } from './WelcomeMessage.helper'


interface WelcomeMessageProps {
  name: string
  date: Date
}

const WelcomeMessage = ({
  name,
  date,
}: WelcomeMessageProps): JSX.Element => {
  const timeOfDay = getGreetingTime(date)
  const greeting = getGreeting(timeOfDay)

  return (
    <Typography variant='h2' gutterBottom>{greeting}, {name} {getRandomEmoji(timeOfDay)}</Typography>
  )
}

export default WelcomeMessage