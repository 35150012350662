import { Link, List, ListItem, ListItemText } from '@mui/material'
import { date } from '@trr/frontend-datetime'
import React from 'react'

import { useEpiString } from '../../../../../Context/EpiStringContext'
import { IStudieAnmalan } from '../../../types/StudieAnmalan.types'
import AnmalanContentItem from '../AnmalanContentItem/AnmalanContentItem'

import s from './StudieAnmalanEducation.module.scss'

interface IStudieAnmalanEducationProps {
  studieAnmalan: IStudieAnmalan
}

const StudieAnmalanEducation: React.FC<React.PropsWithChildren<IStudieAnmalanEducationProps>> = ({ studieAnmalan }) => {
  const epi = useEpiString()
  const { id, educationName, placeDescription, instituteName, lengthInDays, isDistance, startDate, booking, educationLink } = studieAnmalan
  return (
    <ListItem>
      <AnmalanContentItem>
        <ListItemText primaryTypographyProps={{ variant: 'h6' }} primary={epi('common.Education')} />
        <List dense>
          <ListItem>
            <ListItemText
              primary={`${epi('common.Name')}:`}
              secondary={educationLink ? <Link href={educationLink}>{educationName}</Link> : educationName}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={`${epi('common.BookingNumber')}:`} secondary={id ? id : epi('common.Unknown')} />
          </ListItem>
          <ListItem>
            <ListItemText primary={`${epi('common.Supplier')}:`} secondary={instituteName ? instituteName : epi('common.Unknown')} />
          </ListItem>

          <ListItem className={s.ListItemWithSpacing}>
            <ListItemText
              primary={`${epi('common.Locality')}:`}
              secondary={isDistance ? epi('common.Distance') : placeDescription ? placeDescription : epi('common.Unknown')}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={`${epi('common.EstimatedStartOfStudies')}:`}
              secondary={
                startDate
                  ? date.format(startDate, {
                      year: true,
                    })
                  : epi('common.Unknown')
              }
            />
          </ListItem>
          <ListItem className={s.ListItemWithSpacing}>
            <ListItemText
              primary={`${epi('common.LengthOfEducation')}:`}
              secondary={lengthInDays > 0 && `${lengthInDays} ${lengthInDays > 1 ? epi('common.days') : epi('common.day')}`}
            />
          </ListItem>

          {startDate && (
            <ListItem>
              <ListItemText
                primary={`${epi('common.BookingMade')}:`}
                secondary={
                  booking?.date
                    ? date.format(booking.date, {
                        year: true,
                      })
                    : epi('common.Unknown')
                }
              />
            </ListItem>
          )}
        </List>
      </AnmalanContentItem>
    </ListItem>
  )
}

export default StudieAnmalanEducation
