import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { head, takeLast } from 'ramda'
import { ITabItem } from '@local/Components/TabsContainer/TabsContainer'

const useTabs = (items: ITabItem[]) => {
  const history = useHistory()
  const { pathname } = useLocation()

  const getCurrentPath = (items: ITabItem[], pathname: string) => {
    const availableTabs = items.map((item) => item.url)
    const matchedTab = pathname.split('/').filter((splittedUrl) => availableTabs.includes(splittedUrl))
    return takeLast(1, matchedTab).join()
  }

  const currentPath = getCurrentPath(items, pathname)

  const selectedIndex = items.findIndex((item) => currentPath?.includes(item.url))
  const [currentIndex, setCurrentIndex] = useState(selectedIndex !== -1 ? selectedIndex : 0)

  useEffect(() => {
    setCurrentIndex(selectedIndex !== -1 ? selectedIndex : 0)
  }, [selectedIndex])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentIndex(newValue)
    const baseUrl = head(pathname.split(currentPath))
    history.push(baseUrl + items[newValue].url)
  }

  const getA11yProps = (index: number) => {
    return {
      id: `tabbutton-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  return {
    currentIndex,
    handleChange,
    currentPath,
    getA11yProps,
  }
}

export default useTabs
