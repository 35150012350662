import React, { useEffect, useState } from 'react'
import MobileFilterUtilities from '@local/Blocks/Filter/MobileFilterUtilities'
import { buildQuery } from '@local/Utils/helpers'
import { omit } from 'ramda'
import { ISelectOption } from '@local/src/Common.types'
import { useDisplayFilter, useIsSmallScreen } from '@local/src/Hooks'
import { formatAmount } from '@local/src/Utils/smallfuncs'
import Modal from '@local/src/Components/Modal/Modal'
import { Box } from '@mui/material'

import StartklarListWrapper from './StartklarListWrapper/StartklarListWrapper'
import StartklarFilterBar from './StartklarFilterBar/StartklarFilterBar'
import { IFilterTag, IStartklar, IStartklarFilterableProperties, IStartklarStringifyFilter } from './Startklar.types'
import StartklarFilter from './StartklarFilter'

const Startklar = ({
  fetchCaseListStartklar,
  fetchCaseListFacets,
  setStartklarPage,
  setStartklarPageSize,
  resetFilterProp,
  startklarCases,
  fetchingCaseListStartklar,
  filterState,
  facetedTotalHits,
  totalHits,
  setStartklarFilterParameters,
  fetchHandlaggareList,
}: IStartklar): JSX.Element => {
  const isSmallScreen = useIsSmallScreen()
  const { isFilterVisible, toggleFilter } = useDisplayFilter()
  const [listOfTags, setListOfTags] = useState([])
  const [searchText, setSearchText] = useState('')
  const initialFilterState: IStartklarFilterableProperties = {
    foretagNamn: [],
    arbetsmarknadsomraden: [],
    handlaggareObjects: [],
    ejTilldeladHandlaggare: false,
  }

  const resetAll = () => resetFilterProp({ filterParameter: 'resetAll', filterValue: 'resetAll' })

  const buildTags = (initialStateTags: IStartklarFilterableProperties, state: IStartklarFilterableProperties) => {
    const tagList: IFilterTag[] = []
    Object.entries(initialStateTags).forEach(([key, value]) => {
      if (JSON.stringify(state[key]) !== JSON.stringify(value)) {
        switch (key) {
          case 'arbetsmarknadsomraden':
            return state.arbetsmarknadsomraden.map((omrade: string) => {
              tagList.push({ filterParameter: key, filterValue: omrade, displayValue: omrade })
              setListOfTags(tagList)
            })
          case 'foretagNamn':
            return state.foretagNamn.map((foretag: string) => {
              tagList.push({ filterParameter: key, filterValue: foretag, displayValue: foretag })
              setListOfTags(tagList)
            })
          case 'handlaggareObjects':
            return state.handlaggareObjects?.map((obj) => {
              tagList.push({ filterParameter: key, filterValue: obj.value.toString(), displayValue: obj.label })
              setListOfTags(tagList)
            })

          case 'ejTilldeladHandlaggare':
            tagList.push({ filterParameter: key, filterValue: key, displayValue: 'Ej tilldelad' })
            return setListOfTags(tagList)

          default:
            return new Error()
        }
      }
      setListOfTags(tagList)
    })
  }

  const searchTextOnChange = (searchText: string) => {
    setStartklarPage(1)
    setSearchText(searchText)
  }

  const handleChangePageSize = (pageSize: number) => {
    setStartklarPage(1)
    setStartklarPageSize(pageSize)
  }

  useEffect(() => {
    fetchHandlaggareList()
  }, [fetchHandlaggareList])

  useEffect(() => {
    const handlaggareIdn =
      filterState.handlaggareObjects?.length > 0 ? filterState.handlaggareObjects?.map((item: ISelectOption) => item.value) : []
    const filter: IStartklarStringifyFilter = {
      ...omit(['handlaggareObjects'], filterState),
      ...{ handlaggareIdn: handlaggareIdn as string[] },
    }
    fetchCaseListStartklar(buildQuery(filter, searchText))
    fetchCaseListFacets()
    buildTags(initialFilterState, filterState as IStartklarFilterableProperties)
  }, [fetchCaseListFacets, fetchCaseListStartklar, filterState, setListOfTags, setStartklarFilterParameters, searchText])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 0 360px'}}>
      {isSmallScreen && (
        <>
          <MobileFilterUtilities displayModal={toggleFilter} tags={listOfTags} removeFilterParameterValuePair={resetFilterProp} />
          <Modal
            fullScreen
            label='Filter'
            onConfirm={toggleFilter}
            onClose={toggleFilter}
            closeIcon
            confirmText={`Visa ${formatAmount(totalHits)} ärenden`}
            open={isFilterVisible}
          >
            <StartklarFilter />
          </Modal>
        </>
      )}
      <Box display='flex' gap={4} justifyContent='center'>
        {!isSmallScreen && (
          <StartklarFilterBar
            filterTags={listOfTags}
            toggleFilter={toggleFilter}
            visible={isFilterVisible}
            removeFilterParameterValuePair={resetFilterProp}
            resetFilter={resetAll}
          />
        )}
        <StartklarListWrapper
          startklarCases={startklarCases}
          fetchingCaseListStartklar={fetchingCaseListStartklar}
          facetedTotalHits={facetedTotalHits}
          totalHits={totalHits}
          filterState={filterState}
          setStartklarPage={setStartklarPage}
          setStartklarPageSize={handleChangePageSize}
          setStartklarFilterParameters={setStartklarFilterParameters}
          searchText={searchText}
          onSearch={searchTextOnChange}
        />
      </Box>
    </Box>
  )
}

export default Startklar
