import React from 'react'
import { date, time } from '@trr/frontend-datetime'
import Phone from '@mui/icons-material/Phone'
import VideoCam from '@mui/icons-material/Videocam'
import Event from '@mui/icons-material/Event'
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material'
import { IMeeting, MotesTyp } from '@local/src/Views/Case/Case.types'

interface MeetingProps {
  meeting: IMeeting
  divider: boolean
}

const Meeting = ({ meeting, divider }: MeetingProps) => {
  let icon
  switch (meeting.motesTyp) {
    case MotesTyp.Telefon:
      icon = <Phone titleAccess='Telefonmöte' />
      break
    case MotesTyp.Online:
      icon = <VideoCam titleAccess='Onlinemöte' />
      break
    case MotesTyp.Fysiskt:
    default:
      icon = <Event titleAccess='Möte' />
      break
  }

  return (
    <ListItem divider={divider} sx={{ alignItems: 'flex-start', padding: '8px 0' }}>
      <ListItemAvatar sx={{ minWidth: '40px', marginTop: '6px' }}>
        {icon}
      </ListItemAvatar>
      <ListItemText
        primary={`${date.format(meeting.startDatumTid)} \u{2022} ${time.format(meeting.startDatumTid)} - ${time.format(meeting.slutDatumTid)}`}
        secondary={<Typography variant='body2' color='text.secondary' sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{meeting.radgivareNamn}</Typography>}
      />
    </ListItem>
  )
}

export default Meeting