import { handleActions } from 'redux-actions'
import { FetchingState } from '@local/src/Common.types'

import {
  fetchTodoListInitiate,
  fetchTodoListSuccess,
  fetchTodoListFailure,
  completeTodoInitiate,
  completeTodoSuccess,
  completeTodoFailure,
  reactivateTodoInitiate,
  reactivateTodoSuccess,
  reactivateTodoFailure,
} from './TodoList.actions'
import { ITodo, ITodoListState } from './TodoList.types'

export const initialState: ITodoListState = {
  todoList: [],
  fetchingTodoListState: FetchingState.IDLE,
  completeTodoState: FetchingState.IDLE,
  reactivateTodoState: FetchingState.IDLE,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const todoReducer = handleActions(
  {
    [fetchTodoListInitiate.toString()]: (state) => ({
      ...state,
      fetchingTodoListState: FetchingState.LOADING,
    }),
    [fetchTodoListSuccess.toString()]: (state, action) => ({
      ...state,
      fetchingTodoListState: FetchingState.SUCCESS,
      todoList: action.payload.todoList,
    }),
    [fetchTodoListFailure.toString()]: (state) => ({
      ...state,
      fetchingTodoListState: FetchingState.ERROR,
    }),

    [completeTodoInitiate.toString()]: (state) => ({
      ...state,
      completeTodoState: FetchingState.LOADING,
    }),
    [completeTodoSuccess.toString()]: (state: any, action: { payload: { completedTodo: ITodo } }) => {
      return {
        ...(state as ITodoListState),
        todoList: (state as ITodoListState).todoList.map((todo: ITodo) => {
          if (todo.id === action.payload.completedTodo.id) {
            return action.payload.completedTodo
          } else {
            return todo
          }
        }),
        completeTodoState: FetchingState.SUCCESS,
      }
    },
    [completeTodoFailure.toString()]: (state) => ({
      ...state,
      completeTodoState: FetchingState.ERROR,
    }),

    [reactivateTodoInitiate.toString()]: (state) => ({
      ...state,
      reactivateTodoState: FetchingState.LOADING,
    }),
    [reactivateTodoSuccess.toString()]: (state: any, action: { payload: { reactivatedTodo: ITodo } }) => {
      return {
        ...(state as ITodoListState),
        todoList: (state as ITodoListState).todoList.map((todo: ITodo) => {
          if (todo.id === action.payload.reactivatedTodo.id) {
            return action.payload.reactivatedTodo
          } else {
            return todo
          }
        }),
        reactivateTodoState: FetchingState.SUCCESS,
      }
    },
    [reactivateTodoFailure.toString()]: (state) => ({
      ...state,
      reactivateTodoState: FetchingState.ERROR,
    }),
  },
  initialState
)
/* eslint-enable @typescript-eslint/no-explicit-any */

export default todoReducer
