import React, { useEffect } from 'react'
import { withFormik, FormikProps, Form } from 'formik'
import { Redirect } from 'react-router-dom'
import { getUrlByCaseEnv, startklarPath } from '@local/src/basename'
import { useCaseEnvBasePath, useGuidFromUrl } from '@local/src/Hooks'
import { FetchingState } from '@local/src/Common.types'
import { omit } from 'ramda'
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Link, Stack, TextField, Typography } from '@mui/material'

import { IStartklarFormApiValues } from '../Startklar.actions'

import StartklarKlientPrimaryInformation from './StartklarKlientPrimaryInformation'
import StartklarKlientSecondaryInformation from './StartklarKlientSecondaryInformation'
import s from './StartklarForm.module.scss'
import { IReduxProps, IStartklarFormValues } from './StartklarForm.types'

const StartklarForm = ({
  caseDetails,
  startklarCaseFetchingState,
  errors,
  fetchStartklarCase,
  handleChange,
  resetStartklarFormSubmissionState,
  startklarFormSubmissionState,
  fetchStartklarRattTillAge,
  values: { clientDidNotRespond, description, mentionedTrrSe, notReadyToStart, prefersEnglish, seekingJobs },
}: IReduxProps & FormikProps<IStartklarFormValues>) => {
  const caseId = useGuidFromUrl()
  const caseEnvBasePath = useCaseEnvBasePath()

  useEffect(() => {
    void fetchStartklarCase({ caseId })
  }, [fetchStartklarCase])

  useEffect(() => {
    const caseIdIsSet = caseId === caseDetails?.id
    const klientId = caseIdIsSet ? caseDetails?.klient.id : null

    if (klientId && caseIdIsSet && startklarCaseFetchingState === FetchingState.SUCCESS) {
      void fetchStartklarRattTillAge(klientId, caseId)
    }
  }, [startklarCaseFetchingState])

  if (startklarFormSubmissionState === FetchingState.SUCCESS) {
    resetStartklarFormSubmissionState()
    return <Redirect to={getUrlByCaseEnv(startklarPath)} />
  }

  if (startklarCaseFetchingState === FetchingState.ERROR) {
    return (
      <div>
        <Typography variant="h1" align='center' gutterBottom>
          Startklar
        </Typography>
      </div>
    )
  }

  return (
    <Stack>
      <Typography variant="h1" align='center' gutterBottom>
        Startklar
      </Typography>

      <Form className={s.StartklarForm}>
        {startklarCaseFetchingState === FetchingState.LOADING || startklarFormSubmissionState === FetchingState.LOADING ? (
          <Box display='flex' justifyContent='center'>
            <CircularProgress
              data-testid="startklarform-loading-spinner" />
          </Box>
        ) : startklarCaseFetchingState === FetchingState.SUCCESS ? (
          <>
            <Stack>
              <Link href={getUrlByCaseEnv(caseEnvBasePath, caseDetails?.id)}>
                <Typography variant='body2'>#{caseDetails.arendeNummer}</Typography>
              </Link>
              <Typography variant='h3' gutterBottom>
                {caseDetails.klient.fornamn} {caseDetails.klient.efternamn}, {caseDetails.klient.alder} år
              </Typography>
              <StartklarKlientPrimaryInformation
                companyName={caseDetails.omstallningsArende.anstallningsuppgifter.foretag.namn}
                email={caseDetails.klient.epost}
                jobTitle={caseDetails.omstallningsArende.anstallningsuppgifter.befattning}
                phoneNumber={caseDetails.klient.telefonnummer}
                kommun={caseDetails.klient.kommun}
              />
              <StartklarKlientSecondaryInformation
                orsakUppsagning={caseDetails.omstallningsArende.orsakAnsokanDisplayText}
                dayOfNoticeToQuit={caseDetails.omstallningsArende.anstallningsuppgifter.uppsagningsDatum}
                lastDayOfEmployment={caseDetails.omstallningsArende.inkomstuppgifter.sistaDagMedLon}
                totalLon={caseDetails.omstallningsArende.inkomstuppgifter.totalLon}
                severancePayEndDate={caseDetails.omstallningsArende.anstallningsuppgifter.avgangsvederlagSlutDatum}
              />
            </Stack>
            <Divider sx={{ marginY: 3, marginX: 0 }}/>

            <Stack>
              <FormControlLabel
                label='Engelsktalande'
                sx={{paddingBottom: 2}}
                control={
                  <Checkbox
                    name='prefersEnglish'
                    checked={prefersEnglish}
                    onChange={handleChange}/>
                }
              />

              <Box display='flex' flexDirection='column' paddingBottom={2}>
                <Typography variant='h6' gutterBottom>Nuläge och inriktning framåt</Typography>
                <TextField
                  label='Sammanfattning'
                  value={description}
                  name='description'
                  multiline
                  fullWidth
                  rows={10}
                  onChange={handleChange} />
                <FormControlLabel
                  label='Kommit igång med jobbsökningen'
                  control={
                    <Checkbox
                      name='seekingJobs'
                      checked={seekingJobs}
                      onChange={handleChange}/>
                  }
                />
                <FormControlLabel
                  label='Vill komma igång längre fram'
                  control={
                    <Checkbox
                      checked={notReadyToStart}
                      name='notReadyToStart'
                      onChange={handleChange}/>
                  }
                />
              </Box>

              <Box>
                <Typography variant='h6'>Informerat om</Typography>
                <FormControlLabel
                  label='Logga in på trr.se och gå till Kom igång'
                  control={
                    <Checkbox
                      checked={mentionedTrrSe}
                      name='mentionedTrrSe'
                      onChange={handleChange}/>
                  }
                />
              </Box>

              <FormControlLabel
                  label='Omställningskunden ej nåbar'
                  id='message-klient-checkbox'
                  className={s.ClientDidNotRespond}
                  sx={{paddingRight: 2, marginLeft: 0}}
                  control={
                    <Checkbox
                      sx={{ paddingLeft: 0}}
                      checked={clientDidNotRespond}
                      name='clientDidNotRespond'
                      onChange={handleChange}/>
                  }
                />
              <Typography
                aria-live="polite"
                id='message-klient-checkbox-error'
                variant='body1'
                className={s.Error}>
                {errors?.clientDidNotRespond}
              </Typography>
              <Button type='submit' size='large' sx={{ alignSelf: 'baseline' }}>
                Skicka till fördelning
              </Button>
            </Stack>
          </>
        ) : (
          <Box display='flex' justifyContent='center'>
            <CircularProgress
              data-testid="startklarform-loading-spinner" />
          </Box>
        )}
      </Form>
    </Stack>
  )
}

export default withFormik<IReduxProps, IStartklarFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    caseId: props.caseDetails?.id,
    clientId: props.caseDetails?.klient.id,
    clientDidNotRespond: false,
    description: '',
    mentionedTrrSe: false,
    notReadyToStart: false,
    prefersEnglish: false,
    seekingJobs: false,
  }),
  validate: (values) => {
    const errors: { clientDidNotRespond?: string } = {}
    const userProvidedValues = omit(['caseId', 'clientId'], values)
    const changedFields = Object.keys(userProvidedValues).filter((key: string) => userProvidedValues[key])
    if (changedFields.length === 0) {
      errors.clientDidNotRespond = 'Fyll i formuläret eller markera att omställningskunden ej är nåbar.'
    }
    return errors
  },
  handleSubmit: (values, { props: { submitStartklarForm } }) => {
    const { caseId, ...body } = values
    const apiBody: IStartklarFormApiValues = {
      id: caseId,
      beskrivning: body.description,
      ejRedo: body.notReadyToStart,
      engelsktalande: body.prefersEnglish,
      klientId: body.clientId,
      klientenSvarade: !body.clientDidNotRespond,
      pratatOmTrrSe: body.mentionedTrrSe,
      sokerJobb: body.seekingJobs,
    }
    submitStartklarForm(caseId, apiBody)
  },
  displayName: 'Startklar form',
})(StartklarForm)
