import React, { useEffect } from 'react'
import { removeTrailingSlash } from '@local/src/Utils/helpers'
import { TabsContainer } from '@local/src/Components/TabsContainer/TabsContainer'
import { Redirect, useLocation } from 'react-router-dom'
import { Badge, Box, useMediaQuery, useTheme } from '@mui/material'
import { useCanViewArbetslista } from '@local/src/Hooks'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useLazyGetPluppQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'

import { noActiveTab } from './Arenden.helpers'
import ArendeList from './ArendeList/ArendeList'
import Arbetsuppgift from './Arbetsuppgift/Arbetsuppgift'

const Arenden = (): JSX.Element => {

  const { pathname } = useLocation()
  const path = removeTrailingSlash(pathname)

  const canViewArbetslista = useCanViewArbetslista()
  const [fetchPlupp, pluppCount] = useLazyGetPluppQuery()
  const arbetslistaIsEnabled = useIsFeatureEnabled('Medarbetare-Microfrontend_arbetslista_temp_240522')
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (arbetslistaIsEnabled && canViewArbetslista) {
      // only fetch plupp if featureflag is enabled
      fetchPlupp()
    }
  }, [arbetslistaIsEnabled, canViewArbetslista])

  if (noActiveTab(path)) return <Redirect to={path + '/alla'} />

  return (
    <Box sx={{ p: 0, maxWidth: '1512px', m: '0 auto' }}>
      {/* exactly the same as the old view. Refactor later? */}
      <TabsContainer
        items={[
          { heading: isSmallScreen ? 'Alla' : 'Alla ärenden', url: 'alla', content: <ArendeList key="alla-arenden" /> },
          { heading: isSmallScreen ? 'OS-Kund' : 'Omställning', url: 'omstallning', content: <ArendeList key="omstallning" /> },
          { heading: isSmallScreen ? 'KS-Kund' : 'Kompetensstöd', url: 'kompetensstod', content: <ArendeList key="kompetensstod" /> },
          ...(arbetslistaIsEnabled && canViewArbetslista
            ? [
                {
                  heading: (
                    <Badge badgeContent={pluppCount?.data > 0 ? pluppCount?.data : undefined} color="primary" sx={{ '& .MuiBadge-badge': { top: -2, right: -2 } }}>
                      Att göra
                    </Badge>
                  ),
                  url: 'att-gora',
                  content: <Arbetsuppgift />,
                },
              ]
            : []),
        ]}
      />
    </Box>
  )
}

export default Arenden
