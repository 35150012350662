/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useRef, useState } from 'react'
import { useArendenListOptions, useDisplayFilter, useIsSmallScreen } from '@local/src/Hooks'
import { useLocation } from 'react-router-dom'
import { buildArendenFacetsQuery, buildArendenInitialFacetsQuery, buildFetchArendenQuery } from '@local/src/Utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@local/src/Store/configureStore'
import { Facets } from '@local/src/Common.types'
import { ICreateEmailForm } from '@local/src/Blocks/CreateEmail/CreateEmail.types'
import { buildTags } from '@local/src/Components/CasesFilter/CasesFilter.helpers'
import MobileFilterUtilities from '@local/src/Blocks/Filter/MobileFilterUtilities'
import Modal from '@local/src/Components/Modal/Modal'
import CasesFilter from '@local/src/Components/CasesFilter/CasesFilter'
import { formatAmount } from '@local/src/Utils/smallfuncs'
import CaseList from '@local/src/Components/CaseList'
import { createEmail } from '@local/src/Blocks/CreateEmail/CreateEmail.actions'
import { createEmailState } from '@local/src/Blocks/CreateEmail/CreateEmail.selectors'
import { Box } from '@mui/material'

import ArendenFilterPanel from '../ArendenFilterPanel/ArendenFilterPanel'
import { fetchArenden, fetchCaseListFacets, fetchInitialCaseListFacets } from '../Arenden.actions'
import { IFilterTag } from '../Arenden.types'
import { getArendeType } from '../Arenden.helpers'
import { arenden, arendenFacetedHits2, arendenFacets2, arendenTotalHits, arendenTotalPages, fetchingArendenState } from '../Arenden.selectors'
import { arendenEmptyFilterState } from '../Arenden.reducers'

const ArendeList = () => {

  const dispatch = useDispatch()
  const isSmallScreen = useIsSmallScreen()
  const [tags, setListOfTags] = useState<IFilterTag[]>([])
  const { pathname } = useLocation()
  const arendeTypFilter = getArendeType(pathname)

  const subStatusFacets = useSelector((state: RootState) => state.arenden.subStatusFacets as Facets)

  const cases = useSelector(arenden)
  const facetedHits = useSelector(arendenFacetedHits2)
  const facets = useSelector(arendenFacets2)
  const createEmailFetchingState = useSelector(createEmailState)
  const fetchingCaseListState = useSelector(fetchingArendenState)
  const totalHits = useSelector(arendenTotalHits)
  const totalPages = useSelector(arendenTotalPages)

  const {
    page,
    pageSize,
    setArendenPage,
    setPageSize,
    orderBy,
    setArendenOrderBy,
    filter,
    setFilterParameters,
    resetArendenFilter,
    searchText,
    setArendenSearchText,
    removeFilterParameterValuePair,
    setArendeTyperFilter,
  } = useArendenListOptions()

  const { isFilterVisible, toggleFilter } = useDisplayFilter()
  const [filterModalActive, setFilterModalActive] = useState(false)

  const handleCreateEmail = (emailForm: ICreateEmailForm) =>
    dispatch(createEmail(false, buildFetchArendenQuery(filter, searchText, page, pageSize, orderBy), emailForm))

  const filterJson = JSON.stringify(filter)
  const previousFilter = useRef(filterJson)

  useEffect(() => {
    setArendeTyperFilter(arendeTypFilter)
  }, [])

  useEffect(() => {
    dispatch(fetchArenden(buildFetchArendenQuery(filter, searchText, page, pageSize, orderBy)))
  }, [fetchArenden, filterJson, orderBy, searchText, page, pageSize])

  useEffect(() => {
    dispatch(fetchInitialCaseListFacets(buildArendenInitialFacetsQuery(arendeTypFilter)))
  }, [])

  useEffect(() => {
    dispatch(fetchCaseListFacets(buildArendenFacetsQuery(arendeTypFilter, filter.subStatusar)))
  }, [filter.subStatusar])

  useEffect(() => {
    buildTags(filter, arendenEmptyFilterState, setListOfTags)
  }, [filterJson, setListOfTags])

  useEffect(() => {
    if (filterJson !== previousFilter.current) {
      setArendenPage(1, totalPages)
    }
  }, [filter])

  useEffect(() => {
    setArendenPage(1, totalPages)
  }, [searchText])

  return (
    <Box display='flex' flexDirection='column'>
      {isSmallScreen && (
        <MobileFilterUtilities
          displayModal={() => setFilterModalActive(true)}
          removeFilterParameterValuePair={removeFilterParameterValuePair}
          tags={tags}
        />
      )}
      <Box display='flex' gap={4}>
        {isSmallScreen ? (
          <Modal
            label='Filter'
            fullScreen
            confirmText={`Visa ${formatAmount(totalHits)} ärenden`}
            onConfirm={() => setFilterModalActive(false)}
            onClose={() => setFilterModalActive(false)}
            closeIcon
            open={filterModalActive}
            testSelector='arende-filter-modal'
          >
            <CasesFilter
              isDinaArenden={false}
              facets={facets}
              subStatusFacets={subStatusFacets}
              filter={filter}
              setFilterParameters={setFilterParameters}
              emptyFilterState={arendenEmptyFilterState}
            />
          </Modal>
        ) : (
          <ArendenFilterPanel
            filterTags={tags}
            facets={facets}
            subStatusFacets={subStatusFacets}
            filter={filter}
            resetArendenFilter={resetArendenFilter}
            removeFilterParameterValuePair={removeFilterParameterValuePair}
            toggleFilter={toggleFilter}
            visible={isFilterVisible}
            setFilterParameters={setFilterParameters}
          />
        )}

        <Box flex='1 0'>
          <CaseList
            createEmail={handleCreateEmail}
            facetedHits={facetedHits}
            cases={cases}
            setSearchText={setArendenSearchText}
            setOrderBy={setArendenOrderBy}
            setCasesPage={setArendenPage}
            setPageSize={setPageSize}
            searchQuery={searchText}
            page={page}
            pageSize={pageSize}
            orderBy={orderBy}
            totalHits={totalHits}
            totalPages={totalPages}
            fetchingCaseListState={fetchingCaseListState}
            createEmailState={createEmailFetchingState}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ArendeList