import React, { SyntheticEvent } from 'react'
import { Loading, RadioButton, RadioButtonGroup } from '@trr/common-component-library'
import classnames from 'classnames'
import { IAdvisor } from '@local/Views/AllocateCases/AllocateCases.types'
import { FetchingState } from '@local/src/Common.types'

import s from './AdvisorSelectorMobile.module.scss'

interface IAdvisorSelector {
  advisorList: IAdvisor[]
  chooseAdvisor(e: SyntheticEvent<Element, Event>): void
  fetchingAdvisorListState: FetchingState
}

const AdvisorSelectorMobile = ({ advisorList, chooseAdvisor, fetchingAdvisorListState }: IAdvisorSelector): JSX.Element => (
  <div className={s.AdvisorSelectorMobile}>
    <div>
      <RadioButtonGroup label="">
        {advisorList && (
          <div className={s.AdvisorSelectorHeader}>
            <span className={s.RadioButtonWrapperAndName}>{advisorList.length} Rådgivare</span>
            <span className={s.AdvisorNumberHeaders}>
              <span className={s.Number}>Idag</span>
              <span className={classnames(s.Number, s.PL)}>In</span>
              <span className={classnames(s.Number, s.PL)}>In+/-</span>
              <span className={s.Number}>Aktuella</span>
            </span>
          </div>
        )}
        {fetchingAdvisorListState === FetchingState.LOADING && <Loading />}
        {fetchingAdvisorListState === FetchingState.SUCCESS &&
          advisorList.map((advisorFromList: IAdvisor) => (
            <div className={s.AdvisorSelectorItem} key={advisorFromList.id}>
              <span className={s.RadioButtonWrapper}>
                <RadioButton
                  label={`${advisorFromList.fornamn} ${advisorFromList.efternamn}`}
                  name="allocateto"
                  testSelector="radio-btn-selector"
                  onClick={chooseAdvisor}
                  value={advisorFromList.id}
                />
              </span>
              <span className={classnames(s.Number, s.Semibold)}>{advisorFromList.radgivarStatistik.idag}</span>
              <span className={s.Number}>{advisorFromList.radgivarStatistik.in}</span>
              <span className={s.Number}>{advisorFromList.radgivarStatistik.inPlusMinus}</span>
              <span className={s.Number}>{advisorFromList.radgivarStatistik.aktuella}</span>
            </div>
          ))}
      </RadioButtonGroup>
    </div>
  </div>
)

export default AdvisorSelectorMobile
