import { deepEqual } from '@local/src/Utils/helpers'
import { markningChipDisplayNameDic } from '@local/src/Utils/Categories'
import { ArendeMarkning, IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import { ForedragetSprak } from '@local/src/Views/Case/Case.types'

import { IDinaArendenFilterState, IArendenFilterState } from './CasesFilter.types'

const mapTagNames = (stateName: string) => {
  switch (stateName) {
    case 'RiskForUppsagningPgaArbetsbrist':
      return 'Risk'
    case 'KlientEjKontaktad':
      return 'Ej kontaktade'
    case 'NyttForMig':
      return 'Nya för mig'
    case 'Aktuellt':
      return 'Aktuella'
    case 'Fordelning':
      return 'Fördelning'
    case 'ForOmfordelning':
      return 'Omfördelning'
    case 'Nytt':
      return 'Nya'
    case 'radgivningSlutDatumMax':
      return '1,5 åring'
    case 'Avslutat':
      return 'Avslutade'
    case 'saknarInbokatMote':
      return 'Inga inbokade möten'
    case 'saknarRadgivare':
      return 'Ingen rådgivare'
    case 'Ny':
      return 'Ny begäran'
    case 'UnderBedomning':
      return 'Under bedömning'
    case 'Skickad':
      return 'Skickad till CSN'
    case 'AvvaktarSvar':
      return 'Godkänn köpt utbildning'
    case ForedragetSprak.SWEDISH:
      return 'Svenska'
    case ForedragetSprak.ENGLISH:
      return 'Engelska'
    case 'undantagsgrupp':
      return 'Undantagsgrupp'

    case ArendeMarkning.StartaForetag:
      return markningChipDisplayNameDic[ArendeMarkning.StartaForetag]
    case ArendeMarkning.Chefsomstallning:
      return markningChipDisplayNameDic[ArendeMarkning.Chefsomstallning]
    case ArendeMarkning.Undantagsgrupp:
      return markningChipDisplayNameDic[ArendeMarkning.Undantagsgrupp]
    case ArendeMarkning.ForstarktStod:
      return markningChipDisplayNameDic[ArendeMarkning.ForstarktStod]
    case ArendeMarkning.Studier:
      return markningChipDisplayNameDic[ArendeMarkning.Studier]
  }
}

const mapStudiestodAnsokanStatusarTagNames = (stateName: string) => {
  switch (stateName) {
    case 'Ny':
      return 'Ny studiestödsansökan'
    case 'Handlaggs':
      return 'Handläggs'
    case 'Godkand':
      return 'Godkänd'
  }
}

const getFilterTags = (filterKey: string, currentState: IArendenFilterState | IDinaArendenFilterState): IFilterTag[] => {
  const filteredItems = currentState[filterKey] as string[]

  if (filteredItems === undefined || filteredItems.length === 0) {
    return []
  }

  switch (filterKey) {
    case 'arendeMarkningar':
    case 'orsakerAnsokan':
    case 'subStatusar':
    case 'begaranYttrandeStatusar':
    case 'utbildningsAnmalanStatusar':
    case 'klientForedragnaSprak':
      return filteredItems.map((item) => ({
        filterParameter: filterKey,
        filterValue: item,
        displayValue: mapTagNames(item),
      }))
    case 'studiestodAnsokanStatusar':
      return filteredItems.map((item) => ({
        filterParameter: filterKey,
        filterValue: item,
        displayValue: mapStudiestodAnsokanStatusarTagNames(item),
      }))
    case 'arbetsmarknadsomraden':
    case 'radgivningAvslutsKoder':
    case 'befattningar':
    case 'foretagNamn':
    case 'klientKommuner':
    case 'klientLan':
    case 'klientOrter':
    case 'teamNamn':
    case 'projektNamn':
    case 'radgivareNamn':
      return filteredItems.map((item) => ({
        filterParameter: filterKey,
        filterValue: item,
        displayValue: item,
      }))
    default:
      return [
        {
          filterParameter: filterKey,
          filterValue: filterKey,
          displayValue: mapTagNames(filterKey),
        },
      ]
  }
}

export const buildTags = (
  currentState: IArendenFilterState | IDinaArendenFilterState,
  emptyFilterState: IArendenFilterState | IDinaArendenFilterState,
  setListOfTags: (tagList: IFilterTag[]) => void
): void => {
  const tagList: IFilterTag[] = []

  Object.entries(emptyFilterState).forEach(([key, initialValue]) => {
    const typedKey = key as keyof (IArendenFilterState | IDinaArendenFilterState)
    const isInitialState = deepEqual(currentState[typedKey], initialValue)
    const tags = isInitialState ? [] : getFilterTags(key, currentState)
    tags.forEach((tag) => tagList.push(tag))
  })

  setListOfTags(tagList)
}
