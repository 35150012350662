import styled from '@emotion/styled'
import { Avatar, Theme } from '@mui/material'

export const FilterSelectionIndicator = styled(Avatar)({
  width: '1.5rem',
  height: '1.5rem',
  marginLeft: '1rem',
  backgroundColor: '#089188',
  color: '#fff !important',
  fontSize: '0.75rem',
  fontWeight: 'bold',
})

export const FilterSelectionIconIndicator = styled(Avatar)(({ theme }) => ({
  width: '1.5rem',
  height: '1.5rem',
  backgroundColor: (theme as Theme).palette?.primary?.main, /* ugly for the sake of jest. */
  color: '#fff !important',
  fontSize: '0.75rem !important',
  fontWeight: 'bold',
}))
