import { GridColDef } from '@mui/x-data-grid-pro'
import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import PickAdvisor from '@local/src/Components/AdvisorSelectors/AdvisorSelectorDesktop'

import { FordelningsArende } from '../Fordelning.types'

export const getColumns = (): GridColDef<FordelningsArende>[] => {
  return [
    {
      field: 'kund',
      headerName: 'Kund',
      minWidth: 432,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column" gap={1} mb={1} mt={1}>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">
              {params.row.fornamn} {params.row.efternamn},{' '}
              {params.row.klientAlder} år
            </Typography>
            <Typography variant="body2" color="text.secondary">
              #{params.row.arendeNr}, {params.row.befattning}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            {params.row.engelskTalande && (
              <Chip variant="status-info" label="Engelsktalande" />
            )}
            {params.row.hasProjekt && (
              <Chip variant="status-info" label="Projekt" />
            )}
            {params.row.ateroppnatForRadgivning && (
              <Chip variant="status-warning" label="Återöppnad" />
            )}
          </Box>
          <PickAdvisor
            advisorList={[]}
            choose={() => console.log('picked')}
            chosenAdvisor={null}
          />
        </Box>
      ),
    },
    {
      field: 'foretag',
      headerName: 'Företag',
      flex: 1,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary">
          {params.row.arbetsstalle}
        </Typography>
      ),
    },
    {
      field: 'bostadsort',
      headerName: 'Bostadsort',
      flex: 1,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary">
          {params.row.ort}
        </Typography>
      ),
    },
    {
      field: 'kommun',
      headerName: 'Kommun',
      flex: 1,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary">
          {params.row.kommun}
        </Typography>
      ),
    },
    {
      field: 'fordelning',
      headerName: 'Fördelning',
      flex: 1,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary">
          {params.row.radgivningsSubStatus === 'FOROMFORDELNING'
            ? 'Omfördelning'
            : ''}
        </Typography>
      ),
    },
  ]
}
