import React from 'react'
import classnames from 'classnames'

import s from './Filter.module.scss'

export interface IFilter {
  children: JSX.Element
  isSticky?: boolean
  visible?: boolean
}

export const Filter = ({ children, isSticky = false, visible = true }: IFilter): JSX.Element => (
  <div className={classnames(s.FilterWrapper, isSticky && s.isStickyBar, visible ? s.visible : s.hidden)}>
    {children}
  </div>
)

export default Filter
