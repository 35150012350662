import React, { useEffect, useState } from 'react'
import { isSensitiveFolkbokforingsStatus as isSensitive } from '@local/src/Utils/FolkbokföringsStatus'
import { useDispatch, useSelector } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import getConfig from '@local/src/Utils/ConfigService'
import FolkbokforingsStatusChip from '@local/src/Components/PeopleRegistrationStatusChip/PeopleRegistrationStatusChip'
import { RadgivarChip } from '@local/src/Components/Chips'
import PickAdvisor from '@local/src/Components/AdvisorSelectors/AdvisorSelectorDesktop'
import { equals } from 'ramda'
import CaseAvatar from '@local/src/Components/CaseAvatar/CaseAvatar'

import { caseDetailsSelector } from '../Case.selectors'
import { isKompetensstodsArende } from '../../Arenden/Arenden.helpers'
import { tiaRadgivareList } from '../../Arenden/Arenden.selectors'
import { IAdvisor } from '../../AllocateCases/AllocateCases.types'
import { deleteTiaRadgivare, fetchTiaRadgivareList, putTiaRadgivare } from '../../Arenden/Arenden.actions'

import KompetensstodDetails from './Details/KompetensstodDetails/KompetensstodDetails'
import OmstallningsstodDetails from './Details/OmstallningsstodDetails/OmstallningsstodDetails'
import Kontaktuppgifter from './Details/Common/Kontaktuppgifter/Kontaktuppgifter'
import Kundprofiler from './Details/Common/Kundprofiler/Kundprofiler'

const ArendeHeader = (): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const arende = useSelector(caseDetailsSelector)
  const kompetensstodRadgivarList = useSelector(tiaRadgivareList)
  const [chosenAdvisor, setChosenAdvisor] = useState(arende.anstallningsArende?.radgivare as IAdvisor)
  const [expanded, setExpanded] = useState(false)

  const MEDIA_URL: string = getConfig().MEDIA_URL
  const imageSrc = arende?.klient?.klientBildFilnamn
    ? `${MEDIA_URL}/user-profile-images/${arende.klient.klientBildFilnamn}&crop=1:1,smart&width=400`
    : null
  const isKompetensstod = isKompetensstodsArende(arende.arendeTyp)
  const radgivare = isKompetensstod ? arende.anstallningsArende?.radgivare : arende.omstallningsArende?.radgivare
  const radgivareName = `${radgivare?.fornamn ?? ''} ${radgivare?.efternamn ?? ''}`.trim()

  useEffect(() => {
    if (isKompetensstod && kompetensstodRadgivarList.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(fetchTiaRadgivareList())
    }
  }, [])

  useEffect(() => {
    canUpdateTiaRadgivare() && dispatch(putTiaRadgivare(arende.id, chosenAdvisor))
  }, [chosenAdvisor])

  useEffect(() => {
    setChosenAdvisor(arende.anstallningsArende?.radgivare)
  }, [arende])

  const canUpdateTiaRadgivare = () => {
    return chosenAdvisor && !equals(chosenAdvisor, arende.anstallningsArende?.radgivare)
  }

  const removeAdvisor = () => {
    dispatch(deleteTiaRadgivare(arende.id))
    setChosenAdvisor(null)
  }

  const onClickSummary = (event: React.MouseEvent) => {
    const target = event.target as Element
    const clickableElements = ['DIV', 'path', 'svg']
    if (expanded) {
      setExpanded(!clickableElements.includes(target.nodeName))
    } else {
      setExpanded(clickableElements.includes(target.nodeName))
    }
  }

  const arendeChips = (): JSX.Element => {
    return (
      <>
        {isKompetensstod ? (
          <PickAdvisor
            advisorList={kompetensstodRadgivarList}
            choose={setChosenAdvisor}
            chosenAdvisor={chosenAdvisor}
            remove={removeAdvisor}
          />
        ) : (
          <RadgivarChip radgivareNamn={radgivareName} testSelector="radgivareChip" />
        )}
        <CaseAvatar arendeTyp={arende.arendeTyp} />
        {isSensitive(arende.klient.folkbokforingsStatus) && (
          <FolkbokforingsStatusChip status={arende.klient.folkbokforingsStatus} variant="outlined" />
        )}
        {arende.statusDisplayText && <Chip data-cy="case-status-chip" label={arende.statusDisplayText} />}
      </>
    )
  }

  return (
    <Accordion disableGutters elevation={0} sx={{ border: '1px solid', borderColor: 'neutral.divider' }} expanded={expanded}>
      <AccordionSummary
        expandIcon={isMobile ? null : <ExpandMoreIcon aria-label="Visa detaljer" />}
        sx={{ px: 1, userSelect: 'text' }}
        onMouseUp={onClickSummary}
      >
        {isMobile ? (
          <Stack
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={1}
            flexWrap="wrap"
            justifyContent="space-between"
            px={0}
            sx={{ cursor: 'default' }}
          >
            <Box display="flex" gap={2}>
              <Avatar src={imageSrc} sx={{ width: 54, height: 54 }} />
              <Box display="flex" flexDirection="column">
                <Typography variant="h5">
                  {arende.klient.fornamn} {arende.klient.efternamn}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" alignSelf="baseline">
                  #{arende.arendeNummer}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>{arendeChips()}</Box>
            <Kontaktuppgifter telefonnummer={arende.klient.telefonnummer} epost={arende.klient.epost} />
            {!isKompetensstodsArende(arende.arendeTyp) && (
              <Kundprofiler
                klientId={arende.klient.id}
                linkedInUrl={arende.klient.linkedInUrl}
                hasActiveMatchProfile={arende.omstallningsArende.hasActiveMatchProfile}
              />
            )}
          </Stack>
        ) : (
          <Stack
            display="flex"
            flexDirection="row"
            alignItems={isKompetensstodsArende(arende.arendeTyp) ? 'center' : 'flex-start'}
            pr={2}
            pl={1}
            gap={2}
            width="100%"
            sx={{ cursor: 'default' }}
          >
            <Box>
              <Avatar src={imageSrc} sx={{ width: 54, height: 54 }} />
            </Box>
            <Box display="flex" flexDirection="column" gap={1} width="100%">
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Typography variant="h5">
                  {arende.klient.fornamn} {arende.klient.efternamn}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>{arendeChips()}</Box>
              </Box>
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <Typography variant="subtitle1" color="text.secondary" pr={3}>
                  #{arende.arendeNummer}
                </Typography>
                <Kontaktuppgifter telefonnummer={arende.klient.telefonnummer} epost={arende.klient.epost} />
              </Box>
              {!isKompetensstodsArende(arende.arendeTyp) && (
                <Kundprofiler
                  klientId={arende.klient.id}
                  linkedInUrl={arende.klient.linkedInUrl}
                  hasActiveMatchProfile={arende.omstallningsArende.hasActiveMatchProfile}
                />
              )}
            </Box>
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <Divider sx={{ mb: 2 }} />
        {isKompetensstodsArende(arende.arendeTyp) ? <KompetensstodDetails arende={arende} /> : <OmstallningsstodDetails arende={arende} />}
      </AccordionDetails>
    </Accordion>
  )
}

export default ArendeHeader
