import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { useGetArbetsuppgifterQuery, useGetInitialArbetsuppgiftFacetsQuery } from '@local/src/Api/Arbetsuppgift/arbetsuppgiftApi'
import { useAppDispatch } from '@local/src/Store/configureStore'
import { addErrorMessage } from '@local/src/Blocks/UserFeedback/UserFeedback.actions'
import useArbetsuppgiftFilter from '@local/src/Hooks/useArbetsuppgiftFilter'
import { useGetColleaguesQuery } from '@local/src/Api/UserProfile/userProfileApi'

import ArbetsuppgiftTable from './Table/ArbetsuppgiftTable'
import { getQueryParameters } from './helpers'
import ArbetsuppgiftFilters from './Filter/ArbetsuppgiftFilters'
import ArbetsuppgiftFilterContext from './Filter/ArbetsuppgiftFilterContext'
import { ArbetsuppgiftFilter, ArbetsuppgiftOrderBy, ArbetsuppgiftPagination } from './types'

const Arbetsuppgift = () => {
  const [filters, setFilter, clearFilter] = useArbetsuppgiftFilter()
  const dispatch = useAppDispatch()
  const { data: advisors } = useGetColleaguesQuery()
  const queryParams = getQueryParameters(filters)
  const {
    data: arbetsuppgiftData,
    isLoading: isLoadingArbetsuppgifter,
    isError: isErrorArbetsuppgiftData,
  } = useGetArbetsuppgifterQuery(queryParams)

  const {data: initialFacets} = useGetInitialArbetsuppgiftFacetsQuery()

  const handleFilter = (key: keyof ArbetsuppgiftFilter, values: string | string[] | ArbetsuppgiftPagination | ArbetsuppgiftOrderBy) => {
    setFilter(key, values)
  }

  if (isLoadingArbetsuppgifter) return <CircularProgress />

  if (isErrorArbetsuppgiftData) {
    dispatch(addErrorMessage('Något gick fel vid hämtning av arbetsuppgifter'))
    return <></>
  }

  return (
    <Box>
      <ArbetsuppgiftFilterContext.Provider
        value={{
          filters,
          advisors,
          initialFacets,
          setFilter: handleFilter,
          clearFilter,
        }}
      >
        <ArbetsuppgiftFilters />
      </ArbetsuppgiftFilterContext.Provider>
      <ArbetsuppgiftTable
        rows={arbetsuppgiftData.results}
        totalHits={arbetsuppgiftData.totalCount}
        page={filters.pagination.page}
        pageSize={filters.pagination.pageSize}
        orderBy={filters.orderBy}
        onPaginationChange={handleFilter}
        onSortChange={handleFilter}
      />
    </Box>
  )
}

export default Arbetsuppgift
