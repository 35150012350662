import { GridRowParams } from '@mui/x-data-grid-pro'
import React, { useState } from 'react'
import { Box, Button, ListItemText, Stack } from '@mui/material'
import { format } from 'date-fns'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'

import { FordelningsArende, Team } from '../Fordelning.types'

interface FordelningTableDetailPanelProps {
  params: GridRowParams<FordelningsArende>
  teams: Team[]
}

const FordelningTableDetailPanel = ({
  params,
  teams,
}: FordelningTableDetailPanelProps) => {
  const [selectedTeam, setSelectedTeam] = useState<Team>(null)

  return (
    <Stack gap={2} pt={1} pb={2} pl={7.5}>
      <Box display="flex">
        <ListItemText
          primary="Orsak till uppsägning"
          secondary={params.row.orsakTillUppsagningTxt}
        />
        <ListItemText
          primary="Sista anst. dag"
          secondary={
            params.row.sistaAnstallningsdag
              ? format(new Date(params.row.sistaAnstallningsdag), 'yyyy-MM-dd')
              : 'Inget datum'
          }
        />
        <ListItemText primary="Lön" secondary={params.row.lon} />
        <ListItemText
          primary="Aktuellt ärende"
          secondary="Ingen tidigare rådgivare"
        />
        <ListItemText
          primary="Tidigare ärenden"
          secondary="Inga tidigare ärenden"
        />
      </Box>
      <Box display="flex" gap={1}>
        <Box width={217}>
          <Dropdown
            options={teams}
            label="Byt fördelningsteam"
            size="small"
            getOptionLabel={(team: Team) => team.namn}
            getOptionValue={(team: Team) => team.id}
            onChange={(team) =>
              setSelectedTeam(teams.find((t) => team.target.value === t.id))
            }
            value={selectedTeam}
          />
        </Box>
        <Button size="small" variant="outlined">
          Spara
        </Button>
      </Box>
    </Stack>
  )
}

export default FordelningTableDetailPanel
