// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oT1r2feXoXQWfqkavkrU{padding-bottom:8px;margin-bottom:8px;direction:ltr}.oT1r2feXoXQWfqkavkrU button{display:inline-flex;align-items:center}.oT1r2feXoXQWfqkavkrU.divcMphD5wUa2QviMqTF{border-bottom:1px solid #b3b3b3}.DuW9qZ8tNr5jtJB9WM3a{position:sticky;overflow-y:auto;height:100vh;top:32px;direction:rtl;max-width:280px;min-width:280px;margin-top:16px}.DuW9qZ8tNr5jtJB9WM3a.GtgnazCnjM9454jNUJIk{flex:0 1 32px;min-width:unset}`, "",{"version":3,"sources":["webpack://./src/Views/Arenden/ArendenFilterPanel/ArendenFilterPanel.module.scss","webpack://./node_modules/@trr/common-component-library/lib/Styles/variables/structure.scss"],"names":[],"mappings":"AAEA,sBACE,kBCGO,CDFP,iBCEO,CDDP,aAAA,CAEA,6BACE,mBAAA,CACA,kBAAA,CAGF,2CACE,+BAAA,CAIJ,sBACE,eAAA,CACA,eAAA,CACA,YAAA,CACA,QCZY,CDaZ,aAAA,CACA,eAAA,CACA,eAAA,CACA,eClBc,CDoBd,2CACE,aAAA,CACA,eAAA","sourcesContent":["@import 'src/Common.module.scss';\n\n.FilterPanelHeader {\n  padding-bottom: $gutter;\n  margin-bottom: $gutter;\n  direction: ltr;\n\n  button {\n    display: inline-flex;\n    align-items: center;\n  }\n\n  &.BorderBottom {\n    border-bottom: 1px solid $gray-4;\n  }\n}\n\n.StickyPanel {\n  position: sticky;\n  overflow-y: auto;\n  height: 100vh;\n  top: $quad-gutter;\n  direction: rtl;\n  max-width: 280px;\n  min-width: 280px;\n  margin-top: $double-gutter;\n\n  &.Shrink {\n    flex: 0 1 $quad-gutter;\n    min-width: unset;\n  }\n}\n","@use 'sass:math';\n\n$base-gutter: 4px;\n\n$quarter-gutter: math.div($base-gutter, 2);\n$half-gutter: $base-gutter;\n$gutter: $base-gutter * 2;\n$double-gutter: $gutter * 2;\n$triple-gutter: $gutter * 3;\n$quad-gutter: $gutter * 4;\n$quint-gutter: $gutter * 5;\n\n$default-gutter: $gutter;\n\n@function gutter($count: 1) {\n  @return $gutter * $count;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"purpleDark": `#411782`,
	"purple100": `#6222c3`,
	"purple80": `#732fdb`,
	"purple60": `#8a51e1`,
	"purple40": `#a77de8`,
	"purple20": `#d4c1ee`,
	"purple10": `#f2edf8`,
	"green100": `#089188`,
	"green80": `#0bc1b5`,
	"green60": `#26d9cd`,
	"green40": `#50e2d9`,
	"green20": `#b5ede9`,
	"green10": `#e3f8f6`,
	"yellow100": `#ffc700`,
	"yellow80": `#ffe433`,
	"yellow60": `#ffeb66`,
	"yellow40": `#fff5b2`,
	"yellow20": `#fdf9e2`,
	"yellow10": `#fdf9e2`,
	"blue100": `#1437b8`,
	"blue80": `#264cd9`,
	"blue60": `#476aeb`,
	"blue40": `#7d94e8`,
	"blue20": `#bfcaf2`,
	"blue10": `#edf0fd`,
	"orange100": `#cc4900`,
	"orange80": `#e56319`,
	"orange60": `#f57f3d`,
	"orange40": `#fb946a`,
	"orange20": `#fdcab5`,
	"orange10": `#fff1eb`,
	"rose100": `#bf072f`,
	"rose80": `#e51946`,
	"rose60": `#eb476b`,
	"rose40": `#f07590`,
	"rose20": `#f9b9c7`,
	"rose10": `#fdedf0`,
	"petrol100": `#0a5c56`,
	"petrol80": `#0f8a82`,
	"petrol60": `#0bc1b5`,
	"petrol40": `#26d9cd`,
	"petrol20": `#81e4de`,
	"petrol10": `#e3f8f6`,
	"gray0": `#1a1a1a`,
	"gray1": `#4c4c4c`,
	"gray2": `gray`,
	"gray3": `#999`,
	"gray4": `#b3b3b3`,
	"gray5": `#ccc`,
	"gray6": `#f2f2f2`,
	"white": `#fff`,
	"black": `#000`,
	"gray": `#1a1a1a`,
	"textColor": `#1a1a1a`,
	"disabledColor": `#f2f2f2`,
	"letterSpacingHeading": `-0.3px`,
	"linkColor": `#6222c3`,
	"FilterPanelHeader": `oT1r2feXoXQWfqkavkrU`,
	"BorderBottom": `divcMphD5wUa2QviMqTF`,
	"StickyPanel": `DuW9qZ8tNr5jtJB9WM3a`,
	"Shrink": `GtgnazCnjM9454jNUJIk`
};
export default ___CSS_LOADER_EXPORT___;
