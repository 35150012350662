import React, { useState, useEffect } from 'react'
import { Dropdown, Button, Dialog, Loading, Link, Typography } from '@trr/common-component-library'
import { ISelectOption, FetchingState, IPastCase, IPastAdvisor, ITeam } from '@local/src/Common.types'
import { IChangeTeamBody } from '@local/src/Views/Case/Case.actions'
import { formatDateFromDateTime } from '@local/Utils/DateTime'
import { getUrlByCaseEnv } from '@local/src/basename'
import { useCaseEnvBasePath, useIsSmallScreen, useToggle } from '@local/src/Hooks'
import { ArendeTyp } from '@local/src/Views/Arenden/Arenden.types'

import { formatAmount } from '../../Utils/smallfuncs'

import s from './AllocateDetails.module.scss'

interface IAllocateDetails {
  dismissedBy: string
  salary: number
  teamId: string
  allTeams: ITeam[]
  allocatableTeams: ITeam[]
  firstName: string
  lastName: string
  clientId: string
  caseId: string
  changeTeam(changeTeamPayload: IChangeTeamBody): void
  changingClientAllocationTeamState: FetchingState
  isStaged: boolean
  fetchAllocationDetails(clientId: string, caseId: string): void
  pastCases?: IPastCase[]
  pastAdvisors?: IPastAdvisor[]
  fetchingAllocationDetails: FetchingState
}

const AllocateDetails = ({
  dismissedBy,
  salary,
  teamId,
  allTeams,
  firstName,
  lastName,
  clientId,
  caseId,
  changeTeam,
  changingClientAllocationTeamState,
  isStaged,
  fetchAllocationDetails,
  pastCases = null,
  pastAdvisors = null,
  fetchingAllocationDetails,
  allocatableTeams,
}: IAllocateDetails): JSX.Element => {
  const [team, setTeam] = useState<ISelectOption>({ value: 'Välj fördelningsteam', label: 'Välj fördelningsteam' })
  const [changeTeamValue, setChangeTeamValue] = useState(team)
  const { isEnabled: isModalVisible, toggle: toggleModal } = useToggle()
  const [selectableTeams, setSelectableTeams] = useState(null)
  const isSmallScreen = useIsSmallScreen()
  const caseEnvBasePath = useCaseEnvBasePath()

  const getTeam = (teamId: string) => {
    const team = allTeams.filter((item: ITeam) => item.id === teamId.toString())
    return { value: team[0]?.id, label: team[0]?.namn }
  }

  useEffect(() => {
    pastCases === null && fetchAllocationDetails(clientId, caseId)
    setTeam(getTeam(teamId))
    setSelectableTeams(allocatableTeams.map((item: ITeam) => ({ value: item.id, label: item.namn })))
  }, [])

  const handleConfirm = () =>
    changeTeam({
      arendeId: caseId.toString(),
      teamId: changeTeamValue.value.toString(),
    })

  const onTeamChange = (event: ISelectOption) => setChangeTeamValue(event)

  const setTidigareArendenDisplayText = (arende: IPastCase) => {
    let displayText = arende.avslutsDatum ? formatDateFromDateTime(arende.avslutsDatum) : 'Pågående'
    if (arende.arendeTyp === ArendeTyp.Omstallning) {
      displayText +=
        arende.radgivareFornamn || arende.radgivareEfternamn
          ? ` ${arende.radgivareFornamn} ${arende.radgivareEfternamn}`
          : ' Rådgivare saknas'
    } else if (arende.arendeTyp === ArendeTyp.Kompetensstod) {
      return (displayText += ' Kompetensstödsärende')
    }
    return displayText
  }

  return (
    <>
      <div className={s.Wrapper}>
        {isModalVisible && (
          <Dialog heading="Byt team" onCancel={toggleModal} cancelText="Avbryt" onConfirm={handleConfirm} confirmText="Byt team">
            {changingClientAllocationTeamState === FetchingState.LOADING ? (
              <Loading />
            ) : (
              <p>{`${firstName} ${lastName} kommer att byta team till ${changeTeamValue.label}.`}</p>
            )}
          </Dialog>
        )}
        <div className={s.Column}>
          <Typography variant="subtitle2">Orsak till uppsägning</Typography>
          <p data-testid={'dismissedBy'}>{dismissedBy}</p>
        </div>
        <div className={s.Column}>
          <Typography variant="subtitle2">Lön</Typography>
          <p data-salary={salary} data-testid={'salary'}>
            {formatAmount(salary)}
          </p>
        </div>
        <div className={s.Column}>
          <Typography variant="subtitle2">Aktuellt ärende</Typography>
          {fetchingAllocationDetails === FetchingState.LOADING && pastAdvisors === null ? (
            <Loading />
          ) : pastAdvisors !== null && pastAdvisors.length !== 0 ? (
            <ul>
              {pastAdvisors.map((item: IPastAdvisor) => {
                return (
                  <li key={item.id} className={s.PastCase}>
                    <p>{`${item.fornamn} ${item.efternamn} `}</p>
                  </li>
                )
              })}
            </ul>
          ) : (
            'Inga tidigare rådgivare'
          )}
        </div>
        <div className={s.Column}>
          <Typography variant="subtitle2">Tidigare ärenden</Typography>
          {fetchingAllocationDetails === FetchingState.LOADING && pastCases === null ? (
            <Loading />
          ) : pastCases !== null && pastCases.length !== 0 ? (
            <ul>
              {pastCases.map((item: IPastCase) => {
                return (
                  <Link noChevron key={item.arendeId} link={getUrlByCaseEnv(caseEnvBasePath, item.arendeId)}>
                    <li className={s.PastCase}>{setTidigareArendenDisplayText(item)}</li>
                  </Link>
                )
              })}
            </ul>
          ) : (
            'Inga tidigare ärenden'
          )}
        </div>
      </div>
      <div className={s.ColumnSecondRow}>
        <Typography variant="body1">
          {isStaged
            ? 'Fördelningsteam kan inte bytas när rådgivare är vald.'
            : `Byt omställningskundens fördelningsteam. Nuvarande är ${team.label}.`}
        </Typography>
        <div className={s.DetailsSecondRow}>
          <div className={s.DetailsDropdown}>
            <Dropdown
              disabled={isStaged}
              onChange={onTeamChange}
              isSearchable={!isSmallScreen}
              label="Välj fördelningsteam"
              value={changeTeamValue}
              selectOptions={selectableTeams}
            />
          </div>
          <Button disabled={isStaged || changeTeamValue.value === 'Välj team'} secondary large onClick={toggleModal}>
            Spara
          </Button>
        </div>
      </div>
    </>
  )
}

export default AllocateDetails
