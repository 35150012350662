import { createActions } from 'redux-actions'
import { deleteRequest, get, put } from '@local/Utils/network'
import { abortController } from '@local/src/Utils/helpers'

import { IAdvisor } from '../AllocateCases/AllocateCases.types'

export const {
  fetchCaseListInitiate,
  fetchCaseListSuccess,
  fetchCaseListFailure,
  fetchCaseListFacetsInitiate,
  fetchCaseListFacetsSuccess,
  fetchCaseListFacetsFailure,
  fetchInitialCaseListFacetsInitiate,
  fetchInitialCaseListFacetsSuccess,
  fetchInitialCaseListFacetsFailure,
  removeFilterParameterValuePair,
  resetArendenFilter,
  setArendenSearchText,
  setArendenOrderBy,
  setArendenPage,
  setFilterParameters,

  putTiaRadgivareInitiate,
  putTiaRadgivareSuccess,
  putTiaRadgivareFailure,

  deleteTiaRadgivareInitiate,
  deleteTiaRadgivareSuccess,
  deleteTiaRadgivareFailure,
} = createActions({
  removeFilterParameterValuePair: (parameterValuePair) => ({ parameterValuePair }),
  resetArendenFilter: () => null,
  setArendenPage: (page) => ({ page }),
  setArendenOrderBy: (orderBy) => ({ orderBy }),
  setArendenSearchText: (searchText) => ({ searchText }),
  setFilterParameters: (filterParams) => ({ filterParams }),

  fetchCaseListFacetsInitiate: () => null,
  fetchCaseListFacetsSuccess: (facetsResponse) => ({ facetsResponse }),
  fetchCaseListFacetsFailure: (errorMessage) => `Det gick inte att hämta filtreringsalternativ till ärendelistan. ${String(errorMessage)}`,

  fetchInitialCaseListFacetsInitiate: () => null,
  fetchInitialCaseListFacetsSuccess: (facetsResponse) => ({ facetsResponse }),
  fetchInitialCaseListFacetsFailure: (errorMessage) =>
    `Det gick inte att hämta filtreringsalternativ till ärendelistan. ${String(errorMessage)}`,

  fetchCaseListInitiate: () => null,
  fetchCaseListSuccess: (caseObject) => ({ caseObject }),
  fetchCaseListFailure: (errorMessage) => `Det gick inte att hämta ärendelistan. ${String(errorMessage)}`,

  fetchTiaRadgivareListInitiate: () => null,
  fetchTiaRadgivareListSuccess: (tiaRadgivareList) => ({ tiaRadgivareList }),
  fetchTiaRadgivareListFailure: (errorMessage) => `Det gick inte att hämta rådgivarelistan. ${String(errorMessage)}`,

  putTiaRadgivareInitiate: () => null,
  putTiaRadgivareSuccess: () => null,
  putTiaRadgivareFailure: (errorMessage) => `Det gick inte att ändra rådgivare. ${String(errorMessage)}`,

  deleteTiaRadgivareInitiate: () => null,
  deleteTiaRadgivareSuccess: () => null,
  deleteTiaRadgivareFailure: (errorMessage) => `Det gick inte att ta bort rådgivare. ${String(errorMessage)}`,
})

export const fetchArenden = abortController(
  (abortableFetch: unknown) => (query?: string) =>
    get({
      url: `/arenden/${query ? query : ''}`,
      initiated: fetchCaseListInitiate,
      fulfilled: fetchCaseListSuccess,
      rejected: fetchCaseListFailure,
      _fetch: abortableFetch,
      apiVersion: '2.0',
    })
)

export const fetchCaseListFacets = abortController(
  (abortableFetch: unknown) => (query?: string) =>
    get({
      url: `/arenden${query ? query : ''}`,
      initiated: fetchCaseListFacetsInitiate,
      fulfilled: fetchCaseListFacetsSuccess,
      rejected: fetchCaseListFacetsFailure,
      _fetch: abortableFetch,
      apiVersion: '2.0',
    })
)

export const fetchInitialCaseListFacets = abortController(
  (abortableFetch: unknown) => (query?: string) =>
    get({
      url: `/arenden${query ? query : ''}`,
      initiated: fetchInitialCaseListFacetsInitiate,
      fulfilled: fetchInitialCaseListFacetsSuccess,
      rejected: fetchInitialCaseListFacetsFailure,
      _fetch: abortableFetch,
      apiVersion: '2.0',
    })
)

export const putTiaRadgivare = (arendeId: string, radgivare: IAdvisor) =>
  put({
    url: `/anstallningsarenden/radgivare/?arendeId=${arendeId}`,
    initiated: putTiaRadgivareInitiate,
    fulfilled: putTiaRadgivareSuccess,
    rejected: putTiaRadgivareFailure,
    body: radgivare,
    apiVersion: '2.0',
  })

export const deleteTiaRadgivare = (arendeId: string) =>
  deleteRequest({
    url: `/anstallningsarenden/radgivare/?arendeId=${arendeId}`,
    initiated: deleteTiaRadgivareInitiate,
    fulfilled: deleteTiaRadgivareSuccess,
    rejected: deleteTiaRadgivareFailure,
    apiVersion: '2.0',
  })
