import { ISelectOption } from '@local/src/Common.types'

import { Facet } from '../Arenden/Arbetsuppgift/types'

export interface StartklarFilter {
  searchText: string
  subStatusar: 'Nytt'
  handlaggareIdn: string[]
  ejTilldeladHandlaggare: '' | 'true'
  arbetsmarknadsomraden: string[]
  foretagNamn: string[]
  pagination: StartklarPagination
  orderBy: string
}

export const StartklarSortOptions: ISelectOption[] = [
  { value: 'SubStatusDatum asc', label: 'Dagar - äldsta' },
  { value: 'SubStatusDatum desc', label: 'Dagar - nyaste' },
  { value: 'StartklarAntalUppringningsforsok asc', label: 'Samtal - stigande' },
  { value: 'StartklarAntalUppringningsforsok desc', label: 'Samtal - fallande' },
]

export interface StartklarPagination {
  page: number
  pageSize: number
}

export interface IHandlaggare {
  fornamn: string
  efternamn: string
  id: string
}

export interface ICaseStartklar {
  antalUppringningsforsok: number
  arbetsstalle: string
  attesteringsDatum: Date
  befattning: string
  dagNummer: number
  folkbokforingsStatus: string
  handlaggare: IHandlaggare
  id: string
  klientEmail: string
  klientMobilnummer: string
  klientNamn: string
  kommun: string
}

export interface ICaseListStartklar {
  totalHits: number
  totalPages: number
  arenden: ICaseStartklar[]
  facets: StartklarFacets
}

export interface StartklarFacets {
  ArbetsmarknadsomradeNamn: Facet[]
  ForetagNamn: Facet[]
}

export interface IStartklarCaseDetails {
  klientId: string
  arendeNr: string
  arbetsstalle: string
  avgangsvederlagSlutDatum: Date
  befattning: string
  efternamn: string
  epost: string
  fornamn: string
  id: string
  klientAlder: string
  klientKommun: string
  orsakTillUppsagningTxt: string
  sistaAnstallningsdag: string
  telefonnummer: string
  totalLon: number
  uppsagningsdatum: string
}
