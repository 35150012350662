import { IAdvisor } from '@local/src/Views/AllocateCases/AllocateCases.types'

import { baseApi } from '../baseApi'

const baseUrl = '/v1.0/userProfile'

export const userProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getColleagues: builder.query<IAdvisor[], void>({
      query: () => `${baseUrl}/arbetsuppgift/colleagues`,
    }),
  }),
})

export const { useGetColleaguesQuery } = userProfileApi
