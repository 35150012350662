import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { boolean, date, number, object, string } from 'yup'
import Loading from '@local/src/Components/Loading/Loading'
import { FetchingState, ISelectOption } from '@local/src/Common.types'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'
import Autocomplete from '@local/src/Components/Autocomplete/Autocomplete'
import { klientSelector } from '@local/src/Views/Case/Case.selectors'
import { useHistory } from 'react-router-dom'
import useScrollToFirstError from '@local/src/Hooks/useScrollToFirstError'

import {
  avslutaArendeStateSelector,
  branscherSelector,
  fetchingBranscherStateSelector,
  fetchingOrterStateSelector,
  orterSelector,
} from '../../../Avslut.selectors'
import { convertErToAvslutsfragorSvar, foretagstypOptions, hasError } from '../Common/AvslutForms.helpers'
import { AvslutaArendeWithQuestionsRequest, Avslutskod, Bransch, EgenRorelseFormValues } from '../../../Avslut.types'
import HandleActivities from '../../HandleActivities/HandleActivities'
import BranschModal from '../Common/BranschModal/BranschModal'
import AvslutaWithReminder from '../Common/AvslutaWithReminder/AvslutaWithReminder'
import {
  ISubmitQuestionResponsesRequestParameters,
  avslutaArendeWithQuestions,
  fetchBranscher,
  fetchOrter,
  submitQuestionsResponses,
} from '../../../Avslut.actions'

interface EgenRorelseProps {
  arendeId: string
  isUnhandledAvslutshandelse?: boolean
  title: string
  avslutsdatum?: Date
  avslutsId?: string
}

const EgenRorelse = ({ arendeId, isUnhandledAvslutshandelse, title, avslutsdatum, avslutsId }: EgenRorelseProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activitiesHandled, setActivitiesHandled] = useState(false)
  const [showActivitiesErrorMessage, setShowActivitiesErrorMessage] = useState(false)
  const kund = useSelector(klientSelector)
  const branscher = useSelector(branscherSelector)
  const orter = useSelector(orterSelector)
  const fetchingBranscher = useSelector(fetchingBranscherStateSelector)
  const fetchingOrter = useSelector(fetchingOrterStateSelector)
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)
  const loading = fetchingBranscher === FetchingState.LOADING || fetchingOrter === FetchingState.LOADING

  useEffect(() => {
    if (orter.length === 0 || branscher.length === 0) {
      dispatch(fetchBranscher())
      dispatch(fetchOrter())
    }
  }, [])

  useEffect(() => {
    if (orter?.length > 0 && kund) {
      const kundOrt = orter.find((ort) => ort.toLocaleLowerCase() === kund.ort?.toLocaleLowerCase())
      kundOrt && formik.setFieldValue('town', kundOrt)
    }
  }, [orter])

  useEffect(() => {
    if (showActivitiesErrorMessage && activitiesHandled) {
      setShowActivitiesErrorMessage(false)
    }
  }, [activitiesHandled])

  const handleCancel = () => history.goBack()

  const handleSubmit = (values: EgenRorelseFormValues) => {
    if (isUnhandledAvslutshandelse && formik.isValid) {
      const request: ISubmitQuestionResponsesRequestParameters = {
        arendeId,
        avslutskod: Avslutskod.EgenRorelse,
        avslutsId,
        frageSvar: convertErToAvslutsfragorSvar(values),
      }
      dispatch(submitQuestionsResponses(request))
      return
    }

    if (!activitiesHandled) {
      setShowActivitiesErrorMessage(true)
      return
    }

    if (formik.isValid && activitiesHandled) {
      const request: AvslutaArendeWithQuestionsRequest = {
        arendeId: arendeId,
        avslutsFragorSvar: convertErToAvslutsfragorSvar(values),
        radgivningAvslutsdatum: values.avslutsDatum.toISOString(),
        radgivningAvslutskod: Avslutskod.EgenRorelse,
      }
      dispatch(avslutaArendeWithQuestions(request))
    }
  }

  const initialValues: EgenRorelseFormValues = {
    avslutsDatum: new Date(),
    companyName: undefined,
    dateOfRegistration: null,
    spareTimeJobSinceBefore: false,
    companyType: undefined,
    industry: undefined,
    town: undefined,
    isTownAbroad: false,
    previouslyWorkedInIndustry: null,
    previouslyWorkedInProfession: null,
    registrationAfterCeasedEmployment: null,
    foundEmployerAtTRR: false,
  }

  const formik = useFormik({ onSubmit: handleSubmit, initialValues, validationSchema, validateOnMount: true })
  useScrollToFirstError(formik.isValid, formik.isSubmitting, 24)

  const handleAvslutWithReminderClick = () => {
    if (!activitiesHandled) {
      setShowActivitiesErrorMessage(true)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Stack display="flex" flexDirection="column" alignItems="stretch" gap={5} component="form" onSubmit={formik.handleSubmit}>
      <Box>
        <Typography variant="h5">{title}</Typography>
        {!isUnhandledAvslutshandelse && (
          <>
            <Typography mt={2} variant="body1" whiteSpace="pre-line" mb={2}>
              Avslutsdatum är när omställningskunden registrerat F-skatt eller aktiverar en vilande verksamhet. Företagandet är huvudsaklig
              sysselsättning, och omställningskunden har ingen a-kassa. Avslutsdatum styr beräkningen av omställningskundens kvarvarande tid
              för omställningsstöd.
            </Typography>
            <Typography variant="body1" mb={3}>
              Om omställningskunden behöver fortsatt stöd i form av rådgivning eller deltar i aktiviteter på plats ska istället sista dag
              för detta anges som avslutsdatum. Dock senast efter 3 månader.
            </Typography>

            <DesktopDatePicker
              label="Avslutsdatum"
              disableFuture
              sx={{ maxWidth: '228px' }}
              onChange={(value: Date) => void formik.setFieldValue('avslutsDatum', value)}
              value={isUnhandledAvslutshandelse ? avslutsdatum : formik.values.avslutsDatum}
              slotProps={{
                textField: {
                  name: 'avslutsDatum',
                  error: hasError(formik, 'avslutsDatum', true),
                  helperText: formik.errors?.avslutsDatum as string,
                  onBlur: formik.handleBlur,
                },
              }}
            />
          </>
        )}
      </Box>

      <HandleActivities setActivitiesHandled={setActivitiesHandled} showErrorMessage={showActivitiesErrorMessage} />
      <Box>
        <Typography variant="h5">Företagsuppgifter</Typography>
        <Typography variant="body1" mb={3}>
          Fullständigt namn på företaget som registrerats på Bolagsverket.
        </Typography>
        <TextField
          sx={{ width: { xs: '100%', sm: '480px' } }}
          label="Företagsnamn"
          name="companyName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.companyName}
          error={hasError(formik, 'companyName', false)}
          helperText={hasError(formik, 'companyName', false) && formik.errors?.companyName}
        />
      </Box>

      <Box>
        <Typography variant="h6">Registreringsdatum</Typography>
        <Typography variant="body1">Datum då företaget registrerades på Bolagsverket.</Typography>
        <FormGroup>
          <DesktopDatePicker
            label="Registreringsdatum"
            disableFuture
            sx={{ maxWidth: '228px', my: 3 }}
            onChange={(value: Date) => void formik.setFieldValue('dateOfRegistration', value)}
            value={formik.values.dateOfRegistration}
            slotProps={{
              textField: {
                name: 'dateOfRegistration',
                error: hasError(formik, 'dateOfRegistration'),
                helperText: hasError(formik, 'dateOfRegistration') && (formik.errors?.dateOfRegistration as string),
                onBlur: formik.handleBlur,
              },
            }}
          />
          <FormControlLabel
            label="Bisyssla sedan tidigare"
            name="spareTimeJobSinceBefore"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.spareTimeJobSinceBefore}
            sx={{ alignSelf: 'baseline' }}
            control={<Checkbox />}
          />
        </FormGroup>
      </Box>

      <Box>
        <Typography variant="h6">Bolagsform</Typography>
        <Typography variant="body1" mb={3}>
          Företagets bolagsform som registrerats på Bolagsverket.
        </Typography>
        <Dropdown
          label="Bolagsform"
          options={foretagstypOptions}
          getOptionLabel={(value: ISelectOption) => value.label}
          getOptionValue={(value: ISelectOption) => value.value}
          value={formik.values.companyType}
          onBlur={formik.handleBlur}
          name="companyType"
          testSelector="bolagsform"
          error={hasError(formik, 'companyType')}
          errorText={formik.errors?.companyType}
          maxWidth="228px"
          onChange={formik.handleChange}
        />
      </Box>

      <Box>
        <Typography variant="h6">Bransch</Typography>
        <Typography variant="body1">Den bransch som arbetsgivaren verkar inom.</Typography>
        <Autocomplete
          label="Bransch"
          name="industry"
          sx={{ width: { xs: '100%', sm: '480px' }, my: 3 }}
          options={branscher}
          value={formik.values.industry}
          getOptionLabel={(value: Bransch) => value.name}
          onChange={(value: Bransch) => void formik.setFieldValue('industry', value)}
          onBlur={formik.handleBlur}
          error={hasError(formik, 'industry')}
          errorText={formik.errors?.industry as string}
        />
        <BranschModal branscher={branscher} />
      </Box>

      <Box>
        <Typography variant="h6">Stationeringsort</Typography>
        <Typography variant="body1" mb={2}>
          Den ort där företaget har sin hemvist.
        </Typography>
        <Autocomplete
          label="Stationeringsort"
          name="town"
          options={orter}
          value={formik.values.town}
          sx={{ width: { xs: '100%', sm: '480px' }, mt: 3 }}
          getOptionLabel={(value: string) => value}
          onChange={(value: string) => void formik.setFieldValue('town', value)}
          onBlur={formik.handleBlur}
          disabled={formik.values.isTownAbroad}
          error={hasError(formik, 'town')}
          errorText={formik.errors?.town}
        />
        <FormControlLabel
          label="Utlandsort"
          name="isTownAbroad"
          sx={{ mt: 3, alignSelf: 'baseline' }}
          value={formik.values.isTownAbroad}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          control={<Checkbox />}
        />
      </Box>

      <Box>
        <Typography variant="h5" mb={3}>
          Anställning
        </Typography>
        <Box display="flex" flexDirection="column" gap={5}>
          <FormControl error={formik.errors?.previouslyWorkedInIndustry !== undefined}>
            <Typography id="previouslyWorkedInIndustry" variant="subtitle1">
              Har jobbat i branschen tidigare
            </Typography>
            <RadioGroup
              aria-labelledby="previouslyWorkedInIndustry"
              name="previouslyWorkedInIndustry"
              value={formik.values.previouslyWorkedInIndustry}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{ alignSelf: 'baseline' }}
            >
              <FormControlLabel value="0" control={<Radio />} label="Aldrig" />
              <FormControlLabel value="4" control={<Radio />} label="Under 5 år" />
              <FormControlLabel value="5" control={<Radio />} label="Mellan 5-10 år" />
              <FormControlLabel value="6" control={<Radio />} label="Över 10 år" />
            </RadioGroup>
            {hasError(formik, 'previouslyWorkedInIndustry') && <FormHelperText>{formik.errors?.previouslyWorkedInIndustry}</FormHelperText>}
          </FormControl>

          <FormControl error={formik.errors?.previouslyWorkedInProfession !== undefined}>
            <Typography id="previouslyWorkedInProfession" variant="subtitle1">
              Har jobbat med samma yrke tidigare
            </Typography>
            <RadioGroup
              aria-labelledby="previouslyWorkedInProfession"
              name="previouslyWorkedInProfession"
              value={formik.values.previouslyWorkedInProfession}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{ alignSelf: 'baseline' }}
            >
              <FormControlLabel value="0" control={<Radio />} label="Aldrig" />
              <FormControlLabel value="4" control={<Radio />} label="Under 5 år" />
              <FormControlLabel value="5" control={<Radio />} label="Mellan 5-10 år" />
              <FormControlLabel value="6" control={<Radio />} label="Över 10 år" />
            </RadioGroup>
            {hasError(formik, 'previouslyWorkedInProfession') && (
              <FormHelperText>{formik.errors?.previouslyWorkedInProfession}</FormHelperText>
            )}
          </FormControl>

          <FormControl error={formik.errors?.registrationAfterCeasedEmployment !== undefined}>
            <Typography id="registrationAfterCeasedEmployment" variant="subtitle1">
              Hur snabbt efter anställningens upphörande registrerades (startades) företaget
            </Typography>
            <RadioGroup
              aria-labelledby="registrationAfterCeasedEmployment"
              name="registrationAfterCeasedEmployment"
              value={formik.values.registrationAfterCeasedEmployment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{ alignSelf: 'baseline' }}
            >
              <FormControlLabel value="0" control={<Radio />} label="Innan sista anställningsdagen" />
              <FormControlLabel value="1" control={<Radio />} label="Inom 6 månader" />
              <FormControlLabel value="2" control={<Radio />} label="Mellan  6-12 månader" />
              <FormControlLabel value="3" control={<Radio />} label="Mer än 12 månader" />
            </RadioGroup>
            {hasError(formik, 'registrationAfterCeasedEmployment') && (
              <FormHelperText>{formik.errors?.registrationAfterCeasedEmployment}</FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>

      <Box>
        <Typography variant="h6">TRR Matchning</Typography>
        <FormControlLabel
          label="Har träffat uppdragsgivaren genom en Matchningsaktivitet hos TRR."
          name="foundEmployerAtTRR"
          sx={{ mt: 2 }}
          value={formik.values.foundEmployerAtTRR}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          control={<Checkbox />}
        />
      </Box>
      <Divider sx={{ width: '100%' }} />
      <Box display="flex" flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={2}>
        {avslutaArendeState === FetchingState.LOADING ? (
          <Loading />
        ) : (
          <>
            {isUnhandledAvslutshandelse ? (
              <>
                <Button type="submit">Spara</Button>
                <Button variant="outlined" onClick={handleCancel}>
                  Avbryt
                </Button>
              </>
            ) : (
              <>
                <Button type="submit">Avsluta ärendet</Button>
                <AvslutaWithReminder
                  arendeId={arendeId}
                  avslutsKod={Avslutskod.EgenRorelse}
                  avslutsdatum={formik.values.avslutsDatum}
                  hasError={!activitiesHandled}
                  onClick={handleAvslutWithReminderClick}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Stack>
  )
}

export default EgenRorelse

const validationSchema = object<EgenRorelseFormValues>({
  avslutsDatum: date().typeError('Avslutsdatum måste vara ett giltigt datum').required('Ange avslutsdatum'),
  companyName: string().required('Ange företagets namn').max(128, 'Namnet får inte överskrida 128 tecken'),
  dateOfRegistration: date().typeError('Registreringsdatum måste vara ett giltigt datum').required('Ange registreringsdatum'),
  spareTimeJobSinceBefore: boolean().required(),
  companyType: string().required('Ange bolagsform').nullable(),
  industry: object().required('Ange bransch'),
  isTownAbroad: boolean().required(),
  town: string()
    .when('isTownAbroad', {
      is: false,
      then: () => string().required('Ange stationeringsort'),
    }).when('isTownAbroad', {
      is: true,
      then: () => string().nullable()
    }),
  previouslyWorkedInIndustry: number().required('Ange om omställningskunden har jobbat i branschen tidigare'),
  previouslyWorkedInProfession: number().required('Ange omställningskundens erfarenhet av yrket'),
  registrationAfterCeasedEmployment: number().required(
    'Ange hur lång tid det gick mellan det att anställningen tog slut och företaget registrerades'
  ),
  foundEmployerAtTRR: boolean().required(),
})
