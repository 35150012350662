import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid'
import React from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Box, Link, Typography } from '@mui/material'
import { arendeBasename, medarbetareBasepath } from '@local/src/basename'
import { formatDateWithMonthStringFromDateTime as formatMonth } from '@local/Utils/DateTime'

import { Actor, Arbetsuppgift, ArbetsuppgiftStatus } from '../types'
import { getActionLabel, getFormattedDate, getStatusLabel, noOwnerId } from '../helpers'

import TogglestatusChip from './ToggleStatusChip'

export interface ArbetsuppgiftTableColumnVisibility extends GridColumnVisibilityModel {
  newstatusicon: boolean
  uppgift: boolean
  arendenr: boolean
  action: boolean
  owner: boolean
  createdDate: boolean
  status: boolean
}

export const getColumnVisibility = (isMobile: boolean): ArbetsuppgiftTableColumnVisibility => {
  if (isMobile) {
    return {
      action: false,
      arendenr: false,
      createdDate: false,
      newstatusicon: false,
      owner: false,
      status: true,
      uppgift: true,
    }
  } else {
    return {
      action: true,
      arendenr: true,
      createdDate: true,
      newstatusicon: true,
      owner: true,
      status: true,
      uppgift: true,
    }
  }
}

export const getColumns = (isMobile: boolean, userId: string): GridColDef<Arbetsuppgift>[] => {
  return [
    {
      field: 'newstatusicon',
      headerName: '',
      sortable: false,
      maxWidth: isMobile ? 30 : 50,
      renderCell: (params) =>
        params.row.status === ArbetsuppgiftStatus.Ny && params.row.owner.id === userId && (
          <FiberManualRecordIcon sx={{ mx: 'auto' }} color="primary" data-testid={`newstatusicon-${params.row.id}`} />
        ),
    },
    {
      field: 'uppgift',
      headerName: 'Uppgift',
      renderHeader: () => (
        <Typography variant="subtitle2" color="text.secondary">
          Uppgift
        </Typography>
      ),
      flex: 2,
      maxWidth: isMobile ? 186 : undefined,
      renderCell: (params) => (
        <Box my={0.75}>
          <Typography variant="subtitle1"><Link color='inherit' href={`${medarbetareBasepath}${arendeBasename}/${params.row.arende.id}/studier`}>{params.row.header}</Link></Typography>
          <Typography variant="body2" color="text.secondary">{params.row.arende.kund.fullName}</Typography>
            <>
              { params.row.latestStatusUpdate?.triggeredBy === Actor.User && (
                <Typography variant='caption' color="text.secondary">
                  {`${getStatusLabel(params.row.status)} ${formatMonth(params.row.latestStatusUpdate.occuredAt)} - `}
                  {params.row.latestStatusUpdate.user.id === userId ? 'Jag' : `${params.row.latestStatusUpdate.user.firstName} ${params.row.latestStatusUpdate.user.lastName}`}
                  </Typography>
              )}
              { params.row.latestStatusUpdate?.triggeredBy === Actor.System && (
                <Typography variant='caption' color="text.secondary">
                  {getStatusLabel(params.row.status)} {formatMonth(params.row.latestStatusUpdate.occuredAt)} - Systemet
                </Typography>
              )}
            </>
        </Box>
      ),
    },
    {
      field: 'arendenr',
      headerName: 'Ärendenr',
      renderHeader: () => (
        <Typography variant="subtitle2" color="text.secondary">
          Ärendenr
        </Typography>
      ),
      flex: 0.75,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary">
          #{params.row.arende.arendeNummer}
        </Typography>
      ),
    },
    {
      field: 'action',
      headerName: 'Typ',
      renderHeader: () => (
        <Typography variant="subtitle2" color="text.secondary">
          Typ
        </Typography>
      ),
      flex: 0.75,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary">
          {getActionLabel(params.row.action)}
        </Typography>
      ),
    },
    {
      field: 'owner',
      headerName: 'Ansvarig',
      renderHeader: () => (
        <Typography variant="subtitle2" color="text.secondary">
          Ansvarig
        </Typography>
      ),
      flex: 2.5,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary" data-testid="arbetsuppgift-ansvarig-fullName">
          {params.row.owner?.id !== noOwnerId && params.row.owner.fullName}
        </Typography>
      ),
    },
    {
      field: 'createdDate',
      headerName: 'Datum',
      renderHeader: () => (
        <Typography variant="subtitle2" color="text.secondary">
          Datum
        </Typography>
      ),
      flex: 0.75,
      renderCell: (params) => (
        <Typography variant="subtitle1" color="text.secondary">
          {getFormattedDate(params.row.createdDate)}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'right',
      renderHeader: () => (
        <Typography variant="subtitle2" color="text.secondary">
          Status
        </Typography>
      ),
      flex: 1,
      renderCell: (params) => (
        <Box ml="auto">
          <TogglestatusChip id={params.row.id} status={params.row.status} />
        </Box>
      ),
    },
  ]
}