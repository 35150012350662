import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchingState, ISelectOption } from '@local/src/Common.types'
import Loading from '@local/src/Components/Loading/Loading'
import { Box, Button, Stack, Typography } from '@mui/material'
import Dropdown from '@local/src/Components/Dropdown/Dropdown'

import { avslutaArendeStateSelector } from '../../../Avslut.selectors'
import { Avslutskod } from '../../../Avslut.types'
import HandleActivities from '../../HandleActivities/HandleActivities'
import { avslutaArendeWithNumberOfMonths } from '../../../Avslut.actions'
import { createMonthsOptions } from '../Common/AvslutForms.helpers'

interface AvslutWithNumberOfMonthsProps {
  arendeId: string
  avslutskod: Avslutskod
  title: string
  minMonths: number
  maxMonths: number
}

const AvslutWithNumberOfMonths = ({
  arendeId,
  avslutskod,
  title,
  minMonths,
  maxMonths,
}: AvslutWithNumberOfMonthsProps) => {

  const dispatch = useDispatch()
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)
  const [activitiesHandled, setActivitiesHandled] = useState(false)
  const [activitiesHasError, setActivitiesHasError] = useState(false)
  const [numberOfMonths, setNumberOfMonths] = useState<number>(null)
  const [numberOfMonthsHasError, setNumberOfMonthsHasError] = useState(false)

  const handleSubmit = () => {
    if (activitiesHandled && numberOfMonths) {
      dispatch(avslutaArendeWithNumberOfMonths({
        arendeId,
        radgivningAvslutskod: avslutskod,
        radgivningAvslutsdatum: new Date().toISOString(),
        radgivningUppskjutenManader: numberOfMonths
      }))
    } else {
      setActivitiesHasError(!activitiesHandled)
      setNumberOfMonthsHasError(!numberOfMonths)
    }
  }

  if (avslutaArendeState === FetchingState.LOADING) {
    return <Loading />
  }

  return (
    <Stack spacing={5}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography variant='h4'>{title}</Typography>
        <Typography variant='body1'>Omställningsärendet kommer automatiskt återöppnas efter valt antal månader om du inte väljer att återöppna det tidigare.</Typography>
        <Dropdown
          label='Antal månader'
          value={numberOfMonths}
          maxWidth='480px'
          testSelector='monthDropdown'
          onChange={(event) => setNumberOfMonths(parseInt(event.target.value))}
          getOptionValue={(value: ISelectOption) => value.value}
          getOptionLabel={(value: ISelectOption) => value.label}
          options={createMonthsOptions(minMonths, maxMonths)}
          error={numberOfMonthsHasError}
          errorText='Ange antal månader'
        />
      </Box>
      <HandleActivities setActivitiesHandled={setActivitiesHandled} showErrorMessage={activitiesHasError} />
      <Button onClick={handleSubmit}>Avsluta ärendet</Button>
    </Stack>
  )
}

export default AvslutWithNumberOfMonths