/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useArendenListOptions, useGetArendenEnv, useGuidFromUrl } from '@local/src/Hooks'
import { caseList, dinaArendenTotalHits, dinaArendenTotalPages } from '@local/Views/DinaArenden/DinaArenden.selectors'
import { isKompetensstodsArende } from '@local/src/Views/Arenden/Arenden.helpers'
import { EArendenEnv } from '@local/src/Views/Arenden/Arenden.types'
import { arenden, arendenTotalHits, arendenTotalPages } from '@local/src/Views/Arenden/Arenden.selectors'
import { Box, Collapse, Divider, IconButton, List, ListItemButton, ListItemText, Paper } from '@mui/material'
import { fetchDinaArenden } from '@local/src/Views/DinaArenden/DinaArenden.actions'
import { fetchArenden } from '@local/src/Views/Arenden/Arenden.actions'
import CloseIcon from '@mui/icons-material/Close'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { buildFetchArendenQuery } from '@local/src/Utils/helpers'
import { useUser } from '@trr/app-shell-data'
import { ExtendedUser } from '@local/src/App.types'

import Pagination from '../Pagination/Pagination'

import { getUrlToArende } from './helper'

const CompactCaseList = (): JSX.Element => {
  const { pathname } = useLocation()
  const selectedCase = useGuidFromUrl()
  const arendeEnv = useGetArendenEnv()
  const { page, pageSize, setArendenPage, orderBy, filter, searchText } = useArendenListOptions()
  const isDinaArenden = arendeEnv === EArendenEnv.DinaArenden
  const history = useHistory()
  const dispatch = useDispatch()
  const userId = (useUser() as ExtendedUser).mdid

  const caseSelector = isDinaArenden ? caseList : arenden
  const totalPagesSelector = isDinaArenden ? dinaArendenTotalPages : arendenTotalPages
  const totalHitsSelector = isDinaArenden ? dinaArendenTotalHits : arendenTotalHits

  const cases = useSelector(caseSelector)
  const totalPages = useSelector(totalPagesSelector)
  const totalHits = useSelector(totalHitsSelector)

  const [showList, setShowList] = useState(true)

  const changePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => {
    const newPage = page + 1 // mui is zero-based, azure is not.
    setArendenPage(newPage, totalPages)
    fetchNextArendenPage(newPage)
  }

  const fetchNextArendenPage = (newPage: number) => {
    isDinaArenden
      ? dispatch(fetchDinaArenden(buildFetchArendenQuery(filter, searchText, newPage, pageSize, orderBy, userId)))
      : dispatch(fetchArenden(buildFetchArendenQuery(filter, searchText, newPage, pageSize, orderBy)))
  }

  const handleNavigationClick = (currentCaseId: string, selectedCaseId: string) => {
    const newUrl = getUrlToArende(pathname, currentCaseId, selectedCaseId)
    history.push(newUrl)
  }

  const pagination = <Pagination onPageChange={changePage} page={page} count={totalHits} showRowsPerPage={false} pageSize={pageSize} />

  return (
    <Box component="aside" position="relative" mr={showList ? 6 : 0} height={showList ? 'inherit' : 0}>
      {!showList && (
        <IconButton size="small" sx={{ position: 'absolute', right: 12 }} onClick={() => setShowList(!showList)} data-testid="open-btn">
          <MenuOpenIcon />
        </IconButton>
      )}
      <Collapse in={showList} orientation="horizontal">
        <Paper sx={{ width: '280px', borderBottom: 0 }} square={false} variant="outlined">
          <Box display="flex" justifyContent="space-between" alignItems="center" ml={1}>
            <IconButton onClick={() => setShowList(!showList)} size="small" sx={{ marginY: '9px' }} data-testid="close-btn">
              <CloseIcon />
            </IconButton>
            {pagination}
          </Box>
          <Divider />
          <List disablePadding>
            {cases.map((arende) => {
              const isTia = isKompetensstodsArende(arende.arendeTyp)
              const { anstallningsuppgifter } = arende[isTia ? 'anstallning' : 'omstallning']
              return (
                <ListItemButton
                  divider
                  sx={{ px: 2 }}
                  selected={selectedCase === arende.id}
                  role="link listitem"
                  onClick={() => handleNavigationClick(selectedCase, arende.id)}
                  key={arende.id}
                >
                  <ListItemText
                    primary={`${arende.klient.fornamn} ${arende.klient.efternamn}`}
                    secondary={`${anstallningsuppgifter.befattning}, ${anstallningsuppgifter.foretag.namn}`}
                  />
                </ListItemButton>
              )
            })}
          </List>
          {pagination}
          <Divider />
        </Paper>
      </Collapse>
    </Box>
  )
}

export default CompactCaseList
