import React, { useCallback } from 'react'
import { Checkbox, Dropdown, useAccordion } from '@trr/common-component-library'
import Panel from '@local/Components/Panel'
import { ISelectOption } from '@local/src/Common.types'
import {
  convertToSelectOptionArray,
  returnValuesFromSelectOptionsIfAvailable,
  sortAscendingArrayOfObjectsByKey,
} from '@local/Utils/helpers'
import { useIsSmallScreen } from '@local/src/Hooks'
import SuggestionsAutocomplete from '@local/src/Components/SuggestionsAutocomplete/SuggestionsAutocomplete'

import { IHandlaggare, IStartklarFilterState } from '../Startklar.types'

import s from './StartklarFilter.module.scss'

interface IStartklarFilter {
  arbetsmarknadsomraden: string[]
  filterState: IStartklarFilterState
  handlaggareList: IHandlaggare[]
  setStartklarFilterParameters(filterParams: Partial<IStartklarFilterState>): void
}

export const StartklarFilter = ({
  arbetsmarknadsomraden,
  filterState,
  handlaggareList,
  setStartklarFilterParameters,
}: IStartklarFilter): JSX.Element => {
  const isSmallScreen = useIsSmallScreen()
  const sortedHandlaggareList = sortAscendingArrayOfObjectsByKey('fornamn', handlaggareList)
  const handlaggareSelectOptions = sortedHandlaggareList?.map((handlaggare: IHandlaggare) => {
    return {
      value: handlaggare.id,
      label: `${handlaggare.fornamn} ${handlaggare.efternamn}`,
    }
  })

  const arbetsmarknadsomradenSelectOptions = arbetsmarknadsomraden
    ?.sort((omradeA, omradeB) => (omradeA > omradeB ? 1 : -1))
    .map((omrade) => {
      return { label: omrade, value: omrade }
    })

  const setHandlaggare = useCallback(
    (handlaggareOption: ISelectOption[]) => {
      setStartklarFilterParameters({
        handlaggareObjects: handlaggareOption,
        ejTilldeladHandlaggare: false,
      })
    },
    [setStartklarFilterParameters]
  )

  const setArbetsmarknadsomraden = useCallback(
    (arbetsmarknadsomradeOption: ISelectOption[]) => {
      setStartklarFilterParameters({
        arbetsmarknadsomraden:
          arbetsmarknadsomradeOption !== null
            ? returnValuesFromSelectOptionsIfAvailable(arbetsmarknadsomradeOption)
            : [],
      })
    },
    [setStartklarFilterParameters]
  )

  const setDisableAdvisors = useCallback(() => {
    setStartklarFilterParameters({
      handlaggareObjects: null,
      ejTilldeladHandlaggare: !filterState.ejTilldeladHandlaggare,
    })
  }, [filterState.ejTilldeladHandlaggare, setStartklarFilterParameters])

  const setForetagNamn = (foretagOption: string[]) => {
    setStartklarFilterParameters({
      foretagNamn:
      foretagOption !== null
          ? foretagOption
          : []
    })
  }

  const data = [
    {
      title: 'Handläggare',
      content: (
        <div className={s.MultipleDropdownWrapper}>
          <Dropdown
            label="Välj handläggare"
            cacheOptions={false}
            onChange={setHandlaggare}
            multi
            selectOptions={handlaggareSelectOptions}
            value={filterState.handlaggareObjects}
          />
          <Checkbox onChange={setDisableAdvisors} label="Ej tilldelad" checked={filterState.ejTilldeladHandlaggare} />
        </div>
      ),
    },
    {
      title: 'Plats',
      content: (
        <div className={s.MultipleDropdownWrapper}>
          <Dropdown
            label="Arbetsmarknadsområde"
            isSearchable={!isSmallScreen}
            multi
            onChange={setArbetsmarknadsomraden}
            selectOptions={arbetsmarknadsomradenSelectOptions}
            value={convertToSelectOptionArray(filterState.arbetsmarknadsomraden)}
          />
        </div>
      ),
    },
    {
      title: 'Företag',
      content: (
        <div className={s.MultipleDropdownWrapper}>
          <SuggestionsAutocomplete
            key='foretagSuggestions'
            label='Företag'
            filterState={filterState}
            value={filterState.foretagNamn}
            getSelectedValues={setForetagNamn}
            multiple
            propertyName='foretagNamn'
          />
        </div>
      ),
    },
  ]

  const { openIndexes, toggleIndex } = useAccordion()
  return (
    <div className={s.Filter}>
      <div className={s.FilterSection}>
        {data.map((entry, index) => {
          const isOpen = openIndexes.includes(index)
          return (
            <Panel key={index} index={index} isOpen={isOpen} toggleIndex={toggleIndex} title={entry.title}>
              {entry.content}
            </Panel>
          )
        })}
      </div>
    </div>
  )
}

export default StartklarFilter
