import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
export const KeyRoute: React.FunctionComponent<React.PropsWithChildren<any>> = ({ children }) => children

export const AppShellRouter: React.FunctionComponent<React.PropsWithChildren<{
  currentKey: string
}>> = ({ currentKey, children }) => {
  let component

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.props.urlKey === currentKey) {
        component = child
      }
    } else {
      component = null
    }
  })

  return component ?? null
}
