import { connect } from 'react-redux'
import { RootState } from '@local/src/Store/configureStore'

import Startklar from './Startklar'
import {
  fetchCaseListStartklar,
  fetchCaseListFacets,
  setStartklarPage,
  setStartklarPageSize,
  resetFilterProp,
  setStartklarFilterParameters,
  fetchHandlaggareList,
} from './Startklar.actions'
import {
  startklarCases,
  fetchingCaseListStartklar,
  filterState,
  facetedTotalHits,
  totalHits,
  currentPage,
} from './Startklar.selectors'

const mapStateToProps = (state: RootState) => ({
  startklarCases: startklarCases(state),
  fetchingCaseListStartklar: fetchingCaseListStartklar(state),
  filterState: filterState(state),
  facetedTotalHits: facetedTotalHits(state),
  totalHits: totalHits(state),
  currentPage: currentPage(state),
})

const mapDispatchToProps = {
  fetchCaseListStartklar,
  fetchCaseListFacets,
  setStartklarPage,
  setStartklarPageSize,
  resetFilterProp,
  setStartklarFilterParameters,
  fetchHandlaggareList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Startklar)
