import { CardContent, Box, ListItem, ListItemText, CardHeader, Card, Collapse, Typography, Divider } from '@mui/material'
import { format } from 'date-fns'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useIsSmallScreen } from '@local/src/Hooks'
import { isNotNullOrEmpty } from '@local/src/Utils/smallfuncs'

import { Avslutshandelse } from '../../../Avslut.types'

import { ExpandMore } from './ExpandMore'

import { avslutskodRequiresAnswers, isMigratedAndRequiresAnswers } from '.'

interface CardHeaderProps {
  avslutshandelse: Avslutshandelse
  children: React.ReactNode
}

export const AvslutshandelseCardBase = ({ avslutshandelse, children }: CardHeaderProps) => {
  const [expanded, setExpanded] = useState(false)
  const isSmallScreen = useIsSmallScreen()
  const migratedThatMayHaveClosingQuestions = isMigratedAndRequiresAnswers(avslutshandelse)
  const answersRequired = avslutskodRequiresAnswers(avslutshandelse)
  const reOpenDate =
    avslutshandelse.ateroppnasDatumAuto !== null
      ? format(new Date(avslutshandelse.ateroppnasDatumAuto), 'yyyy-MM-dd')
      : format(new Date(avslutshandelse.ateroppnadDatum), 'yyyy-MM-dd')

  return (
    <Card>
      <CardHeader
        title={`${avslutshandelse.radgivningAvslutsBeskr} (${avslutshandelse.radgivningAvslutskod})`}
        sx={{ flexGrow: '1' }}
        action={
          answersRequired && avslutshandelse.avslutsFragorSvar.length ? (
            <ExpandMore expand={expanded} onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          ) : null
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ListItem>
            <ListItemText
              primary={'Avslutat av'}
              secondary={
                isSmallScreen ? (
                  <>
                    <Typography variant="body2">{format(new Date(avslutshandelse.registreringsDatum), 'yyyy-MM-dd')},</Typography>
                    <Typography variant="body2">
                      {isNotNullOrEmpty(avslutshandelse.registreradAv) ? avslutshandelse.registreradAv : 'Rådgivare saknas'}
                    </Typography>
                  </>
                ) : (
                  `${format(new Date(avslutshandelse.registreringsDatum), 'yyyy-MM-dd')}, ${
                    isNotNullOrEmpty(avslutshandelse.registreradAv) ? avslutshandelse.registreradAv : 'Rådgivare saknas'
                  }`
                )
              }
            />
          </ListItem>
          <Box gap={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <ListItem>
              {avslutshandelse.ateroppnasDatumAuto ||
                (avslutshandelse.ateroppnadDatum && (
                  <ListItemText data-testid="ateroppnad-datum" primary={'Återöppnad'} secondary={reOpenDate} />
                ))}
            </ListItem>
            <ListItem>
              <ListItemText primary={'Avslutsdatum'} secondary={format(new Date(avslutshandelse.radgivningAvslutsdatum), 'yyyy-MM-dd')} />
            </ListItem>
          </Box>
        </Box>
        {migratedThatMayHaveClosingQuestions && !avslutshandelse.avslutsFragorSvar.length ? (
          <Box mt={1}>
            <Typography>Avslutsfrågorna är besvarade i tidigare system.</Typography>
          </Box>
        ) : null}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box pt={2} pb={2}>
            <Divider />
          </Box>
          {children}
        </Collapse>
      </CardContent>
    </Card>
  )
}
