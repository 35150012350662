import React, { useState, useEffect, useRef } from 'react'
import { formatDateFromDateTime as formatDate } from '@local/Utils/DateTime'
import { FetchingState } from '@local/src/Common.types'
import { Box, Chip, Divider, Link, Stack, Typography } from '@mui/material'
import CollapsableContent from '@local/src/Components/CollapsableContent/CollapsableContent'
import { useSelector } from 'react-redux'

import EventForm from '../EventForm/EventForm'
import { getLabelFor } from '../EventLog.helpers'
import { deleteEventStateSelector, updateEventStateSelector } from '../../Case.selectors'
import { EventTypes } from '../EventLog.types'

const uneditableEventTypes = [
  EventTypes.Startklar,
  EventTypes.GroupEmail
]

interface EventCardProps {
  id: string
  clientId: string
  eventDate: Date
  bodyText: string
  arendeTyp: string
  eventType: EventTypes
  healthNote: string
  healthConsent: boolean
  registeredBy: string
  registeredDate: Date
  isEditing: boolean
  isReadOnly: boolean
  isHealthNote: boolean
  setIsEditing: (editing: boolean) => void
}

const EventCard = ({
  arendeTyp,
  registeredDate,
  healthNote,
  eventDate,
  registeredBy,
  bodyText,
  eventType,
  healthConsent,
  id,
  clientId,
  setIsEditing,
  isEditing,
  isReadOnly,
  isHealthNote
}: EventCardProps): JSX.Element => {

  const deleteEventState = useSelector(deleteEventStateSelector)
  const updateEventState = useSelector(updateEventStateSelector)

  const noteRef = useRef<HTMLDivElement | null>(null)
  const [editMode, setEditMode] = useState(false)
  const [isLongNote, setIsLongNote] = useState(false)
  const doneLoading = deleteEventState === FetchingState.SUCCESS || updateEventState === FetchingState.SUCCESS
  const hasHealthNote = healthNote && healthConsent
  const FOUR_ROWS_PIXEL_HEIGHT = 120

  useEffect(() => {
    if (noteRef.current) {
      const textHeight = noteRef.current.clientHeight
      setIsLongNote(textHeight > FOUR_ROWS_PIXEL_HEIGHT)
    }
  }, [noteRef])

  useEffect(() => {
    doneLoading && setEditMode(false)
  }, [doneLoading])

  useEffect(() => {
    setIsEditing?.(editMode)
  }, [editMode])

  if (editMode) {
    return (
      <EventForm
        arendeTyp={arendeTyp}
        eventDate={eventDate}
        inEdit
        registeredDate={registeredDate}
        healthConsent={healthConsent}
        bodyText={bodyText}
        eventType={eventType}
        healthNote={healthNote}
        registeredBy={registeredBy}
        leaveEditMode={() => setEditMode(false)}
        id={id}
        clientId={clientId}
      />
    )
  }

  const healthNoteBox = () => (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      padding={2}
      marginTop={2}
      borderRadius={1}
      sx={{ backgroundColor: 'surface.purple' }}
    >
      <Typography variant='body1' fontWeight='bold' paddingBottom={1}>Hälsonotering</Typography>
      <Typography variant='body1'>{healthNote}</Typography>
    </Box>
  )

  return (
    <div>
      <Stack>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' gap={1} paddingY={1}>
            <Chip label={getLabelFor(eventType)} />
            {isHealthNote && <Chip label="Hälsonotering" />}
          </Box>
          <Link
            component='button'
            fontSize='1rem'
            onClick={() => setEditMode(true)}
            hidden={uneditableEventTypes.includes(eventType) || isEditing || isReadOnly}
          >
            Ändra
          </Link>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between' marginBottom={1}>
          <Box display='flex' flexDirection='row' gap={1}>
            <Typography variant="subtitle1">Skapad av</Typography>
            <Typography variant="body1">{registeredBy ?? 'Ingen information'}</Typography>
          </Box>
          <Typography variant="body1">{formatDate(eventDate)}</Typography>
        </Box>
        <div>
          <Box ref={noteRef} sx={{ wordBreak: 'break-word' }} whiteSpace='pre-line'>
            { isLongNote ? (
              <CollapsableContent>
                <Typography variant='body1'>{bodyText}</Typography>
                { hasHealthNote && (
                  <>{healthNoteBox()}</>
                )}
              </CollapsableContent>
            ) : (
              <>
                <Typography variant='body1'>{bodyText}</Typography>
                { hasHealthNote && (
                  <CollapsableContent
                  partiallyOpenedPixels={0}
                  openByDefault={false}
                  >
                  {healthNoteBox()}
                </CollapsableContent>
                )}
              </>
            )}
          </Box>

        </div>
      </Stack>
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
    </div>
  )
}

export default EventCard
