import { isAvslutatArende } from '@local/src/Utils/SubStatus'
import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchingState } from '@local/src/Common.types'
import { Route, Switch } from 'react-router-dom'
import { scrollToTop } from '@local/src/Utils/smallfuncs'

import OvrigaArenden from '../OvrigaArenden/OvrigaArenden'
import { avslutaArendeStateSelector } from '../Avslut.selectors'
import { resetAvslut } from '../Avslut.actions'

import AvslutBaseForm from './AvslutBaseForm/AvslutBaseForm'
import Ateroppna from './Ateroppna/Ateroppna'
import { Avslutshandelser } from './Avslutshandelser/Avslutshandelser'
import UnhandledAvslutshandelseForm from './Avslutshandelser/UnhandledAvslutshandelseForm'

interface AvslutaOmstallningsstodsArendeProps {
  subStatus: string
  arendeId: string
}

const AvslutaOmstallningsstodsArende = ({ subStatus, arendeId }: AvslutaOmstallningsstodsArendeProps) => {
  const dispatch = useDispatch()
  const [isAvslutsformOpen, setIsAvslutsformOpen] = useState<boolean>(false)
  const [isAvslutat, setIsAvslutat] = useState<boolean>(isAvslutatArende(subStatus))
  const avslutaArendeState = useSelector(avslutaArendeStateSelector)

  useEffect(() => {
    return () => {
      dispatch(resetAvslut())
    }
  }, [])
  useEffect(() => {
    if (avslutaArendeState === FetchingState.SUCCESS) {
      scrollToTop()
    }
  }, [avslutaArendeState])

  useEffect(() => {
    setIsAvslutat(isAvslutatArende(subStatus))
  }, [subStatus, arendeId])

  return (
    <Stack mt={5} width={{ lg: '80%' }}>
      <Switch>
        <Route path="*/medarbetare/*/arende/:arendeId/avslut" exact>
          {isAvslutat ? <Ateroppna /> : <AvslutBaseForm arendeId={arendeId} setIsAvslutsformOpen={setIsAvslutsformOpen} />}
          {!isAvslutsformOpen && (
            <>
              <OvrigaArenden />
              <Avslutshandelser arendeId={arendeId} />
            </>
          )}
        </Route>
        <Route path="*/medarbetare/*/arende/:arendeId/avslut/:avslutsId">
          <UnhandledAvslutshandelseForm />
        </Route>
      </Switch>
    </Stack>
  )
}

export default AvslutaOmstallningsstodsArende
