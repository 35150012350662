import HTMLMapper from "@local/src/Utils/HTMLMapper"
import { warningTextsSelector } from "@local/src/Views/Case/Case.selectors"
import { Alert } from '@mui/material'
import React from "react"
import { useSelector } from "react-redux"

const ArendeWarningText = () => {

  const warningTexts = useSelector(warningTextsSelector)

  return (
    <>
    { warningTexts?.bedomningPagar && (
        <Alert severity="warning" variant='outlined' sx={{ borderRadius: 2 }}>
          <HTMLMapper body={warningTexts?.bedomningPagar} wrapper="section" />
        </Alert>
    )}
    </>
  )
}

export default ArendeWarningText
