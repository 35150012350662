import { connect } from 'react-redux'
import { path } from 'ramda'
import {
  fetchStartklarCase,
  fetchStartklarRattTillAge,
  resetStartklarFormSubmissionState,
  submitStartklarForm,
} from '@local/Views/Startklar/Startklar.actions'
import { FetchingState } from '@local/src/Common.types'
import { RootState } from '@local/src/Store/configureStore'

import { ICaseDetails } from '../../Case/Case.types'

import StartklarForm from './StartklarForm'

const caseDetails = path<ICaseDetails>(['startklar', 'caseDetails'])
const startklarFormSubmissionState = path<FetchingState>(['startklar', 'startklarFormSubmissionState'])
const startklarCaseFetchingState = path<FetchingState>(['startklar', 'startklarCaseFetchingState'])

const mapStateToProps = (state: RootState) => ({
  caseDetails: caseDetails(state),
  startklarCaseFetchingState: startklarCaseFetchingState(state),
  startklarFormSubmissionState: startklarFormSubmissionState(state),
})

const mapDispatchToProps = {
  fetchStartklarCase,
  resetStartklarFormSubmissionState,
  submitStartklarForm,
  fetchStartklarRattTillAge,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartklarForm)
