import { Chip } from '@mui/material'
import React from 'react'

import { useEpiString } from '../../../Context/EpiStringContext'
import { IStudieAnmalanStatuses } from '../types/IStudieAnmalanStatuses'

export enum ChipStatus {
  WaitingForAdvisor = 'statusChip.waitingForAdvisor',
  WaitingForSupplier = 'statusChip.waitingForSupplier',
  Booked = 'statusChip.booked',
  Completed = 'statusChip.completed',
  CancelledBySupplier = 'statusChip.cancelledBySupplier',
  CancelledByClient = 'statusChip.cancelledByClient',
  RejectedBySupplier = 'statusChip.rejectedBySupplier',
  RejectedByAdvisor = 'statusChip.rejectedByAdvisor',
}

interface IStudieAnmalanStatusChipProps {
  statuses: IStudieAnmalanStatuses
}

const StudieAnmalanStatusChip: React.FC<IStudieAnmalanStatusChipProps> = ({ statuses }) => {
  const epi = useEpiString()

  const statusChips: {
    status: boolean | null
    key: ChipStatus
    color: 'error' | 'success' | 'info'
  }[] = [
      {
        status: statuses?.isRejectedByAdvisor,
        key: ChipStatus.RejectedByAdvisor,
      color: 'error',
      },
      {
        status: statuses?.isRejectedBySupplier,
        key: ChipStatus.RejectedBySupplier,
        color: 'error',
      },

      {
        status: statuses?.isCancelledBySupplier,
        key: ChipStatus.CancelledBySupplier,
        color: 'error',
      },
      {
        status: statuses?.isCancelledByClient,
        key: ChipStatus.CancelledByClient,
        color: 'error',
      },
      {
        status: statuses?.isLateCancelledByClient,
        key: ChipStatus.CancelledByClient,
        color: 'error',
      },

      {
        status: statuses?.isWaitingForAdvisorApproval,
        key: ChipStatus.WaitingForAdvisor,
        color: 'info',
      },
      {
        status: statuses?.isWaitingForSupplierConfirmation,
        key: ChipStatus.WaitingForSupplier,
        color: 'info',
      },
      {
        status: statuses?.isAcceptedBySupplier,
        key: ChipStatus.Booked,
        color: 'success',
      },
    ]

  const status = statusChips.find((c) => c.status)

  if (!status) {
    return null
  }

  return <Chip variant={`status-${status.color}`} label={epi(status.key)} />
}

export default StudieAnmalanStatusChip
