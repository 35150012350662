import React from 'react'
import { formatDateFromDateTime } from '@local/Utils/DateTime'
import { formatAmount } from '@local/Utils/smallfuncs'
import { useHasRightToAge } from '@local/src/Hooks/useHasRightToAge'
import { Box, Stack, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'

interface IStartklarKlientSecondaryInformation {
  dayOfNoticeToQuit: string
  lastDayOfEmployment: string
  totalLon: number
  severancePayEndDate: Date
  orsakUppsagning: string
}

const StartklarKlientSecondaryInformation = ({
  dayOfNoticeToQuit,
  lastDayOfEmployment,
  totalLon,
  severancePayEndDate,
  orsakUppsagning,
}: IStartklarKlientSecondaryInformation): JSX.Element => {

  const theme = useTheme()
  const isScreenAboveMd = useMediaQuery(theme.breakpoints.up('md'))

  const boxStyling: SxProps<Theme> = {
    gridColumn: isScreenAboveMd ? 'auto' : '1/4'
  }

  const ageFlag = useHasRightToAge()

  return (
    <Stack 
      display='grid'
      gridTemplateColumns='1fr 1fr 1fr'
      marginTop={3}
      gap={2}>
      <Box sx={boxStyling}>
        <Typography variant='subtitle2' color='text.secondary'>Orsak till uppsägning</Typography>
        <Typography variant='body1' color='text.primary'>{orsakUppsagning}</Typography>
      </Box>
      <Box sx={boxStyling}>
        <Typography variant='subtitle2' color='text.secondary'>Uppsägningsdatum</Typography>
        <Typography variant='body1' color='text.primary' display='flex'>
          {formatDateFromDateTime(dayOfNoticeToQuit)}
        </Typography>
      </Box>
      <Box sx={boxStyling}>
        <Typography variant='subtitle2' color='text.secondary'>Sista anställningsdag</Typography>
        <Typography variant='body1' color='text.primary' display='flex'>
          {formatDateFromDateTime(lastDayOfEmployment)}
        </Typography>
      </Box>
      <Box sx={boxStyling}>
        <Typography variant='subtitle2' color='text.secondary'>Totallön</Typography>
        <Typography variant='body1' color='text.primary'>{`${formatAmount(totalLon)} kr`}</Typography>
      </Box>
      <Box sx={boxStyling}>
        <Typography variant='subtitle2' color='text.secondary'>AGE</Typography>
        <Typography variant='body1' color='text.primary'>{ageFlag}</Typography>
      </Box>
      <Box sx={boxStyling}>
        <Typography variant='subtitle2' color='text.secondary'>Avgångsvederlag</Typography>
        <Typography variant='body1' color='text.primary'>{severancePayEndDate ? formatDateFromDateTime(severancePayEndDate) : 'Nej'}</Typography>
      </Box>
    </Stack>
  )
}

export default StartklarKlientSecondaryInformation
