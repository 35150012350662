import React from 'react'
import { ILink } from '@local/src/App.types'
import { Box, Link, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import StartUpIllustration from '../StartupIllustration/StartUpIllustration'
import { HomePageEpiContent } from '../Home.types'

const InternalLinks = (): JSX.Element => {

  const { links } = useEpiContent<HomePageEpiContent>()

  return (
    <Box
      display='flex'
      flexDirection={{xs: 'column', lg: 'row' }}
      justifyContent={{xs: 'center', lg: 'space-between' }}>
      <Box>
        <Typography variant='h5' mb={2}>Dina rådgivningsfunktioner</Typography>
        <Box display='flex' flexDirection='column' gap={2} alignItems={{xs: 'center', md: 'unset'}}>
          {links?.map((route: ILink) => {
            return (
              <Link key={route.id} href={route.url}>
                {route.name}
              </Link>
            )
          })}
        </Box>
      </Box>
      <StartUpIllustration />
    </Box>
  )
}

export default InternalLinks
