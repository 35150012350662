import { kompetensstodsArendenPath, omstallningsArendenPath } from '@local/src/basename'
import {
  allaArendenTabOrderByOptions,
  kompetensstodTabOrderByOptions,
  omstallningTabOrderByOptions,
  dinaArendenTabOrderByOptions,
} from '@local/src/Components/CaseList/CaseList.types'
import { removeTrailingSlash } from '@local/src/Utils/helpers'

import { ArendeTyp, EArendenEnv } from './Arenden.types'

export const getArendeType = (pathname: string): ArendeTyp => {
  const url = removeTrailingSlash(pathname)
  if (url.endsWith(kompetensstodsArendenPath)) return ArendeTyp.Kompetensstod
  if (url.endsWith(omstallningsArendenPath)) return ArendeTyp.Omstallning
  return ArendeTyp.Alla
}

export const isKompetensstodsTab = (pathname: string): boolean => {
  const url = removeTrailingSlash(pathname)
  return url.includes(kompetensstodsArendenPath)
}
export const isOmstallningsTab = (pathname: string): boolean => {
  const url = removeTrailingSlash(pathname)
  return url.includes(omstallningsArendenPath)
}
export const isKompetensstodsArende = (arendeTyp: string): boolean => arendeTyp === ArendeTyp.Kompetensstod
export const isOmstallningsArende = (arendeTyp: string): boolean => arendeTyp === ArendeTyp.Omstallning
export const noActiveTab = (url: string): boolean => url.endsWith('arenden')

export const getTabNameFrom = (url: string): string => ['alla', 'omstallning', 'kompetensstod'].find((t) => url.includes(t))

export const getSortingOptionsFor = (arendenEnv: Partial<EArendenEnv>) => {
  switch (arendenEnv) {
    case EArendenEnv.AllaArenden:
      return allaArendenTabOrderByOptions
    case EArendenEnv.KompetensstodsArenden:
      return kompetensstodTabOrderByOptions
    case EArendenEnv.OmstallningsArenden:
      return omstallningTabOrderByOptions
    case EArendenEnv.DinaArenden:
      return dinaArendenTabOrderByOptions
  }
}
