import { ArendeTyp } from "@local/src/Views/Arenden/Arenden.types"
import { Avatar } from "@mui/material"
import React from "react"

interface CaseAvatarProps {
  arendeTyp: ArendeTyp
}

const CaseAvatar = ({
  arendeTyp,
}: CaseAvatarProps): JSX.Element => {

  const isOmstallning = arendeTyp === ArendeTyp.Omstallning

  return (
    <Avatar sx={{
      backgroundColor: isOmstallning ? 'surface.blue' : 'surface.orange',
      color: isOmstallning ? 'info.main' : 'warning.dark',
      height: 32,
      width: 32,
      fontSize: '1rem'
    }}>{ isOmstallning ? 'OS' : 'KS'}</Avatar>
  )
}

export default CaseAvatar