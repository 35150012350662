import { createActions } from 'redux-actions'
import { abortController } from '@local/Utils/helpers'
import { get } from '@local/Utils/network'

export const {
  fetchCaseInitiate,
  fetchCaseSuccess,
  fetchCaseFailure,
  fetchMyCaseListInitiate,
  fetchMyCaseListSuccess,
  fetchMyCaseListFailure,
  fetchMyCaseListFacetsInitiate,
  fetchMyCaseListFacetsSuccess,
  fetchMyCaseListFacetsFailure,
  fetchInitialMyCaseListFacetsInitiate,
  fetchInitialMyCaseListFacetsSuccess,
  fetchInitialMyCaseListFacetsFailure,
} = createActions({
  fetchCaseInitiate: (reload: boolean) => reload,
  fetchCaseSuccess: (caseDetails) => ({ caseDetails }),
  fetchCaseFailure: (errorMessage) => {
    return `Det gick inte att hämta ärendet. ${String(errorMessage)}`
  },

  fetchMyCaseListFacetsInitiate: () => null,
  fetchMyCaseListFacetsSuccess: (facetsResponse) => ({ facetsResponse }),
  fetchMyCaseListFacetsFailure: (errorMessage) => {
    return `Det gick inte att hämta filtreringsalternativ till ärendelistan. ${String(errorMessage)}`
  },

  fetchInitialMyCaseListFacetsInitiate: () => null,
  fetchInitialMyCaseListFacetsSuccess: (facetsResponse) => ({ facetsResponse }),
  fetchInitialMyCaseListFacetsFailure: (errorMessage) => {
    return `Det gick inte att hämta filtreringsalternativ till ärendelistan. ${String(errorMessage)}`
  },

  fetchMyCaseListInitiate: () => null,
  fetchMyCaseListSuccess: (caseObject) => ({ caseObject }),
  fetchMyCaseListFailure: (errorMessage) => `Det gick inte att hämta ärendelistan. ${String(errorMessage)}`,
})

export const fetchDinaArenden = abortController(
  (abortableFetch: unknown) => (query?: string) =>
    get({
      url: `/arenden/minaarenden${query ? query : ''}`,
      initiated: fetchMyCaseListInitiate,
      fulfilled: fetchMyCaseListSuccess,
      rejected: fetchMyCaseListFailure,
      _fetch: abortableFetch,
      apiVersion: '2.0',
    })
)

export const fetchCaseListFacets = abortController(
  (abortableFetch: unknown) => (query?: string) =>
    get({
      url: `/arenden/minaarenden${query ? query : ''}`,
      initiated: fetchMyCaseListFacetsInitiate,
      fulfilled: fetchMyCaseListFacetsSuccess,
      rejected: fetchMyCaseListFacetsFailure,
      _fetch: abortableFetch,
      apiVersion: '2.0',
    })
)

export const fetchInitialCaseListFacets = abortController(
  (abortableFetch: unknown) => (query?: string) =>
    get({
      url: `/arenden/minaarenden${query ? query : ''}`,
      initiated: fetchInitialMyCaseListFacetsInitiate,
      fulfilled: fetchInitialMyCaseListFacetsSuccess,
      rejected: fetchInitialMyCaseListFacetsFailure,
      _fetch: abortableFetch,
      apiVersion: '2.0',
    })
)
