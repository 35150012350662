import React, { useEffect, useState } from 'react'
import { IAdvisor } from '@local/Views/AllocateCases/AllocateCases.types'
import { useHistory } from 'react-router-dom'
import { FetchingState } from '@local/src/Common.types'
import { useGuidFromUrl, useCurrentArendeListPathname, useToggle } from '@local/src/Hooks'
import { medarbetareBasepath } from '@local/src/basename'
import Modal from '@local/src/Components/Modal/Modal'
import { Alert, Box, Checkbox, FormControlLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { advisorList, fetchingAdvisorListState } from '@local/src/Views/AllocateCases/AllocateCases.selectors'
import { fetchActiveAdvisors } from '@local/src/Views/AllocateCases/AllocateCases.actions'
import Autocomplete from '@local/src/Components/Autocomplete/Autocomplete'

import { IChangeAdvisorBody, changeAdvisor, clearChangingAdvisorState } from '../../Case.actions'
import { changingAdvisorStateSelector } from '../../Case.selectors'

interface ChangeAdvisorProps {
  active: boolean
  close(): void
  teamId: string
}

const ChangeAdvisorModal = ({
  active,
  close,
  teamId,
}: ChangeAdvisorProps): JSX.Element => {

  const dispatch = useDispatch()
  const advisors = useSelector(advisorList)
  const fetchingAdvisor = useSelector(fetchingAdvisorListState)
  const changingAdvisorState = useSelector(changingAdvisorStateSelector)
  const isLoading = fetchingAdvisor === FetchingState.LOADING
  const [selectedAdvisor, setSelectedAdvisor] = useState<IAdvisor>()
  const [payload, setPayload] = useState<IChangeAdvisorBody>(null)
  const { isEnabled: isShowingAllAdvisors, toggle: toggleShowingAllAdvisors } = useToggle()
  const arendeId = useGuidFromUrl()
  const history = useHistory()
  const currentArendeListPathname = useCurrentArendeListPathname()
  const [errorMsg, setErrorMsg] = useState<string>(null)

  useEffect(() => {
    if (changingAdvisorState === FetchingState.SUCCESS) {
      dispatch(clearChangingAdvisorState())
      history.push(`${medarbetareBasepath}${currentArendeListPathname}`)
    }
  }, [changingAdvisorState])

  useEffect(() => {
    active === false && setSelectedAdvisor(null)
  }, [active])

  useEffect(() => {
    isShowingAllAdvisors ? dispatch(fetchActiveAdvisors()) : dispatch(fetchActiveAdvisors(teamId))
  }, [fetchActiveAdvisors, teamId, isShowingAllAdvisors])

  useEffect(() => {
    selectedAdvisor && setPayload({ arendeId: arendeId, radgivareId: selectedAdvisor.id })
  }, [selectedAdvisor, arendeId])

  const selectAdvisor = (event: IAdvisor) => {
    if (event !== null) {
      setSelectedAdvisor(advisors.find((advisor: IAdvisor) => event.id === advisor.id))
      setErrorMsg(null)
    } else {
      setSelectedAdvisor(null)
      setErrorMsg('Välj ett alternativ')
    }
  }

  const handleConfirm = () => {
    if (!selectedAdvisor) setErrorMsg('Välj ett alternativ')
    else {
      setErrorMsg(null)
      dispatch(changeAdvisor(payload))
      close()
    }
  }

  const handleCancel = () => {
    setErrorMsg(null)
    close()
  }

  return (
    <Modal
      label='Byt rådgivare'
      open={active}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      closeIcon
      confirmText='Byt'
    >
      <Box display='flex' flexDirection='column' gap={2} paddingTop={1}>

        <Autocomplete
          value={selectedAdvisor}
          label={isShowingAllAdvisors ? 'Välj en rådgivare' : 'Välj en rådgivare i ditt team'}
          options={advisors}
          onChange={selectAdvisor}
          getOptionLabel={(option: IAdvisor) => `${option.fornamn} ${option.efternamn}`}
          error={errorMsg !== null}
          errorText={errorMsg}
        />

        <FormControlLabel
          label='Välj bland alla rådgivare'
          sx={{ alignSelf: 'baseline' }}
          control={
            <Checkbox
              checked={isShowingAllAdvisors}
              onChange={toggleShowingAllAdvisors}
              disabled={isLoading}
            />
          }
        />
        <Alert severity='warning'>
          Om omställningskunden byter bostadsort, välj hellre att ändra team istället för rådgivare.
        </Alert>
      </Box>
    </Modal>
  )
}

export default ChangeAdvisorModal
