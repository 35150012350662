import React, { useState } from 'react'
import { formatDateFromDateTime } from '@local/Utils/DateTime'
import { getUrlByCaseEnv } from '@local/src/basename'
import { useCaseEnvBasePath } from '@local/src/Hooks'
import { useDispatch } from 'react-redux'
import { Checkbox, FormControlLabel, Link, ListItem, ListItemText, Typography } from '@mui/material'
import AccordionBox from '@local/src/Components/AccordionBox/AccordionBox'

import { ITodo } from '../TodoList.types'
import { completeTodo, reactivateTodo } from '../TodoList.actions'

interface TodoListItemProps {
  todo: ITodo
}

const TodoListItem = ({
  todo,
}: TodoListItemProps): JSX.Element => {

  const dispatch = useDispatch()
  const completedTodo = todo.arbetsuppgiftStatus === 'Completed' ? true : false
  const toggleArbetsuppgift = () => {
    completedTodo ? dispatch(reactivateTodo(todo.id)) : dispatch(completeTodo(todo.id))
  }
  const [isExpanded, setIsExpanded] = useState(false)
  const caseEnvBasePath = useCaseEnvBasePath()

  return (
    <ListItem divider sx={{ paddingY: 1, paddingX: 1 }} alignItems='flex-start'>
      <FormControlLabel label='' onClick={toggleArbetsuppgift} control={
        <Checkbox checked={completedTodo} sx={{ mt: 1, ml: 1 }} />
      } />
      <ListItemText
        id={todo.id}
        sx={{ mt: 2, width: { md: 280 } }}
        disableTypography
        primary={
          <>
            <Typography variant='body2' color='text.secondary'>{formatDateFromDateTime(todo.createdDatum)}</Typography>
            <Link href={getUrlByCaseEnv(caseEnvBasePath, todo.arendeId)} variant='body1'>
              {`${todo.klient.fornamn} ${todo.klient.efternamn}`}
            </Link>
          </>
        }
        secondary={
          <>
            <Typography variant='body2' color='text.secondary'>{todo.title}</Typography>
            {todo.body && (
              <AccordionBox label='Läs mer' expanded={isExpanded} onToggle={(open) => setIsExpanded(open)}>
                <Typography variant='body2' color='text.secondary'>{todo.body}</Typography>
              </AccordionBox>
            )}
          </>
        }
      />
    </ListItem>
  )
}
export default TodoListItem
