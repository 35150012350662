import React from 'react'
import TodoList from '@local/Blocks/TodoList/TodoList'
import { IFilterTag } from '@local/src/Views/Arenden/Arenden.types'
import { Box, Chip } from '@mui/material'

import FilterButton from '../FilterButton'

interface IMobileFilterUtilities {
  displayModal?(): void
  tags: IFilterTag[]
  removeFilterParameterValuePair(tag: IFilterTag): void
  isDinaArenden?: boolean
}

const MobileFilterUtilities = ({
  displayModal,
  tags,
  removeFilterParameterValuePair,
  isDinaArenden,
}: IMobileFilterUtilities): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginY: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FilterButton isSmallScreen onClick={displayModal} />
        {isDinaArenden && <TodoList />}
      </Box>
      {tags.length !== 0 && (
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', flexWrap: 'wrap' }}>
          {tags.map((tag) => {
            return (
              <Chip
                variant="filled"
                key={`${tag.filterParameter}${tag.filterValue}`}
                data-tag={`${tag.filterParameter}${tag.filterValue}`}
                onDelete={() => removeFilterParameterValuePair(tag)} // eslint-disable-line react/jsx-no-bind
                label={tag.displayValue}
              />
            )
          })}
        </Box>
      )}
    </Box>
  )
}
export default MobileFilterUtilities
