import { SignalRRoom } from '@local/src/SignalR/signalR.types'
import { connectToSignalRRoom, disconnectFromSignalRRoom } from '@local/src/SignalR/signalRHelpers'
import { HubConnection } from '@microsoft/signalr'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useAppDispatch } from '@local/src/Store/configureStore'

import { fetchCase } from '../Case.actions'

interface Props {
  caseId: string
  signalRConnection: HubConnection
  roomName: SignalRRoom
}

const useConnectToSignalRRoom = ({ caseId, signalRConnection, roomName }: Props) => {

  const dispatch = useAppDispatch()

  useEffect(() => {
    !isNil(caseId) &&
      connectToSignalRRoom({
        connection: signalRConnection,
        room: roomName,
        onConnection: () => void dispatch(fetchCase({ caseId, reload: false })),
        groupName: caseId,
      })
    return () => {
      disconnectFromSignalRRoom({
        connection: signalRConnection,
        groupName: caseId,
        room: roomName,
      })
    }
  }, [])
}

export default useConnectToSignalRRoom
